import React, { useEffect, useState } from 'react'
import { instituteReportsService } from '../../../Services/ReportsService'
import { errorNotification } from '../../../Constants/Utils';
import moment from 'moment';
import { Card, DatePicker, Spin } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import EmptyComponent from '../../EmptyComponent/EmptyComponent';

const FinanceReports = () => {
  const [dates, setDates] = useState([null, null])
  const [responseData, setResponseData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleDateChange = (value) => {
    setDates(value);
  };

  console.log('responseData', responseData)

  const data = [
    { name: 'Paid Loan', value: responseData?.paidLoan, color: '#4caf50' },  // Green for paid loan
    { name: 'Remaining Loan', value: responseData?.remainingLoan, color: '#ff9800' }, // Orange for remaining loan
    { name: 'Total Loan Amount', value: responseData?.totalLoanAmount, color: '#f44336' } // Red for total loan amount
  ];

  const studentFeeData = [
    { name: 'Paid Fee', value: responseData?.paidStudentFeeAmount, color: '#4caf50' }, // Green for paid fee
    { name: 'Remaining Fee', value: responseData?.remainingStudentFeeAmount, color: '#ff9800' }, // Orange for remaining fee
    { name: 'Total Fee Amount', value: responseData?.totalStudentFeeAmount, color: '#f44336' }, // Red for total fee amount
  ];

  const expensesData = [
    { name: 'Paid Expenses', value: responseData?.totalExpensesPaid, color: '#4caf50' }, // Green for paid fee
    { name: 'Remaining Expenses', value: responseData?.totalExpensesUnPaid, color: '#ff9800' }, // Orange for remaining fee
    { name: 'Total Expenses', value: responseData?.totalExpenses, color: '#f44336' }, // Red for total fee amount
  ];

  const salaryData = [
    { name: 'Paid Salary', value: responseData?.totalSalaryPaid, color: '#4caf50' }, // Green for paid fee
    { name: 'Remaining Salary', value: responseData?.totalSalaryUnPaid, color: '#ff9800' }, // Orange for remaining fee
    { name: 'Total Salary To Pay', value: responseData?.totalSalaryToPay, color: '#f44336' }, // Red for total fee amount
  ];

  useEffect(() => {
    const startOfMonth = moment().startOf('month');
    const today = moment();  // End date: Today's date
    setDates([startOfMonth, today]);  // Set default start and end date
  }, []);

  useEffect(() => {
    setIsLoading(true)
    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const params = {
        startDate: startDate.format('YYYY-MM-DD 00:00:00'),
        endDate: endDate.format('YYYY-MM-DD 00:00:00')
      }
      instituteReportsService.getFinanceReports(params)
        .then(response => {
          console.log('response?.data', response?.data)
          setResponseData(response?.data)
          setIsLoading(false)
        })
        .catch(error => {
          console.log('error', error)
          setIsLoading(false)
        })
    }
  }, [dates])

  return (
      <main>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h1>Finance Reports</h1>
          <DatePicker.RangePicker
            value={dates}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
          />
        </div>
    
        {/* Loan Breakdown Section */}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ width: '50%', padding: "20px", margin: '0 auto' }}>
            <Card title="Loan Breakdown" bordered={false}>
              {data?.length > 0 ? (
                <PieChart width={400} height={400}>
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              ) : (
                <EmptyComponent />
              )}
            </Card>
          </div>
    
          {/* Student Fee Breakdown Section */}
          <div style={{ width: '50%', margin: '0 auto' }}>
            <Card title="Student Fee Breakdown" bordered={false}>
              <PieChart width={400} height={400}>
                <Pie
                  data={studentFeeData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  label
                >
                  {studentFeeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Card>
          </div>
        </div>
    
        {/* Expenses Breakdown Section */}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ width: '50%', padding: "20px", margin: '0 auto' }}>
            <Card title="Expenses Breakdown" bordered={false}>
              {expensesData?.length > 0 ? (
                <PieChart width={400} height={500}>
                  <Pie
                    data={expensesData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                    label
                  >
                    {expensesData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              ) : (
                <EmptyComponent />
              )}
            </Card>
          </div>
    
          {/* Salary Breakdown Section */}
          <div style={{ width: '50%', margin: '0 auto' }}>
            <Card title="Salary Breakdown" bordered={false}>
              <PieChart width={400} height={500}>
                <Pie
                  data={salaryData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  label
                >
                  {salaryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Card>
          </div>
        </div>
      </main>
    )
  
}

export default FinanceReports