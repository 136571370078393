import { CREATE_DAILY_NOTES, CREATE_DIARY_NOTE, CREATE_HOMEWORK, DELETE_DAILY_NOTES, DELETE_DIARY_NOTE, DELETE_TASK_ENDPOINT, GET_CLASS_HOMEWORK, GET_CLASS_HOMEWORK_ENDPOINT, GET_DAILY_NOTES, GET_DIARY_NOTE, UPDATE_DAILY_NOTES, UPDATE_DIARY_NOTE } from "../../src/Constants/Constants";
import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";

const getDailyNotes = (classId) => {
  return GetAPIRequest({
    url: GET_DAILY_NOTES.replace("{classId}", classId),
  });
};
const createDailyNotes = (payload) => {
  return PostAPIRequest({ url: CREATE_DAILY_NOTES, data: payload });
};
const updateDailyNotes = (dailyNotesId, payload) => {
  return PostAPIRequest({ url: UPDATE_DAILY_NOTES.replace("{dailyNotesId}", dailyNotesId), data: payload });
};
const deleteDailyNotes = (dailyNotesId) => {
  return DeleteAPIRequest({ url: DELETE_DAILY_NOTES.replace("{dailyNotesId}", dailyNotesId)});
};

const getDiaryNotes = (classId) => {
  return GetAPIRequest({
    url: GET_DIARY_NOTE.replace("{classId}", classId),
  });
};
const createDiaryNote = (payload) => {
  return PostAPIRequest({ url: CREATE_DIARY_NOTE, data: payload });
};
const updateDiaryNote = (diaryNoteId, payload) => {
  return PostAPIRequest({ url: UPDATE_DIARY_NOTE.replace("{diaryNoteId}", diaryNoteId), data: payload });
};
const deleteDiaryNote = (diaryNoteId) => {
  return DeleteAPIRequest({ url: DELETE_DIARY_NOTE.replace("{diaryNoteId}", diaryNoteId)});
};

const createHomework = (payload, params) => {
  return PostAPIRequest({
    url: CREATE_HOMEWORK,
    data: payload,
    params
  })
}

const getHomework = (id) => {
  return GetAPIRequest({
    url: GET_CLASS_HOMEWORK + id + GET_CLASS_HOMEWORK_ENDPOINT
  })
}

const deleteHomework = (id, params) => {
  return DeleteAPIRequest({
    url: GET_CLASS_HOMEWORK + id + DELETE_TASK_ENDPOINT,
    params
  })
}


export const StudyPlanService = {
  getDailyNotes,
  createDailyNotes,
  updateDailyNotes,
  deleteDailyNotes,
  getDiaryNotes,
  createDiaryNote,
  updateDiaryNote,
  deleteDiaryNote,
  createHomework,
  getHomework,
  deleteHomework
};
