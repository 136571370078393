
import { DeleteOutlined, EditOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import {  Button, Form, Input, Radio, Select, Space, Table, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./SupportService.scss";
import { supportService } from '../../Services/SupportService';
import  ExistingTickets  from './ExistingTickets';
import FormButton from '../Common/formButtons/FormButton';
import AddSupportTicket from './AddSupportTicket/AddSupportTicket';
import TicketDetails from './TicketDetails/TicketDetails';


const SupportService = () => {
const [modalDisplay, setModalDisplay] = useState(false)
const [editTicketData, setEditTicketData] = useState(null)
const [ticketsData, setTicketsData] = useState([])
const [viewData, setViewData] = useState(null)
const [loading, setLoading] = useState(false)

const navigate = useNavigate();


  useEffect(() => {
    ticketData()
  },[])

  const ticketData = () => {
    supportService.getTicket()
    .then(response =>{
      setTicketsData(response?.data)
      console.log('response', response)

    })
    .catch((error=>{
      console.log('error', error)
    }))
  }

  const handleEditTicket = (record) => {
    console.log('record', record)
    setModalDisplay(true)
    setEditTicketData(record)
  }

  const handleModal = () => {
    setModalDisplay(true)
    setEditTicketData(null)
  }

  const handleViewTicket = (item) => {
    setViewData(item);
    // setLoading(true);
    // supportService.viewTicket(id)
    // .then((response) => {
    //   sessionStorage.setItem("ticketData", JSON.stringify(response.data));
    //   navigate(`/ticketDetails/${item.id}`);
    //   setLoading(false);
    // })
    // .catch((error) => {
    //   console.log('error', error)
    // });
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Severity',
      dataIndex: "severity",
      key: 'severity',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text) => formatDate(text),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record, row) => (
        <>
          <Space size="middle">
            <EditOutlined onClick={() => handleEditTicket(record)} />
            <EyeOutlined onClick={() => handleViewTicket(record)} />
          </Space>
        </>
      ),
    },
  ];
    
  return (
    <>
        {viewData ? (
          <TicketDetails
            viewData = {viewData}
            setViewData = {setViewData}
          />
        ) : (
          <main>
            <div className="headingButtonContainer">
          <h1>Support Service</h1>
          <Button className="blue-button back-button" onClick={handleModal}>
            Add Support Ticket
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={ticketsData}
          // onChange={onTableChange}
          showSorterTooltip={false}
          pagination={false}
          rowKey={(record) => record.admissionId}
        />
        <AddSupportTicket
          modalDisplay = {modalDisplay}
          setModalDisplay = {setModalDisplay}
          ticketData = {ticketData}
          editTicketData = {editTicketData}
          setEditTicketData = {setEditTicketData}
        />
        </main>
        )}
    </>
  );
};

export default SupportService;
