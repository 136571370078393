import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { MdOutlineSupportAgent } from "react-icons/md";


const SupportChatIcon = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/service');
  };

  return (
    <>
    </>
    // <Button title="Support"
    //   type="primary"
    //   shape="circle"
    //   icon={<MdOutlineSupportAgent style={{fontSize:40}}/>}
    //   size="large"
    //   onClick={handleClick}
    //   style={{ position: 'fixed',height:"50px", width:"50px", bottom: 20,marginBottom: "20px", right: 20 ,color:"black",backgroundColor:"white",borderColor:'black',}}
    // />
    
  );
};

export default SupportChatIcon;
