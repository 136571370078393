import {
  CREATE_DEPARTMENT_URL,
  EDIT_DEPARTMENT_ENDPOINT,
  EDIT_DEPARTMENT_URL,
  DEPARTMENT_LIST_URL,
  DEPARTMENTS_LIST_FROM_S3,
  DELETE_DEPARTMENT,
  DELETE_DEPARTMENT_ENDPOINT,
} from "../../src/Constants/Constants";

import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";

const createDepartment = (payload) => {
  return PostAPIRequest({ url: CREATE_DEPARTMENT_URL, 
    data: payload 
  });
};

const editDepartment = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_DEPARTMENT_URL + id + EDIT_DEPARTMENT_ENDPOINT,
    data: payload,
  });
};

const getDepartments = (params) => {
  return GetAPIRequest({ url: DEPARTMENT_LIST_URL , params});
};

const departmentListFromS3 = () => {
  return GetAPIRequest({ url: DEPARTMENTS_LIST_FROM_S3 });
};

const deleteDepartment = (deptId) =>{
  return DeleteAPIRequest({
    url:DELETE_DEPARTMENT + deptId + DELETE_DEPARTMENT_ENDPOINT
  })
}
export const DepartmentService = {
  createDepartment,
  editDepartment,
  getDepartments,
  departmentListFromS3,
  deleteDepartment
};
