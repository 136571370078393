import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import config from "../Utils/Config";
export const studentsListApi = createApi({
  reducerPath: "studentsListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/instAdmin",
    // baseUrl: "http://localhost:8081/v1.0/instAdmin",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
        headers.set("Content-Type", "application/json");
      } else {
        window.location.href = `${config.loginUrl}`;
      }

      return headers;
    },
  }),
  tagTypes: ["Students"],
  endpoints: (builder) => ({
    getAllStudents: builder.query({
      query: (studentParams) => ({
        url: "/api/student/getAllStudents",
        method: "GET",
        params: studentParams,
      }),
      providesTags: ["Students"],
    }),
    createStudent: builder.mutation({
      query: (payload) => ({
        url: "/api/student/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Students"],
    }),
    editStudent: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/api/student/${id}/edit`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Students"],
    }),
    getStudentsById: builder.query({
      query: (studentId) => ({
        url: `/api/student/${studentId}/find`,
        method: "GET",
      }),
    }),
    getUploadExcelHistory: builder.query({
      query: () => ({
        url: `/api/student/student-upload-history`,
        method: "GET",
      }),
    }),
    deleteStudents: builder.query({
      query: (studentId) => ({
        url: `/api/student/${studentId}/deleteStudent`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllStudentsQuery,
  useCreateStudentMutation,
  useEditStudentMutation,
  useGetStudentsByIdQuery,
  useDeleteStudentsQuery,
  useGetUploadExcelHistoryQuery
} = studentsListApi;
