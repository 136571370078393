export const feeComponentsObject = [
    {
        id: 1,
        componentName: "Tuition Fee",
        parentFeeComponentResponse: {
            parentComponentId: null,
            parentComponentName: null,
        },
        childFeeComponentResponseList: [
            {
                ChildComponentId: 2,
                ChildComponentName: "Hostel Fee",
            },
            {
                ChildComponentId: 4,
                ChildComponentName: "Management Fee",
            },
        ],
        status: true,
    },
    {
        id: 2,
        componentName: "Hostel Fee",
        parentFeeComponentResponse: {
            parentComponentId: 1,
            parentComponentName: "Tuition Fee",
        },
        childFeeComponentResponseList: [],
        status: true,
    },
    {
        id: 3,
        componentName: "Transport Fee",
        childFeeComponentResponseList: [],
        parentFeeComponentResponse: {
            parentComponentId: null,
            parentComponentName: null,
        },
        status: false,
    },
    {
        id: 4,
        componentName: "Management Fee",
        parentFeeComponentResponse: {
            parentComponentId: 1,
            parentComponentName: "Tuition Fee",
        },
        childFeeComponentResponseList: [],
        status: false,
    },
];
export const classFeeComponentsObject = [
    {
        id: 1,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[1].componentName,
        amount: 20000,
    },
    {
        id: 2,
        feeComponentId: 3,
        feeComponentName: feeComponentsObject[2].componentName,
        amount: 25000,
    },
    {
        id: 3,
        feeComponentId: 4,
        feeComponentName: feeComponentsObject[3].componentName,
        amount: 30000,
    },
]
export const classFeeComponentsData = {
    noOfInstallments: 4,
    classFeeComponents: classFeeComponentsObject,
}

const installmentPlanDetails = [
    {
        id: 1,
        planName: "June",
        installmentNo: 1,
        amount: 5000,
        dueDate: "19/07/2024",
    },
    {
        id: 2,
        planName: "August",
        installmentNo: 2,
        amount: 500,
        dueDate: "19/09/2024",
    },
    {
        id: 3,
        planName: "October",
        installmentNo: 3,
        amount: 5000,
        dueDate: "19/11/2024",
    },
    {
        id: 4,
        planName: "December",
        installmentNo: 4,
        amount: 10000,
        dueDate: "19/01/2025",
    },
]
export const installmentPlans = {
    noOfInstallments: 4,
    installmentPlans: installmentPlanDetails,
}
export const installmentsMaster = [
    {
        id: 1,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[1]?.componentName,
        installmentPlanId: 1,
        installmentPlanDetails: installmentPlanDetails?.[0],
        amount: 2000,
    },
    {
        id: 2,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[1]?.componentName,
        installmentPlanId: 2,
        installmentPlanDetails: installmentPlanDetails?.[1],
        amount: 4000,
    },
    {
        id: 3,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[1]?.componentName,
        installmentPlanId: 3,
        installmentPlanDetails: installmentPlanDetails?.[2],
        amount: 2500,
    },
    {
        id: 4,
        feeComponentId: 3,
        feeComponentName: feeComponentsObject[2]?.componentName,
        installmentPlanId: 1,
        installmentPlanDetails: installmentPlanDetails?.[0],
        amount: 500,
    },
    {
        id: 5,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[2]?.componentName,
        installmentPlanId: 2,
        installmentPlanDetails: installmentPlanDetails[1],
        amount: 1000,
    },
]

export const lrPayments = [
    {
        id: 1,
        feeComponentId: 2,
        feeComponentName: feeComponentsObject[1]?.componentName,
        amount: 10000,
        recurringNumber: 2,
    },
    {
        id: 2,
        feeComponentId: 3,
        feeComponentName: feeComponentsObject[2]?.componentName,
        amount: 5500,
        recurringNumber: 1,
    },
    {
        id: 3,
        feeComponentId: 4,
        feeComponentName: feeComponentsObject[3]?.componentName,
        amount: 7000,
        recurringNumber: 2,
    },
]
export const lrInstallments = [
    {
        id: 1,
        lrPaymentId: 1,
        lrPaymentComponentName: feeComponentsObject[1].componentName,
        lrPaymentComponentId: feeComponentsObject[1].id,
        recurringNumber: 2,
        amount: 6000,
        dueDate: "19/11/2024",
    },
    {
        id: 2,
        lrPaymentId: 1,
        lrPaymentComponentName: feeComponentsObject[1].componentName,
        lrPaymentComponentId: feeComponentsObject[1].id,
        recurringNumber: 2,
        amount: 4000,
        dueDate: "19/12/2024",
    },
    {
        id: 3,
        lrPaymentId: 2,
        lrPaymentComponentName: feeComponentsObject[2].componentName,
        lrPaymentComponentId: feeComponentsObject[2].id,
        recurringNumber: 1,
        amount: 5500,
        dueDate: "19/11/2024",
    },
    {
        id: 4,
        lrPaymentId: 3,
        lrPaymentComponentName: feeComponentsObject[3].componentName,
        lrPaymentComponentId: feeComponentsObject[3].id,
        recurringNumber: 2,
        amount: 3500,
        dueDate: "19/11/2024",
    },
];

export const feeDiscounts = [
    {
        id: 1,
        discountValue: 2000,
        discountType: "Discount Type 1",
        eligibilityCriteria: "Sibling Discount",
    },
    {
        id: 2,
        discountValue: 1200,
        discountType: "Discount Type 2",
        eligibilityCriteria: "Academic Performence",
    },
]



export const studentsFeeConfig = [
    {
        id: 1,
        studentId: 1,
        studentName: "Jithendra Bandi",
        installmentPlanId: installmentPlanDetails[0].id,
        installmentPlanName: installmentPlanDetails[0].planName,
        installmentPlanNo: installmentPlanDetails[0].installmentNo,
    },
    {
        id: 2,
        studentId: 2,
        studentName: "Tilak Sibbala",
        installmentPlanId: installmentPlanDetails[0].id,
        installmentPlanName: installmentPlanDetails[0].planName,
        installmentPlanNo: installmentPlanDetails[0].installmentNo,
    },
    {
        id: 3,
        studentId: 3,
        studentName: "Krishna Koraganji",
        installmentPlanId: installmentPlanDetails[0].id,
        installmentPlanName: installmentPlanDetails[0].planName,
        installmentPlanNo: installmentPlanDetails[0].installmentNo,
    },
    {
        id: 4,
        studentId: 1,
        studentName: "Jithendra Bandi",
        installmentPlanId: installmentPlanDetails[1].id,
        installmentPlanName: installmentPlanDetails[1].planName,
        installmentPlanNo: installmentPlanDetails[1].installmentNo,
    },
    {
        id: 5,
        studentId: 2,
        studentName: "Tilak Sibbala",
        installmentPlanId: installmentPlanDetails[1].id,
        installmentPlanName: installmentPlanDetails[1].planName,
        installmentPlanNo: installmentPlanDetails[1].installmentNo,
    },
    {
        id: 6,
        studentId: 1,
        studentName: "Jithendra Bandi",
        installmentPlanId: installmentPlanDetails[2].id,
        installmentPlanName: installmentPlanDetails[2].planName,
        installmentPlanNo: installmentPlanDetails[2].installmentNo,
    },
]