import { Button, Col, DatePicker, Form, Modal, notification, Row, Select, Space, Upload } from "antd";
import InputFloatLabel from "../Common/FloatLabel/Input";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import _ from "lodash";
import dayjs from "dayjs";
import { CheckCircleFilled, DislikeOutlined, UploadOutlined } from "@ant-design/icons";
import { StudyPlanService } from "../../Services/StudyPlanService";
import { dynamicErrorMsg } from "../../Constants/Utils";
const AddHomeworkModal = ({
    homeworkModal,
    setHomeworkModal,
    selectedSection,
    selectedSubject,
    record,
    getHomeworks
}) => {
  const [homeworkForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  console.log('selectedSubject', selectedSubject)
  
  const handleCancel = () => {
    setHomeworkModal(false);
    homeworkForm.resetFields();
  };

  useEffect(() => {

    if (record?.subjectId) {
      homeworkForm.setFieldsValue({
        // className: classWithAssignment[0]?.className,
        // date: givenDates,
        subject: record?.subject,
        classId: selectedSection?.classId,
        task: record?.task,
      });
    } else {
      homeworkForm.resetFields();
    }
  }, [record, homeworkForm]);

  const onFinish = (values) => {
    const dateObject = new Date(values?.date);
    const today = dateObject.toISOString().split("T")[0];
    const dayOfWeek = dateObject.getDay();
    const formData = new FormData();
    formData.append("staffId", selectedSubject?.staffId);
    formData.append("classId", selectedSection?.classId);
    formData.append("subjectId", selectedSubject?.subjectId);
    formData.append("task", values?.task);
    fileList.map((file, i) => {
      formData.append(`files`, file);
    });
    // const payload = {
    //   ...values,
    //   staffId: selectedStaff
    // }
    const params = {
      date: today
    }
    if (record) {

    }
    else {
      StudyPlanService.createHomework(formData, params)
        .then(response => {
          notification.success({
            message: "Homework Created Successfully",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          getHomeworks();
          handleCancel();

        }).catch(error => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Homework")
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        })
    }
  };
  const handleUpload = ({ fileList }) => {
    const modifiedFiles = [];
    fileList?.forEach(file => modifiedFiles.push(file.originFileObj));
    setFileList(modifiedFiles);
  };

  return (
    <Modal
      title={record ? "Edit Homework " : "Create Homework"}
      open={homeworkModal}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={homeworkForm} onFinish={onFinish}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} label="Date" />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" span={12}>
            <Form.Item
              // name="className"
              rules={[{ required: true, message: "" }]}
            >
              <SelectFloatLabel
                dropdownStyle={{ height: "200px" }}
                options={classOptions}
                onChange={handleClassDropdown}
                label="Select Class"
                name="className"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              rules={[{ required: true, message: "" }]}
              name="classId"
            >
              <SelectFloatLabel
                onChange={handleSectionChange}
                value={selectedSection}
                options={sectionOptions}
                // name="section"
                label="Select Section"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="subjectId" rules={[{ required: true, message: "" }]}>
              <Select
                value={selectedSubject}
                onChange={handleSubject}
                placeholder="Subject"
              >
                {subjectOptions?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <h1>{defaultSubject}</h1>
          </Col> */}
          <Col span={24}>
            <Form.Item name="task" rules={[{ required: true, message: "" }]}>
              <InputFloatLabel label="Enter Task" type="text" name="task" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="homeworkFiles">
              <Upload
                onChange={handleUpload}
                type="file"
                accept=".png, .jpg, .jpeg"
                showUploadList={true}
                beforeUpload={() => false}
              >
                <Button
                  icon={<UploadOutlined />}
                  className="blue-button"
                >
                  Upload Homework
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} align="end">
            <Space direction="vertical" size="large">
              <Button className="blue-button" type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12} size="large">
            <Space direction="vertical" size="large">
              <Button className="newCancelButton " onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default AddHomeworkModal;
