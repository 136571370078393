import React, { useEffect, useState } from "react";
import { UPCOMING } from "../../../Constants/StringConstants";
import { examService } from "../../../Services/ExamService";
import FloatSelect from "../../Common/FloatLabel/Select";
import { Button, DatePicker, Input, Select, Table, TimePicker, TreeSelect } from "antd";
import "./ExamSchedule.scss";
import dayjs from "dayjs";
import {
  DateConverter,
  TimeConverter,
  YearMonthDate,
} from "../../../Utils/DateConverter";
import moment from "moment";
import {
  dynamicErrorMsg,
  errorNotification,
  inputHtmlTypeCheck,
  successNotification,
} from "../../../Constants/Utils";
import FloatInput from "../../Common/FloatLabel/InputDecimal";
import { ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CreateExam from "../createExam";
import { SubjectService } from "../../../Services/SubjectService";
import { getClassList } from "../../../store/classSlice";
import { useSelector } from "react-redux";

const { Option } = Select;

const ExamSchedule = () => {
  const [examDropDownData, setExamDropDownData] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [examModal, setExamModal] = useState(false);
  const [editExamData, setEditExamData] = useState(null);
  const [inputValues, setInputValues] = useState([]);
  const [classIdsWithSubject, setClassIdsWithSubject] = useState([]);
  const [dateValidates, setDateValidates] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [classTableData, setClassTableData] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  const classList = useSelector(getClassList);

  const handleClassChange = (value) => {
    setSelectedSections(value);
  };

  
  const transformData = (data) => {
    const transformedData = [];
    const classesMap = new Map();
  
    data.forEach((item) => {
      const { classId, className, section } = item;
      if (!classesMap.has(className)) {
        classesMap.set(className, []);
      }
      classesMap.get(className).push({ value: classId, label: section });
    });
  
    classesMap.forEach((sections, className) => {
      const classIds = dataSource
        ?.filter(eachSubject => eachSubject?.subjectId === selectedSubject)
        .flatMap(eachSubject => Array.isArray(eachSubject?.classDetails) ? 
          eachSubject?.classDetails?.map(eachClass => eachClass?.classId) : []
        );
  
      const classDisabled = !sections?.some((section) =>
        classIds?.includes(section?.value)
      );
  
      let sectionList = sections?.map((item, index) => {
        const disabled = !classIds?.includes(item?.value);
        return {
          label: `${className} - ${item.label}`,
          value: item.value,
          disabled,
        };
      });
  
      transformedData.push({
        value: className,
        label: className,
        children: sectionList,
        disabled: classDisabled,
      });
    });
    return transformedData;
  };
  

    const handleRemoveClass = (className) => {
        const updatedSections = selectedSections?.filter(sectionId => className?.classId !== sectionId)
        setSelectedSections(updatedSections)
    }

    const getClassName = () => {
        const classNames = dataSource
          ?.filter(eachSubject => eachSubject?.subjectId === selectedSubject)
          .flatMap(eachSubject =>
            eachSubject?.classDetails?.filter(eachClass =>
              selectedSections?.includes(eachClass?.classId)
            ) || []
          );
      
        return (
          <>
            {classNames?.map((className, index) => (
                <div className="classNameContainer">
                    <p className="paragraph" key={index}>{className?.className}</p>
                    <CloseOutlined onClick={() => handleRemoveClass(className)} style={{ fontSize: '12px' }}/>
                </div>

            ))}
          </>
        );
      };
      

  const treeData = transformData(classList);

  useEffect(() => {
    getExamTimetableData();
  }, []);

  const getExamTimetableData = () => {
    examService.listExams().then((response) => {
      const unScheduledExams = response.data[UPCOMING].filter(
        (exam) => !exam.schedule
      );
      setExamDropDownData(unScheduledExams);
      setSelectedExam(unScheduledExams[0]?.examId || null);
    });
  };

  const handleSelectedExam = (value) => {
    setSelectedExam(value);
    setSelectedSections([])
  };

  useEffect(() => {
    if (selectedExam) {
      getExamDetails();
      setSelectedSections([]);
    }
  }, [selectedExam]);

  const getExamDetails = () => {
    const params = {
      examId: selectedExam,
    };
    examService
      .getExamDetails(params)
      .then((res) => {
        setDateValidates({
          startDate: res.data?.startDate.split("T")[0],
          endDate: res.data?.endDate.split("T")[0],
        });
        const data = res.data?.uniqueExamDetails?.map((subject) => ({
          key: subject?.subjectId,
          subjectId: subject?.subjectId,
          subjectName: subject?.subjectName,
          classDetails: subject?.classDetails,
        }));
        setDataSource(data);
        setSelectedSubject(data[0]?.subjectId);
        setClassTableData([{
            cutOffMarks: "",
            totalMarks: "",
            durationInMin: "",
            examDate: null,
            examTime: null,
          }])
        setInputValues(
          [{
            cutOffMarks: "",
            totalMarks: "",
            durationInMin: "",
            examDate: null,
            examTime: null,
          }]
        )
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const saveExamSchedule = () => {
    if (!selectedSections.length > 0){
      return errorNotification("Please select a class and section.")
    }
    const classDetails = selectedSections.map(sectionId => ({
        ...inputValues[0], classId: sectionId,
        examDate: `${inputValues[0]?.examDate} ${inputValues[0]?.examTime}`,
    }))
    const payload = {
      examId: selectedExam,
      subjectId: selectedSubject,
      classDetails: classDetails
    };
    examService
      .multipleClassExamSchedule(payload)
      .then((res) => {
        successNotification("Exam Scheduled Successfully");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleChange = (index, field, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index][field] = value;
    setInputValues(updatedValues);
  };

  const handleDateChange = (index, date) => {
    const formattedDate = date ? YearMonthDate(date) : null;
    handleChange(index, "examDate", formattedDate);
  };

  const handleTimeChange = (index, time) => {
    const formattedTime = time ? TimeConverter(time) : null;
    console.log("formattedTime", formattedTime);
    handleChange(index, "examTime", formattedTime);
  };

  const disabledDate = (current) => {
    // Parse startDate and endDate as Date objects
    const startDateObject = moment(dateValidates?.startDate, "YYYY-MM-DD").startOf('day').toDate();
    const endDateObject = moment(dateValidates?.endDate, "YYYY-MM-DD").endOf('day').toDate();
  
    const currentDate = current && current.valueOf();
  
    // Check if the current date is within the disabled range
    return currentDate < startDateObject.valueOf() || currentDate > endDateObject.valueOf();
  };
  const handleSelectedSubject = (value) => {
    setSelectedSubject(value);
    setSelectedSections([])
    setClassTableData([{
        cutOffMarks: "",
        totalMarks: "",
        durationInMin: "",
        examDate: null,
        examTime: null,
      }])
    // setClassTableData(selectedSubjectDetails?.classDetails);
    setInputValues(
      [{
        cutOffMarks: "",
        totalMarks: "",
        durationInMin: "",
        examDate: null,
        examTime: null,
      }]
    );
  };

  useEffect(() => {
    getClassIdsWithSubjects();
  }, []);
  const getClassIdsWithSubjects = () => {
    SubjectService.subjectClassIds()
      .then((response) => setClassIdsWithSubject(response?.data))
      .catch((error) => {});
  };

  const columns = [
    {
      title: "Exam Date",
      key: "examDate",
      render: (text, record, index) => (
        <DatePicker
          value={
            inputValues[index]?.examDate
              ? moment(inputValues[index]?.examDate, "YYYY-MM-DD")
              : null
          }
          onChange={(date) => handleDateChange(index, date)}
          style={{ width: "100%" }}
          disabledDate={disabledDate}
        />
      ),
    },
    {
      title: "Exam Start Time",
      key: "examStartTime",
      render: (text, record, index) => (
        <TimePicker
          format="HH:mm"
          minuteStep={10}
          value={
            inputValues[index]?.examTime
              ? moment(inputValues[index]?.examTime, "HH:mm:ss")
              : null
          }
          onChange={(time) => handleTimeChange(index, time)}
          placeholder="Select Time"
          inputReadOnly={false}
          showNow={false}
          use12Hours={false}
        />
      ),
    },
    {
      title: "Exam Duration (In Minutes)",
      key: "durationInMin",
      render: (text, record, index) => (
        <FloatInput
          type={inputHtmlTypeCheck.MARKS}
          value={inputValues[index]?.durationInMin}
          onChange={(e) => handleChange(index, "durationInMin", e.target.value)}
          label="Duration In Minutes"
        />
      ),
    },
    {
      title: "Total Marks",
      key: "totalMarks",
      render: (text, record, index) => (
        <FloatInput
          type={inputHtmlTypeCheck.MARKS}
          value={inputValues[index]?.totalMarks}
          onChange={(e) => handleChange(index, "totalMarks", e.target.value)}
          label="Total Marks"
        />
      ),
    },
    {
      title: "Cut Off Marks",
      key: "cutOffMarks",
      render: (text, record, index) => (
        <FloatInput
          type={inputHtmlTypeCheck.MARKS}
          value={inputValues[index]?.cutOffMarks}
          onChange={(e) => handleChange(index, "cutOffMarks", e.target.value)}
          label="Cut Off Marks"
        />
      ),
    },
  ];

  const createExamBtn = () => {
    setExamModal(true);
  };
  
  return (
    <main>
      <div className="exam-dropdown-container">
        <h1>Exam Schedule</h1>

        <div className="exam-dropdown-container">
          <>
            <FloatSelect
              value={selectedExam}
              onChange={handleSelectedExam}
              className="option-dropdown"
              label="Select Exam"
            //   style={{marginRight: "20px"}}
            >
              {examDropDownData?.map((exam) => (
                <Option key={exam?.examId} value={exam?.examId}>
                  {exam?.title}
                </Option>
              ))}
            </FloatSelect>
            <FloatSelect
              value={selectedSubject}
              onChange={handleSelectedSubject}
              label="Selected Subject"
              className="option-dropdown"
            >
              {dataSource?.map((subject) => (
                <Option key={subject?.subjectId} value={subject?.subjectId}>
                  {subject?.subjectName}
                </Option>
              ))}
            </FloatSelect>
            <TreeSelect
                treeData={treeData}
                placeholder="Select class and section"
                onChange={handleClassChange}
                value={selectedSections}
                treeCheckable
                multiple
                style={{ width: '250px' }}
                dropdownStyle={{ maxHeight: 400, overflowY: 'auto' }}
                maxTagCount={2}
                maxTagTextLength={10} 
                tokenSeparators={[',']}
            />
            <Button id="Add_buton" onClick={createExamBtn}>
              <PlusOutlined style={{fontSize: "16x"}}/>
            </Button>
          </>
        </div>
      </div>
      {selectedSections.length > 0 && (
        <div className="classDetails">
            {getClassName()}
        </div>
      )}
      <Table dataSource={classTableData} columns={columns} pagination={false} />
      <div className="save-button">
        <Button className="blue-button" onClick={saveExamSchedule}>
          Save
        </Button>
      </div>
      <CreateExam
        examModal={examModal}
        setExamModal={setExamModal}
        setEditExamData={setEditExamData}
        classIdsWithSubject={classIdsWithSubject}
        getExamTimetableData={getExamTimetableData}
      />
    </main>
  );
};

export default ExamSchedule;
