import { Col, Form, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import FloatDatePicker from "../../Common/FloatLabel/Datepicker";
import { dynamicErrorMsg, errorNotification, lenderTypes, loanStatusValues, loanTypes, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import moment from 'moment';

const LoanModal = ({
    loanModal,
    setLoanModal,
    record,
    setRecord,
    getLoansData,
    lendersData,
}) => {
    const [loanForm] = Form.useForm();

    useEffect(() => {
        if (record) {
            const lenderIds = record?.lenderResponseDTOS?.map(lender => lender?.id);
            loanForm.setFieldsValue({
                loanAmount: record?.loanAmount,
                loanType: record?.loanType,
                status: record?.status,
                lenderIds: lenderIds,
                // takenDate: record?.takenDate ? moment(record?.takenDate, 'DD-MM-YYYY') : null,
            })
        }
    }, [])
    const handleCancel = () => {
        setLoanModal(false);
        setRecord(null);
    }
    const onFinish = values => {
        if (record) {
            AccountsService.editLoan(record?.id, values)
                .then(response => {
                    successNotification("Loan Updated Successfully.");
                    handleCancel();
                    getLoansData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Loan details"));
                })
        }
        else {
            AccountsService.createLoan(values)
                .then(response => {
                    successNotification("Loan Created Successfully.");
                    handleCancel();
                    getLoansData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Loan details"));
                })
        }
    }
    return (
        <Modal
            title={record ? "Edit Loan" : "Add Loan"}
            open={loanModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={loanForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="lenderIds"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={lendersData?.map(lender => ({ label: lender?.lenderName, value: lender?.id }))}
                                label="Lenders"
                                mode="multiple"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanAmount"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatInput
                                type="number"
                                label="Amount"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanType"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loanTypes?.map(type => ({ label: type, value: type }))}
                                label="Loan Type"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    {/* <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loanStatusValues?.map(status => ({ label: status, value: status }))}
                                label="Status"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col> */}
                    {/* <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="takenDate"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatDatePicker 
                                label="Date"
                            />
                        </Form.Item>
                    </Col> */}
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default LoanModal