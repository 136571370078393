import { createSlice } from "@reduxjs/toolkit";
import { ClassService } from "../Services/ClassService";

const classInitialState = {
  classList: JSON.parse(sessionStorage.getItem("classlist")) || [],
};

export const fetchClasses = () => {
  return async (dispatch) => {
    try {
      const response = await ClassService.getClasses();
      const data = response.data.data;
      if (data.length > 0) {
        dispatch(setClassList(data));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

const Class = createSlice({
  name: "Class",
  initialState: classInitialState,
  reducers: {
    setClassList: (state, action) => {
      state.classList = action.payload;
      sessionStorage.setItem("classlist", JSON.stringify(action.payload));
    },
    addClass: (state, action) => {
      state.classList.push(action.payload);
      sessionStorage.setItem("classlist", JSON.stringify(state.classList));
    },
    editClass: (state, action) => {
      const { classId, data } = action.payload;
      const index = state?.classList?.findIndex((c) => c.classId === classId);
      if (index !== -1) {
        state.classList[index] = {
          ...state.classList[index],
          ...data,
        };
        sessionStorage.setItem("classlist", JSON.stringify(state.classList));
      }
    },
    deleteClass: (state, action) => {
      const id = action.payload;
      state.classList = state?.classList?.filter((c) => c.classId !== id);
      sessionStorage.setItem("classlist", JSON.stringify(state.classList));
    },
  },
});

export const getClassList = (state) => state?.Class?.classList || [];

export const { setClassList, addClass, editClass, deleteClass } = Class.actions;

export default Class.reducer;
