import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from "antd";
import HostelService from "../../../../Services/HostelService";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import DatepickerFloatLabel from "../../../Common/FloatLabel/Datepicker";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import { Option } from "antd/lib/mentions";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import moment from "moment";
import { cancelConfirmModal } from "../../../../Constants/Utils";
const ExpenseModal = ({
  isModalOpen,
  setIsModalOpen,
  editRow,
  seteditRow,
  getExpenseList,
}) => {
  const [expenseForm] = Form.useForm();
  const [typeList, settypeList] = useState([]);

  const expenseCategoryList = () => {
    HostelService.getKitchenItems().then((res) => {
      settypeList(res?.data);
    });
  };
  useEffect(() => {
    expenseCategoryList();
  }, []);
  useEffect(() => {
    if (editRow?.expensesId) {
      const updatedDate =
        editRow?.date === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(editRow?.date, "YYYY-MM-DD");
      expenseForm.setFieldsValue({
        ...editRow,
        date: updatedDate,
      });
    } else {
      expenseForm.resetFields();
    }
  }, [editRow]);

  console.log("edit", editRow);

  const onFinisher = (values) => {
    console.log(values);
    const updatedDate = moment(values.date)?.format("YYYY-MM-DD");
    const payload = {
      ...values,
      date: updatedDate,
    };

    if (editRow?.expensesId) {
      HostelService.editKitchenExpenses(editRow?.expensesId, payload)
        .then((response) => {
          getExpenseList();
          seteditRow(null);
          setIsModalOpen(false);
          notification.success({
            message: `Expense Updated Sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description:
              "An error occurred while Updating the Expense, try again later.",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    } else {
      HostelService.createKitchenExpenses(payload)
        .then((response) => {
          notification.success({
            message: `Expense Created Sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          getExpenseList();
          cancelForm();
          expenseForm.resetFields();
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description:
              "An error occurred while Creating the Expense, try again later.",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    }
  };

  const cancelForm = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    cancelConfirmModal({cancelForm})
  };

  return (
    <>
      <Modal
        title={editRow ? "Edit Expense " : "Create Expense"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <Form form={expenseForm} onFinish={onFinisher} autoComplete="off">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name="itemId"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel label="Select Item" name="itemId">
                  {typeList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.itemsName}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="quantity"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Quantity" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatepickerFloatLabel style={{ width: "100%" }} label="Date" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="totalAmount"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Total Amount" type="number" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="frequency"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Frequency" name="frequency">
                  <Option value="daily">Daily</Option>
                  <Option value="weekly">Weekly</Option>
                  <Option value="monthly">Monthly</Option>
                  <Option value="frequently">Frequently</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Status" name="status">
                  <Option value="paid">Paid</Option>
                  <Option value="unpaid">Un Paid</Option>
                  <Option value="pending">Pending</Option>
                  <Option value="declined">Declined</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="description">
                <InputFloatLabel
                  label="Description"
                  type="text"
                  name="description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default ExpenseModal;
