import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import EmptyComponent from "../../EmptyComponent/EmptyComponent.jsx";
import { useEffect, useState, useRef } from "react";
import { Button, Spin, Card } from "antd";
import { classCustomOrder, customOrder, handleExport } from "../../../Constants/Utils";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

am4core.useTheme(am4themes_animated);

var chart = null;

const AdmissionColumnChart = ({ chartId, admissionsData, activeYear }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Refs to store individual chart instances
  const chartRefs = useRef({
    enquiries: null,
    admissions: null,
    totalStrength: null, // Modified to represent totalStrength chart that includes occupiedStrength
    admissionFee: null,
  });

  const parseData = (metric) => {
    
    const sortedData = admissionsData
      .map((item) => {
        // Only include class name for "enquiries" and "admissions" charts
        let category;
        if (metric === "enquiries" || metric === "admissions") {
          category = item.className; // Only use class name, no section
        } else {
          category = item.className + "-" + item.section; 
        }
  
        return {
          category: category,
          value: item[metric], // Dynamic field based on metric
        };
      })
      .sort((a, b) => {
        const indexA = customOrder.indexOf(a.category.split('-')[0]); // Split to get class name
        const indexB = customOrder.indexOf(b.category.split('-')[0]); // Split to get class name
        return indexA - indexB; // Sort by custom order
      });
  
    console.log('sortedData', sortedData);
    return sortedData;
  };

  useEffect(() => {
    if (admissionsData?.length > 0) {
      // Load individual charts for each metric immediately
      loadChart("enquiries", `${chartId}-enquiries`);
      loadChart("admissions", `${chartId}-admissions`);
      loadChart("admissionFee", `${chartId}-admissionFee`);
      loadTotalStrengthChart(`${chartId}-totalStrength`); // Load the combined total strength chart
    }
  }, [admissionsData, chartId]);

  // Function to load individual chart for each metric
  const loadChart = (metric, chartContainerId) => {
    // Create a chart for each metric
    let chart = am4core.create(chartContainerId, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.colors.list = [am4core.color("#ED914A")]; // Set the color for the chart

    chart.data = parseData(metric); // Pass the relevant data for the metric
    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    // Set up category axis (X-axis)
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var labelTemplate = categoryAxis.renderer.labels.template;
    labelTemplate.wrap = true;
    labelTemplate.maxWidth = 100;

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        if (cellWidth > 30 && cellWidth < 100) {
          axis.renderer.labels.template.rotation = -45;
          axis.renderer.labels.template.maxWidth = 100;
        } else if (cellWidth > 100) {
          axis.renderer.labels.template.rotation = 0;
          axis.renderer.labels.template.wrap = true;
          axis.renderer.labels.template.maxWidth = 100;
        } else {
          axis.renderer.labels.template.rotation = -45;
          axis.renderer.labels.template.maxWidth = 160;
        }

        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";

        axis.renderer.labels.template.fontSize = 14;
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    // Set up value axis (Y-axis)
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Set up series for the current metric
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(80);
    series.columns.template.tooltipText = "{name}: {valueY}";
    series.name = metric;
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = "value";

    series.tooltip.pointerOrientation = "vertical";
    series.columns.template.column.cornerRadiusTopLeft = 0;
    series.columns.template.column.cornerRadiusTopRight = 0;

    var bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = 45;
    bullet.label.text = "{valueY}";
    bullet.label.fill = am4core.color("#ffffff");

    chartRefs.current[metric] = chart;
  };

  const loadTotalStrengthChart = (chartContainerId) => {
    if (chart) chart.dispose();
    chart = am4core.create(chartContainerId, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.colors.list = [am4core.color("#ED914A"), am4core.color("#316883")];

    chart.data = admissionsData
        .map((item) => ({
            category: item.className + "-" + item.section,
            value1: item.occupiedCapacity,
            value2: item.totalCapacity,
        }))
        .sort((a, b) => {
            const aCategoryPrefix = a.category.split('-')[0];
            const bCategoryPrefix = b.category.split('-')[0];
            return customOrder.indexOf(aCategoryPrefix) - customOrder.indexOf(bCategoryPrefix);
        });

    console.log('Sorted chart.data', chart.data);
    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var labelTemplate = categoryAxis.renderer.labels.template;
    labelTemplate.wrap = true;
    labelTemplate.maxWidth = 100;

    categoryAxis.events.on("sizechanged", function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        if (cellWidth > 30 && cellWidth < 100) {
          axis.renderer.labels.template.rotation = -45;
          axis.renderer.labels.template.maxWidth = 100;
        } else if (cellWidth > 100) {
          axis.renderer.labels.template.rotation = 0;
          axis.renderer.labels.template.wrap = true;
          axis.renderer.labels.template.maxWidth = 100;
        } else {
          axis.renderer.labels.template.rotation = -45;
          axis.renderer.labels.template.maxWidth = 160;
        }

        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";

        axis.renderer.labels.template.fontSize = 14;
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText = "{name}: {valueY}";

    series1.name = "Occupied Strength";
    series1.dataFields.categoryX = "category";
    series1.dataFields.valueY = "value1";

    series1.stacked = true;
    series1.tooltip.pointerOrientation = "vertical";
    series1.tooltip.label.adapter.add("text", function (text, target) {
      if (target.dataItem && target.dataItem.valueY == 0) {
        return "";
      } else {
        return text;
      }
    });
    series1.columns.template.column.cornerRadiusTopLeft = 0;
    series1.columns.template.column.cornerRadiusTopRight = 0;
    // var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    // bullet1.interactionsEnabled = false;
    // bullet1.label.text = "{valueY}";
    // bullet1.label.fill = am4core.color("#ffffff");
    // bullet1.locationY = 0.5;

    var bullet = series1.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = 45;
    bullet.label.text = "{valueY}";
    bullet.label.fill = am4core.color("#ffffff");

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);
    series2.columns.template.tooltipText = "{name}: {valueY}";
    series2.name = "Total Stregth";
    series2.dataFields.categoryX = "category";
    series2.dataFields.valueY = "value2";

    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";

    series2.columns.template.column.cornerRadiusTopLeft = 10;
    series2.columns.template.column.cornerRadiusTopRight = 10;

    series2.tooltip.label.adapter.add("text", function (text, target) {
      if (target.dataItem && target.dataItem.valueY == 0) {
        return "";
      } else {
        return text;
      }
    });

    var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{valueY}";
    bullet2.locationY = 0.5;
    bullet2.label.fill = am4core.color("#ffffff");

    // Need to check this later
    chart.legend.itemContainers.template.events.on("hit", function (ev) {
      let seriesColumn = ev.target.dataItem.name;
      if (seriesColumn === "Available Seats") {
        series1.columns.template.column.cornerRadiusTopLeft = 10;
        series1.columns.template.column.cornerRadiusTopRight = 10;
      } else {
        series1.columns.template.column.cornerRadiusTopLeft = 0;
        series1.columns.template.column.cornerRadiusTopRight = 0;
      }
    });
  };

  const handleDownload = () => {
    // Export each chart individually
    Object.keys(chartRefs.current).forEach((metric) => {
      const chart = chartRefs.current[metric];
      if (chart) {
        handleExport(chart, metric); // Make sure handleExport can accept the chart instance
      }
    });
  };

  return (
    <div className="column-chart">
      <Spin spinning={loading}>
        <div
          className="header-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h1 className="admission-heading">
            Admissions for the academic year of {activeYear?.year}
          </h1>
          {admissionsData?.length === 0 ? null : (
            <Button
              style={{ backgroundColor: "#172b4d", color: "white" }}
              onClick={handleDownload}
            >
              <DownloadOutlined />
            </Button>
          )}
        </div>

        {admissionsData?.length === 0 ? (
          <>
          <div className="flex-between">
            <h1 className="color-red">Please configure required classes for Dashboard overview...</h1>
            <Button className="blue-button" onClick={() => navigate("/class-details")}>Configure Classes</Button>
          </div>
            <EmptyComponent />
          </>
        ) : (
          <div>
            {/* Render individual charts */}
            <Card
              title="Enquiries"
              bordered={false}
              style={{
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px"
              }}
            >
              <div id={`${chartId}-enquiries`} style={{ width: "100%", height: "400px" }}></div>
            </Card>

            <Card
              title="Admissions"
              bordered={false}
              style={{
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px"
              }}
            >
              <div id={`${chartId}-admissions`} style={{ width: "100%", height: "400px" }}></div>
            </Card>

            <Card
              title="Total Strength (Occupied Strength in Total)"
              bordered={false}
              style={{
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px"
              }}
            >
              <div id={`${chartId}-totalStrength`} style={{ width: "100%", height: "400px" }}></div>
            </Card>

            <Card
              title="Admission Fee"
              bordered={false}
              style={{
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px"
              }}
            >
              <div id={`${chartId}-admissionFee`} style={{ width: "100%", height: "400px" }}></div>
            </Card>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default AdmissionColumnChart;
