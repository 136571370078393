import { createSlice } from "@reduxjs/toolkit";
import { FeatureService } from "../Services/FeatureService";

const customFeaturesState = {
  customFeaturesList: [],
};

export const fetchCustomFeaturesList = () => async (dispatch) => {
  try {
    const response = await FeatureService.getCustomBranchFeaturesList();
    const featuresList = response?.data?.map(feature => feature?.identifierName);
    if (featuresList.length > 0) {
      dispatch(setCustomFeaturesList(featuresList));
    }
  } catch (error) {
    console.log("error", error);
  }
};

const CustomFeatures = createSlice({
  name: "CustomFetures",
  initialState: customFeaturesState,
  reducers: {
    setCustomFeaturesList: (state, action) => {
      state.customFeaturesList = action.payload;
    },
  },
});

export const getCustomFeaturesList = (state) => state?.CustomFeatures?.customFeaturesList || [];

export const { setCustomFeaturesList } =
  CustomFeatures.actions;

export default CustomFeatures.reducer;
