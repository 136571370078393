import React, { useEffect, useState } from 'react'
import ClassDropdown from '../Common/Class/ClassDropdown'
import SectionDropdown from '../Common/Section/SectionDropdown'

import "./StudyPlan.scss"
import { SubjectService } from '../../Services/SubjectService'
import { Button, Modal, Select, Space, Table } from 'antd'
import AddHomeworkModal from './AddHomeworkModal'
import { StudyPlanService } from '../../Services/StudyPlanService'
import { DeleteOutlined, FilePdfFilled } from '@ant-design/icons'
import FileViewMultiple from '../../Utils/FileViewMultiple'
import { dynamicErrorMsg, errorNotification, successNotification } from '../../Constants/Utils'

const Homework = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [classSubjects, setClassSubjects] = useState(null);
  const [homeworkModal, setHomeworkModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [view, setView] = useState(false);
  const [homework, setHomework] = useState([])

  useEffect(() => {
    if (selectedSection !== null) {
      getClassAssociatedSubjects()
    }
  }, [selectedSection])

  useEffect(() => {
    if (selectedSection !== null) {
      getHomeworkData()
    }
  }, [selectedSection])

  const getClassAssociatedSubjects = () => {
    SubjectService.getClassRelatedSubjectList(selectedSection?.classId)
      .then((response) => {
        setClassSubjects(response.data);
      })
      .catch((error) => {
        console.log('error', error)
      });
  };

  const getHomeworkData = () => {
    StudyPlanService.getHomework(selectedSection?.classId)
      .then(response => {
        console.log('response?.data', response?.data)
        const transformedData = [];

        for (const [date, homeworkArray] of Object.entries(response?.data)) {
          homeworkArray.forEach((homework) => {
            transformedData.push({
              date,
              subject: homework?.subject,
              task: homework?.task,
              fileUrls: homework?.fileUrls,
              subjectId: homework?.subjectId,
              staffId: homework?.staffId
            });
          });
        }

        console.log('transformedData', transformedData)

        setHomework(transformedData)

      })
      .catch(error => {
        console.log('error', error)
      })
  }

  const subjects = classSubjects?.reduce((acc, classData) => {
    // Extracting subjects for the dropdown from the response structure
    classData.subjects.forEach(subject => {
      acc.push({
        subjectId: subject.subjectId,
        subjectName: subject.subjectName,
        staffId: subject.staff[0].staffId
      });
    });
    return acc;
  }, []);

  const handleFileView = (urls) => {
    setFileUrl(urls);
    setView(true);
  };

  const deleteHomework = (record) => {
    Modal.confirm({
      title: "Are you sure to delete homework",
      onOk: () => {
        const params = {
          classId: selectedSection?.classId,
          subjectId: record?.subjectId,
          date: record?.date,
        }
        StudyPlanService.deleteHomework(record?.staffId, params)
          .then(response => {
            getHomeworkData();
            successNotification(response?.data);
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "homework")));
      }
    })
  }


  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "task",
      dataIndex: "task",
      key: "task",
      align: "center",
      sorter: (a, b) => a.task.localeCompare(b.task),
    },
    {
      title: "File",
      dataIndex: "fileUrls",
      render: (fileUrls) => fileUrls.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(fileUrls)} />) : ("-"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          {/* <EditOutlined onClick={() => editHomework(record)} /> */}
          <DeleteOutlined onClick={() => deleteHomework(record)} />
        </Space>
      ),
    },
  ];

  const onClassChange = (value) => {
    setSelectedClass(value);
  };
  const onSubjectChange = (value) => {
    const subjectData = subjects.find(subject => subject?.subjectId === value)
    setSelectedSubject(subjectData);
  };
  const onSectionChange = (value) => {
    setSelectedSection(value);
  };

  const showModal = () => {
    setHomeworkModal(true)
  }

  console.log('homework', homework)

  const filteredHomework = () => {
    return homework?.filter(work => work?.subjectId === selectedSubject?.subjectId);
  }

  return (
    <main>
      <div className='homework-container'>
        <h1>Homework</h1>
        <div className='homework-class-dropdown'>
          <div>
            <ClassDropdown
              onClassChange={onClassChange}
              selectedClass={selectedClass}
            />
          </div>
          <div>
            <SectionDropdown
              classValue={selectedClass}
              onSectionChange={onSectionChange}
            />
          </div>
          <Select
            value={selectedSubject?.subjectId}
            onChange={onSubjectChange}
            placeholder="Select a subject"
          >
            {subjects?.map((subject) => (
              <Select.Option key={subject.subjectId} value={subject.subjectId}>
                {subject.subjectName}
              </Select.Option>
            ))}
          </Select>
          <Button
            className="blue-button"
            onClick={showModal}
          >
            Create Homework
          </Button>
        </div>
      </div>
      <Table
        dataSource={filteredHomework()}
        columns={columns}
        showSorterTooltip={false}
        pagination={false}
      />
      <AddHomeworkModal
        homeworkModal={homeworkModal}
        setHomeworkModal={setHomeworkModal}
        selectedSection={selectedSection}
        selectedSubject={selectedSubject}
        getHomeworks={getHomeworkData}
      />

      {view && (
        <FileViewMultiple
          view={view}
          setView={setView}
          fileData={fileUrl}
          heading="Homework"
        />
      )}
    </main>
  )
}

export default Homework;