import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Select, Form, Upload, notification } from 'antd';
import { CheckCircleFilled, DislikeOutlined, UploadOutlined } from '@ant-design/icons';
import { supportService } from '../../../Services/SupportService';
import FloatInputAllCharacters from '../../Common/FloatLabel/InputAllCharacters';
import { dynamicErrorMsg } from '../../../Constants/Utils';
import moment from 'moment';

const { Option } = Select;

const AddSupportTicket = ({
    modalDisplay,
    setModalDisplay,
    ticketData,
    editTicketData,
    setEditTicketData
}) => {
  const [supportForm] = Form.useForm();

  useEffect(() => {
    console.log('editTicketData', editTicketData)
    if (editTicketData?.id) {
      const updatedDate =
        editTicketData.announcementDate === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(editTicketData?.createDate, "YYYY-MM-DD");

      supportForm.setFieldsValue({
        title: editTicketData?.title,
        description: editTicketData?.description,
        severity: editTicketData?.severity,
        status: editTicketData?.status,
        createDate: updatedDate,
      });
    } else {
      supportForm.resetFields();
    }
  }, [editTicketData]);

  const handleCancel = () => {
    setModalDisplay(false);
    setEditTicketData(null)
    supportForm.resetFields()
  };

  const onFinish = (values) => {
    console.log('values', values)
    const formData = new FormData()
    formData.append("title",values?.title)
    formData.append("description",values?.description)
    formData.append("severity",values?.severity)
    if (values?.supportFile !== undefined){
        formData.append("supportFile", values?.supportFile?.file?.originFileObj)
    }
    const id = editTicketData?.id;
    if (!id) {
        supportService.createTicket(formData)
        .then((response) => {
            setModalDisplay(false)
            supportForm.resetFields()
            ticketData()
            notification.success({
                message: `Create Created Sucessfully`,
                icon: (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                    }}
                  />
                ),
                duration: 3,
                placement: "bottomRight",
              });
        }).catch((error) => {
            setModalDisplay(false)
            const errorMessage = dynamicErrorMsg(error, "Create", "Ticket")

            notification.error({
                message: errorMessage,
                placement: "bottomRight",
                icon: <DislikeOutlined style={{ color: "red" }} />,
              });
        })
    }else{
        supportService.updateTicket(id,formData)
        .then((response) => {
            setModalDisplay(false)
            supportForm.resetFields()
            ticketData()
            notification.success({
                message: `Ticket Updated Sucessfully`,
                icon: (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                    }}
                  />
                ),
                duration: 3,
                placement: "bottomRight",
              });
        }).catch((error) => {
            setModalDisplay(false)
            const errorMessage = dynamicErrorMsg(error, "Create", "Ticket")

            notification.error({
                message: errorMessage,
                placement: "bottomRight",
                icon: <DislikeOutlined style={{ color: "red" }} />,
              });
        })
    }
  };

  return (
      <Modal
      title={
        editTicketData && Object.keys(editTicketData).length > 0
          ? "Update Ticket"
          : "Create Ticket"
      }
        open={modalDisplay}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={supportForm}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    pattern: /^[^"]*$/,
                    message:
                      "Please enter a valid title (excluding double quotes)",
                  },
                ]}
            >
                <FloatInputAllCharacters
                  id="inputs"
                //   label="Title"
                  type="text"
                />
              </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={
                [{ required: true, message: 'Please input the description!' }]
            }
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Severity"
            name="severity"
            rules={[{ required: true, message: 'Please select the severity!' }]}
          >
            <Select>
              <Option value="low">Low</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
              <Option value="critical">Critical</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Upload Image"
            name="supportFile"
          >
            <Upload showUploadList={true} name="image">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item 
          style={{display:'flex', justifyContent:"center" , marginTop:5}}
          >
            <Button 
                style={{backgroundColor:"#172b4d", color:"white"}}
                htmlType="submit">
                Save
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: '10px' , backgroundColor:"#AA0B28", color:"white" }}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default AddSupportTicket;
