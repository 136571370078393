import { createSlice } from "@reduxjs/toolkit";
import { StaffService } from "../Services/StaffService";

const staffInitialState = {
  staffList: JSON.parse(sessionStorage.getItem("staffList")) || [],
};

export const fetchStaffList = () => async (dispatch) => {
  const staffParams = { teachingStaff: true, pageSize: 1000 };

  try {
    const response = await StaffService.getAllStaffList(staffParams);
    const data = response?.data?.data;
    if (data.length > 0) {
      dispatch(setStaffList(data));
    }
  } catch (error) {
    console.log("error", error);
  }
};

const Staff = createSlice({
  name: "Staff",
  initialState: staffInitialState,
  reducers: {
    setStaffList: (state, action) => {
      state.staffList = action.payload;
      sessionStorage.setItem("staffList", JSON.stringify(action.payload));
    },
    createStaff: (state, action) => {
      state.staffList.push(action.payload);
      sessionStorage.setItem("staffList", JSON.stringify(state.staffList));
    },
    editStaff: (state, action) => {
      const { staffId, data } = action.payload;
      const index = state.staffList.findIndex((s) => s.staffId === staffId);
      if (index !== -1) {
        state.staffList[index] = {
          ...state.staffList[index],
          ...data,
        };

        sessionStorage.setItem("staffList", JSON.stringify(state.staffList));
      }
    },

    deleteStaff: (state, action) => {
      const id = action.payload;
      state.staffList = state.staffList.filter((c) => c.classId !== id);
      sessionStorage.setItem("staffList", JSON.stringify(state.staffList));
    },
  },
});

export const getStaffList = (state) => state?.Staff?.staffList || [];

export const { setStaffList, createStaff, editStaff, deleteStaff } =
  Staff.actions;

export default Staff.reducer;
