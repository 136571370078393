import React, { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Button, Modal, Space, Table } from 'antd';
import LendersModal from './LendersModal';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';

const Lenders = () => {
    const [lendersData, setLendersData] = useState();
    const [lendersModal, setLendersModal] = useState(false);
    const [record, setRecord] = useState(null);
    useEffect(() => {
        getLendersData();
    }, [])
    const getLendersData = () => {
        AccountsService.getLenders()
            .then(response => {
                setLendersData(response?.data);
            })
            .catch(error => { })
    }
    const columns = [
        {
            title: "S.No",
            render: (_, record, index) => index + 1,
            width: 50,
        },
        {
            title: "Lender Name",
            dataIndex: "lenderName",
            key: "lenderName",
            width: 120,
        },
        {
            title: "Type",
            dataIndex: "lenderType",
            key: "lenderType",
            width: 120,
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
            key: "contactNumber",
            width: 120,
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: 120,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 120,
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            fixed: "right",
            width: 140,
            render: (_, record) => (
              <Space>
                <EditOutlined 
                    onClick={() => {
                        setRecord(record);
                        setLendersModal(true);
                    }}
                />
                <DeleteOutlined onClick={() => handleDeleteLender(record)}/>
              </Space>
            ),
          },
    ];
    const handleDeleteLender = record => {
        Modal.confirm({
            title: "Are you sure remove this lender ?",
            onOk: () => {
                AccountsService.deleteLender(record?.id)
                    .then(response => {
                        successNotification(response?.data);
                        getLendersData();
                    })
                    .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "lender")));
            }
        })
    }
    return (
        <main>
            <div className="flex-between">
                <h1>Lenders Details</h1>
                <Button className="blue-button" onClick={() => setLendersModal(true)}><PlusOutlined /> Add</Button>
            </div>
            <Table
                pagination={false}
                dataSource={lendersData}
                columns={columns}
            />
            {lendersModal && (
                <LendersModal
                    lendersModal={lendersModal}
                    setLendersModal={setLendersModal}
                    record={record}
                    setRecord={setRecord}
                    getLendersData={getLendersData}
                />
            )}
        </main>
    )
}

export default Lenders