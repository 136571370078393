import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Upload,
  notification,
  Radio,
  Image,
  Spin,
} from "antd";
import InputFloatLabel from "../Common/FloatLabel/Input";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CheckCircleFilled,
  DislikeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { socialMediaService } from "../../Services/SocialMediaService";
import SocialMediaPostAi from "./SocialMediaPostAi";
import "./createSocialMediaPost.scss";
import { dynamicErrorMsg, validateFiles } from "../../Constants/Utils";

const CreatePost = ({
  socialMediaModal,
  setSocialMediaModal,
  getSocialMediaPosters,
}) => {
  const [fileList, setFileList] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [AiImage, setAiImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [socialMediaModalAI, setsocialMediaModalAI] = useState(false);
  const [loading, setloading] = useState(false);
  const [divElement, setdivElement] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [publishposter, setpublishposter] = useState([]);
  const [emptyImages, setEmptyImages] = useState([]);
  const [animation, setanimation] = useState(false);

  const [AiForm] = Form.useForm();
  const [postsForm] = Form.useForm();
  const navigate = useNavigate();

  const handleCancel = () => {
    // setSocialMediaModal(false);
    postsForm.resetFields();
  };

  const handleEmptyImages = () => {
    setSelectedImages([]);
  };
  const handleCancelAI = () => {
    setSocialMediaModal(false);
  };
  const handleProceed = () => {
    // setsocialMediaModalAI(true);
    {
      selectedImages?.length > 0 && setdivElement(true);
    }
  };
  const accept = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
  const maxSizeMB = 3;
  const errorMessage =
    "Some files were invalid. Please check the file type and size.";
  const handleUpload = ({ fileList }) => {
    const validFiles = validateFiles(fileList, accept, maxSizeMB, errorMessage);
    setFileList(validFiles);
    if (validFiles.length > 0) {
      setUploadError("");
    }
  };

  const onFinish = (values) => {
    setanimation(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    fileList.map((file, i) => {
      formData.append(`images`, file);
    });
    // formData.append(`postersURL`, "");
    socialMediaService
      .createSocialMediaPoster(formData)
      .then((response) => {
        handlepost(response?.data);
        handleCancel();
        // console.log("it is getting hitted now");
        // getSocialMediaPosters();
        // setpublishposter(response?.data);
        // postsForm.resetFields();
        // setSocialMediaModal(false);
        notification.success({
          message: `Post Created Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Update", "Exam grade");

        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  console.log("publish poster", publishposter);
  const onFinishAi = (values) => {
    setloading(true);
    console.log(values);
    socialMediaService
      .GenerateAiImage(values)
      .then((response) => {
        setloading(false);
        setAiImage(response);
        AiForm.resetFields();
      })
      .catch((error) => {
        setloading(false);
        console.log("unable to generate the images", error);
      });
  };
  console.log("here re ai images", AiImage);
  const toggleImageSelection = (imageUrl) => {
    const currentIndex = selectedImages.indexOf(imageUrl);
    const newSelectedImages = [...selectedImages];

    if (currentIndex === -1) {
      newSelectedImages.push(imageUrl);
    } else {
      newSelectedImages.splice(currentIndex, 1);
    }

    setSelectedImages(newSelectedImages);
  };
  const onFinishFailed = () => {};
  const onFinishFailedAi = () => {};

  const handleRadioChange = (e) => {
    setActiveTab(e.target.value);
  };

  const instagramPost = (values) => {
    socialMediaService
      .InstagramPost(values)
      .then((response) => {
        // AiForm.resetFields();
        // setdivElement(false);
        notification.success({
          message: `Post Uploaded to Instagram Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Upload", "In Instagram");

        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const handleGenerateAiImages = () => {};
  const handlepost = (values) => {
    // console.log("for the good", values);
    const payload = {
      message: values.description,
      longUrls: values?.postersURL?.toString(),
    };
    // console.log("payyyyy", payload);

    instagramPost(payload);
    console.log("here are the values", payload);
    socialMediaService
      .facebookpost(payload)
      .then((response) => {
        AiForm.resetFields();
        setanimation(false);
        notification.success({
          message: `Post Uploaded Successfully to Facebook`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Upload", "In Facebook");

        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  return (
    <div>
      <Radio.Group
        value={activeTab}
        onChange={handleRadioChange}
        optionType="button"
        buttonStyle="solid"
        style={{
          marginBottom: 20,
          display: divElement ? "none" : "block",
        }}
      >
        <Radio.Button value="tab1">Upload Poster</Radio.Button>
        <Radio.Button value="tab2">Create Poster using AI</Radio.Button>
      </Radio.Group>
      {activeTab === "tab1" && (
        <Form
          form={postsForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="white-card "
          style={{ padding: 40 }}
        >
          <Row className="gutter-row" span={16}>
            <Col className="gutter-row" span={10}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Title" name="title" type="string" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={14}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  label="Description"
                  name="description"
                  type="string"
                />
              </Form.Item>
            </Col>
            <Form.Item name="images">
              <Upload
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleUpload}
                multiple
                showUploadList={true}
                beforeUpload={() => false}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{ width: 200, height: 40, borderRadius: "5px" }}
                >
                  Upload Image
                </Button>
              </Upload>
              {uploadError && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {uploadError}
                </div>
              )}
            </Form.Item>
          </Row>

          <>
            {animation && (
              <div className="loading">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Button
                type="primary"
                className="cancel-button"
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="danger"
                className="newCancelButton"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Button
                type="primary"
                className="cancel-button"
                onClick={handlepost}
                style={{ margin: 20 }}
              >
                Publish
              </Button>
            </Col>
          </Row> */}
        </Form>
      )}
      {activeTab === "tab2" && (
        <>
          {divElement === false ? (
            <div>
              <Form
                form={AiForm}
                onFinish={onFinishAi}
                onFinishFailed={onFinishFailedAi}
                autoComplete="off"
                className="white-card  "
                style={{ padding: 40 }}
              >
                <Row gutter={16}>
                  <Col span={14}>
                    <Form.Item
                      name="prompt"
                      rules={[
                        {
                          required: true,
                          message: "Please describe the image",
                        },
                      ]}
                    >
                      <InputFloatLabel
                        label="Describe the Image"
                        name="prompt"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="n"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the number of images",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || value <= 10) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Only 10 Images per request")
                            );
                          },
                        }),
                      ]}
                    >
                      <InputFloatLabel
                        label="Number of Images"
                        name="n"
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={5}>
                    <Form.Item name="size">
                      <InputFloatLabel
                        label="Size of Images"
                        name="size"
                        type="number"
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="cancel-button"
                      style={{ margin: 10 }}
                      onClick={onFinishAi}
                    >
                      Generate
                    </Button>
                  </Col>
                </Row>

                {loading ? (
                  <Spin spinning={loading} style={{ marginLeft: "50%" }} />
                ) : (
                  <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                    {AiImage.length > 0 &&
                      AiImage.map((imageUrl, index) => (
                        <div
                          key={index}
                          className="SelectImages"
                          style={{
                            width: 212,
                            padding: selectedImages.includes(imageUrl)
                              ? "4px"
                              : "5px",
                            border: selectedImages.includes(imageUrl)
                              ? "5px solid #1890ff"
                              : "1px solid #f0f0f0",
                            boxShadow: selectedImages.includes(imageUrl)
                              ? "0 4px 8px 0 rgba(24, 144, 255, 0.2)"
                              : "0 1px 3px 0 rgba(0,0,0,0.1)",
                            cursor: "pointer",
                            borderRadius: "8px",
                            margin: "5px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => toggleImageSelection(imageUrl)}
                        >
                          <img src={imageUrl} alt={`AI Generated ${index}`} />
                        </div>
                      ))}
                  </div>
                )}
              </Form>

              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="cancel-button"
                  onClick={handleProceed}
                  style={{
                    display: selectedImages?.length > 0 ? "block" : "none",
                  }}
                >
                  Proceed
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <SocialMediaPostAi
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                socialMediaModalAI={socialMediaModalAI}
                setsocialMediaModalAI={setsocialMediaModalAI}
                divElement={divElement}
                setdivElement={setdivElement}
                handleEmptyImages={handleEmptyImages}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreatePost;
