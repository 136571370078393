import { useEffect, useState } from "react";
import HostelService from "../../../../Services/HostelService";
import { Button, DatePicker, Select, Space, Table, notification } from "antd";
import {
  CheckCircleFilled,
  DislikeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import "./Expenses.scss";
import ExpenseModal from "./ExpenseModal";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../../Constants/Constants";
const Expenses = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const accessToken = Cookies.get("accessToken");
  const { Option } = Select;

  const getExpenseList = () => {
    const params = {
      month: selectedMonth,
      date: selectedDate,
      year: selectedYear,
    };
    HostelService.getAllKitchenExpenses(params)
      .then((response) => {
        setExpenseList(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      getExpenseList();
    }
  }, [selectedYear, selectedMonth, selectedDate]);

  const handleEditExpense = (record) => {
    seteditRow(record);
    setIsModalOpen(true);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      fixed: "left",
    },
    {
      title: "Date (DD-MM-YYYY)",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Amount (INR)",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Price Per Unit (INR)",
      dataIndex: "pricePerUnit",
      key: "pricePerUnit",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditExpense(record)} />
        </Space>
      ),
    },
  ];

  const handleAddExpense = () => {
    setIsModalOpen(true);
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      const dateString = moment(date).format("YYYY-MM-DD");
      setSelectedDate(dateString);
    } else {
      setSelectedDate(date);
    }
  };

  const generateReport = () => {
    if (selectedDate) {
      return axios.get(
        `${API_URL}/api/kitchen/kitchen-expenses-report?month=${selectedMonth}&year=${selectedYear}&date=${selectedDate}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
    } else {
      return axios.get(
        `${API_URL}/api/kitchen/kitchen-expenses-report?month=${selectedMonth}&year=${selectedYear}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
    }
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  const handleReport = () => {
    generateReport()
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, `Expense_report.pdf`);
          notification.success({
            message: "The File is downloading...",
            description: `Expense report downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error.data);
        notification.error({
          message: "Error",
          description:
            "An error occurred while generating report, try again later.",
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const getLastThreeYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 3 }, (_, index) => currentYear - index).map(
      (year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      )
    );
  };

  return (
    <section>
      <div className="add-button">
        <h1>Expenses</h1>
        <div>
          <Select
            style={{ width: 120, margin: 5 }}
            value={selectedYear}
            onChange={handleYearChange}
            placeholder="Select Year"
          >
            {getLastThreeYears()}
          </Select>
          <Select
            style={{ width: 150, margin: 5 }}
            value={months[selectedMonth - 1]}
            onChange={handleMonthChange}
            placeholder="Select Month"
            allowClear
          >
            {months?.map((month, index) => (
              <Option key={index + 1} value={index + 1}>
                {month}
              </Option>
            ))}
          </Select>
          <DatePicker style={{ margin: 5 }} onChange={handleDateChange} />
          <Button className="blue-button" onClick={handleAddExpense}>
            Add Expense
          </Button>
          <Button className="blue-button" onClick={handleReport}>
            Download Report
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={expenseList} pagination={false} />
      {isModalOpen && (
        <ExpenseModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editRow={editRow}
          seteditRow={seteditRow}
          getExpenseList={getExpenseList}
        />
      )}
    </section>
  );
};

export default Expenses;
