import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from "antd";
import HostelService from "../../../../Services/HostelService";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import DatepickerFloatLabel from "../../../Common/FloatLabel/Datepicker";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import { Option } from "antd/lib/mentions";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import moment from "moment";
const DailyConsmptionModal = ({
  isModalOpen,
  setIsModalOpen,
  editRow,
  seteditRow,
  getConsumptionDetails,
}) => {
  const [expenseForm] = Form.useForm();
  const [typeList, settypeList] = useState([]);
  const [reaminingStock, setReaminingStock] = useState([]);
  const [selectedTypeQuantity, setSelectedTypeQuantity] = useState(null);

  const getReaminingStock = () => {
    HostelService.getRemainingItems()
      .then((response) => {
        setReaminingStock(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getReaminingStock();
  }, []);

  const expenseCategoryList = () => {
    HostelService.getKitchenItems().then((res) => {
      settypeList(res?.data);
    });
  };
  useEffect(() => {
    expenseCategoryList();
  }, []);

  useEffect(() => {
    if (editRow?.consumptionId && reaminingStock) {
      handleTypeChange(editRow.itemId);
      const updatedDate =
        editRow?.date === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(editRow?.date, "YYYY-MM-DD");

      expenseForm.setFieldsValue({
        ...editRow,
        date: updatedDate,
      });
      console.log("edit", editRow);
    } else {
      expenseForm.resetFields();
    }
  }, [editRow, reaminingStock]);

  const handleTypeChange = (typeId) => {
    setSelectedTypeQuantity(null);
    const selectItem = reaminingStock?.filter((item) => item.itemId === typeId);
    setSelectedTypeQuantity(selectItem?.[0]?.remainingQuantity);
  };

  const onFinisher = (values) => {
    console.log(values);
    const updatedDate = moment(values.date)?.format("YYYY-MM-DD");
    const payload = {
      ...values,
      date: updatedDate,
    };

    if (editRow?.consumptionId) {
      HostelService.updateDailyConsumption(editRow?.consumptionId, payload)
        .then((response) => {
          getConsumptionDetails();
          seteditRow(null);
          setIsModalOpen(false);
          notification.success({
            message: `Consmption updated sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description:
              "An error occurred while Updating the Consmption, try again later.",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    } else {
      HostelService.createDailyConsumption(payload)
        .then((response) => {
          notification.success({
            message: `Daily consmption created sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          getConsumptionDetails();
          handleCancel();
          expenseForm.resetFields();
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description:
              error?.data?.message ||
              "An error occurred while Creating the consmption, try again later.",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const validateQuantity = (_, value) => {
    if (value <= 0) {
      return Promise.reject("Quantity must be greater than 0");
    } else if (selectedTypeQuantity !== null && value > selectedTypeQuantity) {
      return Promise.reject("Quantity cannot exceed available stock");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <>
      <Modal
        title={editRow ? "Edit Consumption " : "Create Consumption"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={expenseForm} onFinish={onFinisher} autoComplete="off">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name="itemId"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  label="Select Expense"
                  name="itemId"
                  onChange={handleTypeChange}
                >
                  {typeList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.itemsName}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quantity"
                rules={[
                  { required: true, message: "" },
                  { validator: validateQuantity },
                ]}
              >
                <InputFloatLabel label="Quantity" type="number" />
              </Form.Item>
              {selectedTypeQuantity !== null && (
                <p>Avilable stock quantity : {selectedTypeQuantity}</p>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatepickerFloatLabel style={{ width: "100%" }} label="Date" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description">
                <InputFloatLabel
                  label="Description"
                  type="text"
                  name="description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default DailyConsmptionModal;
