
import { GetAPIRequest, PostAPIRequest, PutAPIRequest, DeleteAPIRequest } from "./Api";
import { ADD_CLASS_FEE_COMPONENTS, CREATE_CLASS_INSTALLMENTS, CREATE_FEE_COMPONENTS, CREATE_INSTALLMENT_PLANS, CREATE_INSTALLMENTS_MASTER, DELETE_CLASS_FEE_COMPONENTS, DELETE_CLASS_INSTALLMENTS, DELETE_FEE_COMPONENTS, DELETE_INSTALLMENT_PLANS, DELETE_INSTALLMENTS_MASTER, EDIT_CLASS_FEE_COMPONENTS, EDIT_CLASS_INSTALLMENTS, EDIT_FEE_COMPONENTS, EDIT_INSTALLMENT_PLANS, EDIT_INSTALLMENTS_MASTER, GET_ALL_CLASS_INSTALLMENTS, GET_CLASS_FEE_COMPONENTS, GET_FEE_COMPONENTS, GET_INSTALLMENT_PLANS, GET_INSTALLMENTS_MASTER, CREATE_FEE_DISCOUNTS, GET_FEE_DISCOUNTS, EDIT_FEE_DISCOUNTS, DELETE_FEE_DISCOUNTS, GET_LR_PAYMENTS, EDIT_LR_PAYMENTS, CREATE_LR_PAYMENTS, DELETE_LR_PAYMENTS, GET_LR_INSTALLMENTS, EDIT_LR_INSTALLMENTS, CREATE_LR_INSTALLMENTS, DELETE_LR_INSTALLMENTS, GET_STUDENT_FEE_BREAKUP, EDIT_STUDENT_FEE_BREAKUP, EDIT_STUDENT_PAID_FEE_BREAKUP, CREATE_STUDENT_FEE_BREAKUP, PAY_STUDNET_FEE_BREAKUP, DELETE_STUDENT_FEE_BREAKUP, GET_STUDENT_FEE_DISCOUNTS, EDIT_STUDENT_FEE_DISCOUNTS, CREATE_STUDENT_FEE_DISCOUNTS, DELELTE_STUDENT_FEE_DISCOUNTS, GENERATE_STUDENT_FEE_BREAKUP_FOR_CLASS, GENERATE_INSTALLMENT_PLANS, CREATE_STUDENT_FEE_TRANSACTION, GET_STUDENT_FEE_TRANSACTION, EDIT_STUDENT_FEE_TRANSACTION, CLEAR_STUDENT_FEE_FUNDS, UPDATE_STUDENT_FEE_TRANSACTION, GET_FEE_SUMMARY_DETAILS, UPDATE_STUDENT_FEE_BREAKUP_AMOUNT, GET_ADVANCED_FEE_CONFIGURATION, EDIT_ADVANCED_FEE_CONFIGURATION, CREATE_ADVANCED_FEE_CONFIGURATION, DELELTE_ADVANCED_FEE_CONFIGURATION, CREATE_DEFAULT_FEE_COMPONENTS, GET_STUDENT_INSTALLMENT_FEE_BREAKUP, ADD_STUDENT_FEE_DISCOUNTS, GET_SPECIAL_FEES, EDIT_SPECIAL_FEE, CREATE_SPECIAL_FEE, DELETE_SPECIAL_FEE, GET_CLASS_SPECIAL_FEES, CREATE_STUDENT_SPECIAL_FEE, GET_CLASS_STUDENT_SPECIAL_FEES, DELETE_STUDENT_SPECIAL_FEE, EDIT_STUDENT_SPECIAL_FEE, GENERATE_CLASS_STUDENT_SPECIAL_FEES, PAY_STUDENT_SPECIAL_FEE } from "../Constants/Constants";
import { FEE_SERVICE } from "../Constants/StringConstants";

// Fee Components
const getFeeComponents = (params) => {
  return GetAPIRequest({ url: GET_FEE_COMPONENTS, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editFeeComponent = (feeComponentId, data, params) => {
  return PutAPIRequest({ url: EDIT_FEE_COMPONENTS.replace("{feeComponentId}", feeComponentId), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createFeeComponent = (data, params) => {
  return PostAPIRequest({ url: CREATE_FEE_COMPONENTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createDefaultFeeComponent = () => {
  return PostAPIRequest({ url: CREATE_DEFAULT_FEE_COMPONENTS, service: FEE_SERVICE});
};
const deleteFeeComponent = (feeComponentId, params) => {
  return DeleteAPIRequest({ url: DELETE_FEE_COMPONENTS.replace("{feeComponentId}", feeComponentId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Class Fee Components
const getClassFeeComponents = (classId, params) => {
  return GetAPIRequest({ url: GET_CLASS_FEE_COMPONENTS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editClassFeeComponent = (classFeeComponentId, data, params) => {
  return PutAPIRequest({ url: EDIT_CLASS_FEE_COMPONENTS.replace("{classFeeComponentId}", classFeeComponentId), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createClassFeeComponent = (data, params) => {
  return PostAPIRequest({ url: ADD_CLASS_FEE_COMPONENTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteClassFeeComponent = (classFeeComponentId, params) => {
  return DeleteAPIRequest({ url: DELETE_CLASS_FEE_COMPONENTS.replace("{classFeeComponentId}", classFeeComponentId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Class Installment Numbers
const getAllClassInstallmentNumbers = ( params) => {
  return GetAPIRequest({ url: GET_ALL_CLASS_INSTALLMENTS, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editClassInstallmentNumber = (id, params) => {
  return PutAPIRequest({ url: EDIT_CLASS_INSTALLMENTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createClassInstallmentNumber = (data, params) => {
  return PostAPIRequest({ url: CREATE_CLASS_INSTALLMENTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteClassInstallmentNumber = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_CLASS_INSTALLMENTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Installment Plans
const generateInstallmentPlans = (params) => {
  return PostAPIRequest({ url: GENERATE_INSTALLMENT_PLANS, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const getInstallmentPlans = (classId, params) => {
  return GetAPIRequest({ url: GET_INSTALLMENT_PLANS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editInstallmentPlans = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_INSTALLMENT_PLANS.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createInstallmentPlans = (data, params) => {
  return PostAPIRequest({ url: CREATE_INSTALLMENT_PLANS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteInstallmentPlans = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_INSTALLMENT_PLANS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Installment Master
const getInstallmentMaster = (classId, params) => {
  return GetAPIRequest({ url: GET_INSTALLMENTS_MASTER.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editInstallmentMaster = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_INSTALLMENTS_MASTER.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createInstallmentMaster = (data, params) => {
  return PostAPIRequest({ url: CREATE_INSTALLMENTS_MASTER, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteInstallmentMaster = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_INSTALLMENTS_MASTER.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Class Fee Discounts
const getFeeDiscounts = (classId, params) => {
  return GetAPIRequest({ url: GET_FEE_DISCOUNTS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editFeeDiscounts = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_FEE_DISCOUNTS.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createFeeDiscounts = (data, params) => {
  return PostAPIRequest({ url: CREATE_FEE_DISCOUNTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteFeeDiscounts = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_FEE_DISCOUNTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// LR Payments
const getLrPayments = (classId, params) => {
  return GetAPIRequest({ url: GET_LR_PAYMENTS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editLrPayments = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_LR_PAYMENTS.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createLrPayments = (data, params) => {
  return PostAPIRequest({ url: CREATE_LR_PAYMENTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteLrPayments = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_LR_PAYMENTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// LR Installments
const getLrInstallments = (classId, params) => {
  return GetAPIRequest({ url: GET_LR_INSTALLMENTS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editLrInstallments = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_LR_INSTALLMENTS.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createLrInstallments = (data, params) => {
  return PostAPIRequest({ url: CREATE_LR_INSTALLMENTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteLrInstallments = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_LR_INSTALLMENTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Student Installment Breakup
const getStudentInstallmentFeeBreakUp = (classId, params) => {
  return GetAPIRequest({ url: GET_STUDENT_INSTALLMENT_FEE_BREAKUP.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Student Fee BreakUp
const getStudentFeeBreakUp = (classId, params) => {
  return GetAPIRequest({ url: GET_STUDENT_FEE_BREAKUP.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editStudentFeeBreakUp = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_STUDENT_FEE_BREAKUP.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const updateStudentFeeBreakUpAmount = (id, params) => {
  return PutAPIRequest({ url: UPDATE_STUDENT_FEE_BREAKUP_AMOUNT.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const payStudentFeeBreakup = (id, data, params) => {
  return PutAPIRequest({ url: PAY_STUDNET_FEE_BREAKUP.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createStudentFeeBreakUp = (data, params) => {
  return PostAPIRequest({ url: CREATE_STUDENT_FEE_BREAKUP, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteStudentFeeBreakup = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_STUDENT_FEE_BREAKUP.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const generateStudentFeeBreakupForClass = (classId, params) => {
  return PostAPIRequest({ url: GENERATE_STUDENT_FEE_BREAKUP_FOR_CLASS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Student Fee Discounts
const addStudentFeeDiscounts = (data, params) => {
  return PostAPIRequest({ url: ADD_STUDENT_FEE_DISCOUNTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

const getStudentFeeDiscounts = (classId, params) => {
  return GetAPIRequest({ url: GET_STUDENT_FEE_DISCOUNTS.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editStudentFeeDiscounts = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_STUDENT_FEE_DISCOUNTS.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createStudentFeeDiscounts = (data, params) => {
  return PostAPIRequest({ url: CREATE_STUDENT_FEE_DISCOUNTS, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteStudentFeeDiscounts = (id, params) => {
  return DeleteAPIRequest({ url: DELELTE_STUDENT_FEE_DISCOUNTS.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Advanced Fee Configuration
const getAdvancedFeeConfiguration = (classId, params) => {
  return GetAPIRequest({ url: GET_ADVANCED_FEE_CONFIGURATION.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editAdvancedFeeConfiguration = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_ADVANCED_FEE_CONFIGURATION.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createAdvancedFeeConfiguration = (data, params) => {
  return PostAPIRequest({ url: CREATE_ADVANCED_FEE_CONFIGURATION, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteAdvancedFeeConfiguration = (id, params) => {
  return DeleteAPIRequest({ url: DELELTE_ADVANCED_FEE_CONFIGURATION.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};


//class fee summary
const getClassFeeSummary = (id) => {
  return GetAPIRequest({ url: GET_FEE_SUMMARY_DETAILS + id, service: FEE_SERVICE, params: {isStaging: false}})
}

const getStudentFeeTransactions = (studentId, params) => {
  return GetAPIRequest({ url: GET_STUDENT_FEE_TRANSACTION.replace("{studentId}", studentId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editStudentFeeTransaction = (id, data, params) => {
  return PutAPIRequest({ url: UPDATE_STUDENT_FEE_TRANSACTION.replace("{transactionId}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createStudentFeeTransaction = (data, params) => {
  return PostAPIRequest({ url: CREATE_STUDENT_FEE_TRANSACTION, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const clearStudentFeeFunds = (studentId, data, params) => {
  return PostAPIRequest({ url: CLEAR_STUDENT_FEE_FUNDS.replace("{studentId}", studentId), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Special Fee
const getClassSpecialFees = (classId, params) => {
  return GetAPIRequest({ url: GET_CLASS_SPECIAL_FEES.replace("{classId}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const getSpecialFees = (params) => {
  return GetAPIRequest({ url: GET_SPECIAL_FEES, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editSpecialFee = (id, data, params) => {
  return PutAPIRequest({ url: EDIT_SPECIAL_FEE.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createSpecialFee = (data, params) => {
  return PostAPIRequest({ url: CREATE_SPECIAL_FEE, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteSpecialFee = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_SPECIAL_FEE.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};

// Student Special Fee
const generateClassStudentSpecialFees = (specialFeeId, params) => {
  return GetAPIRequest({ url: GENERATE_CLASS_STUDENT_SPECIAL_FEES.replace("{id}", specialFeeId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const createStudentSpecialFee = (data, params) => {
  return PostAPIRequest({ url: CREATE_STUDENT_SPECIAL_FEE, data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const getClassStudentSpecialFees = (classId, params) => {
  return GetAPIRequest({ url: GET_CLASS_STUDENT_SPECIAL_FEES.replace("{id}", classId), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const editStudentSpecialFee = (id, params) => {
  return PutAPIRequest({ url: EDIT_STUDENT_SPECIAL_FEE.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const deleteStudentSpecialFee = (id, params) => {
  return DeleteAPIRequest({ url: DELETE_STUDENT_SPECIAL_FEE.replace("{id}", id), service: FEE_SERVICE, params: {isStaging: false, ...params}});
};
const payStudentSpecialFee = (id, data, params) => {
  return PostAPIRequest({ url: PAY_STUDENT_SPECIAL_FEE.replace("{id}", id), data, service: FEE_SERVICE, params: {isStaging: false, ...params}});
};


export const FeeManagementService = {
  getFeeComponents,
  createFeeComponent,
  createDefaultFeeComponent,
  editFeeComponent,
  deleteFeeComponent,

  getClassFeeComponents,
  createClassFeeComponent,
  editClassFeeComponent,
  deleteClassFeeComponent,

  getAllClassInstallmentNumbers,
  createClassInstallmentNumber,
  editClassInstallmentNumber,
  deleteClassInstallmentNumber,

  generateInstallmentPlans,
  getInstallmentPlans,
  createInstallmentPlans,
  editInstallmentPlans,
  deleteInstallmentPlans,

  getInstallmentMaster,
  createInstallmentMaster,
  editInstallmentMaster,
  deleteInstallmentMaster,

  getFeeDiscounts,
  createFeeDiscounts,
  editFeeDiscounts,
  deleteFeeDiscounts,

  getAdvancedFeeConfiguration,
  createAdvancedFeeConfiguration,
  editAdvancedFeeConfiguration,
  deleteAdvancedFeeConfiguration,

  getLrPayments,
  createLrPayments,
  editLrPayments,
  deleteLrPayments,

  getLrInstallments,
  createLrInstallments,
  editLrInstallments,
  deleteLrInstallments,

  getStudentInstallmentFeeBreakUp,

  generateStudentFeeBreakupForClass,
  getStudentFeeBreakUp,
  createStudentFeeBreakUp,
  editStudentFeeBreakUp,
  updateStudentFeeBreakUpAmount,
  payStudentFeeBreakup,
  deleteStudentFeeBreakup,

  addStudentFeeDiscounts,
  getStudentFeeDiscounts,
  createStudentFeeDiscounts,
  editStudentFeeDiscounts,
  deleteStudentFeeDiscounts,

  createStudentFeeTransaction,
  editStudentFeeTransaction,
  getStudentFeeTransactions,
  clearStudentFeeFunds,

  getClassSpecialFees,
  getSpecialFees,
  createSpecialFee,
  editSpecialFee,
  deleteSpecialFee,

  generateClassStudentSpecialFees,
  createStudentSpecialFee,
  editStudentSpecialFee,
  getClassStudentSpecialFees,
  deleteStudentSpecialFee,
  payStudentSpecialFee,

  getClassFeeSummary,
}