import React from "react";
import { Empty } from "antd";

const EmptyComponent = (props) => {
  return (
    <Empty
      style={{ marginTop: "20px" }}
      description={props.message ?? "No data available"}
    />
  );
};

export default EmptyComponent;
