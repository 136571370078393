import React, { useEffect, useState } from 'react'
import { deductions, fixed, variable } from '../../../Constants/StringConstants'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import { salaryStructureService } from '../../../Services/SalaryStructureService'
import { dynamicErrorMsg, errorNotification } from '../../../Constants/Utils'

const AddCategoryModal = ({addCategoryModal, setAddCategoryModal, getCategoryTableData, editCategoryData, setEditCategoryData}) => {
  const [isdisabled, setDisabled] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    if (editCategoryData) {
      form.setFieldsValue({
        categoryName: editCategoryData?.categoryName,
        salaryType: editCategoryData?.salaryType
      })
    }
  }, [])

  const onCancel = () => {
    setAddCategoryModal(false)
    form.resetFields()
    setEditCategoryData(null)
  }

  const addCategory = (values) => {
    const payload = {...values}
    if (editCategoryData?.categoryId){
      salaryStructureService.updateCategory(editCategoryData?.categoryId,payload)
      .then(response => {
        getCategoryTableData()
        setAddCategoryModal(false)
        form.resetFields()
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Update", "Category")
        errorNotification(errorMsg)
      })
    }else{
    salaryStructureService.createCategory(payload)
      .then(response => {
        getCategoryTableData()
        setAddCategoryModal(false)
        form.resetFields()
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Create", "Category")
        errorNotification(errorMsg)
      })
    }
  }


  return (
    <Modal
      title="Add Salary Category"
      open={addCategoryModal}
      onCancel={onCancel}
      cancelText="Cancel"
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="addCategoryForm"
        onFinish={addCategory}
      >
        <Form.Item
          label="Category Name"
          name="categoryName"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Salary Type"
          name="salaryType"
          rules={[{ required: true, message: 'Please select the salary type!' }]}
        >
          <Select placeholder="Select Salary Type">
            <Select.Option value={fixed}>Fixed</Select.Option>
            <Select.Option value={variable}>Variable</Select.Option>
            <Select.Option value={deductions}>Deduction</Select.Option>
          </Select>
        </Form.Item>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="buttons cancel-button "
                  htmlType="submit"
                  disabled={isdisabled}
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={onCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
      </Form>
    </Modal>
  )
}

export default AddCategoryModal