import { useEffect, useState } from "react";
import HostelService from "../../../../Services/HostelService";
import {
  CheckCircleFilled,
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import "./KitchenItems.scss";
import { Button, Modal, Spin, notification } from "antd";
import ItemsModal from "./ItemsModal";

const KitchenItems = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const getAllItems = () => {
    setLoading(true);
    HostelService.getKitchenItems()
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };

  useEffect(() => {
    getAllItems();
  }, []);

  const handleEditItem = (record) => {
    setIsModalOpen(true);
    setSelectedRecord(record);
  };

  const handleAddItem = () => {
    setIsModalOpen(true);
  };

  const handleDeleteItem = (record) => {
    Modal.confirm({
      title: `Are you sure you want delete ${record?.itemsName}`,
      onOk: () => {
        HostelService.deleteKitchenItem(record?.id)
          .then((response) => {
            getAllItems();
            notification.success({
              message: `${record?.itemsName} Item Deleted Successfully`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          })
          .catch((err) => {
            notification.error({
              message:
                err?.data?.message ||
                "Item deleting failed, please try again later",
              icon: (
                <DislikeOutlined
                  style={{
                    color: "red",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          });
      },
    });
  };

  return (
    <section>
      <div className="items-main-container">
        <div className="items-container">
          {loading ? (
            <div className="spin-style">
              <Spin spinning={loading} />
            </div>
          ) : (
            <div className="items">
              {items?.map((cate) => (
                <div key={cate?.id}>
                  <div className="white-card card-container">
                    <div className="item-name">
                      <h1>
                        {cate?.itemsName} {`(${cate.type})`}
                      </h1>
                    </div>
                    <div className="item-icons">
                      <EditOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditItem(cate)}
                      />
                      <DeleteOutlined onClick={() => handleDeleteItem(cate)} />
                    </div>
                  </div>
                </div>
              ))}
              <Button className="blue-button" onClick={handleAddItem}>
                Add
              </Button>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ItemsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          getAllItems={getAllItems}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
    </section>
  );
};

export default KitchenItems;
