import React, { useEffect, useState } from "react";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from "antd";
import HostelService from "../../../../Services/HostelService";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
const ItemsModal = ({
  isModalOpen,
  setIsModalOpen,
  getAllItems,
  selectedRecord,
  setSelectedRecord,
}) => {
  const [itemForm] = Form.useForm();
  const { Option } = Select;
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRecord(null);
    itemForm.resetFields();
  };

  const onFinish = (values) => {
    if (selectedRecord?.id) {
      HostelService.editKitchenItem(selectedRecord?.id, values)
        .then((response) => {
          getAllItems();
          handleCancel();
          notification.success({
            message: `Item Edited Successfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Item editing failed, please try again later",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    } else {
      HostelService.createKitchenItem(values)
        .then((response) => {
          getAllItems();
          handleCancel();
          notification.success({
            message: `Item Created Successfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Item creating failed, please try again later",
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    }
  };

  useEffect(() => {
    if (selectedRecord?.id) {
      itemForm.setFieldsValue({
        ...selectedRecord,
      });
    } else {
      itemForm.resetFields();
    }
  }, [selectedRecord]);

  return (
    <>
      <Modal
        title={selectedRecord?.id ? "Edit Item" : "Create Item"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={itemForm} onFinish={onFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="itemsName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  label="Item name"
                  name="itemsName"
                  // style={{ textTransform: "capitalize" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Type" name="type">
                  <Option value="kg">Kilogram</Option>
                  <Option value="liter">Liter</Option>
                  <Option value="dozen">Dozen</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="buttons cancel-button "
                  htmlType="submit"
                  // disabled={isDisabled}
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default ItemsModal;
