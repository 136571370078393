import { Col, Form, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import FormButton from '../../Common/formButtons/FormButton';
import SelectFloatLabel from '../../Common/FloatLabel/Select';
import InputFloatLabel from '../../Common/FloatLabel/Input';
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import { FeeManagementService } from '../../../Services/FeeManagementService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';

const ClassFeeStructureModal = ({
    classFeeStructureModal,
    setClassFeeStructureModal,
    classesAndSections,
    componentsData,
    getClassFeeComponents,
    activeClass,
    activeYear
}) => {
    const [feeStructureForm] = Form.useForm();
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    useEffect(() => {
        handleTotalAmount();
    }, [selectedComponents])
    useEffect(() => {
        feeStructureForm.setFieldValue("className", activeClass);
        setSelectedClass(activeClass);
    }, [])

    useEffect(() => {
        if (classesAndSections) {
            const classArray = classesAndSections.map((classObj) => ({
                value: classObj.className,
                label: classObj.className,
            }));
            setClasses(classArray);
        }
    }, [classesAndSections]);

    useEffect(() => {
        if (selectedClass !== null) {
            const selectedClassData = classesAndSections.find(
                (item) => item.className === selectedClass
            );
            let selectedClassSectionsList = [];
            if (selectedClassData) {
                const sectionsArray = selectedClassData.sections.map((sectionObject) => {
                    selectedClassSectionsList.push(sectionObject?.classId);
                    return ({
                    value: sectionObject?.classId,
                    label: sectionObject?.section,
                })});
                setSections(sectionsArray);
                feeStructureForm.setFieldValue("section", selectedClassSectionsList);
            } else {
                setSections([]);
                feeStructureForm.setFieldValue("section", undefined);
            }
        } else {
            setSections([]);
            feeStructureForm.setFieldValue("section", undefined);
        }
    }, [selectedClass, classes, classesAndSections]);

    const handleCancel = () => {
        setClassFeeStructureModal(false);
        feeStructureForm.resetFields();
    }
    const onFinish = values => {
        const feeComponents = [];
        Object.entries(values)?.forEach(([key, value]) => {
            if (key.includes("amount_")) {
                feeComponents.push({ feeComponentId: key.split("_")[1], amount: value });
            }
        })
        const payload = values?.section?.map(classId => ({ classId, feeComponents }));
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        FeeManagementService.createClassFeeComponent(payload, params)
            .then(response => {
                successNotification("Fee Components added successfully.");
                getClassFeeComponents();
                handleCancel();
            })
            .catch(error => {
                errorNotification(dynamicErrorMsg(error, "Create", "Class Fee Components"));
            });
    }
    const handleTotalAmount = () => {
        const values = feeStructureForm.getFieldsValue();
        let total = 0;
        Object.entries(values).forEach(([key, value]) => {
            if (key?.includes("amount_")) {
                if (parseInt(value)) {
                    total += parseInt(value);
                }
            }
        })
        setTotalAmount("Rs. " + total);
    }

    return (
        <Modal
            title="Fee Configuration"
            open={classFeeStructureModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
        >
            <Form
                form={feeStructureForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                name="className"
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <SelectFloatLabel
                                    options={classes}
                                    allowClear={false}
                                    onChange={(value) => setSelectedClass(value)}
                                    optionFilterProp="label"
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    label="Select Class"
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                name="section"
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <SelectFloatLabel
                                    mode="multiple"
                                    options={sections}
                                    allowClear={false}
                                    label="Select Section"
                                ></SelectFloatLabel>
                            </Form.Item>
                        </Col>
                    </>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="components"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                options={componentsData?.map(component => ({ label: component?.componentName, value: `${component?.id}_${component?.componentName}` }))}
                                onChange={(value) => setSelectedComponents(value)}
                                mode="multiple"
                                label="Select Fee Components"
                            />
                        </Form.Item>
                    </Col>
                    {selectedComponents?.map(componentValue => (
                        <Col span={12}
                            key={componentValue?.split("_")[0]}
                        >
                            <Form.Item
                                name={`amount_${componentValue?.split("_")[0]}`}
                                onChange={handleTotalAmount}
                                rules={[
                                    {
                                        required: true,
                                        message: ""
                                    },
                                ]}
                            >
                                <InputFloatLabel
                                    label={componentValue?.split("_")[1]}
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
                <div className="flex-center total-amount-styles">
                    {totalAmount}
                </div>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>

        </Modal>
    )
}

export default ClassFeeStructureModal