import { CATEGORY_URL, COMPONENT_URL, SALARY_CONFIG_URL, SALARY_GRADE, SALARY_PAID_STATUS, SALARY_STRUCTURE } from "../Constants/Constants"
import { ACCOUNTS_SERVICE } from "../Constants/StringConstants"
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest, PutAPIRequest } from "./Api"

//Category

const createCategory = (payload) => {
    return PostAPIRequest({
        url: CATEGORY_URL,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const updateCategory = ( id, payload) => {
    return PostAPIRequest({
        url: CATEGORY_URL + "/" + id,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const getAllCategories = () => {
    return GetAPIRequest({
        url: CATEGORY_URL,
        service: ACCOUNTS_SERVICE
    })
}

const getCategory = (id) => {
    return GetAPIRequest({
        url: CATEGORY_URL + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const deleteCategory = (id) => {
    return DeleteAPIRequest({
        url: CATEGORY_URL + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

//Component

const createComponent = (payload) => {
    return PostAPIRequest({
        url: COMPONENT_URL,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const updateComponent = (id, payload) => {
    return PostAPIRequest({
        url: COMPONENT_URL + "/" + id,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const getAllComponents = () => {
    return GetAPIRequest({
        url: COMPONENT_URL,
        service: ACCOUNTS_SERVICE
    })
}

const getComponent = (id) => {
    return GetAPIRequest({
        url: COMPONENT_URL + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const deleteComponent = (id) => {
    return DeleteAPIRequest({
        url: COMPONENT_URL + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const createStaffSalaryConfig = (payload) => {
    return PostAPIRequest({
        url: SALARY_CONFIG_URL,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const updateStaffSalaryConfig = (id, payload) => {
    return PutAPIRequest({
        url: SALARY_CONFIG_URL + "/" + id,
        data: payload,
        service: ACCOUNTS_SERVICE
    })
}

const getStaffSalaryConfig = (staffId) => {
    return GetAPIRequest({
        url: SALARY_CONFIG_URL + "/staff/" + staffId,
        service: ACCOUNTS_SERVICE
    })
}

const deleteStaffSalaryConfig = (id) => {
    return DeleteAPIRequest({
        url: SALARY_CONFIG_URL + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const createGrade = (payload) => {
    return PostAPIRequest({
        url: SALARY_GRADE,
        service: ACCOUNTS_SERVICE,
        data: payload
    })
}

const updateGrade = (id, payload) => {
    return PostAPIRequest({
        url: SALARY_GRADE + "/" + id,
        service: ACCOUNTS_SERVICE,
        data: payload
    })
}

const getGrades = () => {
    return GetAPIRequest({
        url: SALARY_GRADE,
        service: ACCOUNTS_SERVICE,
    })
}

const deleteGrade = (id) => {
    return DeleteAPIRequest({
        url: SALARY_GRADE + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const assignSalaryStructure = (id) => {
    return PostAPIRequest({
        url: SALARY_STRUCTURE + "/" + id,
        service: ACCOUNTS_SERVICE
    })
}

const assignForAllStaff = (payload) => {
    return PostAPIRequest({
        url: SALARY_STRUCTURE,
        service: ACCOUNTS_SERVICE,
        data: payload
    })
}

const getAllStaffSalaryStructure = () => {
    return GetAPIRequest({
        url: SALARY_STRUCTURE,
        service: ACCOUNTS_SERVICE,
    })
}

const getStaffSalaryStructure = (staffId) => {
    return GetAPIRequest({
        url: SALARY_STRUCTURE + "/staff/" + staffId,
        service: ACCOUNTS_SERVICE,
    })
}

const getAllStaffSalaryPaidStatus = (params) => {
    return GetAPIRequest({
        url: SALARY_PAID_STATUS,
        params,
        service: ACCOUNTS_SERVICE,
    })
}

const updateStaffSalaryPaidStatus = (id, params) => {
    return PostAPIRequest({
        url: SALARY_PAID_STATUS + "/" + id,
        params,
        service: ACCOUNTS_SERVICE,
    })

}


export const salaryStructureService = {
    // Category
        createCategory,
        updateCategory,
        getAllCategories,
        getCategory,
        deleteCategory,
    
    //Component
        createComponent,
        updateComponent,
        getAllComponents,
        getComponent,
        deleteComponent,

    //Staff Salary Config
        createStaffSalaryConfig,
        getStaffSalaryConfig,
        updateStaffSalaryConfig,
        deleteStaffSalaryConfig,

    //Salary Grade
        createGrade,
        updateGrade,
        getGrades,
        deleteGrade,

    //assign salary structure
        assignSalaryStructure,
        assignForAllStaff,
        getAllStaffSalaryStructure,
        getStaffSalaryStructure,

    //Staff Salary Paid Status
        getAllStaffSalaryPaidStatus,
        updateStaffSalaryPaidStatus
        
}