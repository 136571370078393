import { Col, Form, Modal, Row } from "antd";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import FormButton from "../../Common/formButtons/FormButton";
import { useEffect } from "react";
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, successNotification } from "../../../Constants/Utils";
import { modalMediumWidth, modalSize } from "../../../Constants/StyleConstants";

const EditClassFeeComponentModal = ({
    editClassComponentModal,
    setEditClassComponentModal,
    record,
    setRecord,
    feeComponentsData,
    classId,
    getClassFeeComponents,
    activeYear
}) => {
    const [editClassComponentForm] = Form.useForm();
    useEffect(() => {
        editClassComponentForm.setFieldsValue({
            feeComponentId: record?.feeComponentId,
            amount: record?.amount,
        })
    }, [record])
    const handleCancel = () => {
        setEditClassComponentModal(false);
        setRecord(null);
        editClassComponentForm.resetFields();
    }
    const onFinish = values => {
        const payload = {
            classId,
            ...values
        }
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        FeeManagementService.editClassFeeComponent(record?.id, payload, params)
            .then(response => {
                successNotification("Class Fee Component updated successfully.");
                handleCancel();
                getClassFeeComponents();
            })
            .catch(error => {
                errorNotification(dynamicErrorMsg(error, "Edit", "Class fee Component"))
            })
    }
    return (
        <Modal
            title="Update Class Fee Component"
            open={editClassComponentModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
        >
            <Form
                form={editClassComponentForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="feeComponentId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                disabled={true}
                                options={feeComponentsData?.filter(component => component?.childFeeComponentResponseList?.length === 0)?.map(component => ({ label: component?.componentName, value: component?.id }))}
                                label="Fee Component"
                                allowClear={false}
                            ></SelectFloatLabel>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Amount"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default EditClassFeeComponentModal