import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Input, Space, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { salaryStructureService } from '../../../../Services/SalaryStructureService';
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../../Constants/Utils';

const SalaryGrade = () => {
    const [salaryGrades, setSalaryGrades] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [editSalaryGrade, setEditSalaryGrade] = useState(null)

    const [form] = Form.useForm();

    useEffect(() => {
        getSalaryGrades()
    }, [])

    const getSalaryGrades = () => {
        salaryStructureService.getGrades()
            .then(response => {
                console.log('response', response)
                setSalaryGrades(response?.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    // Function to show modal for creating a salary grade
    const showCreateModal = () => {
        setIsEditing(false);
        setSelectedGrade(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    // Function to show modal for editing a salary grade
    const showEditModal = (grade) => {
        setEditSalaryGrade(grade)
        setIsEditing(true);
        setSelectedGrade(grade);
        form.setFieldsValue({
            gradeName: grade.gradeName,
            minSalary: grade.minSalary,
            maxSalary: grade.maxSalary,
        });
        setIsModalVisible(true);
    };

    // Function to handle modal cancel
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Function to handle form submission (Create or Edit)
    const handleFormSubmit = (values) => {
        console.log('values', values)
        const payload = {...values}
        if (editSalaryGrade){
            salaryStructureService.updateGrade(editSalaryGrade?.id ,payload)
            .then(response => {
                successNotification("Salary grade created successfully")
                getSalaryGrades()
                setIsModalVisible(false)
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Create", "Salary Grade")
                errorNotification(errorMsg)
            })
        }else{
            salaryStructureService.createGrade(payload)
            .then(response => {
                successNotification("Salary grade created successfully")
                setIsModalVisible(false)
                getSalaryGrades()
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Create", "Salary Grade")
                errorNotification(errorMsg)
            })
        }
    };

    const deleteGrade = (record) => {
        salaryStructureService.deleteGrade(record?.id)
            .then(response => {
                successNotification("Salary Grade deleted successfully")
                getSalaryGrades()
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Delete", "Salary Grade")
                errorNotification(errorMsg)
            })
    }

    const columns = [
        {
            title: 'Grade Name',
            dataIndex: 'gradeName',
            key: 'gradeName',
        },
        {
            title: 'Start Salary',
            dataIndex: 'minSalary',
            key: 'minSalary',
        },
        {
            title: 'End Salary',
            dataIndex: 'maxSalary',
            key: 'maxSalary',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <EditOutlined onClick={(e) => showEditModal(record, e)} />
                    <DeleteOutlined onClick={() => deleteGrade(record)} />
                </Space>
            ),
        },
    ];

    return (
        <main>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h1>Salary Grades</h1>

                {/* Display Salary Grades in a Table */}
                <Button
                    icon={<PlusOutlined />}
                    onClick={showCreateModal}
                    style={{ marginBottom: 16 }}
                    className='blue-button'
                >
                    Create Salary Grade
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={salaryGrades}
                rowKey="id"
                pagination={false}
            />

            {/* Modal for Create/Edit Salary Grade */}
            <Modal
                title={isEditing ? 'Edit Salary Grade' : 'Create Salary Grade'}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        label="Grade Name"
                        name="gradeName"
                        rules={[{ required: true, message: 'Please enter the grade name!' }]}
                    >
                        <Input placeholder="Enter grade name" />
                    </Form.Item>

                    <Form.Item
                        label="Start Salary"
                        name="minSalary"
                        rules={[{ required: true, message: 'Please enter the start salary!' }]}
                    >
                        <Input type="number" placeholder="Enter start salary" />
                    </Form.Item>

                    <Form.Item
                        label="End Salary"
                        name="maxSalary"
                        rules={[{ required: true, message: 'Please enter the end salary!' }]}
                    >
                        <Input type="number" placeholder="Enter end salary" />
                    </Form.Item>
                    <Row gutter={[16, 8]} justify="end">
                        <Col span={10} className="modal-buttons">
                            <Space direction="vertical" size="large">
                                <Button
                                    className="buttons cancel-button"
                                    htmlType="submit"
                                    style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
                                >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col span={10} className="modal-buttons">
                            <Space direction="vertical" size="large">
                                <Button
                                    className="newCancelButton"
                                    onClick={handleCancel}
                                    style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
                                >
                                    Cancel
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </main>
    );
};

export default SalaryGrade;
