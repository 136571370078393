import React, { useState } from 'react';
import { Card, Row, Col, Typography, Button, Modal, Input, message } from 'antd';

const { Title, Text } = Typography;

const ExistingTickets = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!inputValue.trim()) {
      message.error('Comment cannot be empty');
      return;
    }
    console.log(inputValue); // Handle the send button logic here
    setIsModalVisible(false);
    setInputValue('');
    setIsInputEmpty(true);
    message.success('Your comment was sent');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsInputEmpty(!e.target.value.trim());
  };

  return (
    <div style={{ padding: '20px' }}>
      <Card bordered={false} style={{ borderRadius: '8px' }}>
        <Row gutter={60}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <div>
              <Title level={4}>Title :</Title>
              <Text>Ticket-1</Text>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Title level={5}>Description :</Title>
              <Text>Can you please resolve the issue in Announcement</Text>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Text strong>Ticket Type :</Text> <Text>severity</Text>
            </div>
            <div>
              <Button style={{ marginTop: 20 }} onClick={showModal}>
                Comment
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <img
              src="https://images.pexels.com/photos/8424927/pexels-photo-8424927.jpeg?auto=compress&cs=tinysrgb&w=600" // Replace with your image URL
              alt="description"
              style={{ width: '100%', borderRadius: '8px' }}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="Add a Comment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
        okButtonProps={{ disabled: isInputEmpty }}
      >
        <Input
          placeholder="Enter your comment"
          value={inputValue}
          onChange={handleInputChange}
          required
        />
      </Modal>
    </div>
  );
};

export default ExistingTickets;
