import { Radio } from "antd";
import { useState } from "react";
import "./KitchenDashboard.scss";
import Expenses from "../Expenses/Expenses";
import KitchenReports from "../KitchenReports/KitchenReports";
import KitchenItems from "../KitchenItems/KitchenItems";
import DailyConsmption from "../DailyConsmption/DailyConsmption";
import ReaminingStock from "../ReaminingStock/ReaminingStock";
const KitchenDashboard = () => {
  const [selectedRadio, setSelctedRadio] = useState("items");
  return (
    <main>
      <div className="kitchen-header">
        <h1>Kitchen Management</h1>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          defaultValue="open"
          value={selectedRadio}
          onChange={(e) => {
            const value = e.target.value;
            setSelctedRadio(value);
          }}
        >
          <Radio.Button value="items">Items</Radio.Button>
          <Radio.Button value="expenses">Expenses</Radio.Button>
          <Radio.Button value="consmption">Daily Consumption</Radio.Button>
          <Radio.Button value="reaminingStock">Remaining Stock</Radio.Button>
          {/* <Radio.Button value="reports">Reports</Radio.Button> */}
        </Radio.Group>
      </div>

      {selectedRadio === "items" && <KitchenItems />}
      {selectedRadio === "consmption" && <DailyConsmption />}
      {selectedRadio === "reaminingStock" && <ReaminingStock />}
      {selectedRadio === "expenses" && <Expenses />}
      {/* {selectedRadio === "reports" && <KitchenReports />} */}
    </main>
  );
};

export default KitchenDashboard;
