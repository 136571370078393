import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import "./Settings.scss";
import { socialMediaService } from "../../Services/SocialMediaService";

const Settings = () => {
  const [facebookShowPassword, setFacebookShowPassword] = useState(false);
  const [instagramShowPassword, setInstagramShowPassword] = useState(false);
  const [socialMediaPosts, setSocialMediaPosts] = useState([]);
  const gettingSocialMediaPosts = () => {
    socialMediaService
      .getSocialMediaPosts()
      .then((response) => {
        setSocialMediaPosts(response?.data);
      })
      .catch((error) => {
        console.log("unable to fetch the data", error);
      });
  };
  console.log("oneplus Nord", socialMediaPosts);
  useEffect(() => {
    gettingSocialMediaPosts();
  }, []);
  const toggleFacebookPasswordVisibility = () => {
    setFacebookShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleInstagramPasswordVisibility = () => {
    setInstagramShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <div className="settings-facebook">
        <h1>FaceBook Token: </h1>
        &nbsp;&nbsp;&nbsp;
        <Input
          style={{ width: 300, opacity: facebookShowPassword ? 1 : 0.1 }}
          // type={facebookShowPassword ? "text" : "password"}
          disabled={!facebookShowPassword}
        />
        {facebookShowPassword ? (
          <EyeInvisibleOutlined
            style={{ fontSize: 20, marginLeft: 10 }}
            onClick={toggleFacebookPasswordVisibility}
          />
        ) : (
          <EyeOutlined
            style={{ fontSize: 20, marginLeft: 10 }}
            onClick={toggleFacebookPasswordVisibility}
          />
        )}
      </div>
      <h1>Total Posts: {socialMediaPosts?.facebookPost}</h1>
      <div className="settings-facebook">
        <h1>Instagram Token: </h1>
        &nbsp;&nbsp;&nbsp;
        <Input
          style={{
            width: 300,
            opacity: instagramShowPassword ? 1 : 0.1,
            marginLeft: 10,
          }}
          disabled={!instagramShowPassword}
          // type={instagramShowPassword ? "text" : "password"}
        />
        {instagramShowPassword ? (
          <EyeInvisibleOutlined
            style={{ fontSize: 20, marginLeft: 10 }}
            onClick={toggleInstagramPasswordVisibility}
          />
        ) : (
          <EyeOutlined
            style={{ fontSize: 20, marginLeft: 10 }}
            onClick={toggleInstagramPasswordVisibility}
          />
        )}
      </div>
      <h1>Total Posts: {socialMediaPosts?.instagramPost}</h1>
    </div>
  );
};

export default Settings;
