import React, { useEffect, useState } from 'react'
import ClassDropdown from '../../Common/Class/ClassDropdown'
import SectionDropdown from '../../Common/Section/SectionDropdown'

import "./FeeSummary.scss"
import { FeeManagementService } from '../../../Services/FeeManagementService'
import { Table } from 'antd'

const FeeSummary = ({ activeYear }) => {
    const [selectedClass, setSelectedClass] = useState();
    const [selectedSection, setSelectedSection] = useState();
    const [data, setData] = useState();

    const onClassChange = (value) => {
        setSelectedClass(value);
    }
    const onSectionChange = (value) => {
        setSelectedSection(value);
    };

    useEffect(() => {
        getClassFeeSummary()
    }, [selectedSection])

    const getClassFeeSummary = () => {
        if (selectedSection) {
            FeeManagementService.getClassFeeSummary(selectedSection?.classId)
                .then(response => {
                    setData(response?.data)
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    }

    const filteredData = data?.filter(
        (record) =>
            record.discountedAmount >= 0 &&
            record.paidAmount >= 0 &&
            record.remainingAmount >= 0
    );

    // Define columns
    const columns = [
        // {
        //     title: "Student ID",
        //     dataIndex: "studentId",
        //     key: "studentId",
        // },
        {
            title: "Student Name",
            dataIndex: "studentName",
            key: "studentName",
        },
        // {
        //     title: "Class ID",
        //     dataIndex: "classId",
        //     key: "classId",
        // },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (amount) => `₹${amount.toLocaleString()}`, // Format as currency
        },
        // {
        //     title: "Actual Amount",
        //     dataIndex: "actualAmount",
        //     key: "actualAmount",
        //     render: (amount) => `₹${amount.toLocaleString()}`, // Format as currency
        // },
        {
            title: "Discounted Amount",
            dataIndex: "discountedAmount",
            key: "discountedAmount",
            render: (amount) => `₹${amount.toLocaleString()}`, // Format as currency
        },
        {
            title: "Paid Amount",
            dataIndex: "paidAmount",
            key: "paidAmount",
            render: (amount) => `₹${amount.toLocaleString()}`, // Format as currency
        },
        {
            title: "Remaining Amount",
            dataIndex: "remainingAmount",
            key: "remainingAmount",
            render: (amount) => `₹${amount.toLocaleString()}`, // Format as currency
        },
    ];


    return (
        <main>
            <div className='feeSummaryContainer'>
                FeeSummary
                <div className="flex-row">
                    <ClassDropdown
                        onClassChange={onClassChange}
                        selectedClass={selectedClass}
                        fromAdmissions={!activeYear?.isPresentYear}
                    />
                    <SectionDropdown
                        classValue={selectedClass}
                        onSectionChange={onSectionChange}
                        selectedSection={selectedSection}
                        fromAdmissions={!activeYear?.isPresentYear}
                    />
                </div>
            </div>
            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="studentId"
                // pagination={{ pageSize: 10 }}
                pagination={false}
            />
        </main>
    )
}

export default FeeSummary