import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import InstallmentsOverviewModal from './InstallmentsOverviewModal';
import { FeeManagementService } from '../../../Services/FeeManagementService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';

const InstallmentsOverview = ({
    selectedPlan,
    feeComponentsData,
    classId,
    activeYear,
    getInstallmentsMasterData,
    installmentsMasterData,
}) => {
    const [record, setRecord] = useState(null);
    const [editModal, setEditModal] = useState(false);

    const handleEdit = record => {
        setEditModal(true);
        setRecord(record);
    }
    const handleDeleteInstallmentMaster = (record) => {
        Modal.confirm({
            title: "Are you sure to delete Installment Component ?",
            onOk: () => {
                const params = {
                    isStaging: !activeYear?.isPresentYear
                }
                FeeManagementService.deleteInstallmentMaster(record?.id, params)
                    .then(response => {
                        successNotification(response?.data);
                        getInstallmentsMasterData();
                    })
                    .catch(error => {
                        errorNotification(dynamicErrorMsg(error, "Delete", "Installment Component"))
                    })
            }
        })
    }
    const tableData = () => installmentsMasterData?.filter(installment => installment?.installmentPlanResponseDTO?.id === selectedPlan?.id);
    const columns = [
        {
            title: "S.No",
            render: (_, record, index) => index + 1,
            width: 50,
        },
        {
            title: "Fee Component",
            // render: (_, record) => record?.feeComponentsResponse?.componentName,
            render: (_, record) => {
                const componentName = record?.feeComponentsResponse?.componentName;
                return record?.advanced ? <span>{componentName} <span className="color-green">(Advanced)</span></span> : componentName;
            }
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            render: (amount, record) => record?.advanced ? <span className="color-green">+ {amount}</span> : amount,
        },
        // {
        //     title: "Action",
        //     key: "action",
        //     fixed: "right",
        //     render: (_, record) => (
        //         <Space>
        //             <EditOutlined
        //                 onClick={() => handleEdit(record)}
        //             />
        //             <DeleteOutlined
        //                 onClick={() => handleDeleteInstallmentMaster(record)}
        //             />
        //         </Space>
        //     ),
        // },
    ];
    return (
        <div className="card-view class-components-data-view margin-top margin-left" style={{ width: "30vw" }}>
            <div className="flex-between">
                <h1 className="color-green">Installment: {selectedPlan?.planName}</h1>
                {/* <Button className='blue-button'
                    onClick={() => setEditModal(true)}
                ><PlusOutlined /></Button> */}
            </div>
            <Table
                columns={columns}
                dataSource={tableData()}
                pagination={false}
            />
            {editModal && (
                <InstallmentsOverviewModal
                    modalOpen={editModal}
                    setModalOpen={setEditModal}
                    record={record}
                    setRecord={setRecord}
                    feeComponentsData={feeComponentsData}
                    classId={classId}
                    selectedPlan={selectedPlan}
                    getInstallmentsMasterData={getInstallmentsMasterData}
                    activeYear={activeYear}
                />
            )}
        </div>
    )
}

export default InstallmentsOverview