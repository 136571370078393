import { Button, Col, Form, Modal, Row, notification } from "antd";
import {
  CheckCircleFilled,
  CloseOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import InputFloatLabel from "../Common/FloatLabel/Input";

import { useState } from "react";
import { socialMediaService } from "../../Services/SocialMediaService";
import { dynamicErrorMsg } from "../../Constants/Utils";

const SocialMediaPostAi = ({
  selectedImages,
  socialMediaModalAI,
  setSelectedImages,
  setsocialMediaModalAI,
  divElement,
  setdivElement,
  handleEmptyImages,
}) => {
  const [localSelectedImages, setLocalSelectedImages] =
    useState(selectedImages);
  const [AiForm] = Form.useForm();
  const [publishposter, setpublishposter] = useState([]);
  const [animation, setanimation] = useState(false);

  const handleBack = () => {
    setdivElement(false);
  };

  const handleCancel = () => {
    setdivElement(false);
  };

  const onFinishPoster = (values) => {
    setanimation(true);
    console.log("for the good", values);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("postersURL", localSelectedImages);
    // localSelectedImages.forEach((imageUrl, index) => {

    // });

    console.log("here are the values", formData);
    socialMediaService
      .createSocialMediaPoster(formData)
      .then((response) => {
        AiForm.resetFields();
        onFinish(response?.data);
        // setdivElement(false);
        notification.success({
          message: `Poster Created Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Social Media Poster");
          
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
      });
  };

  const instagramPost = (values) => {
    socialMediaService
      .InstagramPost(values)
      .then((response) => {
        AiForm.resetFields();
        setdivElement(false);
        notification.success({
          message: `Instagram Post Created Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Instagram Post");
          
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
      });
  };

  const onFinish = (values) => {
    console.log("for the good", values);
    const payload = {
      message: values.description,
      longUrls: values.postersURL.toString(),
    };
    console.log("payyyyy", payload);

    instagramPost(payload);
    console.log("here are the values", payload);
    socialMediaService
      .facebookpost(payload)
      .then((response) => {
        AiForm.resetFields();
        setLocalSelectedImages([]);
        handleEmptyImages();
        setanimation(false);
        notification.success({
          message: `Facebook Post Created Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Facebook Post");
          
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...localSelectedImages];
    updatedImages.splice(index, 1);
    setLocalSelectedImages(updatedImages);
  };
  console.log("publishposter is hereses", publishposter);
  return (
    <div className="white-card " style={{ padding: 40 }}>
      {/* <Modal
        title="Create Social Media Post"
        open={socialMediaModalAI}
        onCancel={handleCancel}
        footer={null}
        centered
      > */}
      <Button
        className="cancel-button"
        onClick={handleBack}
        style={{ margin: 10 }}
      >
        Back
      </Button>
      <Form
        onFinish={onFinishPoster}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={AiForm}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please enter the title" }]}
            >
              <InputFloatLabel name="title" type="string" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="description"
              rules={[
                { required: true, message: "Please enter the description" },
              ]}
            >
              <InputFloatLabel name="description" type="string" />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{ display: "flex", flexWrap: "wrap", gap: 15, marginTop: 20 }}
        >
          {localSelectedImages.length > 0 &&
            localSelectedImages.map((imageUrl, index) => (
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt={`AI Generated ${index}`}
                  style={{ width: 200 }}
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => handleRemoveImage(index)}
                />
              </div>
            ))}
        </div>
        <>
          {animation && (
            <div
              class="loading"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} align="end">
            <Button type="primary" htmlType="submit" className="cancel-button">
              Post
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="danger"
              onClick={handleCancel}
              className="newCancelButton"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      {/* <Button
        className="cancel-button"
        style={{ display: publishposter ? "block" : "none" }}
        onClick={onFinish}
      >
        Publish
      </Button> */}
      {/* </Modal> */}
    </div>
  );
};

export default SocialMediaPostAi;
