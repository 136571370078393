import { Modal } from "antd"
import './UpdatesPopupModal.scss';
import { modalSize } from "../../../Constants/StyleConstants";

const AppUpdatesModal = ({
    appUpdatesModal,
    setAppUpdatesModal,
    updates,
}) => {
    const handleCancel = () => {
        setAppUpdatesModal(false);
    }

    return (
        <Modal
            title={<h1 className="store-modal-heading">Important Announcement</h1>}
            open={appUpdatesModal}
            width={700}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            {updates?.map(record => (
                <div key={record?.id}>
                    <h1 className="color-green">{record?.title}</h1>
                    {record?.updates?.map((update, index) => (
                        update !== "" && (<li key={index}>• {update}</li>)
                    ))}
                </div>

            ))}
        </Modal>
    )
}

export default AppUpdatesModal
// →