import { Select } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getClassList } from "../../../store/classSlice";
import { classCustomOrder } from "../../../Constants/Utils";
import { stagedService } from "../../../Services/StagedService";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
const { Option } = Select;

function ClassDropdown({ onClassChange, selectedClass, showAllOption, fromAdmissions=false }) {
  const [Class, setClass] = useState(null);
  const [classAndSections, setClassAndSections] = useState([])

  const classListFromRedux = useSelector(getClassList);

  useEffect(() => {
    if (fromAdmissions) {
      // Fetch data from stagedService when fromAdmissions is true
      stagedService.getStagedClasses()
        .then(response => {
          setClassAndSections(response?.data);
        })
        .catch(error => {
          console.error('Error fetching staged classes:', error);
        });
    } else {
      // Use Redux data when fromAdmissions is false
      setClassAndSections(classListFromRedux);
    }
  }, [fromAdmissions, classListFromRedux]);

  const uniqueClasses = classAndSections?.reduce((acc, cur) => {
    if (!acc.includes(cur.className)) {
      acc.push(cur.className);
    }
    return acc;
  }, []);
  const handleIntialClass = () => {
    const initialClass =
      selectedClass ||
      (uniqueClasses.length > 0 ? uniqueClasses[0] : undefined);
    setClass(initialClass);
    onClassChange(initialClass);
  };

  useEffect(() => {
    handleIntialClass();
  }, [uniqueClasses]);

  const sortClasses = (classes) => {
    const customOrderIndex = (className) => {
      const index = classCustomOrder.indexOf(className);
      return index === -1 ? Infinity : index;
    };

    const numericClasses = classes.filter(c => !classCustomOrder.includes(c))
      .sort((a, b) => {
        const numA = parseInt(a);
        const numB = parseInt(b);
        if (isNaN(numA) || isNaN(numB)) {
          return a.localeCompare(b);
        }
        return numA - numB;
      });

    return [
      ...classCustomOrder.filter(c => classes.includes(c)),
      ...numericClasses
    ];
  };

  const sortedUniqueClasses = sortClasses(uniqueClasses);

 
  return (
    <Select
      value={Class}
      onChange={(value) => {
        setClass(value);
        onClassChange(value);
      }}
      placeholder="Class"
    >
      {showAllOption && <Option value="all">All </Option>}
      {sortedUniqueClasses.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}

export default ClassDropdown;
