import { Button, Form, Modal, Select, Space, Table } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useState, useEffect } from 'react';
import { salaryStructureService } from '../../../../Services/SalaryStructureService';
import { EditOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../../Constants/Utils';

const StaffSalaryPaidStatus = () => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [staffSalaryData, setStaffSalryData] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [months] = useState([
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ]);
  const [selectedStatus,  setSelectedStatus] = useState()
  
  useEffect(() => {
    // Get current date
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Default to the previous month if today is before the 28th
    if (currentDay < 28) {
      setSelectedMonth(months[currentMonthIndex - 1] || months[11]); // Set previous month, default to December if it's January
      handleMonthChange(months[currentMonthIndex - 1] || months[11])
    } else {
      setSelectedMonth(months[currentMonthIndex]); // Set current month if it's 28 or later
      handleMonthChange(months[currentMonthIndex])
    }
  }, [months]);

  const handleMonthChange = (value) => {
    setSelectedMonth(value)
  };

  useEffect(() => {
    getSalaryPaidData()
  }, [selectedMonth])

  const getSalaryPaidData = () => {
    const params = {
      monthName: selectedMonth
    }
    salaryStructureService.getAllStaffSalaryPaidStatus(params)
      .then(response => {
        setStaffSalryData(response?.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  const editSalaryPaidStatus = (record) => {
    setIsModalVisible(true)
    setSelectedRow(record)
    console.log('record', record) 
  }

  const columns = [
    {
      title: 'Staff Name',
      dataIndex: 'staffName',
      key: 'staffName',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Monthly Salary',
      dataIndex: 'staffMonthlySalary',
      key: 'staffMonthlySalary',
      render: (text) => (text ? text : 'N/A'), // If null, show 'N/A'
    },
    {
      title: 'Annual Salary',
      dataIndex: 'staffAnnualSalary',
      key: 'staffAnnualSalary',
      render: (text) => (text ? text : 'N/A'), // If null, show 'N/A'
    },
    {
      title: 'Salary Status',
      dataIndex: 'salaryStatus',
      key: 'salaryStatus',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      fixed: "right",
      align: "center",
      render: (_, record) => (
          <div style={{ padding: "0px 25px" }}>
              <Space size="middle">
                  <EditOutlined onClick={(e) => editSalaryPaidStatus(record, e)} />
              </Space>
          </div>
      ),
  },
  ];

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedRow(null)
        setSelectedStatus(null)
  }

  const handleStatusChange = (value) => {
    setSelectedStatus(value)
  }

  const submitStatus = () => {
    const params = {
      status: selectedStatus
    }

    salaryStructureService.updateStaffSalaryPaidStatus(selectedRow?.id,params)
      .then(response => {
        successNotification("Status updated successfully")
        getSalaryPaidData()
        setSelectedRow(null)
        setSelectedStatus(null)
        setIsModalVisible(false)
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Update", "Status")
        errorNotification(errorMsg)
      })
  }


  return (
    <main>
      <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"space-between", padding: "20px"}}>
      <h1>Staff Salary Paid Status</h1>
      
      <Select id="month" value={selectedMonth} onChange={handleMonthChange}>
        {months.map((month, index) => (
          <Option key={index} value={month}>
            {month}
          </Option>
        ))}
      </Select>
      </div>
      <Table
        dataSource={staffSalaryData}
        columns={columns}
        rowKey={(record) => record.staffName} // Setting a unique key for each row
      />

<Modal
        title="Select Salary Status"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Custom footer
      >
        <Form layout="vertical" onFinish={submitStatus}>
          <Form.Item label="Salary Status">
            <Select
              placeholder="Select Salary Status"
              onChange={handleStatusChange}
              value={selectedStatus}
            >
              <Option value="PAID">Paid</Option>
              <Option value="PARTIAL_PAID">Partial Paid</Option>
              <Option value="UNPAID">Unpaid</Option>
            </Select>
          </Form.Item>

          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button htmlType="submit" className='blue-button'>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </main>
  );
};

export default StaffSalaryPaidStatus;
