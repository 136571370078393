import { Col, Modal, Radio, Row, Form, TimePicker, DatePicker } from "antd"
import './UpdatesPopupModal.scss';
import { modalBigWidth, modalSize } from "../../../Constants/StyleConstants";
import FormButton from "../../Common/formButtons/FormButton";
import { useState } from "react";
import { NO, PAY_LATER, PAY_NOW } from "../../../Constants/StringConstants";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import moment from "moment";
import TextArea from "../../Common/FloatLabel/TextArea";
import { BranchService } from "../../../Services/BranchService";
import { successNotification } from "../../../Constants/Utils";

const UnpaidModal = ({
    unpaidModal,
    setUnpaidModal,
}) => {
    const [reminderForm] = Form.useForm();
    const [paymentResponse, setPaymentResponse] = useState(PAY_NOW);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setUnpaidModal(false);
    }

    const onFinish = values => {
        let dateTime;
        if (paymentResponse === PAY_LATER) {
            dateTime = moment(values?.date).set({
                hour: values?.time.hour(),
                minute: values?.time.minute(),
                second: values?.time.second(),
            });
        }
        else {
            dateTime = moment().set({
                hour: values?.time.hour(),
                minute: values?.time.minute(),
                second: values?.time.second(),
            });
        }
        const reminderDate = dateTime.format("DD-MM-YYYY HH:mm:ss");
        const payload = {
            reminderDate,
            contactNumber: values?.contactNumber,
            reason: values?.reason ?? null,
            response: values?.response,
        }
        setLoading(true);
        BranchService.sendPaymentResponse(payload)
            .then(response => {
                // sessionStorage.setItem("paymentReminder", "reminder displayed");
                // setUnpaidModal(false);
                successNotification(response?.data)
            })
            .catch(error => { })
            .finally(() => {
                sessionStorage.setItem("paymentReminder", "reminder displayed");
                handleCancel();
                setLoading(false);
            })
    }

    const disabledDate = (current) => {
        const today = moment().startOf('day');
        const next15Days = moment().add(15, 'days').endOf('day');
        return current && (current.isBefore(today) || current.isAfter(next15Days));
    };

    return (
        <Modal
            title="Payment Reminder"
            open={unpaidModal}
            width={modalBigWidth}
            footer={null}
            // onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
            closable={false}
        >
            <h1>Dear Sir/Madam,</h1>
            <p>We have noticed that the payment for the EdPedia app is pending, and our team has been unable to reach you despite multiple attempts.</p>
            <p>To ensure uninterrupted access to the app and its features, we kindly request you to address the payment at the earliest or contact our support team for assistance.</p>
            <p>Your prompt action will help us continue delivering seamless services to your institution.</p>
            <Form
                form={reminderForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="response"
                            label="Response"
                            initialValue={PAY_NOW}
                            rules={[{ required: true, message: "Please select your gender!" }]}
                        >
                            <Radio.Group value={paymentResponse} onChange={(event) => setPaymentResponse(event.target.value)}>
                                <Radio value={PAY_NOW}>{PAY_NOW}</Radio>
                                <Radio value={PAY_LATER}>{PAY_LATER}</Radio>
                                <Radio value={NO}>{NO}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="contactNumber"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                                {
                                    len: 10,
                                    message: "Please enter valid contact number",
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Contact Number"
                                name="contact"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                    {paymentResponse === PAY_LATER && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item initialValue={moment()} name="date" rules={[{ required: true, message: "" }]}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder="Select Date"
                                    disabledDate={disabledDate}
                                    format="DD-MM-YYYY"
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col className="gutter-row" span={6}>
                        <Form.Item name="time" rules={[{ required: true, message: "" }]}>
                            <TimePicker
                                format="HH:mm"
                                minuteStep={10}
                                placeholder="Select Time"
                                inputReadOnly={false}
                                showNow={false}
                                use12Hours={true}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {paymentResponse === NO && (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={20}>
                            <Form.Item name="reason" rules={[{ required: true, message: "" }]}>
                                <TextArea rows={4} label="Reason" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <FormButton
                    saveText="Send Response"
                    showCancel={false}
                    disabled={loading}
                />

            </Form>
        </Modal>
    )
}

export default UnpaidModal