import { Col, Form, Modal, Row } from "antd";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import FormButton from "../../Common/formButtons/FormButton";
import { useEffect } from "react";
import { modalMediumWidth, modalSize } from "../../../Constants/StyleConstants";
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, successNotification } from "../../../Constants/Utils";

const InstallmentsOverviewModal = ({
    modalOpen,
    setModalOpen,
    record,
    setRecord,
    feeComponentsData,
    classId,
    selectedPlan,
    getInstallmentsMasterData,
    activeYear
}) => {
    const [planComponentForm] = Form.useForm();
    useEffect(() => {
        if (record) {
            planComponentForm.setFieldsValue({
                feeComponentId: record?.feeComponentsResponse?.id,
                amount: record?.amount,
            })
        }
    }, [])
    const handleCancel = () => {
        setModalOpen(false);
        setRecord(null);
    }
    const onFinish = values => {
        const payload = {
            ...values,
            classId,
            installmentPlanId: selectedPlan?.id,
        }
        if (record) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.editInstallmentMaster(record?.id, payload, params)
                .then(response => {
                    getInstallmentsMasterData();
                    handleCancel();
                    successNotification("Installment Component updated successfully.");
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Installment Component"))
                });
        }
        else {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.createInstallmentMaster(payload, params)
                .then(response => {
                    getInstallmentsMasterData();
                    handleCancel();
                    successNotification("Installment Component created successfully.");
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Installment Component"))
                });
        }
    }
    return (
        <Modal
            title={`${record ? "Update" : "Create"} Component Amount`}
            open={modalOpen}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
        >
            <Form
                form={planComponentForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="feeComponentId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                options={feeComponentsData?.map(component => ({ label: component?.componentName, value: component?.id }))}
                                label="Fee Component"
                                allowClear={false}
                            ></SelectFloatLabel>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Amount"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default InstallmentsOverviewModal