import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Empty, Modal } from "antd";
import "./TicketDetails.scss";
import { Link } from "react-router-dom";
import { supportService } from "../../../Services/SupportService";
import FloatInputAllCharacters from "../../Common/FloatLabel/InputAllCharacters";
import { LeftOutlined, SendOutlined } from "@ant-design/icons";
const TicketDetails = ({viewData,setViewData}) => {
  console.log('viewData', viewData?.id)
    const [viewDetailsData, setViewDetailsData] = useState({})
    const [commentInput, setCommentInput] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
    useEffect(() => {
      viewTicketData()
    },[])

    const viewTicketData = () => {
      supportService.viewTicket(viewData?.id)
      .then((response) => {
        console.log('response.data', response.data)
        setViewDetailsData(response?.data)
        console.log('response', response)
      })
      .catch((error) => {
        console.log('error', error)
      });
    }



    const sendComment = () => {
      const payload = {
        "fromClient" : "INST_ADMIN",
        "comment" : commentInput
      }
      supportService.sendComment(viewData?.id, payload )
        .then(response => {
          viewTicketData()
          setCommentInput("")
          console.log('response', response)
        }).catch(error => {
          console.log('error', error)
        })
    }

    const handleBack = () => {
      setViewData(null)
    }

    const addComment = (event) => {
      if (event.key === "Enter"){
        sendComment()
      }else{
        setCommentInput(event.target.value)
      }
    }

    const formatDate = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Returns the date in YYYY-MM-DD format
    };
  
  
  return (
    <>
      <main>
        <Button className="blue-button" onClick={handleBack}>
          <LeftOutlined />
        </Button>
        <div className="details-container">
          <div >
            <h1>Title: {viewDetailsData?.title}</h1>
            <h2>Description: {viewDetailsData?.description}</h2>
            <h3>Status: {viewDetailsData?.status}</h3>
            {viewDetailsData?.closedDate !== null ? (
              <h3>Closed Date: {formatDate(viewDetailsData?.closedDate)}</h3>
            ) : (
              <h3>Created Date: {formatDate(viewDetailsData?.createDate)}</h3>
            )}
            {viewDetailsData?.estimatedDate && (
              <h3>Estimated Date: {formatDate(viewDetailsData?.closedDate)}</h3>
            )}
          </div>
          {viewDetailsData?.supportFile ? (
              <>
                <img
                  src={viewDetailsData?.supportFile}
                  alt="ticket-img"
                  className="image"
                  onClick={handleOpenModal}
                  style={{ cursor: 'pointer' }}
                />
                <Modal open={isModalOpen} 
                  onCancel={handleCloseModal} 
                  footer={null} 
                  centered 
                  bodyStyle={{ padding: 0 }}
                >
                  <div className="modal">
                    <img className="modal-content" src={viewDetailsData?.supportFile} alt="ticket-img" />
                  </div>
                </Modal>
                {/* {isModalOpen && (
                  <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img className="modal-content" src={viewDetailsData?.supportFile} alt="ticket-img" />
                  </div>
                )} */}
              </>
            ) : (
              <h2>No Image</h2>
            )}
        </div>
        <div className="comments-container">
          <h1>Comments</h1>
          {viewDetailsData?.commentsList?.length === 0 ? (
            <Empty />
          ) : (
            <div className="comments">
              {viewDetailsData?.commentsList?.map((comment, index) => (
                <div className="comment-container" key={index}>
                  {comment.fromClient === "INST_ADMIN" ? (
                    <div className="user-container">
                      <div className="user">
                        <h1 className="user-icon">U</h1>
                      </div>
                      <h1 className="comment-text">{comment.comment}</h1>
                    </div>
                  ) : (
                    <div className="admin-container">
                      <div className="admin">
                        <h1 className="admin-icon">A</h1>
                      </div>
                      <h1 className="comment-text">{comment.comment}</h1>
                    </div>
                  )}
                </div>
              ))}
            </div>

          )}
          <div style={{display:"flex",justifyContent:"center", alignItems:"center",marginTop:"100px"}}>
            <FloatInputAllCharacters value={commentInput} label="Add Comment" style={{width:"50vw", height:"60px", marginRight:"10px"}} onChange={addComment}/>
            <Button className="blue-button" style={{height:"60px"}} onClick={sendComment}><SendOutlined style={{fontSize:"30px"}} /></Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default TicketDetails;
