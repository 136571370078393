import { ADMISSION_DASHBOARD_DATA, CREATE_CLASSES_FROM_PREVIOUS_YEAR, GET_STAGED_STUDENTS, STAGED_CLASSES } from "../Constants/Constants"
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest, PutAPIRequest } from "./Api"

const createClassesFromPreviousYear = () => {
    return PostAPIRequest({url: CREATE_CLASSES_FROM_PREVIOUS_YEAR})
}

const getStagedClasses = () => {
    return GetAPIRequest({url: STAGED_CLASSES})
}

const createStagedClass = (payload) => {
    return PostAPIRequest({url: STAGED_CLASSES, data: payload})
}

const editStagedClass = (classId, payload) => {
    return PutAPIRequest({url: STAGED_CLASSES + "/" + classId, data: payload})
}

const deleteStagedClass = (classId) => {
    return DeleteAPIRequest({url: STAGED_CLASSES + "/" + classId})
}

const getAllStagedStudents = (params) => {
    return GetAPIRequest({url: GET_STAGED_STUDENTS, params })
}

const getDashboardData = (params) => {
    return GetAPIRequest({url: ADMISSION_DASHBOARD_DATA, params})
}

export const stagedService = {
    createClassesFromPreviousYear,
    getStagedClasses,
    createStagedClass,
    editStagedClass,
    deleteStagedClass,
    getAllStagedStudents,
    getDashboardData
}