export const modalSize = {
  maxHeight: "600px",
  overflowY: "auto",
  overflowX: "clip",
};
export const modalScreenSize = {
  height: "600px",
  overflowY: "auto",
  overflowX: "clip",
};

export const modalMediumWidth = 600;
export const modalBigWidth = 900;
export const modal1000Width = 1000;
export const modalScreenSizeWidth = 1400;
