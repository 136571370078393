import React, { useEffect, useState } from 'react'
import { stagedService } from '../../../Services/StagedService'
import AdmissionColumnChart from '../../Charts/StackedColumnChart/AdmissionColumnChart'
import { classCustomOrder, classSorting, customOrder } from '../../../Constants/Utils'

const AdmissionDashboard = ({activeYear}) => {
  const [chartData, setChartData] = useState()
  useEffect(() => {
    getDashboardData()
  }, [])

  const sortData = (data) => {
    const sortedData =  data.sort((a, b) => {
        const classA = a.className;
        const classB = b.className;

        return customOrder.indexOf(classA) - customOrder.indexOf(classB) || a.section.localeCompare(b.section);
    });

    return sortedData;
};

  const getDashboardData = () => {
    const params = {
      academicYear: activeYear?.year
    }
    stagedService.getDashboardData(params)
      .then(response => {
        setChartData(response.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  return (
    <main>
        <div>
          <AdmissionColumnChart 
            chartId="admissionChart"
            admissionsData={chartData}
            activeYear={activeYear}
          />
        </div>
    </main>
  )
}

export default AdmissionDashboard