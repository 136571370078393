import { Link } from "react-router-dom";
import {
  ResultsIcon,
  AttendanceIcon,
  ExpensesIcon,
  ExamIcon,
  TutionFeeIcon,
  AnnouncementsIcon,
  HolidaysAndLeavesIcon,
  OneOnOneMeetingsIcon,
  SubjectIcon,
  SyllabusIcon,
  FeeIcon,
  Tutor,
  Resignation,
  Interview,
  Transport,
  SummerCamp,
  UserManagement,
  Attendance,
  Class,
  ClassDetails,
  Staff,
} from "../../../assets/Icons";

import {
  UsergroupAddOutlined,
  MailOutlined,
  AppstoreOutlined,
  RobotOutlined,
  UserOutlined,
  IdcardOutlined,
  TableOutlined,
  TeamOutlined,
  HomeOutlined,
  CreditCardOutlined,
  SolutionOutlined,
  GroupOutlined,
  TrophyOutlined,
  QuestionCircleOutlined,
  AuditOutlined,
  FormOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
  UserAddOutlined,
  UnlockOutlined,
  DashboardOutlined,
  CarOutlined,
  BusOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  ExclamationCircleOutlined,
  BarChartOutlined,
  SettingOutlined,
  ToolOutlined,
  FireOutlined,
  BankOutlined,
  MoneyCollectOutlined,
  ShoppingOutlined,
  FundProjectionScreenOutlined,
  ReadOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  FileExcelOutlined,
  FileAddOutlined,
  HistoryOutlined,
  CheckSquareOutlined,
  ProfileOutlined,
  BarcodeOutlined,
  ShoppingCartOutlined,
  CommentOutlined,
  ApartmentOutlined,
  RestOutlined,
  SafetyCertificateOutlined,
  AccountBookOutlined,
  MenuOutlined,
  WhatsAppOutlined,
  BookOutlined,
  EditOutlined,
  DollarOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { FaHandHoldingUsd, FaMoneyBill, FaRupeeSign } from "react-icons/fa";
import { TbGardenCart, TbMoneybag, TbNotebook } from "react-icons/tb";
import { MdGrade, MdLeaderboard, MdSummarize } from "react-icons/md";
import Cookies from "js-cookie";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const branchCode = Cookies.get("branchCode");

export const nextYearAdmissionSidebarList = [
  getItem("Admissions", "Admissions", <SolutionOutlined />, [
    getItem(
      <Link to="/admission-dashboard" className="link">
        Dashboard
      </Link>,
      "dashboard",
      <AppstoreOutlined />
    ),
    getItem(
      <Link to="/enquiry-forms" className="link">
        Enquiries
      </Link>,
      "enquiry-forms",
      <QuestionCircleOutlined />
    ),
    getItem(
      <Link to="/admissions" className="link">
        Admissions
      </Link>,
      "admissions",
      <FormOutlined />
    ),
    getItem(
      <Link to="/send-enquiry-forms" className="link">
        Send Enquiry Forms
      </Link>,
      "send-enquiry-forms",
      <MailOutlined />
    ),
    
    getItem(
      <Tooltip title={"Class & Section Planning"} placement="right">
        <Link to="/class-details" className="link">
          Class & Section Planning
        </Link>
      </Tooltip>,
      "class-management",
      <img src={Class} alt="Class" className="custom-icon" />
    ),
    getItem(
      <Tooltip title={"Student Management"} placement="right">
        <Link to="/student-details" className="link">
          Student Management
        </Link>
      </Tooltip>,
      "student-management",
      <UserOutlined />
    ),
  ]),
  getItem(
    "Fee Management", "fee-management", <img src={Attendance} alt="fee management" className="custom-icon" />,
    [
      getItem(
        <Link to="/fee-components" className="link">
          Fee Components
        </Link>,
        "fee-components",
        <BookOutlined />
      ),
      getItem(
        <Tooltip title={"Class Fee Structure"} placement="right">
          <Link to="/class-fee-structure" className="link">
            Class Fee Structure
          </Link>
        </Tooltip>,
        "class-fee-structure",
        <img src={Class} alt="Class" className="custom-icon" />
      ),
      getItem(
        <Tooltip title={"Student Fee Breakup"} placement="right">
          <Link to="/student-fee-breakup" className="link">
          Student Fee Breakup
          </Link>
        </Tooltip>,
        "student-fee-breakup",
        <FaMoneyBill />
      ),
    ]
  ),
]

export const sidebarList = [
  getItem(
    <Link to="/branchlist" className="link">
      My Institute
    </Link>,
    "branchlist",
    <BankOutlined />
  ),
  getItem(
    <Link to="/dashboard" className="link">
      Dashboard
    </Link>,
    "dashboard",
    <AppstoreOutlined />
  ),
  getItem("Admissions", "Admissions", <SolutionOutlined />, [
    getItem(
      <Link to="/enquiry-forms" className="link">
        Enquiries
      </Link>,
      "enquiry-forms",
      <QuestionCircleOutlined />
    ),
    getItem(
      <Link to="/admissions" className="link">
        Admissions
      </Link>,
      "admissions",
      <FormOutlined />
    ),
    getItem(
      <Link to="/send-consent-forms" className="link">
        Send Consent Form
      </Link>,
      "send-consent-forms",
      <WhatsAppOutlined />
    ),
    getItem(
      <Link to="/send-enquiry-forms" className="link">
        Send Enquiry Forms
      </Link>,
      "send-enquiry-forms",
      <MailOutlined />
    ),
  ]),
  // getItem(
  //   <a href={`https://leads.edpedia.co?branchCode=${branchCode}`} target="_blank" rel="noopener noreferrer">
  //     Lead Management
  //   </a>,
  //   "leadManagement",
  //   <MdLeaderboard />,
    // [
    //   getItem(
    //     <Link to="/lead-dashboard" className="link">
    //       Dashboard
    //     </Link>,
    //     "lead-dashboard",
    //     <AppstoreOutlined />
    //   ),
    //   getItem(
    //     <Link to="/all-leads" className="link">
    //       All Leads
    //     </Link>,
    //     "all-leads",
    //     <FundProjectionScreenOutlined />
    //   ),
    //   getItem(
    //     <Tooltip title={"Executives"} placement="right">
    //       <Link to="/executives" className="link">
    //         Executivies
    //       </Link>
    //     </Tooltip>,
    //     "executives",
    //     <AccountBookOutlined />
    //   ),
    // ]
  // ),
  getItem(
    <Tooltip title={"Students Management"} placement="right">
      Students Management
    </Tooltip>,
    "studentsManagement",
    <img src={ClassDetails} alt="Students" className="custom-icon" />,
    [
      getItem(
        <Link to="/students" className="link">
          Students
        </Link>,
        "students",
        <UserOutlined />
      ),
      // getItem(
      //   <Link to="/student-performance" className="link">
      //     Student Performance
      //   </Link>,
      //   "student-performance",
      //   <UserOutlined />
      // ),
      getItem(
        <Link to="/students-fee-collection" className="link">
          Fee Collection
        </Link>,
        "students-fee-collection",
        <FundProjectionScreenOutlined />
      ),
      getItem(
        <Tooltip title={"Fee Collection Reports"} placement="right">
          <Link to="/fee-collection-reports" className="link">
            Fee Collection Reports
          </Link>
        </Tooltip>,
        "fee-collection-reports",
        <AccountBookOutlined />
      ),
    ]
  ),
  getItem(
    <Tooltip title={"Student Fee Managment"} placement="right">
      Student Fee Managment
    </Tooltip>,
    "feedetails", <CreditCardOutlined />, [
    getItem(
      <Link to="/feecomponent" className="link">
        Fee Component
      </Link>,
      "feecomponent",
      <img src={FeeIcon} alt="feeicon2" className="custom-icon1" />
    ),
    getItem(
      <Link to="/feedetails" className="link">
        Tuition Fee Details
      </Link>,
      "feedetails",
      <img src={TutionFeeIcon} alt="TutionFeeIcon" className="custom-icon1" />
    ),
  ]),

  getItem(
    <Tooltip title={"Fee Management"} placement="right">
      Fee Management
    </Tooltip>,
    "fee-management",
    <img src={Attendance} alt="fee management" className="custom-icon" />,
    [
      getItem(
        <Link to="/fee-components" className="link">
          Fee Components
        </Link>,
        "fee-components",
        <BookOutlined />
      ),
      getItem(
        <Tooltip title={"Class Fee Structure"} placement="right">
          <Link to="/class-fee-structure" className="link">
            Class Fee Structure
          </Link>
        </Tooltip>,
        "class-fee-structure",
        <img src={Class} alt="Class" className="custom-icon" />
      ),
      getItem(
        <Tooltip title={"Student Fee Breakup"} placement="right">
          <Link to="/student-fee-breakup" className="link">
          Student Fee Breakup
          </Link>
        </Tooltip>,
        "student-fee-breakup",
        <FaMoneyBill />
      ),
      getItem(
        <Tooltip title={"Class fee summary"} placement="right">
          <Link to="/class-fee-summary" className="link">
           Class Fee Summary
          </Link>
        </Tooltip>,
        "student-fee-summary",
        <MdSummarize />
      ),
    ]
  ),
  
  getItem(
    <Tooltip title={"Student Attendance"} placement="right">
      Student Attendance
    </Tooltip>,
    "student-attendance",
    <img src={Attendance} alt="studentAttendance" className="custom-icon" />,
    [
      getItem(
        <Link to="/daily-attendance" className="link">
          Daily Attendance
        </Link>,
        "daily-attendance",
        <ClockCircleOutlined />
      ),
      getItem(
        <Tooltip title={"Monthly Attendance"} placement="right">
          <Link to="/monthly-attendance" className="link">
            Monthly Attendance
          </Link>
        </Tooltip>,
        "monthly-attendance",
        <CalendarOutlined />
      ),
      // getItem(
      //   <Link to="/accept-attendance" className="link">
      //     Accept Attendance
      //   </Link>,
      //   "accept-attendance",
      //   <CheckCircleOutlined />
      // ),
    ]
  ),

  
  // getItem(
      //   <Tooltip title={"Payment Analysis"} placement="right">
      //     <Link to="/payment-analysis" className="link">
      //       Payment Analysis
      //     </Link>
      //   </Tooltip>,
      //   "payment-analysis",
      //   <EditOutlined />
      // ),


// -------------------
  getItem(
    "Class Management",
    "classdetails",
    <img src={ClassDetails} alt="classDetails" className="custom-icon" />,
    [
      getItem(
        <Link to="/classdetails" className="link">
          Class Details
        </Link>,
        "classdetails",

        <img src={Class} alt="Class" className="custom-icon" />
      ),
      getItem(
        <Link to="/timetable" className="link">
          Time Table
        </Link>,
        "timetable",
        <TableOutlined />
      ),
      getItem(
        <Link to="/subject" className="link">
          Subjects
        </Link>,
        "subject",
        <img src={SubjectIcon} alt="SubjectIcon" className="custom-icon1" />
      ),
      getItem(
        <Link to="/homework" className="link">
          Homework
        </Link>,
        "homework",
        <TbNotebook />
      ),
    ]
  ),
  getItem(
    <Tooltip title={"Study Plan"} placement="right">
      Study Plan
    </Tooltip>,
    "study-plan",
    <img src={Attendance} alt="studyPlan" className="custom-icon" />,
    [
      getItem(
        <Link to="/daily-notes" className="link">
          Daily Notes
        </Link>,
        "daily-notes",
        <BookOutlined />
      ),
      getItem(
        <Tooltip title={"Diary Note"} placement="right">
          <Link to="/diary-note" className="link">
            Diary Note
          </Link>
        </Tooltip>,
        "diary-note",
        <TbNotebook />
      ),
    ]
  ),
  getItem(
    "Exam Management",
    "examManagement",
    <img src={ClassDetails} alt="classDetails" className="custom-icon" />,
    [
      getItem(
        <Link to="/exams" className="link">
          Exams
        </Link>,
        "exams",
        <img src={ExamIcon} alt="ExamIcon" className="custom-icon" />
      ),
      getItem(
        <Link to="/exam-grades" className="link">
          Exam Grades
        </Link>,
        "exam-grades",
        <ReadOutlined />
      ),
      getItem(
        <Link to="/exam-results" className="link">
          Enter Marks
        </Link>,
        "exam-results",
        <FormOutlined />
      ),
      getItem(
        <Link to="/exam-schedule" className="link">
          Exam Schedule
        </Link>,
        "exam-schedule",
        <BookOutlined />
      )
      // getItem(
      //   <Link to="/ai-question-papers" className="link">
      //     AI-Question papers
      //   </Link>,
      //   "ai-question-papers",
      //   <RobotOutlined />
      // ),
    ]
  ),
  getItem(
    <Link to="/results" className="link">
      Result
    </Link>,
    "results",
    <img src={ResultsIcon} alt="Results" className="custom-icon" />
  ),
  getItem(
    <Tooltip title={"Announce Management"} placement="right">
      Announcements
    </Tooltip>,
    "announcementManagement",
    <ShoppingOutlined />,
    [
      getItem(
        <Link to="/announcements" className="link">
          Announcement
        </Link>,
        "announcements",
        <img
          src={AnnouncementsIcon}
          alt="AnnouncementsIcon"
          className="custom-icon"
        />
      ),

      getItem(
        <Tooltip title={"Announcement Type"} placement="right">
          <Link to="/announcement-type" className="link">
            Announcement Type
          </Link>
        </Tooltip>,
        "announcement-type",
        <ProfileOutlined />
      ),
      // getItem(
      //   <Tooltip title={"Email"} placement="right">
      //     <Link to="/email" className="link">
      //       Email
      //     </Link>
      //   </Tooltip>,
      //   "email",
      //   <ProfileOutlined />
      // ),
      getItem(
        <Tooltip title={"WhatsApp"} placement="right">
          <Link to="/whatsapp-message" className="link">
            WhatsApp
          </Link>
        </Tooltip>,
        "whatsapp",
        <WhatsAppOutlined />      
      ),

    ]
  ),

  getItem(
    <Tooltip title={"Staff Management"} placement="right">
      Staff
    </Tooltip>,
    "staffManagement",
    <img src={Staff} alt="staff" className="custom-icon" />,
    [
      getItem(
        <Link to="/teaching-staff" className="link">
          Teaching Staff
        </Link>,
        "teachers",
        <UserAddOutlined />
      ),
      getItem(
        <Link to="/non-teaching-staff" className="link">
          Non-Teaching Staff
        </Link>,
        "non-teaching",
        <UserOutlined />
      ),
      getItem(
        <Link to="/staff-attendance" className="link">
          Staff Attendance
        </Link>,
        "staff-attendance",
        <TeamOutlined />
      ),
      // getItem(
      //   <Tooltip title={"Staff Salary Structure"} placement="right">
      //     <Link to="/staff-salary-structure" className="link">
      //       Staff Salary Structure
      //     </Link>
      //   </Tooltip>,
      //   "staff-salary-structure",
      //   <DollarCircleOutlined />
      // ),
      getItem(
        <Tooltip title={"Salary Grade"} placement="right">
          <Link to="/salary-grade" className="link">
            Salary Grade
          </Link>
        </Tooltip>,
        "salary grade",
        <ReadOutlined />
      ),
      getItem(
        <Tooltip title={"Salary Structure"} placement="right">
          <Link to="/salary-structure" className="link">
            Salary Structure
          </Link>
        </Tooltip>,
        "salary-structure",
        <BookOutlined />
      ),
      getItem(
        <Tooltip title={"Staff Salary Structure"} placement="right">
          <Link to="/staff-salary-structure" className="link">
            Staff Salary Structure
          </Link>
        </Tooltip>,
        "staff-salary-structure",
        <DollarCircleOutlined />
      ),
      getItem(
        <Tooltip title={"Staff Salary Paid Status"} placement="right">
          <Link to="/staff-salary-paid-status" className="link">
            Staff Salary Paid Status
          </Link>
        </Tooltip>,
        "staff-salary-paid-status",
        <FaMoneyBill />
      ),
    ]
  ),
  getItem(
    <Link to="/daily-update" className="link">
      Daily Update
    </Link>,
    "daily-update",
    <ProfileOutlined />
  ),

  getItem(
    <Tooltip title={"Finance"} placement="right">
      Finance
    </Tooltip>,
    "finance",
    <DollarOutlined />,
    [
      getItem(
        <Link to="/lenders" className="link">
          Lenders
        </Link>,
        "lenders",
        <ProfileOutlined />
      ),

      getItem(
        <Link to="/loans" className="link">
          Loans
        </Link>,
        "loans",
        <TbMoneybag />
      ),
      getItem(
        <Link to="/repayments" className="link">
          Repayments
        </Link>,
        "repayments",
        <FaRupeeSign />
      ),
      getItem(
        <Link to="/borrowers" className="link">
          Borrowers
        </Link>,
        "borrowers",
        <FaHandHoldingUsd />
      ),
      getItem(
        <Link to="/finance-reports" className="link">
          Reports
        </Link>,
        "finance-reports",
        <FileExcelOutlined />
      ),
    ]
  ),

  getItem(
    <Tooltip title={"Expense Management"} placement="right">
      Expense Management
    </Tooltip>,
    "expenseManagement",
    <ShoppingOutlined />,
    [
      getItem(
        <Link to="/expense-type" className="link">
          Expense Type
        </Link>,
        "expenses-type",
        <ProfileOutlined />
      ),
      getItem(
        <Link to="/expenses" className="link">
          All Expenses
        </Link>,
        "expenses",
        <img src={ExpensesIcon} alt="ExpensesIcon" className="custom-icon1" />
      ),
    ]
  ),
  getItem(
    <Tooltip title={"Syllabus Management"} placement="right">
      Syllabus Management
    </Tooltip>,
    "ai-syllabus-management",
    <img src={ClassDetails} alt="classDetails" className="custom-icon" />,
    [
      getItem(
        <Link to="/syllabus" className="link">
          Syllabus
        </Link>,
        "syllabus",
        <img src={SyllabusIcon} alt="SyllabusIcon" className="custom-icon" />
      ),
      getItem(
        <Tooltip title={"AI generated study material"} placement="right">
          <Link to="/ai-generated-syllabus" className="link">
            AI generated study material
          </Link>
        </Tooltip>,
        "ai-generated-syllabus",
        <FileTextOutlined />
      ),
    ]
  ),
  
  // getItem(
  //   <Link to="/student-attendance" className="link">
  //     Student Attendance
  //   </Link>,
  //   "student-attendance",
  //   <img src={Attendance} alt="classDetails" className="custom-icon" />
  // ),

  
  getItem(
    <Tooltip title={"Account & PayRoll Management"} placement="right">
      Account & PayRoll Management
    </Tooltip>,
    "PayRoll Management",
    <MoneyCollectOutlined />,
    [
      getItem(
        <Link to="/payroll-dashboard" className="link">
          Dashboard
        </Link>,
        "payroll-dashboard",
        <FundProjectionScreenOutlined />
      ),

      getItem(
        <Link to="/payslips" className="link">
          PaySlips
        </Link>,
        "payslips",
        <FileTextOutlined />
      ),

      // getItem(
      //   <Link to="/payroll-leaves" className="link">
      //     Leaves
      //   </Link>,
      //   "payroll-leaves",
      //   <CalendarOutlined />
      // ),
      getItem(
        <Link to="/lop-reports" className="link">
          LOP Reports
        </Link>,
        "lop-reports",
        <FileExcelOutlined />
      ),
      getItem(
        <Link to="/reimbursements" className="link">
          Reimbursements
        </Link>,
        "reimbursements",
        <CreditCardOutlined />
      ),
      getItem(
        <Tooltip title={"Employee Bank Accounts"} placement="right">
          <Link to="/employee-bank-accounts" className="link">
            Employee Bank Accounts
          </Link>
        </Tooltip>,
        "employee-bank-accounts",
        <BankOutlined />
      ),
    ]
  ),
  getItem(
    <Link to="/departments" className="link">
      Departments
    </Link>,
    "departments",
    <GroupOutlined />
  ),
  getItem(
    <Link to="/designations" className="link">
      Designations
    </Link>,
    "designations",
    <RobotOutlined />
  ),

  getItem(
    <Link to="/holidays" className="link">
      Holidays
    </Link>,
    "holidays",
    <img
      src={HolidaysAndLeavesIcon}
      alt="HolidaysAndLeavesIcon"
      className="custom-icon"
    />
  ),

  getItem(
    <Link to="/sports-management" className="link">
      Sports Management
    </Link>,
    "sports-management",
    <TrophyOutlined />
  ),
  // getItem(
  //   <Link to="/hostel-management" className="link">
  //     Hostel Management
  //   </Link>,
  //   "hostel-management",
  //   <HomeOutlined />
  // ),

  getItem(
    <Tooltip title={"Hostel Management"} placement="right">
      Hostel Management
    </Tooltip>,
    "hostel-management",
    <HomeOutlined />,
    [
      getItem(
        <Link to="/hostel-dashboard" className="link">
          Dashboard
        </Link>,
        "hostel-dashboard ",
        <DashboardOutlined />
      ),
      getItem(
        <Link to="/hostel-rooms" className="link">
          Rooms
        </Link>,
        "hostel-rooms",
        <BankOutlined />
      ),
      getItem(
        <Link to="/hostel-beds" className="link">
          Beds
        </Link>,
        "hostel-beds",
        <SafetyCertificateOutlined />
      ),
      getItem(
        <Link to="/hostel-rent" className="link">
          Rent
        </Link>,
        "hostel-rent",
        <DollarCircleOutlined />
      ),
      getItem(
        <Link to="/hostel-students" className="link">
          Students
        </Link>,
        "hostel-students",
        <UserOutlined />
      ),
      getItem(
        <Link to="/hostel-expenses" className="link">
          Expense Management
        </Link>,
        "hostel-expenses",
        <ShoppingCartOutlined />
      ),
      getItem(
        <Link to="/hostel-expense-category" className="link">
          Expense Category
        </Link>,
        "hostel-expense-category",
        <DollarCircleOutlined />
      ),
      getItem(
        <Link to="/hostel-complaints" className="link">
          Complaints
        </Link>,
        "hostel-complaints",
        <CommentOutlined />
      ),
      getItem(
        <Link to="/kitchen-management" className="link">
          Kitchen Management
        </Link>,
        "kitchen-management",
        <MenuOutlined />
      ),
    ]
  ),

  getItem(
    <Link to="/meetings" className="link">
      1:1 Meetings
    </Link>,
    "meetings",
    <img
      src={OneOnOneMeetingsIcon}
      alt="OneOnOneMeetingsIcon"
      className="custom-icon"
    />
  ),
  getItem(
    <Link to="/summercamp" className="link">
      Summer Camp
    </Link>,
    "summercamp",
    <img src={SummerCamp} alt="summerCamp" className="custom-icon" />
  ),
  getItem(
    <Tooltip title={"Inventory Management"} placement="right">
      <Link to="/inventory" className="link">
        Inventory Management
      </Link>
    </Tooltip>,
    "inventory",
    <UsergroupAddOutlined />
  ),
  getItem(
    <Link to="/user-management" className="link">
      User Management
    </Link>,
    "user-management",
    <img src={UserManagement} alt="userManagement" className="custom-icon" />
  ),
  getItem(
    "Transport",
    "transport",
    <img src={ClassDetails} alt="classDetails" className="custom-icon" />,
    [
      getItem(
        <Link to="/transport-dashboard" className="link">
          Dashboard
        </Link>,
        "transport-dashboard",
        <DashboardOutlined />
      ),
      getItem(
        <Link to="/drivers-helpers" className="link">
          Drivers and Helpers
        </Link>,
        "drivers-helpers",
        <UsergroupAddOutlined />
      ),
      getItem(
        <Link to="/buses" className="link">
          Buses
        </Link>,
        "buses",
        <CarOutlined />
      ),
      getItem(
        <Link to="/pickup-points" className="link">
          Pickup Points
        </Link>,
        "pickup-points",
        <EnvironmentOutlined />
      ),
      getItem(
        <Link to="/routes" className="link">
          Routes
        </Link>,
        "routes",
        <GlobalOutlined />
      ),
      getItem(
        <Link to="/tranport-enrolled-students" className="link">
          Students
        </Link>,
        "tranport-enrolled-students",
        <UserOutlined />
      ),
      getItem(
        <Link to="/fuel-filling" className="link">
          Fuel Filling
        </Link>,
        "fuel-filling",
        <FireOutlined />
      ),
      getItem(
        <Link to="/vehicle-repair" className="link">
          Vehicle Repair
        </Link>,
        "vehicle-repair",
        <ToolOutlined />
      ),
      getItem(
        <Link to="/maintenance" className="link">
          Maintenance
        </Link>,
        "maintenance",
        <SettingOutlined />
      ),
      getItem(
        <Link to="/transport-analytics" className="link">
          Analysis
        </Link>,
        "transport-analytics",
        <BarChartOutlined />
      ),
      getItem(
        <Link to="/complaints" className="link">
          Complaints
        </Link>,
        "complaints",
        <ExclamationCircleOutlined />
      ),
    ]
  ),

  getItem("Human Resources", "humanresources", <RobotOutlined />, [
    getItem(
      <Link to="/payroll" className="link">
        Payroll
      </Link>,
      "payroll",
      <MoneyCollectOutlined />
    ),
    getItem(
      <Link to="/hiring" className="link">
        Hiring
      </Link>,
      "hiring",
      <UserAddOutlined />
    ),
    getItem(
      <Tooltip title={"Staff Regularization"} placement="right">
        <Link to="/staff-regularization" className="link">
          Staff Regularization
        </Link>
      </Tooltip>,
      "staff-regularization",
      <IdcardOutlined />
    ),
    getItem(
      <Tooltip placement="right" title={"Regularization Unlocks"}>
        <Link to="/regularization-unlocks" className="link">
          Regularization Unlocks
        </Link>
      </Tooltip>,
      "regularization-unlocks",
      <UnlockOutlined />
    ),
    getItem(
      <Link to="/resignation" className="link">
        Resignations
      </Link>,
      "resignation",
      <img src={Resignation} alt="resignation" className="custom-icon" />
    ),
  ]),
  getItem(
    <Tooltip title={"Leaves Management"} placement="right">
      Leaves Management
    </Tooltip>,
    "Leaves Management",
    <FileAddOutlined />,

    [
      getItem(
        <Link to="/leave-dashboard" className="link">
          Dashboard
        </Link>,
        "leave-dashboard",
        <DashboardOutlined />
      ),
      getItem(
        <Link to="/leave-calendar" className="link">
          Leave Calendar
        </Link>,
        "leave-calendar",
        <CalendarOutlined />
      ),
      getItem(
        <Link to="/leave-requests" className="link">
          Leave Requests
        </Link>,
        "leave-requests",
        <SolutionOutlined />
      ),

      getItem(
        <Link to="/approval-workflow" className="link">
          Approval Workflow
        </Link>,
        "approval-workflow",
        <CheckSquareOutlined />
      ),
      getItem(
        <Link to="/leave-balances" className="link">
          Leave Balances
        </Link>,
        "leaves-balances",
        <CalendarOutlined />
      ),
      getItem(
        <Link to="/leaves-history" className="link">
          Leaves History
        </Link>,
        "leaves-history",
        <HistoryOutlined />
      ),
    ]
  ),
  getItem(
    <Link to="/social-media" className="link">
      Social Media
    </Link>,
    "social-media",
    <DashboardOutlined />
  ),
  getItem(
    <Link to="/user_log_details" className="link">
      User Log Details
    </Link>,
    "social-media",
    <UserOutlined />
  ),
  getItem(
    <Link to="/tutors" className="link">
      Tutor
    </Link>,
    "tutors",
    <img src={Tutor} alt="tutor" className="custom-icon" />
  ),
  getItem(
    <Link to="/reports" className="link">
      Reports
    </Link>,
    "reports",
    <FileTextOutlined />
  ),
  getItem(
    <Link to="/policies" className="link">
      Policies
    </Link>,
    "policies",
    <AuditOutlined />
  ),
  getItem(
    <Link to="/edpedia-store" className="link">
      Edpedia Store
    </Link>,
    "edpedia-store",
    <img src={Interview} alt="resignation" className="custom-icon" />
  ),

  // getItem(
  //   <Link to="/helpCenter" className="link">
  //     Help & Support
  //   </Link>,
  //   "helpCenter",
  //   <UsergroupAddOutlined />
  // ),
];
