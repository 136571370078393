import { Button, Card, Radio, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'

import "./SalaryStructure.scss";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { salaryStructureService } from '../../../Services/SalaryStructureService';
import AddCategoryModal from './AddCategoryModal';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import AddComponentModal from './AddComponentModal';
import EmptyComponent from '../../EmptyComponent/EmptyComponent';
import UpdateSalaryComponent from './UpdateSalaryComponent';

const NewSalaryStructure = () => {
  const [selectedTab, setSelectedTab] = useState("CATEGORY")
  const [categoryTableData, setCategoryTableData] = useState([])
  const [addCategoryModal, setAddCategoryModal] = useState(false)
  const [editCategoryData, setEditCategoryData] = useState(null)
  const [addComponentModal, setAddComponentModal] = useState(null)
  const [componentsTableData, setComponentsTabelData] = useState([])
  const [editComponent, setEditComponent] = useState(null)
  const [editModal, setEditModal] = useState(false)

  useEffect(() => {
    if (selectedTab === "CATEGORY"){
      getCategoryTableData()
    }else if (selectedTab === "COMPONENT"){
      getComponentTableData()
    }
  }, [selectedTab])

  const getCategoryTableData = () => {
    salaryStructureService.getAllCategories()
      .then(response => {
        console.log('response', response)
        setCategoryTableData(response?.data)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  const getComponentTableData = () => {
    salaryStructureService.getAllComponents()
      .then(response => {
        setComponentsTabelData(response?.data)
      })
      .catch(error => {
        console.log(error)
      })

  }

  const handleTabChange = (event) => {
    setSelectedTab(event.target.value)
  }

  const handleAdd = () => {
    if (selectedTab === "CATEGORY") {
      setAddCategoryModal(true)
    } else if (selectedTab === "COMPONENT") {
      setAddComponentModal(true)
    }
  }

  const editCategory = (record) => {
    setEditCategoryData(record)
    setAddCategoryModal(true)
  }

  const deleteCategory = (record) => {
    salaryStructureService.deleteCategory(record?.categoryId)
      .then(response => {
        successNotification("Category deleted successfully")
        getCategoryTableData()
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Delete", "Category")
        errorNotification(errorMsg)
      })
  }

  const columns = [
    {
      title: 'Category ID',
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (text) => <span>{text}</span>, // Render categoryId as text
    },
    {
      title: 'Category Name',
      dataIndex: 'categoryName',
      key: 'categoryName',
      render: (text) => <span>{text}</span>, // Render categoryName as text
    },
    {
      title: 'Salary Type',
      dataIndex: 'salaryType',
      key: 'salaryType',
      render: (text) => <span>{text}</span>, // Render salaryType as text
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <div style={{ padding: "0px 25px" }}>
          <Space size="middle">
            <EditOutlined onClick={(e) => editCategory(record, e)} />
            <DeleteOutlined onClick={() => deleteCategory(record)} />
          </Space>
        </div>
      ),
    },
  ];

  const handleEditComponent = (component) => {
    setEditComponent(component)
    setEditModal(true)
  }

  const handleDeleteComponent = (id) => {
    salaryStructureService.deleteComponent(id)
      .then(response => {
        successNotification("Component deleted successfully")
        getComponentTableData()
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Delete", "Component")
        errorNotification(errorMsg)
      })
  }


  return (
    <main>
      <div className="category">
        <div className="staffSalaryRadio" >
          <Radio.Group
            onChange={handleTabChange}
            value={selectedTab}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button className="RadioButtons" value="CATEGORY">
              Category
            </Radio.Button>
            <Radio.Button className="RadioButtons" value="COMPONENT">
              Component
            </Radio.Button>
          </Radio.Group>
        </div>
        <Button id="Add_buton" onClick={() => handleAdd()}>
          <PlusOutlined style={{ fontSize: "15px" }} />
        </Button>
      </div>
      {selectedTab === "CATEGORY" ? (
        <Table
          columns={columns}
          dataSource={categoryTableData}
        />
      ) : (
        componentsTableData.length > 0 ? (
          <div className='componentsCotainer'>
            {componentsTableData.map(component => (
            <div className="card-container">
              <div className="card-name">
                {component?.componentName}
              </div>
              <div className="card-actions">
                <EditOutlined className="edit-icon" onClick={() => handleEditComponent(component)} />
                <DeleteOutlined className="delete-icon" onClick={() => handleDeleteComponent(component?.id)} />
              </div>
            </div>
          ))}
          </div>
        ) : (
          <EmptyComponent />
        )
      )}
      {addCategoryModal && (
        <AddCategoryModal
          addCategoryModal={addCategoryModal}
          setAddCategoryModal={setAddCategoryModal}
          getCategoryTableData={getCategoryTableData}
          editCategoryData={editCategoryData}
          setEditCategoryData={setEditCategoryData}
        />
      )}
      {addComponentModal && (
        <AddComponentModal
          addComponentModal={addComponentModal}
          setAddComponentModal={setAddComponentModal}
          getComponentTableData={getComponentTableData}
        />
      )}
      {editComponent && (
        <UpdateSalaryComponent
          editComponent = {editComponent}
          editModal = {editModal}
          setEditModal = {setEditModal}
          getComponentTableData = {getComponentTableData}
        />
      )}

    </main>
  )
}

export default NewSalaryStructure