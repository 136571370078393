import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table, Tooltip } from 'antd';
import { StaffService } from '../../../../Services/StaffService';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, PoundCircleOutlined } from '@ant-design/icons';
import { FaRupeeSign } from 'react-icons/fa';
import AddStaffSalaryConfig from './AddStaffSalaryConfig';
import { salaryStructureService } from '../../../../Services/SalaryStructureService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../../Constants/Utils';
import { modalBigWidth, modalScreenSize, modalSize } from '../../../../Constants/StyleConstants';

const StaffSalaryStructure = () => {
    const [staffData, setStaffData] = useState([]);
    const [addSalaryConfigModal, setAddSalaryConfigModal] = useState(false)
    const [selectedStaffId, setSelectedStaffId] = useState(null)
    const [showSalaryDetails, setShowSalaryDetails] = useState(false)
    const [salaryData, setSalaryData] = useState([])
    const [editSalaryConfig, setEditSalaryConfig] = useState(null)
    const [listOfStaffIds, setListOfStaffIds] = useState([])
    const [salaryStructureAssignedData, setSalaryStructureAssignedData] = useState([])
    const [showSalaryStructureModal, setShowSalaryStructureModal] = useState(false)
    const [salaryDetails, setSalaryDetails] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        getAllStaffSalaryStructure()
    }, [])

    const getAllStaffSalaryStructure = () => {
        salaryStructureService.getAllStaffSalaryStructure()
            .then(response => {
                console.log('response', response)
                setSalaryStructureAssignedData(response?.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    useEffect(() => {
        // Fetch data from the API
        StaffService.getAllStaffList()
            .then(response => {
                console.log('response', response?.data?.data);
                const ids = response?.data?.data.map(staff => staff.staffId);
                setListOfStaffIds(ids)
                setStaffData(response?.data?.data); // Set the fetched staff data into state
            })
            .catch(error => {
                console.log('error', error);
            });
    }, []);

    const addStaffSalary = (staffId) => {
        salaryStructureService.getStaffSalaryStructure(staffId)
            .then(response => {
                setSalaryDetails(response?.data)
                setShowSalaryStructureModal(true)
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Assign", "Salary Structure")
                errorNotification(errorMsg)
            })
    }

    const salaryDetailsColumns = [
        {
            title: 'Staff ID',
            dataIndex: 'staffId',
            key: 'staffId',
        },
        {
            title: 'Fixed Salary',
            dataIndex: 'fixedSalary',
            key: 'fixedSalary',
        },
        {
            title: 'Variable Salary',
            dataIndex: 'variableSalary',
            key: 'variableSalary',
        },
        {
            title: 'Deduction Salary',
            dataIndex: 'deductionSalary',
            key: 'deductionSalary',
        },
        {
            title: 'Total Salary',
            dataIndex: 'totalSalary',
            key: 'totalSalary',
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
        },
        {
            title: 'Annual Salary',
            dataIndex: 'annualSalary',
            key: 'annualSalary',
        },
        // {
        //   title: 'Salary Configurations',
        //   dataIndex: 'salaryConfigs',
        //   key: 'salaryConfigs',
        //   render: (salaryConfigs) => (salaryConfigs.length > 0 ? salaryConfigs.join(", ") : 'N/A'),
        // },
    ];

    // Columns for the table
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact Number',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text) => text || 'N/A', // Handling null/undefined values
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
        },
        {
            title: 'Date of Joining',
            dataIndex: 'dateOfJoining',
            key: 'dateOfJoining',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => {
                const data = salaryStructureAssignedData.filter(salary => salary?.staffId === record?.staffId)
                const color = data.length > 0 ? "green" : "red"
                return (
                    <div style={{ padding: "0px 25px" }}>
                        <Space size="middle">
                            <PlusOutlined onClick={() => handleAddSalary(record?.staffId)} />
                            <EyeOutlined onClick={() => handleSalaryAction(record?.staffId)} />
                            <Tooltip title={data.length === 0 ? "No salary assigned" : ''}>
                                <span onClick={() => data.length > 0 && addStaffSalary(record?.staffId)}>
                                    <FaRupeeSign style={{ color: `${color}`, cursor: data.length === 0 ? 'not-allowed' : 'pointer' }} />
                                </span>
                            </Tooltip>
                        </Space>
                    </div>
                )
            },
            // render: (_, record) => (
            //     <Space size="middle">
            //         <Button
            //             type="primary" 
            //             // icon={<PoundCircleOutlined />}
            //             className='blue-button' 
            //             onClick={() => handleSalaryAction(record.staffId)}
            //         >
            //             <FaRupeeSign />
            //         </Button>
            //         <Button 
            //             className='blue-button'
            //             // icon={<PlusOutlined />} 
            //             onClick={() => handleAddSalary(record.staffId)}
            //         >
            //             <PlusOutlined style={{color: "white"}}/>
            //         </Button>
            //     </Space>
            // ),
        },
    ];

    const salaryColumns = [
        {
            title: 'Category',
            dataIndex: ['categoryResponseDTO', 'categoryName'],
            key: 'categoryName',
        },
        {
            title: 'Category',
            dataIndex: ['categoryResponseDTO', 'salaryType'],
            key: 'categoryName',
        },
        {
            title: 'Component',
            dataIndex: ['componentsResponseDTO', 'componentName'],
            key: 'componentName',
        },
        // {
        //     title: 'Percentage',
        //     dataIndex: 'percentage',
        //     key: 'percentage',
        //     render: (text) => (text !== null ? text : 'N/A'),
        // },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: "Action",
            dataIndex: "",
            key: "action",
            fixed: "right",
            align: "center",
            render: (_, record) => (
                <div style={{ padding: "0px 25px" }}>
                    <Space size="middle">
                        <EditOutlined onClick={(e) => editStaffSalaryConfig(record, e)} />
                        <DeleteOutlined onClick={() => deleteStaffSalaryConfig(record)} />
                    </Space>
                </div>
            ),
        },
    ];

    const editStaffSalaryConfig = (record) => {
        console.log('record', record)
        setEditSalaryConfig(record)
        setAddSalaryConfigModal(true)
        setShowSalaryDetails(false)
    }

    const deleteStaffSalaryConfig = (record) => {
        console.log('record', record)
        salaryStructureService.deleteStaffSalaryConfig(record?.id)
            .then(response => {
                successNotification("Staff Salary config deleted successfully")
                handleSalaryAction(record?.staffId)
            })
    }

    const assignStaffSalary = () => {
        setIsDisabled(true)
        const payload = {
            staffIds: listOfStaffIds
        }
        salaryStructureService.assignForAllStaff(payload)
            .then(response => {
                successNotification("Salary Assigned for all staff successfully")
                getAllStaffSalaryStructure()
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Assign", "Staff Salary")
                errorNotification(errorMsg)
            })
            .finally(() => {
                setIsDisabled(false)
            })
    }

    const handleSalaryAction = (staffId) => {
        salaryStructureService.getStaffSalaryConfig(staffId)
            .then(response => {
                console.log('response', response)
                setSalaryData(response?.data)
                setShowSalaryDetails(true)
            })
            .catch(error => {
                console.log('error', error)
            })

    };

    const handleClose = () => setShowSalaryStructureModal(false);

    const handleAddSalary = (staffId) => {
        setAddSalaryConfigModal(true)
        setSelectedStaffId(staffId)
    };

    const handleCancel = () => {
        setShowSalaryDetails(false)
    }

    return (
        <main>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1>Staff Salary Structure</h1>
                <Button
                    className='blue-button'
                    onClick={assignStaffSalary}
                    disabled={isDisabled}
                >
                    Assign salary to staff
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={staffData} // The data fetched from the API
                rowKey="staffId" // Unique key for each row
                pagination={false}
            />
            {addSalaryConfigModal && (
                <AddStaffSalaryConfig
                    selectedStaffId={selectedStaffId}
                    addSalaryConfigModal={addSalaryConfigModal}
                    setAddSalaryConfigModal={setAddSalaryConfigModal}
                    editSalaryConfig={editSalaryConfig}
                    setEditSalaryConfig={setEditSalaryConfig}
                />
            )}
            {showSalaryDetails && (
                <Modal
                    title="Salary Details"
                    open={showSalaryDetails}
                    onCancel={handleCancel}
                    footer={null}
                    width={800} // You can adjust the modal width as needed
                >
                    <Table
                        columns={salaryColumns}
                        dataSource={salaryData}
                        rowKey="id"
                        pagination={false} // Disable pagination if you don't want it
                    />
                </Modal>
            )}
            {showSalaryStructureModal && (
                <Modal
                    title="Salary Information"
                    open={showSalaryStructureModal}
                    // bodyStyle={modalScreenSize}
                    width={modalBigWidth}
                    onCancel={handleClose}
                    footer={[
                        <Button key="close" onClick={handleClose}>
                            Close
                        </Button>
                    ]}
                >
                    {/* Table displaying salary data */}
                    <Table
                        dataSource={[salaryDetails]}
                        columns={salaryDetailsColumns}
                        rowKey="id"  // Unique key for each row
                        pagination={false} // Disable pagination as we have only one row of data
                    />
                </Modal>
            )}
        </main>
    );
};

export default StaffSalaryStructure;
