import {
  CREATE_EXAM_URL,
  EXAM_LIST_URL,
  FIND_EXAM_ENDPOINT,
  EDIT_EXAM,
  EDIT_EXAM_ENDPOINY,
  FIND_EXAM_LIST_URL,
  DELETE_EXAM,
  DELETE_EXAM_ENDPOINT,
  CREATE_EXAMGRADES,
  LIST_EXAMGRADES,
  EDIT_EXAMGRADES,
  EDIT_EXAMGRADES_ENDPOINT,
  FIND_EXAMGRADES_ENDPOINT,
  FIND_EXAMGRADES,
  DELETE_EXAMGRADES,
  DELETE_EXAMGRADES_ENDPOINT,
  GET_QUESTIONPAPER,
  GET_QUESTIONPAPER_ENDPOINT,
  DOWNLOAD_QUESTIONPAPER,
  GENERATE_HALLTICKETS,
  GET_CLASS_HALLTICKETS,
  UPDATE_TO_SHOW_UN_SCHEDULE,
  TO_SHOW_UN_SCHEDULE_EXAMS,
  CREATE_INITIAL_EXAM_GRADES,
  GET_EXAM_DETAILS,
  MULTIPLE_CLASS_EXAM_SCHEDULE
} from "../Constants/Constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

const createInitialExamGrades = () => {
  return GetAPIRequest({url: CREATE_INITIAL_EXAM_GRADES})
}

const listExams = (params) => {
  return GetAPIRequest({ url: EXAM_LIST_URL, params });
};

const createExam = (payload) => {
  return PostAPIRequest({ url: CREATE_EXAM_URL, data: payload });
};

const findExam = (examId, params) => {
  return GetAPIRequest({
    url: FIND_EXAM_LIST_URL + examId + FIND_EXAM_ENDPOINT,
    params,
  });
};

const getExamDetails = (params) => {
  return GetAPIRequest({
    url: GET_EXAM_DETAILS,
    params
  })
}

const multipleClassExamSchedule = (payload) => {
  return PostAPIRequest({
    url: MULTIPLE_CLASS_EXAM_SCHEDULE,
    data: payload
  })
}

const editExam = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_EXAM + id + EDIT_EXAM_ENDPOINY,
    data: payLoad,
  });
};

const deleteExam = (examId) => {
  return DeleteAPIRequest({
    url: DELETE_EXAM + examId + DELETE_EXAM_ENDPOINT,
  });
};

//exam grades
const listOfExamGrades = (params) => {
  return GetAPIRequest({ url: LIST_EXAMGRADES, params });
};

const createExamGrades = (payload) => {
  return PostAPIRequest({ url: CREATE_EXAMGRADES, data: payload });
};

const findExamGrades = (id) => {
  return GetAPIRequest({
    url: FIND_EXAMGRADES + id + FIND_EXAMGRADES_ENDPOINT,
  });
};

const editExamGrades = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_EXAMGRADES + id + EDIT_EXAMGRADES_ENDPOINT,
    data: payLoad,
  });
};
const deleteExamGrades = (id) => {
  return DeleteAPIRequest({
    url: DELETE_EXAMGRADES + id + DELETE_EXAMGRADES_ENDPOINT,
  });
};

const getQuestionPaper = (classId, params) => {
  return GetAPIRequest({
    url: GET_QUESTIONPAPER + classId + GET_QUESTIONPAPER_ENDPOINT,
    params,
  });
};

const downloadQuestionpaper = (payLoad) => {
  return PostAPIRequest({
    url: DOWNLOAD_QUESTIONPAPER,
    data: payLoad,
  });
};

// Halltickets
const generateHalltickets = (params) => {
return GetAPIRequest({
  url:GENERATE_HALLTICKETS, params
})
}

const getHalltickets = (params) => {
  return GetAPIRequest({
    url:GET_CLASS_HALLTICKETS, params
  })
}

const toShowUnScheduleExamModal = () => {
  return GetAPIRequest({
    url: TO_SHOW_UN_SCHEDULE_EXAMS
  })
}

const updateShowUnScheduleExamModal = ()=>{
  return PostAPIRequest({
    url :  UPDATE_TO_SHOW_UN_SCHEDULE
  })
}

export const examService = {
  createInitialExamGrades,
  listExams,
  createExam,
  findExam,
  editExam,
  deleteExam,
  createExamGrades,
  editExamGrades,
  listOfExamGrades,
  findExamGrades,
  deleteExamGrades,
  getQuestionPaper,
  downloadQuestionpaper,
  generateHalltickets,
  getHalltickets,
  toShowUnScheduleExamModal,
  updateShowUnScheduleExamModal,
  getExamDetails,
  multipleClassExamSchedule
};
