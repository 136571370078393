import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import FeeDiscountsModal from '../FeeDiscounts/FeeDiscountsModal';
import { FeeManagementService } from '../../../Services/FeeManagementService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import { fixed, PERCENTAGE } from '../../../Constants/StringConstants';
import AdvancedFeeConfigurationModal from './AdvancedFeeConfigurationModal';

const AdvancedFeeConfiguration = ({
    classId,
    advancedModal,
    setAdvancedModal,
    activeYear,
    getInstallmentsMasterData,
    getInstallmentPlans,
    setLoading,
}) => {
    const [advancedConfigurationData, setAdvancedConfigurationData] = useState();
    const [record, setRecord] = useState(null);
    const handleEditAdvanced = record => {
        setAdvancedModal(true);
        setRecord(record);
    }
    useEffect(() => {
        getAdvanceFeeConfiguration();
    }, [classId, activeYear])
    const getAdvanceFeeConfiguration = () => {
        if (classId) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.getAdvancedFeeConfiguration(classId, params)
                .then(response => {
                    setAdvancedConfigurationData(response?.data);
                })
                .catch(error => {})
        }
    }

    const handleDeleteDiscount = (record) => {
        Modal.confirm({
            title: "Are you sure to delete Advanced Fee Structure ?",
            onOk: () => {
                const params = {
                    isStaging: !activeYear?.isPresentYear
                }
                setLoading(true);
                FeeManagementService.deleteAdvancedFeeConfiguration(record?.id, params)
                    .then(response => {
                        successNotification("Advanced Fee Deleted Successfully");
                        getAdvanceFeeConfiguration();
                        getInstallmentPlans();
                        getInstallmentsMasterData();
                    })
                    .catch(error => {
                        errorNotification(dynamicErrorMsg(error, "Delete", "Advanced Fee"))
                    })
                    .finally(() => setLoading(false));
            }
        })
    }
    const columns = [
        {
            title: "S.No",
            render: (_, record, index) => index + 1,
            width: 50,
        },
        {
            title: "Accommodation Type",
            key: "accommodationType",
            dataIndex: "accommodationType",
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space>
                    <EditOutlined
                        onClick={() => handleEditAdvanced(record)}
                    />
                    <DeleteOutlined
                        onClick={() => handleDeleteDiscount(record)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <div className="margin-top">
            {advancedConfigurationData?.length > 0 && (
                <Table
                    columns={columns}
                    dataSource={advancedConfigurationData}
                    pagination={false}
                />
            )}
            {advancedModal && (
                <AdvancedFeeConfigurationModal
                    advancedModal={advancedModal}
                    setAdvancedModal={setAdvancedModal}
                    record={record}
                    setRecord={setRecord}
                    classId={classId}
                    getAdvanceFeeConfiguration={getAdvanceFeeConfiguration}
                    activeYear={activeYear}
                    getInstallmentPlans={getInstallmentPlans}
                    getInstallmentsMasterData={getInstallmentsMasterData}
                    setLoading={setLoading}
                />
            )}
        </div>
    )
}

export default AdvancedFeeConfiguration