import Cookies from "js-cookie";
import config from "./Config";

export function checkAuth() {
  const tokenInCookies = Cookies.get("accessToken");
  const urlParams = new URLSearchParams(window.location.search);
  const tokenInUrl = urlParams.get("accessToken");
  const branchCode = urlParams.get("branchCode");
  const userId = urlParams.get("userId");
  const entity = urlParams.get("entity");

  if (tokenInCookies) {
    // Token is already in cookies
    // Update cookies with the latest details if present in the URL
    if (tokenInUrl && (branchCode || userId || entity)) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      Cookies.set("accessToken", tokenInUrl, { expires: expirationDate });

      if (branchCode) {
        Cookies.set("branchCode", branchCode, { expires: expirationDate });
      }
      if (userId) {
        Cookies.set("userId", userId, { expires: expirationDate });
      }
      if (entity) {
        Cookies.set("entity", entity, { expires: expirationDate });
      }

      // Remove token details from URL
      urlParams.delete("accessToken");
      urlParams.delete("branchCode");
      urlParams.delete("userId");
      urlParams.delete("entity");

      // Redirect to the same URL without token details
      const newUrl = `${window.location.pathname}`;
      setTimeout(() => {
        window.location.href = newUrl;
      }, 0);
    }
  } else if (tokenInUrl && branchCode && userId) {
    // Token is in URL, store it in cookies
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    Cookies.set("accessToken", tokenInUrl, { expires: expirationDate });
    Cookies.set("branchCode", branchCode, { expires: expirationDate });
    Cookies.set("userId", userId, { expires: expirationDate });
    Cookies.set("entity", entity, { expires: expirationDate });

    // Remove token details from URL
    urlParams.delete("accessToken");
    urlParams.delete("branchCode");
    urlParams.delete("userId");
    urlParams.delete("entity");

    // Redirect to the same URL without token details
    const newUrl = `${window.location.pathname}`;
    setTimeout(() => {
      window.location.href = newUrl;
    }, 0);
  } else {
    // Redirect to the login
    redirectToPing();
  }
}

export function getRefreshToken() {
  var isRefreshToken = false;
  /*let accessToken = getCookie("accessToken");
  let refreshToken = getCookie("refresh-token");
  var promiseObj = await $httpClient.getApiCall(
    "/identity/ping/refreshtoken?refreshToken=" +
      refreshToken +
      "&grantType=refresh_token"
  );

  $.when(promiseObj).done((response) => {
    isRefreshToken = true;
    var decoded = jwt_decode(response.accessToken);
    if (decoded.exp) {
      setCookie("accessToken", response.accessToken, decoded.exp);
      setCookie("refresh-token", response.refresh_token, decoded.exp);
    }
  });*/

  return isRefreshToken;
}

export function redirectToPing() {
  let uri = `${config.loginUrl}`;

  sessionStorage.clear();
  localStorage.clear();
  clearAllCookies();

  setTimeout(() => {
    window.location.href = uri;
  }, 1000);
}

function clearAllCookies() {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
}
