import React from "react";
import { updatesCardList } from "../../../Constants/EdpediaStroreStatic";
import StoreItem from "../UpdatesPopup/StoreItem";

const EdpediaStoreStatic = () => {
  return (
    <main>
        <h1>Edpedia Store</h1>
        <div className="modal-store-items-container">
        {updatesCardList?.map((item) => (
          <StoreItem
            key={item?.id}
            src={item?.src}
            name={item?.name}
            description={item?.description}
            isHighlighted={item?.isHighlighted}
            id={item?.id}
          />
        ))}
      </div>
    </main>
  )
};

export default EdpediaStoreStatic;
