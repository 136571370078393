import {
  BRANCH_LIST,
  CREATE_BRANCHADMIN,
  EDIT_BRANCH,
  EDIT_BRANCHADMIN,
  GET_BRANCH_PAYMENT_DATA,
  SAVE_BRANCH,
  SEND_PAYMENT_RESPONSE,
} from "../Constants/Constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";

const getBranchList = (instituteCode) => {
  return GetAPIRequest({
    url: `${BRANCH_LIST}/${instituteCode}/getBranches`,
  });
};

const getPaymentData = (params) => {
  return GetAPIRequest({url: GET_BRANCH_PAYMENT_DATA, params});
}
const sendPaymentResponse = (data) => {
  return PostAPIRequest({url: SEND_PAYMENT_RESPONSE, data});
}

const createBranch = (payload) => {
  return PostAPIRequest({
    url: SAVE_BRANCH,
    data: payload,
  });
};

const editBranch = (payload, branchId) => {
  return PostAPIRequest({
    url: `${EDIT_BRANCH}/${branchId}/editBranch`,
    data: payload,
  });
};

const createBranchAdmin = (payload) => {
  return PostAPIRequest({
    url: CREATE_BRANCHADMIN,
    data: payload,
  });
};

const editBranchAdmin = (payload, branchAdminId) => {
  return PostAPIRequest({
    url: `${EDIT_BRANCHADMIN}/${branchAdminId}/editBranchAdmin`,
    data: payload,
  });
};

export const BranchService = {
  getBranchList,
  createBranch,
  editBranch,
  createBranchAdmin,
  editBranchAdmin,
  getPaymentData,
  sendPaymentResponse,
};
