import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { salaryStructureService } from '../../../../Services/SalaryStructureService';
import { Option } from 'antd/lib/mentions';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../../Constants/Utils';

const AddStaffSalaryConfig = ({selectedStaffId, addSalaryConfigModal, setAddSalaryConfigModal, editSalaryConfig, setEditSalaryConfig}) => {
    console.log('selectedStaffId', selectedStaffId)

    const [form] = Form.useForm();
    const [categoriesList, setCategoriesList] = useState(null)
    const [componentList, setComponentList] = useState(null)
    const [isdisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (editSalaryConfig){
            form.setFieldsValue({
                staffId: editSalaryConfig?.staffId,
                salaryCategoryId: editSalaryConfig?.categoryResponseDTO?.categoryId,
                salaryComponentId: editSalaryConfig?.componentsResponseDTO?.id,
                amount: editSalaryConfig?.amount
            })
        }
    }, [])

    useEffect(() => {
        getCategoriesList()
        getComponentsList()
    }, [])

    const getCategoriesList = () => {
        salaryStructureService.getAllCategories()
          .then(response => {
            console.log('response', response)
            setCategoriesList(response?.data)
          })
          .catch(error => {
            console.log('error', error)
          })
      }
    
      const getComponentsList = () => {
        salaryStructureService.getAllComponents()
          .then(response => {
            setComponentList(response?.data)
          })
          .catch(error => {
            console.log(error)
          })
    
      }

      console.log('componentList', componentList)

      const handleCancel = () => {
        setAddSalaryConfigModal(false);  // Close modal without submission
        setEditSalaryConfig(null)
    };
    
    const onFinish = (values) => {
        const payload = {
            ...values
        }
        if (editSalaryConfig){
            salaryStructureService.updateStaffSalaryConfig(editSalaryConfig?.id, payload)
            .then(response => {
                successNotification("Staff Salary Config updated successfully")
                setAddSalaryConfigModal(false)
                setEditSalaryConfig(null)
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Update", "Staff Salary Config")
                errorNotification(errorMsg)
            })
        }else{
            salaryStructureService.createStaffSalaryConfig(payload)
            .then(response => {
                successNotification("Staff Salary Config created successfully")
                setAddSalaryConfigModal(false)
            })
            .catch(error => {
                const errorMsg = dynamicErrorMsg(error, "Create", "Staff Salary Config")
                errorNotification(errorMsg)
            })
        }
    }

    return (
        <Modal
            title="Add Salary Configuration"
            open={addSalaryConfigModal}
            onCancel={handleCancel}
            cancelText="Cancel"
            footer={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    staffId: selectedStaffId,  // Automatically filled when the modal opens
                    salaryCategoryId: '',
                    salaryComponentId: '',
                    percentage: 0,
                    amount: 0
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Staff ID"
                    name="staffId"
                    rules={[{ required: true, message: 'Please enter the staff ID' }]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Salary Category"
                    name="salaryCategoryId"
                    rules={[{ required: true, message: 'Please select a salary category' }]}
                >
                    <Select placeholder="Select a category">
                        {categoriesList?.map(category => (
                            <Option value={category?.categoryId}>{category?.categoryName}({category?.salaryType})</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Salary Component"
                    name="salaryComponentId"
                    rules={[{ required: true, message: 'Please select a salary component' }]}
                >
                    <Select placeholder="Select a component">
                        {componentList?.map(component => (
                            <Option value={component?.id}>{component?.componentName}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[{ required: true, message: 'Please enter amount' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
                <Row gutter={[16, 8]} justify="end">
          <Col span={10} className="modal-buttons">
            <Space direction="vertical" size="large">
              <Button
                className="buttons cancel-button"
                htmlType="submit"
                disabled={isdisabled}
                style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
              >
                Save
              </Button>
            </Space>
          </Col>
          <Col span={10} className="modal-buttons">
            <Space direction="vertical" size="large">
              <Button
                className="newCancelButton"
                onClick={handleCancel}
                style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
            </Form>
        </Modal>
    )
}

export default AddStaffSalaryConfig