import { Button, Modal, Space, Spin, Table, notification } from "antd";
import React, { useEffect, useState } from "react";

import {
  CheckCircleFilled,
  DislikeOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./ExpenseCategory.scss";
import HostelService from "../../../../Services/HostelService";
import CreateExpenseCategory from "./CreateExpenseCategory";

const ExpenseCategory = () => {
  const [editRow, setEditRow] = useState(null);
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [typeList, setTypeLists] = useState([]);
  const [loading, setloading] = useState(true);

  const handleOpenTypeModal = () => {
    setOpenTypeModal(true);
  };

  const CategoryList = () => {
    HostelService.expenseCategoryList()
      .then((res) => {
        setloading(false);
        setTypeLists(res?.data);
      })
      .catch((error) => {
        setloading(false);
        console.log("unable to get announcement list", error);
      });
  };
  useEffect(() => {
    CategoryList();
  }, []);

  const handleEdit = (record) => {
    setEditRow(record);

    setOpenTypeModal(true);
  };

  // const handleDelete = (record) => {
  //   Modal.confirm({
  //     title: `Are you sure you want delete ${record?.type}?`,
  //     onOk: () => {
  //       AnnouncementService.deleteAnounceType(record?.id)
  //         .then((response) => {
  //           announceTypeList();
  //           notification.success({
  //             message: `${record?.type} Deleted Sucessfully`,
  //             icon: (
  //               <CheckCircleFilled
  //                 style={{
  //                   color: "green",
  //                 }}
  //               />
  //             ),
  //             duration: 3,
  //             placement: "bottomRight",
  //           });
  //         })
  //         .catch((error) => {
  //           notification.error({
  //             message: "Error",
  //             description:
  //               "An error occurred while deleting the Announcement Type, try again later.",
  //             icon: (
  //               <DislikeOutlined
  //                 style={{
  //                   color: "red",
  //                 }}
  //               />
  //             ),
  //             duration: 3,
  //             placement: "bottomRight",
  //           });
  //         });
  //     },
  //   });
  // };

  return (
    <main>
      <div className="department_main_conatiner">
        <div className="container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Expense Category Type</h1>

            <Button
              style={{
                backgroundColor: "#172b4d",
                color: "#fff",
                borderRadius: "5px",
              }}
              onClick={handleOpenTypeModal}
            >
              <PlusOutlined />
            </Button>
          </div>
          <div className="department_container">
            {loading ? (
              <div style={{ marginLeft: "50%", marginTop: "20%" }}>
                {" "}
                <Spin spinning={loading} />
              </div>
            ) : (
              <>
                {" "}
                {typeList?.map((category) => {
                  return (
                    <>
                      <div
                        key={category?.categoryId}
                        className="departmentCard"
                      >
                        <div className="deptName">
                          <h1>{category?.categoryType}</h1>
                        </div>
                        <div className="deptEdit">
                          <EditOutlined onClick={() => handleEdit(category)} />
                          {/* <DeleteOutlined
                            onClick={() => handleDelete(announceType)}
                          /> */}
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
            <div className="table-container">
              <CreateExpenseCategory
                openTypeModal={openTypeModal}
                setOpenTypeModal={setOpenTypeModal}
                CategoryList={CategoryList}
                setEditRow={setEditRow}
                editRow={editRow}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExpenseCategory;
