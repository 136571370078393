import React, { useState } from "react";
import { Input } from "antd";
import "./index.css";

const FloatInputAllCharacters = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, onBlur, onPressEnter } =
    props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const rules = required
    ? [{ required: true, message: `${label} is required` }]
    : [];


  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props}
        defaultValue={value}
        type={type}
        min={0}
      />
      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

export default FloatInputAllCharacters;
