import { Col, Form, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import { borrowerTypes, dynamicErrorMsg, errorNotification, lenderTypes, loanStatusValues, loanTypes, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import TextArea from 'antd/lib/input/TextArea';
import { EMPLOYEE, NON_EMPLOYEE, OTHERS, STAFF } from '../../../Constants/StringConstants';

const BorrowersModal = ({
    borrowersModal,
    setBorrowersModal,
    record,
    setRecord,
    getBorrowersData,
    loansData,
    staffData,
}) => {
    const [borrowersForm] = Form.useForm();
    const [type, setType] = useState();

    useEffect(() => {
        if (record) {
            if (record?.entityType === STAFF) {
                setType(EMPLOYEE);
                borrowersForm.setFieldsValue({
                    staffId: record?.entityId,
                })
            }
            else if (record?.entityType === OTHERS) {
                setType(NON_EMPLOYEE);
                borrowersForm.setFieldsValue({
                    borrowerName: record?.borrowerName,
                    entityId: record?.entityId,
                    entityType: STAFF,
                    email: record?.email,
                    phoneNumber: record?.phoneNumber,
                    address: record?.address,
                })
            }
            borrowersForm.setFieldsValue({
                loanId: record?.loanResponseDTO?.id,
                borrowerType: record?.borrowerType,
            })
        }
    }, [])
    const handleCancel = () => {
        setBorrowersModal(false);
        setRecord(null);
    }
    const onFinish = values => {
        let payload;
        if (type === EMPLOYEE) {
            const selectedStaff = staffData?.find(staff => staff?.staffId === values?.staffId);
            payload = {
                loanId: values?.loanId,
                borrowerName: selectedStaff?.name,
                borrowerType: values?.borrowerType,
                entityId: values?.staffId,
                entityType: STAFF,
                email: selectedStaff?.email,
                phoneNumber: selectedStaff?.contact,
                address: selectedStaff?.address,
            }
        }
        else {
            payload = {...values, entityType: OTHERS}
        }
        if (record) {
            AccountsService.editBorrowers(record?.id, payload)
                .then(response => {
                    successNotification("Borrower Updated Successfully.");
                    handleCancel();
                    getBorrowersData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Borrower"));
                })
        }
        else {
            AccountsService.createBorrowers(payload)
                .then(response => {
                    successNotification("Borrower Created Successfully.");
                    handleCancel();
                    getBorrowersData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Borrower"));
                })
        }
    }
    return (
        <Modal
            title={record ? "Edit Borrower" : "Add Borrower"}
            open={borrowersModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={borrowersForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loansData?.map(loan => ({ label: loan?.loanNumber, value: loan?.id }))}
                                label="Loan"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="borrowerType"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                onChange={value => setType(value)}
                                options={borrowerTypes?.map(type => ({ label: type, value: type }))}
                                label="Type"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    {type === NON_EMPLOYEE && (
                        <>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="entityId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <FloatInput
                                        label="Unique Identifier No."
                                        type="number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="borrowerName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <FloatInput
                                        label="Name"
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="phoneNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                        {
                                            min: 10,
                                            message: "Please enter valid phone number",
                                        },
                                        {
                                            max: 10,
                                            message: "Cannot exceed more than 10",
                                        },
                                    ]}
                                >
                                    <FloatInput
                                        label="Mobile Number"
                                        type="number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: "" }]}
                                >
                                    <FloatInput
                                        label="Email"
                                        type="email"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        placeholder="Address"
                                    />
                                </Form.Item>
                            </Col>
                        </>                        
                    )}
                    {type == EMPLOYEE && (
                        <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="staffId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={staffData?.map(staff => ({ label: `${staff?.name} (${staff?.designation})`, value: staff?.staffId }))}
                                label="Staff"
                            >
                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    )}


                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default BorrowersModal