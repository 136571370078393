import React, { useState, useEffect } from "react";
import { Button, Modal, notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import EmptyComponent from "../components/EmptyComponent/EmptyComponent";
import { BASE_URL } from "../Constants/Constants";

const FileView = ({ view, setView, fileData, heading, longUrl = false }) => {
  const [fileContent, setFileContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const handleCancel = () => {
    setView(false);
  };

  useEffect(() => {
    const fetchFileContent = async () => {
      if (!fileData) return;

      setLoading(true);
      setFileContent(null);
      if (longUrl) {
        setFileContent(fileData);
      } else {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/storage/viewFile?url=${fileData}`,
            {
              headers: {
                Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
              },
            }
          );
          setFileContent(response?.data?.data);
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      }
    };

    if (fileData) {
      fetchFileContent();
    }
  }, [fileData, accessToken]);

  const handleDownload = () => {
    const link = document.createElement("a");

    // Check if the content is a valid URL for an image or file
    if (fileContent) {
      link.href = fileContent;
      link.target = "_blank"
      link.download = "preview-file"; // You can customize the file name here
      link.click();
    } else {
      notification.error({
        message: "Download Error",
        description: "The file could not be downloaded.",
      });
    }
  };

  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <p>{`File View: ${heading}`}</p>
            <Button
              onClick={handleDownload}
              className="blue-button"
              style={{ margin: "4px", float: "right", marginRight: "30px" }}
            >
              Download File
            </Button>
          </div>
        }
        open={view}
        centered
        onCancel={handleCancel}
        footer={null}
        width={900}
        bodyStyle={{
          maxHeight: "800px",
          overflowY: "auto",
          overflowX: "clip",
        }}
      >
        <div>
          {fileContent ? (
            <iframe
              src={`${fileContent}#toolbar=1&view=FitH&page=1`}
              style={{
                width: "100%",
                height: "500px",
                border: "none",
              }}
              title="File Preview"
            ></iframe>
          ) : (
            <EmptyComponent />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FileView;
