import { useEffect, useState } from "react";
import ClassDropdown from "../../Common/Class/ClassDropdown"
import SectionDropdown from "../../Common/Section/SectionDropdown"
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import "../feeManagement.scss"
import { Button, Col, Form, Modal, Radio, Row, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import ClassFeeStructureModal from "./ClassFeeStructureModal";
import { FaRupeeSign } from "react-icons/fa";
import { DISCOUNTS, FEE_STRUCTURE } from "../../../Constants/StringConstants";
import FeeInstallments from "../FeeInstallments/FeeInstallments";
import InstallmentsOverview from "../InstallmentsOverview/InstallmentsOverview";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getClassList } from "../../../store/classSlice";
import EditClassFeeComponentModal from "./EditClassFeeComponentModal";
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, handleInvalidInputErrorMessage, installmentsNumberList, successNotification } from "../../../Constants/Utils";
import { modalMediumWidth, modalSize } from "../../../Constants/StyleConstants";
import FormButton from "../../Common/formButtons/FormButton";
import { stagedService } from "../../../Services/StagedService";
import AdvancedFeeConfiguration from "../AdvancedFeeConfiguration/AdvancedFeeConfiguration";

const ClassFeeComponents = ({
    selectedClass,
    selectedSection,
    classFeeStructureModal,
    setClassFeeStructureModal,
    activeYear,
    setLoading,
}) => {
    const navigate = useNavigate();
    // const [feeDiscountsData, setFeeDiscountsData] = useState();
    const [feeComponentsData, setFeeComponentsData] = useState();
    const [classComponentsData, setClassComponentsData] = useState();
    const [classComponentsIds, setClassComponentsIds] = useState([]);
    // const [selectedClass, setSelectedClass] = useState();
    // const [selectedSection, setSelectedSection] = useState();
    // const [classFeeStructureModal, setClassFeeStructureModal] = useState(false);
    // const [activeTab, setActiveTab] = useState(FEE_STRUCTURE);
    const [installmentsData, setInstallmentsData] = useState();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [record, setRecord] = useState(null);
    const [editClassComponentModal, setEditClassComponentModal] = useState(false);
    const [allClassesInstNo, setAllClassesInstNo] = useState();
    const [installmentNoModal, setInstallmentNoModal] = useState(false);
    const [classData, setClassData] = useState([]);
    const [installmentsMasterData, setInstallmentsMasterData] = useState();
    // const RadioGroup = Radio.Group;
    const classesData = useSelector(getClassList);
    const [advancedModal, setAdvancedModal] = useState(false);

    useEffect(() => {
        if (!activeYear?.isPresentYear) {
            // Fetch data from stagedService when fromAdmissions is true
            stagedService.getStagedClasses()
                .then(response => {
                    setClassData(response?.data);
                })
                .catch(error => {
                    console.error('Error fetching staged classes:', error);
                });
        } else {
            setClassData(classesData);
        }
    }, [activeYear]);
    const [installmentNoForm] = Form.useForm();
    useEffect(() => {
        getFeeComponents();
        getClassFeeComponents();
        // getFeeDiscounts();
        getInstallmentPlans();
        getInstallmentsMasterData();
    }, [selectedSection, activeYear])

    useEffect(() => {
        getAllClassInstallmentNumbers();
    }, [activeYear])
    const getInstallmentsMasterData = () => {
        if (selectedSection?.classId) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.getInstallmentMaster(selectedSection?.classId, params)
                .then(response => {
                    setInstallmentsMasterData(response?.data);
                })
                .catch(error => {
                    console.log('error', error)
                });
        }
    }

    const getInstallmentPlans = () => {
        if (selectedSection?.classId) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.getInstallmentPlans(selectedSection?.classId, params)
                .then(response => {
                    setInstallmentsData(response?.data);
                    const isPlanPresent = response?.data?.some(plan => plan?.id === selectedPlan?.id);
                    if (!isPlanPresent) {
                        setSelectedPlan(response?.data?.[0]);
                    }
                })
                .catch(error => { });
        }
    }

    // const getFeeDiscounts = () => {
    //     if (selectedSection?.classId) {
    //         FeeManagementService.getFeeDiscounts(selectedSection?.classId)
    //             .then(response => {
    //                 setFeeDiscountsData(response?.data);
    //             })
    //             .catch(error => {})
    //     }
    // }
    const getAllClassInstallmentNumbers = () => {
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        FeeManagementService.getAllClassInstallmentNumbers(params)
            .then(response => {
                setAllClassesInstNo(response?.data);
            })
            .catch(error => { })
    }
    const installmentNoOnFinish = values => {
        const payload = {
            ...values,
            classId: selectedSection?.classId,
            isStaging: !activeYear?.isPresentYear
        }

        if (getSelectedClassInstallmentNo()?.totalInstallments) {
            FeeManagementService.editClassInstallmentNumber(getSelectedClassInstallmentNo()?.id, payload)
                .then(response => {
                    handleCancel();
                    successNotification("Total Installments Updated Successfully.");
                    getAllClassInstallmentNumbers();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Total Installments"));
                })
        }
        else {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.createClassInstallmentNumber(payload, params)
                .then(response => {
                    handleCancel();
                    successNotification("Total Installments Created Successfully.");
                    getAllClassInstallmentNumbers();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Total Installments"));
                })
        }
    }

    const getFeeComponents = () => {
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        FeeManagementService.getFeeComponents(params)
            .then(response => {
                setFeeComponentsData(response?.data);
            })
            .catch(error => {
                console.log('error', error)
            })
    }
    const getClassFeeComponents = () => {
        if (selectedSection?.classId) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.getClassFeeComponents(selectedSection?.classId, params)
                .then(response => {
                    setClassComponentsData(response?.data);
                    const classComponentsIds = response?.data?.map(classComponent => classComponent?.feeComponentId);
                    setClassComponentsIds(classComponentsIds);
                })
                .catch(error => {
                    console.log('error', error)
                });
        }
    }
    const handleCancel = () => {
        setInstallmentNoModal(false);
        installmentNoForm.resetFields();
    }
    const getSelectedClassInstallmentNo = () => {
        return allClassesInstNo?.find(classInst => classInst?.classId === selectedSection?.classId);
    }

    // const onClassChange = (value) => {
    //     setSelectedClass(value);
    // }
    // const onSectionChange = (value) => {
    //     setSelectedSection(value);
    // };
    const getClassesAndSections = () => {
        let classesList = [];
        classData?.forEach(classData => !classesList?.includes(classData?.className) && classesList.push(classData?.className));
        let finalList = [];
        classesList?.forEach(className => {
            let sections = [];
            classData?.forEach(classData => {
                if (classData?.className === className) {
                    sections.push({ section: classData?.section, classId: classData?.classId });
                }
            })
            finalList.push({ className, sections });
        });
        return finalList;
    }
    const actualFeeComponets = () => {
        const parentComponentIds = feeComponentsData?.map(component => component?.parentFeeComponentResponse?.parentComponentId);
        return feeComponentsData?.filter(component => !parentComponentIds?.includes(component?.id) && !component?.advanced);
    }
    const filterClassComponents = () => {
        const parentComponentIds = feeComponentsData?.map(component => component?.parentFeeComponentResponse?.parentComponentId);
        return feeComponentsData?.filter(component => !parentComponentIds?.includes(component?.id) && classComponentsIds?.includes(component?.id) && !component?.advanced);
    }

    const getTotalAmount = () => {
        let total = 0;
        classComponentsData?.forEach(component => total += component?.amount);
        return <h1>Total: <FaRupeeSign style={{ fontSize: "12px" }} />{total} </h1>
    }
    const handleEditClassComponent = (record) => {
        setRecord(record);
        setEditClassComponentModal(true);
    }
    const handleDeleteClassComponent = record => {
        Modal.confirm({
            title: "Are you sure to delete fee component ?",
            onOk: () => {
                const params = {
                    isStaging: !activeYear?.isPresentYear
                }
                FeeManagementService.deleteClassFeeComponent(record?.id, params)
                    .then(response => {
                        successNotification(response?.data);
                        getClassFeeComponents();
                    })
                    .catch(error => {
                        errorNotification(dynamicErrorMsg(error, "Delete", "Class Fee Component"))
                    })
            }
        })
    }
    const handleGenerateInstallments = () => {
        const params = {
            isStaging: !activeYear?.isPresentYear,
            classId: selectedSection?.classId,
        }
        setLoading(true);
        FeeManagementService.generateInstallmentPlans(params)
            .then(response => {
                successNotification(response?.data);
                getInstallmentPlans();
                getInstallmentsMasterData();
            })
            .catch(error => {
                handleInvalidInputErrorMessage(error, "Generate", "Installment Plans");
            })
            .finally(() => setLoading(false));
    }

    const columns = [
        {
            title: "S.No",
            render: (_, record, index) => index + 1,
            width: 50,
        },
        {
            title: "Fee Component",
            key: "feeComponentName",
            dataIndex: "feeComponentName",
            width: 400,
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space>
                    <EditOutlined
                        onClick={() => handleEditClassComponent(record)}
                    />
                    <DeleteOutlined
                        onClick={() => handleDeleteClassComponent(record)}
                    />
                </Space>
            ),
        },
    ];
    const handleClassInstallmentChange = () => {
        setInstallmentNoModal(true);
        installmentNoForm.setFieldValue("totalInstallments", getSelectedClassInstallmentNo()?.totalInstallments);
    }
    const filterInstallmentsMasterData = () => {
        return installmentsMasterData?.filter(master => !master?.advanced && master?.installmentPlanResponseDTO?.id === selectedPlan?.id);
    }

    return (
        <div className="class-fee-components-wrapper">
            <div>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={classComponentsData}
                />
                {classComponentsData?.length > 0 && (
                    <div className="flex-around total-installment-data-container">
                        {getTotalAmount()}
                        <div className="flex-row">
                            <h1>Installments: {getSelectedClassInstallmentNo()?.totalInstallments ?? "Configure"}</h1>
                            <p className="margin-left installment-no-para" onClick={handleClassInstallmentChange}>{getSelectedClassInstallmentNo()?.totalInstallments ? <EditOutlined /> : <PlusOutlined />}</p>
                            {getSelectedClassInstallmentNo()?.totalInstallments > 0 && installmentsData?.length === 0 && <Button className="blue-button margin-left" onClick={handleGenerateInstallments}>Generate</Button>}
                        </div>
                    </div>
                )}
                {getSelectedClassInstallmentNo()?.totalInstallments && classComponentsData?.length > 0 && (
                    <div className="flex-row">
                        <FeeInstallments
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                            classId={selectedSection?.classId}
                            classInstallments={getSelectedClassInstallmentNo()?.totalInstallments}
                            getInstallmentPlans={getInstallmentPlans}
                            installmentsData={installmentsData}
                            activeYear={activeYear}
                            totalInstallments={getSelectedClassInstallmentNo()?.totalInstallments}
                            componentsData={filterClassComponents()}
                            installmentsMasterData={filterInstallmentsMasterData()}
                            getInstallmentsMasterData={getInstallmentsMasterData}
                        />
                        <InstallmentsOverview
                            selectedPlan={selectedPlan}
                            feeComponentsData={filterClassComponents()}
                            classId={selectedSection?.classId}
                            activeYear={activeYear}
                            getInstallmentsMasterData={getInstallmentsMasterData}
                            installmentsMasterData={installmentsMasterData}
                        />
                    </div>
                )}
                <div className="advanced-fee-wrapper-container">
                    <div className="flex-between">
                        <h1>Configure Advanced Fee Structure</h1>
                        <Button className="blue-button" onClick={() => setAdvancedModal(true)}><PlusOutlined /></Button>
                    </div>
                    <AdvancedFeeConfiguration
                        classId={selectedSection?.classId}
                        advancedModal={advancedModal}
                        setAdvancedModal={setAdvancedModal}
                        activeYear={activeYear}
                        getInstallmentsMasterData={getInstallmentsMasterData}
                        getInstallmentPlans={getInstallmentPlans}
                        setLoading={setLoading}
                    />
                </div>
            </div>
            {classFeeStructureModal && (
                <ClassFeeStructureModal
                    classFeeStructureModal={classFeeStructureModal}
                    setClassFeeStructureModal={setClassFeeStructureModal}
                    classesAndSections={getClassesAndSections()}
                    componentsData={actualFeeComponets()}
                    getClassFeeComponents={getClassFeeComponents}
                    activeClass={selectedClass}
                    activeYear={activeYear}
                />
            )}
            {editClassComponentModal && (
                <EditClassFeeComponentModal
                    record={record}
                    setRecord={setRecord}
                    editClassComponentModal={editClassComponentModal}
                    setEditClassComponentModal={setEditClassComponentModal}
                    feeComponentsData={feeComponentsData}
                    classId={selectedSection?.classId}
                    getClassFeeComponents={getClassFeeComponents}
                    activeYear={activeYear}
                />
            )}
            {installmentNoModal && (
                <Modal
                    title="Total Number of Installments"
                    open={installmentNoModal}
                    onCancel={handleCancel}
                    maskClosable={false}
                    footer={false}
                    width={modalMediumWidth}
                    bodyStyle={modalSize}
                >
                    <Form
                        form={installmentNoForm}
                        onFinish={installmentNoOnFinish}
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    name="totalInstallments"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <SelectFloatLabel
                                        options={installmentsNumberList?.map(term => ({ label: term, value: term }))}
                                        allowClear={false}
                                        label="Total Installments"
                                    ></SelectFloatLabel>
                                </Form.Item>
                            </Col>
                        </Row>
                        <FormButton
                            saveText="Save"
                            cancelText="Cancel"
                            onCancel={handleCancel}
                        />
                    </Form>
                </Modal>
            )}
        </div>
    )
}

export default ClassFeeComponents
