import { Button, Col, Form, Modal, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import { CheckCircleFilled, LikeOutlined } from "@ant-design/icons";
import HostelService from "../../../../Services/HostelService";
import { dynamicErrorMsg, errorNotification } from "../../../../Constants/Utils";
const CreateExpenseCategory = ({
  setOpenTypeModal,
  openTypeModal,
  CategoryList,
  setEditRow,
  editRow,
}) => {
  const [typeForm] = Form.useForm();

  useEffect(() => {
    if (editRow?.categoryId) {
      typeForm.setFieldsValue({
        categoryType: editRow?.categoryType,
      });
    } else {
      typeForm.resetFields();
    }
  }, [typeForm, editRow]);
  const submitannounceTypeForm = (values) => {
    const payload = { ...values };

    if (editRow?.categoryId) {
      HostelService.editExpenseCategory(editRow?.categoryId, payload).then(
        (res) => {
          CategoryList();
          notification.success({
            message: "Expense Type Updated Successfully",
            description: `Expense Type has been Created with the name ${values.categoryType}`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          CategoryList();
          typeForm.resetFields();
          setOpenTypeModal(false);
          setEditRow(null);
        }
      );
    } else {
      HostelService.createExpenseCategory(payload)
      .then((res) => {
        CategoryList();
        notification.success({
          message: "Expense Type Created Successfully",
          description: `Expense Type has been updated with the name ${values.categoryType}`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        CategoryList();
        typeForm.resetFields();
        setOpenTypeModal(false);
        setEditRow(null);
      })
      .catch(error => {
        errorNotification(dynamicErrorMsg(error,"Create", "Expense Category"))
      })
    }
  };

  const handleCancel = () => {
    setOpenTypeModal(false);
    typeForm.resetFields();
    setEditRow(null);
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "",
      description: "Please try again later .",
      duration: 5,
      placement: "topRight",
    });
  };

  return (
    <div>
      <Modal
        open={openTypeModal}
        title={editRow ? "Edit Expense Type" : "Create Expense Type"}
        footer={null}
        onCancel={handleCancel}
        width={450}
        centered
      >
        <Form
          form={typeForm}
          name="basic"
          onFinish={submitannounceTypeForm}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item name="categoryType">
                <InputFloatLabel label="categoryType" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="buttons cancel-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateExpenseCategory;
