import {
  CREATE_UI_COMPONENT,
  CREATE_USER_MANAGEMENT,
  CREATE_USER_ROLE,
  DELETE_COMPONENT,
  DELETE_COMPONENT_ENDPOINT,
  DELETE_UI_ROLE,
  DELETE_UI_ROLE_ENDPOINT,
  EDIT_UI_COMPONENT,
  EDIT_UI_COMPONENT_ENDPOINT,
  EDIT_USER_ROLE,
  EDIT_USER_ROLE_ENDPOINT,
  FIND_USER_COMPONENTS,
  GET_COMPONENT_LIST,
  USERS_LIST,
  USER_MANAGEMENT_EDIT,
  USER_MANAGEMENT_EDIT_ENDPOINT,
  CREATE_USER_MANAGEMENT_UPDATE,
  USERS_LIST_USERS,
  CREATE_USER,
  DELETE_USER,
  USER_DETAILS,
  FEATURES_LIST,
  USER_LOG_DETAILS
} from "../Constants/Constants";
import { GetAPIRequest, PostAPIRequest, DeleteAPIRequest } from "./Api";

// ui components
const getComponentsList = (params) => {
  return GetAPIRequest({
    url: GET_COMPONENT_LIST,
  });
};

const deleteComponent = (componentId) => {
  return DeleteAPIRequest({
    url: DELETE_COMPONENT + componentId,
  });
};

const editComponent = (componentId, payload) => {
  return PostAPIRequest({
    url: EDIT_UI_COMPONENT + componentId + EDIT_UI_COMPONENT_ENDPOINT,
    data: payload,
  });
};

const createComponent = (payload) => {
  return PostAPIRequest({
    url: CREATE_UI_COMPONENT,
    data: payload,
  });
};

// ui-role

const createUserRole = (payload) => {
  return PostAPIRequest({
    url: CREATE_USER_ROLE,
    data: payload,
  });
};

const createUser = (payload) => {
  return PostAPIRequest({
    url: CREATE_USER,
    data: payload,
  });
};

const getUsersList = () => {
  return GetAPIRequest({
    url: USERS_LIST,
  });
};

const getUsersListForUser = () => {
  return GetAPIRequest({
    url: USERS_LIST_USERS,
  });
};

const editUser = (userId, payload) => {
  return PostAPIRequest({
    url: EDIT_USER_ROLE + userId + EDIT_USER_ROLE_ENDPOINT,
    data: payload,
  });
};

const deleteUser = (roleId) => {
  return DeleteAPIRequest({
    url: DELETE_UI_ROLE + roleId,
  });
};
const deleteUserInUsers = (roleId) => {
  return DeleteAPIRequest({
    url: DELETE_USER + roleId,
  });
};

// user management

const userManagementList = () => {
  return GetAPIRequest({
    url: USER_MANAGEMENT_EDIT,
  });
};

const createUserManagement = (roleId, payload) => {
  return PostAPIRequest({
    url: CREATE_USER_MANAGEMENT + roleId + CREATE_USER_MANAGEMENT_UPDATE,
    data: payload,
  });
};

const findUserComponents = (roleId) => {
  return GetAPIRequest({
    url: FIND_USER_COMPONENTS + roleId,
  });
};

const editUserManagement = (id, payload) => {
  return PostAPIRequest({
    url: USER_MANAGEMENT_EDIT + id + USER_MANAGEMENT_EDIT_ENDPOINT,
    data: payload,
  });
};

const deleteUserManagement = (id) => {
  return DeleteAPIRequest(id);
};

const getLoginUserDetails = (params) => {
  return GetAPIRequest({
    url: USER_DETAILS,
    params,
  });
};

// Institute Feature
const getInstituteFeatures = () => {
  return GetAPIRequest({
    url: FEATURES_LIST,
  });
};

const getUserLogDetails = () => {
  return GetAPIRequest({
    url: USER_LOG_DETAILS
  })
}

export const UserManagementService = {
  getComponentsList,
  deleteComponent,
  editComponent,
  createComponent,
  createUserRole,
  getUsersList,
  editUser,
  deleteUser,
  findUserComponents,
  userManagementList,
  createUserManagement,
  editUserManagement,
  deleteUserManagement,
  getUsersListForUser,
  createUser,
  deleteUserInUsers,
  getLoginUserDetails,
  getInstituteFeatures,
  getUserLogDetails
};
