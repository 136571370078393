import { useState } from "react";
import "../feeManagement.scss";
import { Button, Form, Modal, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import FeeInstallmentsModal from "./FeeInstallmentsModal";
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, successNotification } from "../../../Constants/Utils";

const FeeInstallments = ({
    selectedPlan,
    setSelectedPlan,
    classId,
    classInstallments,
    getInstallmentPlans,
    installmentsData,
    activeYear,
    totalInstallments,
    componentsData,
    installmentsMasterData,
    getInstallmentsMasterData,
}) => {
    const [installmentModal, setInstallmentModal] = useState(false);
    const [record, setRecord] = useState(null);

    const handleDeleteInstallment = (record) => {
        Modal.confirm({
            title: "Are you sure to delete installment plan ?",
            onOk: () => {
                const params = {
                    isStaging: !activeYear?.isPresentYear
                }
                FeeManagementService.deleteInstallmentPlans(record?.id, params)
                    .then(response => {
                        successNotification(response?.data);
                        getInstallmentPlans();
                    })
                    .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "Installment Plan")));
            }
        })
    }
    const columns = [
        {
            title: "Inst. No.",
            key: "installmentNo",
            dataIndex: "installmentNo",
            width: 10,
            sorter: (a, b) => a.installmentNo - b.installmentNo,
            defaultSortOrder: 'ascend',
        },
        {
            title: "Installment Name",
            key: "planName",
            dataIndex: "planName",
        },
        {
            title: "Due Date",
            key: "dueDate",
            dataIndex: "dueDate",
        },
        // {
        //     title: "Amount",
        //     key: "installmentTotalAmount",
        //     dataIndex: "installmentTotalAmount",
        // },
        {
            title: "Accommodation Type",
            key: "type",
            children: [
                {
                    title: "Day Scholar",
                    key: "scholarAmount",
                    dataIndex: "installmentTotalAmount",
                    render: (value) => value || "-",
                    width: 100,
                },
                {
                    title: "Boarding",
                    key: "boardingAmount",
                    dataIndex: "boardingAmount",
                    render: (value, record) => value ? record?.installmentTotalAmount + value : "-",
                    width: 100,
                },
                {
                    title: "Hosteler",
                    key: "hostelerAmount",
                    dataIndex: "hostelerAmount",
                    render: (value, record) => value ? record?.installmentTotalAmount + value : "-",
                    width: 100,
                },
            ],
        },
        {
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space>
                    <EditOutlined
                        onClick={() => handleEditInstallment(record)}
                    />
                    <DeleteOutlined
                        onClick={() => handleDeleteInstallment(record)}
                    />
                </Space>
            ),
        },

    ];
    const rowClassName = (record) => {
        return record?.id === selectedPlan?.id ? "selected-plan-row" : "";
    }
    const handleEditInstallment = record => {
        setInstallmentModal(true);
        setRecord(record);
    }
    const getInstallmentsTotalAmount = () => {
        let total = 0;
        installmentsData?.forEach(installment => total+= installment?.installmentTotalAmount);
        return "- Rs."+total;
    }

    return (
        <div className="margin-top">
            <div className={`card-view installments-view-container`} style={{ width: "50vw" }}>
                <div className="flex-between">
                    <h1 style={{ color: "green" }}>Fee Installments {getInstallmentsTotalAmount()}</h1>
                    {installmentsData?.length < totalInstallments && <Button
                        className="blue-button"
                        onClick={() => setInstallmentModal(true)}
                        disabled={installmentsData?.length >= totalInstallments}
                    >
                        <PlusOutlined />
                    </Button>}
                </div>
                <Table
                    columns={columns}
                    dataSource={installmentsData}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => setSelectedPlan(record),
                    })}
                    rowClassName={rowClassName}
                />
            </div>
            {installmentModal && (
                <FeeInstallmentsModal
                    installmentModal={installmentModal}
                    setInstallmentModal={setInstallmentModal}
                    record={record}
                    setRecord={setRecord}
                    classId={classId}
                    getInstallmentPlans={getInstallmentPlans}
                    classInstallments={classInstallments}
                    activeYear={activeYear}
                    componentsData={componentsData}
                    installmentsMasterData={installmentsMasterData}
                    getInstallmentsMasterData={getInstallmentsMasterData}
                />
            )}
        </div>
    )
}

export default FeeInstallments