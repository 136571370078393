import { Button, Modal } from "antd"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import './UpdatesPopupModal.scss';
import { modalScreenSizeWidth, modalScreenSize } from "../../../Constants/StyleConstants";
import StoreItem from "./StoreItem";

import { updatesCardList } from "../../../Constants/EdpediaStroreStatic";

const UpdatesPopupModal = ({
  updatePopupModal,
  setUpdatePopupModal,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem("updatePopupModal", "updates received");
  }, []);

  const handleCancel = () => {
    setUpdatePopupModal(false);
  }
  const goToStore = () => {
    navigate("/edpedia-store");
    handleCancel();
  }

  const storeModalElement = document.querySelector('.store-modal');
  if (storeModalElement) {
    storeModalElement.parentElement.style.zIndex = '10000';
  }

  return (
    <Modal
      title={<h1 className="store-modal-heading">EdPedia Store</h1>}
      centered
      open={updatePopupModal}
      width={modalScreenSizeWidth}
      footer={null}
      onCancel={handleCancel}
      bodyStyle={modalScreenSize}
      className="store-modal"
    >
      <div className="modal-store-items-container">
        {updatesCardList?.map((item) => (
          <StoreItem
            key={item?.id}
            src={item?.src}
            name={item?.name}
            description={item?.description}
            isHighlighted={item?.isHighlighted}
            id={item?.id}
            showGetQuote={false}
          />
        ))}
      </div>
      <Button onClick={goToStore} className="modal-go-to-store-button blue-button">Go To Store</Button>
    </Modal>
  )
}

export default UpdatesPopupModal