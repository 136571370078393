import { useEffect, useState } from "react";
import HostelService from "../../../../Services/HostelService";
import { Button, Table, notification } from "antd";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import axios from "axios";
import Cookies from "js-cookie";
import "./ReaminingStock.scss";
import { API_URL } from "../../../../Constants/Constants";

const ReaminingStock = () => {
  const [reaminingStock, setReaminingStock] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const getReaminingStock = () => {
    HostelService.getRemainingItems()
      .then((response) => {
        setReaminingStock(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getReaminingStock();
  }, []);

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    // },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
    },
    {
      title: "Spent Quantity",
      dataIndex: "spentQuantity",
      key: "spentQuantity",
    },
    {
      title: "Remaining Quantity",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
    },
  ];

  const generateReport = () => {
    return axios.get(`${API_URL}/api/dailyConsumption/reamingItemsReport`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  const handleReport = () => {
    generateReport()
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, `Reamining_stock_report.pdf`);
          notification.success({
            message: "The File is downloading...",
            description: `Reamining stock report downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error.data);
        notification.error({
          message: "Error",
          description:
            "An error occurred while generating report, try again later.",
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  return (
    <section>
      <div>
        <Button className="blue-button button-style" onClick={handleReport}>
          Download Report
        </Button>
      </div>
      <Table dataSource={reaminingStock} columns={columns} pagination={false} />
    </section>
  );
};

export default ReaminingStock;
