import {
  CREATE_FEE_COMPONENT,
  CREATE_TUITION_FEE,
  EDIT_FEE_COMPONENT,
  EDIT_FEE_COMPONENT_ENDPOINT,
  EDIT_TUITION_FEE,
  EDIT_TUITION_FEE_ENDPOINT,
  FEE_COMPONENT_LIST_URL,
  FIND_FEE_COMPONENT,
  FIND_TUITION_FEE,
  FIND_TUITION_FEE_ENDPOINT,
  GETALL_TUITION_FEE_LIST,
  CLASS_FEE_STRUCTURE,
  CLASS_FEE_STRUCTURE_ENDPOINT,
  DELETE_TUTION_FEE,
  DELETE_TUTION_FEE_ENDPOINT,
  DELETE_FEE_COMPONENT,
  DELETE_FEE_COMPONENT_ENDPOINT,
  YEARWISE_FEE_COMPONENT,
  PREFERENCE_YEAR,
  CREATE_PREFERENCE,
  PREFERENCE_EDIT,
  FIND_ACADEMIC_YEAR_CLASS_FEE,
  DELETE_PREFERENCE,
  DELETE_END_POINT,
  CEATE_DEFAULT_FEE_COMPONENTS,
  CLEAR_TOTAL_FEE
} from "../Constants/Constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

const feeListComponent = () => {
  return GetAPIRequest({ url: FEE_COMPONENT_LIST_URL });
};
const createFeeComponent = (payload) => {
  return PostAPIRequest({ url: CREATE_FEE_COMPONENT, data: payload });
};

const createDefaultFeeComponents = () => {
  return GetAPIRequest({url: CEATE_DEFAULT_FEE_COMPONENTS});
};

const editFeeComponent = (feeComponentId, payload) => {
  return PostAPIRequest({
    url: EDIT_FEE_COMPONENT + feeComponentId + EDIT_FEE_COMPONENT_ENDPOINT,
    data: payload,
  });
};
const findFeeComponent = () => {
  return GetAPIRequest({ url: FIND_FEE_COMPONENT });
};
const getAlltuitionFeeList = (params) => {
  return GetAPIRequest({ url: GETALL_TUITION_FEE_LIST }, params);
};
const createTuitionFee = (payload) => {
  return PostAPIRequest({ url: CREATE_TUITION_FEE, data: payload });
};
const editTuitionFee = (tuitionFeeId, payload) => {
  return PostAPIRequest({
    url: EDIT_TUITION_FEE + tuitionFeeId + EDIT_TUITION_FEE_ENDPOINT,
    data: payload,
  });
};
const findTuitionFee = () => {
  return GetAPIRequest({ url: FIND_TUITION_FEE + FIND_TUITION_FEE_ENDPOINT });
};

const classFeeStructure = (id) => {
  return GetAPIRequest({
    url: CLASS_FEE_STRUCTURE + id + CLASS_FEE_STRUCTURE_ENDPOINT,
  });
};

const deleteTuitionFee = (tuitionFeeId) => {
  return DeleteAPIRequest({
    url: DELETE_TUTION_FEE + tuitionFeeId + DELETE_TUTION_FEE_ENDPOINT,
  });
};
const deleteFeeComponent = (FeeComponentId) => {
  return DeleteAPIRequest({
    url: DELETE_FEE_COMPONENT + FeeComponentId + DELETE_FEE_COMPONENT_ENDPOINT,
  });
};
const yearwiseFeePayment = (params) => {
  return GetAPIRequest({ url: YEARWISE_FEE_COMPONENT, params });
};

const findAcademicYearClassFee = (classId, params) => {
  return GetAPIRequest({
    url: FIND_ACADEMIC_YEAR_CLASS_FEE.replace("{classId}", classId),
    params,
  });
};

// Preferences
const preferenceYear = () => {
  return GetAPIRequest({ url: PREFERENCE_YEAR });
};

const createPreference = (payload) => {
  return PostAPIRequest({
    url: CREATE_PREFERENCE,
    data: payload,
  });
};
const editPreference = (feeStructureCategoryId, payload) => {
  return PostAPIRequest({
    url: PREFERENCE_EDIT.replace(
      "{feeStructureCategoryId}",
      feeStructureCategoryId
    ),
    data: payload,
  });
};

const payTotalFee=(studentId,params)=>{
  return PostAPIRequest({
    url: CLEAR_TOTAL_FEE.replace('{studentId}',studentId),params
  })
}

const deletePreference = (feeStructureCategoryId) => {
  return DeleteAPIRequest({url: DELETE_PREFERENCE+feeStructureCategoryId+DELETE_END_POINT})
}

export const tuitionFeeService = {
  feeListComponent,
  createFeeComponent,
  editFeeComponent,
  findFeeComponent,
  getAlltuitionFeeList,
  createTuitionFee,
  editTuitionFee,
  findTuitionFee,
  classFeeStructure,
  deleteTuitionFee,
  deleteFeeComponent,
  payTotalFee,
  yearwiseFeePayment,
  preferenceYear,
  createPreference,
  editPreference,
  findAcademicYearClassFee,
  deletePreference,
  createDefaultFeeComponents,
};
