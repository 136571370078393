import { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Modal, Radio, Space, Table } from 'antd';
import LoanModal from './LoanModal';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import "../finance.scss";
import LoanViewModal from './LoanViewModal';

const Loans = ({
  loanModal,
  setLoanModal,
  activeTab,
  loansData,
  getLoansData,
}) => {
  const [lendersData, setLendersData] = useState();
  const [record, setRecord] = useState(null);
  const [loanViewModal, setLoanViewModal] = useState(false);
  useEffect(() => {
    getLendersData();
  }, [])
  const getLendersData = () => {
    AccountsService.getLenders()
      .then(response => {
        setLendersData(response?.data);
      })
      .catch(error => { })
  }
  const columns = [
    {
      title: "S.No",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Loan No.",
      dataIndex: "loanNumber",
      key: "loanNumber",
      width: 80,
    },
    {
      title: "Lenders",
      render: (_, record) => {
        const lenderNameList = record?.lenderResponseDTOS?.map(lender => lender?.lenderName);
        return lenderNameList.join(", ");
      }
    },
    {
      title: "Loan Type",
      dataIndex: "loanType",
      key: "loanType",
    },
    // {
    //   title: "Date",
    //   dataIndex: "takenDate",
    //   key: "takenDate",
    // },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
    },
    {
      title: "Remaining Amount",
      dataIndex: "reamingAmount",
      key: "reamingAmount",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space>
          <EyeOutlined 
            onClick={() => {
              setRecord(record);
              setLoanViewModal(true);
            }}
          />
          <EditOutlined
            onClick={() => {
              setRecord(record);
              setLoanModal(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDeleteLoan(record)} />
        </Space>
      ),
    },
  ];
  const filteredLoansData = () => {
    return loansData?.filter(loan => loan?.status === activeTab);
  }
  const handleDeleteLoan = record => {
    Modal.confirm({
      title: "Are you sure remove this loan ?",
      onOk: () => {
        AccountsService.deleteLoan(record?.id)
          .then(response => {
            successNotification(response?.message);
            getLoansData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "lender")));
      }
    })
  }
  return (
    <div>
      <Table
        pagination={false}
        dataSource={filteredLoansData()}
        columns={columns}
      />
      {loanModal && (
        <LoanModal
          loanModal={loanModal}
          setLoanModal={setLoanModal}
          record={record}
          setRecord={setRecord}
          lendersData={lendersData}
          getLoansData={getLoansData}
        />
      )}
      {loanViewModal && (
        <LoanViewModal 
          loanViewModal={loanViewModal}
          setLoanViewModal={setLoanViewModal}
          record={record}
          setRecord={setRecord}
        />
      )}
    </div>
  )
}

export default Loans