import {
  CREATE_CLASS_URL,
  EDIT_CLASS_END_POINT,
  EDIT_CLASS_URL,
  GET_ALL_CLASSES_URL,
  VIEW_CLASS_END_POINT,
  VIEW_CLASS_URL,
  GET_CLASSES_AND_SECTIONS_FROM_S3_URL,
  GET_CLASS_SUBJECTS,
  GET_CLASS_SUBJECTS_ENDPOINT,
  TOP_STUDENTS_IN_CLASS,
  DELETE_CLASS,
  DELETE_CLASS_ENDPOINT,
  GETGENDERBYCLASSID,
} from "../../src/Constants/Constants";
import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";

const createClass = (payload) => {
  return PostAPIRequest({ url: CREATE_CLASS_URL, data: payload });
};

const editClass = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_CLASS_URL + id + EDIT_CLASS_END_POINT,
    data: payload,
  });
};

const getClasses = (params) => {
  return GetAPIRequest({ url: GET_ALL_CLASSES_URL, params });
};

const getTopStudentsInClass = (params) => {
  return GetAPIRequest({ url: TOP_STUDENTS_IN_CLASS, params });
};

const viewClass = (id) => {
  return GetAPIRequest({ url: VIEW_CLASS_URL + id + VIEW_CLASS_END_POINT });
};

const getClassesAndSections = () => {
  return GetAPIRequest({ url: GET_CLASSES_AND_SECTIONS_FROM_S3_URL });
};

const getClassSubjects = (classId) => {
  return GetAPIRequest({
    url: GET_CLASS_SUBJECTS + classId + GET_CLASS_SUBJECTS_ENDPOINT,
  });
};

const deleteClass = (classId) => {
  return DeleteAPIRequest({
    url: DELETE_CLASS + classId + DELETE_CLASS_ENDPOINT,
  });
};
const getGenderByClass = () => {
  return GetAPIRequest({
    url: GETGENDERBYCLASSID,
  });
};

export const ClassService = {
  getGenderByClass,
  createClass,
  editClass,
  getClasses,
  viewClass,
  getClassesAndSections,
  getClassSubjects,
  getTopStudentsInClass,
  deleteClass,
};
