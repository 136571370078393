import { GET_SERVER_HEALTH_STATUS } from "../../src/Constants/Constants";
import { GetAPIRequest } from "./Api";

const getServerHealth = () => {
  return GetAPIRequest({ url: GET_SERVER_HEALTH_STATUS });
};

export const ServerHealthService = {
  getServerHealth,
};
