import moment from "moment";

export const DateConverter = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const TimeConverter=(date)=>{
  return moment(date).format("HH:mm:ss")
}

export const YearMonthDate=(date)=>{
  return moment(date).format("YYYY-MM-DD");
}