import {
  CheckCircleFilled,
  DislikeOutlined,
  LikeTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Space,
  TreeSelect,
  Upload,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { examService } from "../../Services/ExamService";
import { getClassList } from "../../store/classSlice";
import InputFloatLabel from "../../components/Common/FloatLabel/Input";
import SelectFloatLabel from "../../components/Common/FloatLabel/Select";
import DatePickerFloatLabel from "../../components/Common/FloatLabel/Datepicker";
import { useEffect } from "react";
import FloatInputAll from "../Common/FloatLabel/InputAll";
import TextAreaFloatLabel from "../Common/FloatLabel/TextArea";
import {
  cancelConfirmModal,
  createErrorMessage,
  dynamicErrorMsg,
  updateErrorMessage,
} from "../../Constants/Utils";
import { modalBigWidth, modalSize } from "../../Constants/StyleConstants";
const CreateExam = ({
  examModal,
  setExamModal,
  editExamData,
  setEditExamData,
  getExamTimetableData,
  classIdsWithSubject,
}) => {
  const [checkboxValue, setCheckboxValue] = useState("Internal");
  const [selectedSections, setSelectedSections] = useState([1, 23, 24]);
  const [showUpload, setShowUpload] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isDisabled, setisDisabled] = useState(false);
  const handleChange = (value) => {
    setSelectedSections(value);
  };

  const [examForm] = Form.useForm();
  const classList = useSelector(getClassList);
  const { Option } = Select;
  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    const startDate = examForm.getFieldValue("startDate");
    return current && current < startDate?.startOf("day");
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? "External" : "Internal";
    setCheckboxValue(newValue);
  };

  useEffect(() => {
    if (editExamData?.examId) {
      const formattedStartDate = moment(editExamData?.startDate, "YYYY-MM-DD");
      const formattedEndDate = moment(editExamData?.endDate, "YYYY-MM-DD");
      const classIdsArray = editExamData?.classes.map(
        (classItem) => classItem.id
      );
      setSelectedSections(classIdsArray);

      examForm.setFieldsValue({
        endDate: formattedEndDate.isValid() ? formattedEndDate : undefined,
        startDate: formattedStartDate.isValid()
          ? formattedStartDate
          : undefined,
        status: editExamData?.status,
        title: editExamData?.title,
        type: editExamData?.type,
        className: editExamData?.className,
        classes: classIdsArray,
        set: editExamData.set,
        examDescription: editExamData.examDescription,
        requiredQuestionPapersFromSubjectTeachers:
          editExamData.requiredQuestionPapersFromSubjectTeachers,
      });
    }
  }, [editExamData]);
  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  const submitExam = (values) => {
    setisDisabled(true);
    const formattedStartDate = moment(values.startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(values.endDate).format("YYYY-MM-DD");
    const payload = {
      ...values,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      type: checkboxValue,
      classes: selectedSections,
      requiredQuestionPapersFromSubjectTeachers: showUpload,
    };
    const id = editExamData?.examId;

    if (id) {
      examService
        .editExam(id, payload)
        .then((res) => {
          setisDisabled(false);
          examForm.resetFields();
          getExamTimetableData();
          setExamModal(false);
          notification.success({
            message: "Exam updated successfully",
            description: `Exam has been updated with the name ${values.title}`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Exam");

          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        });
    } else {
      examService
        .createExam(payload)
        .then((response) => {
          setisDisabled(false);
          examForm.resetFields();
          getExamTimetableData();
          setExamModal(false);
          if (response.status === 200) {
            notification.success({
              message: "Exam created successfully",
              description: `Exam has been created with the name ${values.title}`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          }
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Exam");

          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        });
    }
  };

  const cancelForm = () => {
    setExamModal(false);
    setEditExamData(null);
    examForm.resetFields();
  };

  const cancelExamModal = () => {
    cancelConfirmModal({cancelForm})
  };

  const transformData = (data) => {
    const transformedData = [];
    const classesMap = new Map();

    data.forEach((item) => {
      const { classId, className, section } = item;
      if (!classesMap.has(className)) {
        classesMap.set(className, []);
      }
      classesMap.get(className).push({ value: classId, label: section });
    });

    classesMap.forEach((sections, className) => {
      const classDisabled = !sections?.some((section) =>
        classIdsWithSubject?.includes(section?.value)
      );
      let sectionList = sections?.map((item, index) => {
        const disabled = !classIdsWithSubject?.includes(item?.value);
        return {
          label: `${className} - ${item.label}`,
          value: item.value,
          disabled,
        };
      });
      transformedData.push({
        value: className,
        label: className,
        children: sectionList,
        disabled: classDisabled,
      });
    });
    return transformedData;
  };

  const treeData = transformData(classList);
  const handleTypeChange = (value) => {
    const boardExamField = examForm.getFieldInstance("boardExam");
    const isExternalType = value == "EXTERNAL";
    examForm.setFieldsValue({ boardExam: false });
    boardExamField.props.disabled = !isExternalType;
  };

  return (
    <>
      <Modal
        title={
          editExamData && Object.keys(editExamData).length > 0
            ? "Edit Exam"
            : "Create Exam"
        }
        open={examModal}
        onCancel={cancelExamModal}
        footer={false}
        width={modalBigWidth}
        // className="createmodel"
        centered
        className="Addtutionfees-wrapper "
        wrapClassName="vertical-scroll-modal"
        bodyStyle={modalSize}
        maskClosable={false}
      >
        <Form onFinish={submitExam} form={examForm}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="title"
                placeholder="Enter Exam Title"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <FloatInputAll label="Title" name="title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                onChange={handleTypeChange}
              >
                <SelectFloatLabel
                  label="Type"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value="EXTERNAL">External</Option>
                  <Option value="INTERNAL">Internal</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="set"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                onChange={handleTypeChange}
              >
                <SelectFloatLabel
                  label="Total Sets"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Board Exam"
                name="boardExam"
                initialValue={checkboxValue}
              >
                <Checkbox
                  defaultChecked={false}
                  // disabled
                  onChange={handleCheckboxChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="startDate"
                placeholder="Select Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePickerFloatLabel
                  label="Start Date"
                  name="startDate"
                  format="DD-MM-YYYY"
                  disabledDate={disabledStartDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="endDate"
                placeholder="Select Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePickerFloatLabel
                  label="End Date"
                  name="endDate"
                  // format="YYYY-MM-DD"
                  disabledDate={disabledEndDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="uploadQuestionPapers"
                label="Question paper created by subject teacher / staff"
                valuePropName="checked"
              >
                <Checkbox onChange={(e) => setShowUpload(e.target.checked)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="classes"
                placeholder="Select Class"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ width: 350 }}
              >
                <TreeSelect
                  treeData={treeData}
                  placeholder="Select class and section"
                  onChange={handleChange}
                  value={selectedSections}
                  treeCheckable
                  multiple
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className="gutter-row" span={24}>
            <Form.Item name="examDescription">
              <TextAreaFloatLabel
                name="examDescription"
                label="Description"
                rows={8}
                //  maxLength={100}
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </Form.Item>
          </Col>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="buttons cancel-button "
                  htmlType="submit"
                  // disabled={isDisabled}
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={cancelExamModal}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateExam;
