import { useState } from "react";
import { Radio } from "antd";
import Settings from "./Settings";
import SocialMediaPosts from "./SocialMediaPosts";
import "./SocialMedia.scss";
import CreatePost from "./createSocialMediaPost";
const RadioGroup = Radio.Group;
const SocialMedia = () => {
  const [activeTabKey, setActiveTabKey] = useState("settings");
  const handleOnChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  return (
    <main>
      <div className="radioGroupContainer">
        <h1>Social Media</h1>
        <div>
          <RadioGroup
            onChange={handleOnChange}
            value={activeTabKey}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button className="RadioButtons" value="settings">
              Settings
            </Radio.Button>
            <Radio.Button className="RadioButtons" value="myPosts">
              My Posts
            </Radio.Button>
            <Radio.Button
              className="cancel-button"
              value="createPosts"
              style={{ marginLeft: 40 }}
            >
              Create Posts
            </Radio.Button>
          </RadioGroup>
        </div>
      </div>
      {activeTabKey === "settings" && (
        <div>
          <Settings />
        </div>
      )}
      <br />
      {activeTabKey === "myPosts" && (
        <div>
          <SocialMediaPosts />
        </div>
      )}
      {activeTabKey === "createPosts" && (
        <div>
          <CreatePost />
        </div>
      )}
    </main>
  );
};

export default SocialMedia;
