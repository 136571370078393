import { Modal } from "antd";
import { useState } from "react";
import { storeDetailsApi } from "../../../Services/StoreService";
import { CheckCircleFilled } from "@ant-design/icons";
import Cookies from "js-cookie";
import { BACK_TO_YOU_SOON_MESSAGE, SUPPORT } from "../../../Constants/StringConstants";

const StoreItem = ({
  id,
  src,
  name,
  description,
  isHighlighted,
  showGetQuote = true,
}) => {

  const handleOk = () => {
    const payload = {
      itemName: name,
      itemId: id,
      branchCode: Cookies.get("branchCode"),
    }
    storeDetailsApi.saveStoreDetails(payload)
      .then(response =>{
        Modal.info({
          title: BACK_TO_YOU_SOON_MESSAGE,
          icon: <CheckCircleFilled style={{color: "green"}}/>
        })
      })
      .catch(error =>{})
  };

  const confirmQuote = () => {
    Modal.confirm({
      title: `Need Quote on product - ${name}`,
      onOk: () => handleOk(),
    })
  }
  return (
    <div className={`modal-store-item-container ${isHighlighted ? "highligh-modal-store-item" : ""}`}>
      <img src={src} className="modal-store-item-image" alt="Store Item"/>
      <h1 className="modal-store-item-name">{name}</h1>
      <p className="modal-store-item-description">{description}</p>
      {showGetQuote && name !== SUPPORT && <p className="quote-text" onClick={confirmQuote}>Get Quote</p>}
    </div>
  );
};

export default StoreItem;
