import React from "react";

const VoiceCall = () => {
  return (
    <main
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <h1 style={{ fontSize: 25 }}>
          <strong>
            Our AI agent generates the Voice call. Please wait for a while.!
          </strong>
        </h1>
      </div>
    </main>
  );
};

export default VoiceCall;
