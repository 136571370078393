import React, { useEffect, useState } from 'react'
import ClassDropdown from '../Common/Class/ClassDropdown'
import SectionDropdown from '../Common/Section/SectionDropdown'
import { StudyPlanService } from "../../Services/StudyPlanService";
import "./StudyPlan.scss";
import { Button, Modal, Space, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined, FilePdfFilled, PlusOutlined } from '@ant-design/icons';
import FileViewMultiple from '../../Utils/FileViewMultiple';
import DiaryNoteModal from './DiaryNoteModal';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../Constants/Utils';
const DiaryNote = () => {
  const [selectedClass, setSelectedClass] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [diaryNoteData, setDiaryNoteData] = useState(null);
  const [record, setRecord] = useState(null);
  const [view, setView] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [diaryNoteModal, setDiaryNoteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Notes",
      dataIndex: "diaryNoteUrl",
      render: (diaryNoteUrl) => diaryNoteUrl?.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(diaryNoteUrl)} />) : ("-"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditDiaryNote(record)} />
          <DeleteOutlined onClick={() => handleDeleteRecord(record)} />
        </Space>
      ),
    },
  ];

  const handleEditDiaryNote = (record) => {
    setDiaryNoteModal(true);
    setRecord(record);
  }


  const onClassChange = (value) => {
    setSelectedClass(value);
  }

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };

  const handleEditDailyNotes = (record) => {
    setDiaryNoteModal(true);
    setRecord(record);
  }

  const handleFileView = (url) => {
    setFileUrl(url);
    setView(true);
  };

  useEffect(() => {
    getDiaryNotesData();
  }, [selectedSection]);

  const getDiaryNotesData = () => {
    if (selectedSection?.classId) {
      StudyPlanService.getDiaryNotes(selectedSection?.classId)
        .then(response => {
          setDiaryNoteData(response?.data?.reverse());
        })
        .catch(error => { });
    }
  }

  const handleDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure to delete record ?",
      onOk: () => {
        setLoading(true);
        StudyPlanService.deleteDiaryNote(record?.id)
          .then(response => {
            successNotification(response?.data);
            getDiaryNotesData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "Diary Note")))
          .finally(() => setLoading(false));
      }
    })
  }

  return (
    <>
    {loading && (
        <div className="total-page-loading">
          <Spin size="large" spinning={loading} />
        </div>
      )}
    <main className="daily-notes-wrapper">
      <div className="flex-between">
        <h1>Diary Note</h1>
        <div className="flex-row">
          <ClassDropdown
            onClassChange={onClassChange}
            selectedClass={selectedClass}
          />
          <SectionDropdown
            classValue={selectedClass}
            onSectionChange={onSectionChange}
          />
          <Button className="blue-button" onClick={() => setDiaryNoteModal(true)}>
            <PlusOutlined className="assignment-icon" />
            Create Diary Note
          </Button>
        </div>
      </div>
      <Table
        dataSource={diaryNoteData}
        columns={columns}
        pagination={{ pageSize: 20 }}
      />
      {diaryNoteModal && (
        <DiaryNoteModal
          diaryNoteModal={diaryNoteModal}
          setDiaryNoteModal={setDiaryNoteModal}
          record={record}
          setRecord={setRecord}
          selectedSection={selectedSection}
          getDiaryNotesData={getDiaryNotesData}
          setLoading={setLoading}
        />
      )}
      {view && (
        <FileViewMultiple
          view={view}
          setView={setView}
          fileData={fileUrl}
          heading="Daily Notes"
        />
      )}
    </main>
    </>
  )
}

export default DiaryNote