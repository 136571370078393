import { Col, Form, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import { dynamicErrorMsg, errorNotification, handleInvalidInputErrorMessage, lenderTypes, loanPaymentModes, loanStatusValues, loanTypes, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import FloatDatePicker from "../../Common/FloatLabel/Datepicker";
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

const LoanTermsModal = ({
    termsModal,
    setTermsModal,
    record,
    setRecord,
    loansData,
    getTermsData,
}) => {
    const [termsForm] = Form.useForm();

    useEffect(() => {
        if (record) {
            termsForm.setFieldsValue({
                loanId: record?.loanResponseDTO?.id,
                repaymentTerms: record?.repaymentTerms,
                takenDate: record?.takenDate ? moment(record?.takenDate, 'YYYY-MM-DD') : null,
            })
        }
    }, [])
    const handleCancel = () => {
        setTermsModal(false);
        setRecord(null);
    }
    const onFinish = values => {
        const dateString = values?.takenDate && moment(values?.takenDate).isValid()
            ? values?.takenDate.format('YYYY-MM-DD')
            : null;
        const payload = {
            ...values,
            takenDate: dateString ? new Date(dateString) : null
        }
        if (record) {
            AccountsService.editTerms(record?.id, payload)
                .then(response => {
                    successNotification("Terms Updated Successfully.");
                    handleCancel();
                    getTermsData?.();
                })
                .catch(error => {
                    handleInvalidInputErrorMessage(error, "Update", "Loan terms")
                })
        }
        else {
            AccountsService.createTerms(payload)
                .then(response => {
                    successNotification("Loan Terms Created Successfully.");
                    handleCancel();
                    getTermsData?.();
                })
                .catch(error => {
                    handleInvalidInputErrorMessage(error, "Create", "Loan terms")
                })
        }
    }
    return (
        <Modal
            title={record ? "Edit Terms" : "Add Terms"}
            open={termsModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={termsForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loansData?.map(loan => ({ label: loan?.loanNumber, value: loan?.id }))}
                                label="Select Loan"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="takenDate"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatDatePicker
                                label="Loan Taken Date"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="repaymentTerms"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Enter terms...",
                                },
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 6, maxRows: 10 }}
                                placeholder="Enter Loan Terms..."
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default LoanTermsModal