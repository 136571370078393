import { Button, Modal } from "antd"
import { useNavigate } from "react-router-dom";
import "./settings.css";

const SettingsFieldsReminderModal = ({
    reminderModal,
    setReminderModal,
    cancelDisabled,
}) => {
    const navigate = useNavigate();
    const handleCancel= () => {
        setReminderModal(false);
    }
    const goToSettings = () => {
        navigate("/settings");
        handleCancel();
    }
  return (
    <Modal
        title="Configure Settings"
        open={reminderModal}
        closable={!cancelDisabled}
        onCancel={handleCancel}
        // centered
        footer={null}
        maskClosable={false}
    >
        <h1>Some settings are not yet configured. Please configure them for a seamless experience.</h1>
        <div className="align-right">
            <Button className="buttons blue-button" onClick={goToSettings}>Go to Settings</Button>
        </div>
    </Modal>

  )
}

export default SettingsFieldsReminderModal