import { Button, Col, Form, Modal, Row, Spin, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';

const LoanDocumentsModal = ({
    documentsModal,
    setDocumentsModal,
    record,
    setRecord,
    loansData,
    getDocumentsData,
}) => {
    const [documentForm] = Form.useForm();
    const [docFile, setDocFile] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (record) {
            documentForm.setFieldsValue({
                loanId: record?.loanResponseDTO?.id,
                docTitle: record?.docTitle,
                docDescription: record?.docDescription,
                docName: record?.docName,
            })
        }
    }, [])
    const handleCancel = () => {
        setDocumentsModal(false);
        setRecord(null);
        setDocFile(null);
    }
    const onFinish = values => {
        if (!docFile && !record) {
            errorNotification("Please upload document");
            return;
        }
        const formData = new FormData();
        formData.append("loanId", values?.loanId)
        formData.append("docTitle", values?.docTitle)
        formData.append("docDescription", values?.docDescription)
        formData.append("docName", values?.docName)
        if (docFile) {
            formData.append("file", docFile);
        }
        setLoading(true);
        if (record) {
            AccountsService.editDocuments(record?.id, formData)
                .then(response => {
                    successNotification("Loan Document Updated Successfully.");
                    handleCancel();
                    getDocumentsData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Loan Document"));
                })
                .finally(() => setLoading(false));
        }
        else {
            AccountsService.createDocuments(formData)
                .then(response => {
                    successNotification("Loan Document Created Successfully.");
                    handleCancel();
                    getDocumentsData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Loan Document"));
                })
                .finally(() => setLoading(false));
        }
    }
    const handleUploadDocument = ({fileList}) => {
        setDocFile(fileList?.[0].originFileObj);
    }
    return (
        <>
        {loading && (
        <div className="total-page-loading">
        <Spin size="large" spinning={true} />
      </div>
      )}
        <Modal
            title={record ? "Edit Document" : "Add Document"}
            open={documentsModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={documentForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loansData?.map(loan => ({ label: loan?.loanNumber, value: loan?.id }))}
                                label="Select Loan"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="docTitle"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatInput
                                label="Document Title"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="docName"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatInput
                                label="Document Name"
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col className="gutter-row" span={12}>
                        <Form.Item>
                            <Upload
                                beforeUpload={() => false}
                                onChange={handleUploadDocument}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Upload Document</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="docDescription"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 4, maxRows: 10 }}
                                placeholder="Description..."
                            // style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
        </>
    )
}

export default LoanDocumentsModal