import { GET_ACADEMIC_YEAR_DETAILS } from "../Constants/Constants";
import { GetAPIRequest } from "./Api";

const getAcademicYearStatus = () => {
  return GetAPIRequest({ url: GET_ACADEMIC_YEAR_DETAILS });
};


export const AcademicYearService = {
  getAcademicYearStatus,
};
