
import { GET_DAILY_UPDATE, SAVE_DAILY_UPDATE, TODAY_DAILY_UPDATE } from "../Constants/Constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";

const getTodayDailyUpdate = (classId, params) => {
    return GetAPIRequest({url : TODAY_DAILY_UPDATE + classId + GET_DAILY_UPDATE, params})
}

const saveDailyUpdate = (payload) => {
    return PostAPIRequest({url: SAVE_DAILY_UPDATE, data: payload})
}

export const dailyUpdateService = {
    getTodayDailyUpdate,
    saveDailyUpdate
}