import React from "react";

const BlurWrapper = ({ grantAccess, isAllowed, children }) => {
  const blurStyle = {
    filter: grantAccess && isAllowed ? "none" : "blur(1px)",
    pointerEvents: grantAccess && isAllowed ? "auto" : "none",
    position: "relative",
  };

  const overlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "10px 20px",
    borderRadius: "5px",
  };

  return (
    <div style={blurStyle}>
      {children}
      {!grantAccess && <div style={overlayStyle}>Access Denied</div>}
    </div>
  );
};

export default BlurWrapper;
