import { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Button, Modal, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import "../finance.scss";
import LoanDocumentsModal from './LoanDocumentsModal';

const LoanDocuments = ({
  documentsModal,
  setDocumentsModal,
  loansData,
}) => {
  const [documentsData, setDocumentsData] = useState();
  const [record, setRecord] = useState(null);
  useEffect(() => {
    getDocumentsData();
  }, [])
  const getDocumentsData = () => {
    AccountsService.getDocuments()
      .then(response => {
        setDocumentsData(response?.data);
      })
      .catch(error => { })
  }
  const columns = [
    {
      title: "S.No",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Loan No.",
      render: (_, record) => record?.loanResponseDTO?.loanNumber,
      width: 50,
    },
    // {
    //   title: "Amount",
    //   render: (_, record) => record?.loanResponseDTO?.loanAmount,
    // },
    // {
    //   title: "Status",
    //   render: (_, record) => record?.loanResponseDTO?.status,
    // },
    {
      title: "Title",
      dataIndex: "docTitle",
      key: "docTitle",
    },
    {
      title: "Description",
      dataIndex: "docDescription",
      key: "docDescription",
    },
    {
      title: "Document",
      dataIndex: "docUrl",
      key: "docUrl",
      render: (url) => url !== null && url !== "" ? (
        <FileOutlined 
          onClick={() => handleDownloadFile(url)}
        />
      ) : ("-"),
      width: 50,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space>
          <EditOutlined
            onClick={() => {
              setRecord(record);
              setDocumentsModal(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDeleteDocument(record)} />
        </Space>
      ),
    },
  ];
  const handleDownloadFile = (data) => {
    const link = document.createElement('a');
    link.href = data;
    link.download = "loan-document";
    link.click();
  }
  const handleDeleteDocument = record => {
    Modal.confirm({
      title: "Are you sure remove loan document ?",
      onOk: () => {
        AccountsService.deleteDocuments(record?.id)
          .then(response => {
            successNotification(response?.data);
            getDocumentsData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "loan document")));
      }
    })
  }
  return (
    <div>
      <Table
        pagination={false}
        dataSource={documentsData}
        columns={columns}
      />
      {documentsModal && (
        <LoanDocumentsModal
          documentsModal={documentsModal}
          setDocumentsModal={setDocumentsModal}
          record={record}
          setRecord={setRecord}
          loansData={loansData}
          getDocumentsData={getDocumentsData}
        />
      )}
    </div>
  )
}

export default LoanDocuments