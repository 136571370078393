import { Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { modalBigWidth, modalSize } from '../../../Constants/StyleConstants';
import { AccountsService } from '../../../Services/AccountsService';
import { FileOutlined } from '@ant-design/icons';
import { downloadFile } from '../../../Constants/Constants';

const LoanViewModal = ({
    loanViewModal,
    setLoanViewModal,
    record,
    setRecord,
}) => {
    const [loanDetails, setLoanDetails] = useState();
    useEffect(() => {
        AccountsService.getLoanDetails(record?.id)
            .then(response => {
                setLoanDetails(response?.data);
                console.log('response?.data', response?.data);
            })
            .catch(error => {})
        
    }, [])
    const handleCancel = () => {
        setLoanViewModal(false);
        setRecord(null);
    }
    const getLenderNames = () => {
        return record?.lenderResponseDTOS?.map(lender => lender?.lenderName).join(", ");
    }
    const handleDownloadFile = (data) => {
        const link = document.createElement('a');
        link.href = data;
        link.download = "loan-document";
        link.click();
      }
      const columns = [
        {
            title: "S.No",
            render: (_, record, index) => index+1,
            width: 50,
        },
        {
            title: "Date",
            key: "txnDate",
            dataIndex: "txnDate",
        },
        {
            title: "Payment Mode",
            key: "mode",
            dataIndex: "mode",
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
        },
      ];
    return (
        <Modal
            title="Loan Details"
            open={loanViewModal}
            width={modalBigWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
            // centered
        >
            <div className="flex-between">
                <h1>{loanDetails?.loanNumber} - {loanDetails?.status}</h1>
                <h1>Date: {loanDetails?.responseDTO?.takenDate}</h1>
                <p>Type: {loanDetails?.loanType}</p>
                <p>Amount: {loanDetails?.loanAmount}</p>
            </div>
            <p>Lenders: {getLenderNames()}</p>
            {loanDetails?.repaymentTxnResponseDTOS?.length > 0 && (
                <div>
                    <Table 
                        columns={columns}
                        dataSource={loanDetails?.repaymentTxnResponseDTOS}
                        pagination={false}
                    />
                    <hr />
                </div>
            )}
            {loanDetails?.loanDocsResponseDTOS?.length > 0 && (
                <div>
                    <h1>Documents</h1>
                    {loanDetails?.loanDocsResponseDTOS?.map((document, index) => (
                        <div key={document?.id}>
                            <span>{index+1}. {document?.docTitle} -</span>
                            <span className="margin-left">{document?.docName} - <FileOutlined style={{color:"blue"}} onClick={() => handleDownloadFile(document?.docUrl)}/></span>
                        </div>
                    ))}
                    <hr />
                </div>
            )}
            {loanDetails?.responseDTO?.repaymentTerms && (
                <div>
                    <h1>Terms</h1>
                    <pre>{loanDetails?.responseDTO?.repaymentTerms}</pre>
                </div>
            )}
        </Modal>
    )
}

export default LoanViewModal