import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import config from "../Utils/Config";

export const stagedStudentsListApi = createApi({
  reducerPath: "stagedStudentsListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/admissions",
    // baseUrl: "http://localhost:8091/v1.0/admissions",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
        headers.set("Content-Type", "application/json");
      } else {
        window.location.href = `${config.loginUrl}`;
      }

      return headers;
    },
  }),
  tagTypes: ["Students"],
  endpoints: (builder) => ({
    getAllStagedStudents: builder.query({
      query: (studentParams) => ({
        url: "/api/stg_student/getAllStudents",
        method: "GET",
        params: studentParams,
      }),
      providesTags: ["Students"],
    }),
    createStagedStudent: builder.mutation({
      query: (payload) => ({
        url: "/api/stg_student/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Students"],
    }),
    editStagedStudent: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/api/stg_student/${id}/edit`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Students"],
    }),
    getStagedStudentsById: builder.query({
      query: (studentId) => ({
        url: `/api/stg_student/${studentId}/find`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllStagedStudentsQuery,
  useCreateStagedStudentMutation,
  useEditStagedStudentMutation,
  useGetStagedStudentsByIdQuery,
} = stagedStudentsListApi;
