import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { salaryStructureService } from '../../../Services/SalaryStructureService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';  // For the add button icon

const AddComponentModal = ({ addComponentModal, setAddComponentModal, getComponentTableData }) => {
  const [isdisabled, setDisabled] = useState(false);
  const [form] = Form.useForm();

  const onCancel = () => {
    setAddComponentModal(false);
  };

  const addCategory = (values) => {
    const payload = [...values?.componentNames]; // Sending the list of component names

    salaryStructureService.createComponent(payload)
      .then(response => {
        getComponentTableData();
        form.resetFields();
        setAddComponentModal(false)
        successNotification(response?.data)
      })
      .catch(error => {
        const errorMsg = dynamicErrorMsg(error, "Create", "Category");
        errorNotification(errorMsg);
      });
  };

  return (
    <Modal
      title="Add Salary Component"
      open={addComponentModal}
      onCancel={onCancel}
      cancelText="Cancel"
      footer={false}
      width={600}  // Set modal width to make it look better
    >
      <Form
        form={form}
        layout="vertical"
        name="addCategoryForm"
        onFinish={addCategory}
        initialValues={{
          componentNames: [{ componentName: '' }] // Initialize with one empty input
        }}
      >
        <Form.List
          name="componentNames"
          initialValue={[{ componentName: '' }]}  // Ensure the initial value is set here
          rules={[
            {
              validator: async(_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least one component name is required'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row key={key} gutter={[16, 8]} align="middle">
                  <Col span={18}>
                    <Form.Item
                      {...restField}
                      name={[name, 'componentName']}
                      fieldKey={[fieldKey, 'componentName']}
                      label={`Component Name ${name + 1}`}
                      rules={[{ required: true, message: 'Please input the component name!' }]}
                      style={{ marginBottom: 8 }}
                    >
                      <Input placeholder="Enter component name" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Button
                      onClick={() => remove(name)}
                      style={{ width: '100%', marginTop:"14px", marginLeft: "10px" }}
                      className='blue-button'
                    >
                      <CloseOutlined />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  block
                  style={{ marginTop: 8 }}
                >
                  Add Component Name
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row gutter={[16, 8]} justify="end">
          <Col span={10} className="modal-buttons">
            <Space direction="vertical" size="large">
              <Button
                className="buttons cancel-button"
                htmlType="submit"
                disabled={isdisabled}
                style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
              >
                Save
              </Button>
            </Space>
          </Col>
          <Col span={10} className="modal-buttons">
            <Space direction="vertical" size="large">
              <Button
                className="newCancelButton"
                onClick={onCancel}
                style={{ width: '100%', padding: '8px 16px', fontSize: 16 }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddComponentModal;
