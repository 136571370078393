import React, { useEffect, useState, lazy, Suspense } from "react";
import SupportChatIcon from "./components/Support/SupportChatIcon";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import config from "./Utils/Config";
import { Menu, Spin, notification } from "antd";
import { checkAuth } from "./Utils/cookie-helper";
import Cookies from "js-cookie";
import LoadData from "./components/Common/ApplicationData/ApplicationData";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { UserManagementService } from "./Services/UserManagementService";
import { tuitionFeeService } from "./Services/PaymentService";
import ViewPolicy from "./components/Policies/ViewPolicy";
import GenerateAiQuestionPaperPdf from "./components/Exam/AIQuestionPapers/GenerateAiQuestionPaperPdf";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import ExpenseCategory from "./components/HostelManagement/Expenses/ExpenseCategory/ExpenseCategory";
import FeeCollectionReports from "./components/StudentFee/FeeCollectionReports";
import KitchenDashboard from "./components/HostelManagement/KitchenManagement/KitchenDashboard/KitchenDashboard";
import StudentPromotion from "./components/StudentItems/StudentPromotions/StudentPromotion";
import ProgressCard from "./components/ProgressCard/ProgressCard";
import VoiceCall from "./components/Annoucement/Voicecall/VoiceCall";
import Settings from "./components/Settings/Settings";
import BlurWrapper from "./Utils/BlurWrapper";
import SupportService from "./components/Support/SupportService";
import UserLogDetails from "./components/UserLogDetails/UserLogDetails"
import { nextYearAdmissionSidebarList, sidebarList } from "./components/Header/Header/SidebarList";
import TermsAndConditions from "./components/Footer/Terms-conditions";
import PrivacyPolicy from "./components/Footer/privacy-policy";
import UpdatesPopupModal from "./components/EdpediaStore/UpdatesPopup/UpdatesPopupModal";
import EdpediaStoreStatic from "./components/EdpediaStore/EdepediaStore/EdpediaStoreStatic";
import ExamSchedule from "./components/Exam/ExamSchedule/ExamSchedule";
import { InstituteService } from "./Services/InstituteService";
import SettingsFieldsReminderModal from "./components/Settings/SettingsFieldsReminderModal";
import { ServerHealthService } from "./Services/ServerHealthService";
import ServerDownPage from "./components/ErrorComponent/ServerDownPage";
import DailyNotes from "./components/StudyPlan/DailyNotes";
import DiaryNote from "./components/StudyPlan/DiaryNote";
import DailyUpdate from "./components/DailyUpdate/DailyUpdate";
import AppUpdatesModal from "./components/EdpediaStore/UpdatesPopup/AppUpdatesModal";
import { AcademicYearService } from "./Services/AcademicYearService";
import { nextYearFeatures, nextYearSpecificRoutes } from "./Constants/Utils";
import Lenders from "./components/Finance/Lenders/Lenders";
import Loans from "./components/Finance/Loans/Loans";
import Repayments from "./components/Finance/Repayments/Repayments";
import LoanDocuments from "./components/Finance/LoanDocuments/LoanDocuments";
import LoanTerms from "./components/Finance/LoanTerms/LoanTerms";
import LoansHome from "./components/Finance/LoansHome/LoansHome";
import Borrowers from "./components/Finance/Borrowers/Borrowers";
// import FeeComponents from "./components/FeeManagement/FeeComponents/FeeComponents";
import ClassFeeComponents from "./components/FeeManagement/ClassFeeComponents/ClassFeeComponents";
import FeeInstallments from "./components/FeeManagement/FeeInstallments/FeeInstallments";
import InstallmentsOverview from "./components/FeeManagement/InstallmentsOverview/InstallmentsOverview";
import LRPayments from "./components/FeeManagement/LRPayments/LRPayments";
import StudentFeeConfig from "./components/FeeManagement/StudentFeeConfig/StudentFeeConfig";
// import StudentFeeBreakup from "./components/FeeManagement/StudentFeeBreakup/StudentFeeBreakup";
// import ClassFeeStructure from "./components/FeeManagement/ClassFeeStructure/ClassFeeStructure";
import AdmissionDashboard from "./components/AdmissionsManagement/AdmissionDashboard/AdmissionDashboard";
import FinanceReports from "./components/Finance/Reports/FinanceReports";
import NewSalaryStructure from "./components/PayRoll Management/SaleryStructures/NewSalaryStructure";
import StaffSalaryStructure from "./components/PayRoll Management/SaleryStructures/StaffSalaryStructure/StaffSalaryStructure";
import SalaryGrade from "./components/PayRoll Management/SaleryStructures/SalaryGrade/SalaryGrade";
import StaffSalaryPaidStatus from "./components/PayRoll Management/SaleryStructures/StaffSalaryPaidStatus/StaffSalaryPaidStatus";
import Homework from "./components/StudyPlan/Homework";
import UnpaidModal from "./components/EdpediaStore/UpdatesPopup/UnpaidModal";
import { BranchService } from "./Services/BranchService";
import FeeSummary from "./components/FeeManagement/FeeSummary/FeeSummary";
import ConsentForm from "./components/ConsentForm/ConsentForm";


const FeeComponents = lazy(() => import("./components/FeeManagement/FeeComponents/FeeComponents"))
const StudentFeeBreakup = lazy(() => import("./components/FeeManagement/StudentFeeBreakup/StudentFeeBreakup"))
const ClassFeeStructure = lazy(() => import("./components/FeeManagement/ClassFeeStructure/ClassFeeStructure"))
const Whatsapp = lazy(() =>
  import("./components/Annoucement/WhatsappMessages/WhatsAppMessage")
);
const email = lazy(() => import("./components/Annoucement/email/Email"));
const PayrollPayslips = lazy(() =>
  import("./components/PayRoll Management/Payslips")
);
const Payroll = lazy(() =>
  import("./components/HumanResours/PayRolls/Payrols")
);
const Reimbursements = lazy(() =>
  import("./components/PayRoll Management/Reimbursements")
);
const studentPromotion = lazy(() =>
  import("./components/StudentItems/StudentPromotions/StudentPromotion")
);

const DailyAttendance = lazy(() =>
  import("./components/StudentAttendance/DailyAttendance/DailyAttendance")
);
const MonthlyAttendance = lazy(() =>
  import("./components/StudentAttendance/MonthlyAttendance/MonthlyAttendance")
);
const AcceptAttendance = lazy(() =>
  import("./components/StudentAttendance/AcceptAttendance/AcceptAttendance")
);
const PayrollDashboard = lazy(() =>
  import("./components/PayRoll Management/PayrollDashboard/PayrollDashboard")
);
const PayrollLeaves = lazy(() =>
  import("./components/PayRoll Management/Leaves")
);
const LopReports = lazy(() =>
  import("./components/PayRoll Management/LopReports")
);

const BankAccounts = lazy(() =>
  import("./components/PayRoll Management/BankAccounts/EmployeeBankAccounts")
);
const LeavesManagementDashboard = lazy(() =>
  import("./components/LeavesManagement/LeaveDashboard/Dashboard")
);
const Holidays = lazy(() =>
  import("./components/Holiday/HolidayTabs/HolidaysTabs")
);
const ApprovalWorkflow = lazy(() =>
  import("./components/LeavesManagement/ApprovalWorkflow/ApprovalWorkflow")
);
const EmployeesPayrolls = lazy(() =>
  import("./components/PayRoll Management/Employees/EmployeesPayrolls")
);
const Leaves = lazy(() =>
  import("./components/LeavesManagement/Leaves/Leaves")
);
const PayRollLeaveCalendar = lazy(() =>
  import("./components/LeavesManagement/LeavesCalender/LeavesCalendar")
);
const StaffLeavesUsage = lazy(() =>
  import("./components/LeavesManagement/StaffLeaveUsage/StaffLeavesUsage")
);
const Policies = lazy(() => import("./components/Policies/Policies"));
const ViewStocksModal = lazy(() =>
  import("./components/InventoryManagementSystem/Stocks/ViewStocksModal")
);
const LeaveBalances = lazy(() =>
  import("./components/LeavesManagement/LeaveBalances/LeaveBalances")
);
const Reimbursement = lazy(() =>
  import("./components/Reimbursement/Reimbursement")
);
const AIQuestionPapers = lazy(() =>
  import("./components/Exam/AIQuestionPapers/AIQuestionPapaers")
);
const AiGeneratedSyllabus = lazy(() =>
  import("./components/AIGeneratedSyllabus/AiGeneratedSyllabus")
);
const TransportMainHome = lazy(() =>
  import("./components/Transport/TransportHome/TransportMain")
);
const StaffRegUnlock = lazy(() =>
  import("./components/HumanResours/staffAttenance/StaffAttenance")
);
const StudentAttendanceChart = lazy(() =>
  import(
    "./components/StudentAttendance/StudentAttedanceChart/StudentAtendanceChart"
  )
);
const UpdateStudentAttendaceRequest = lazy(() =>
  import(
    "./components/StudentAttendance/StudentAttedanceChart/UpdateStudentAttendaceRequest"
  )
);
const HostelDashboard = lazy(() =>
  import("./components/HostelManagement/HostelDashboard/HostelDashboard")
);
const SportsManagement = lazy(() =>
  import("./components/SportsManagement/SportsHome")
);
const Payrols = lazy(() =>
  import("./components/HumanResours/PayRolls/Payrols")
);
const Designations = lazy(() =>
  import("./components/Designations/Designations")
);
const SendEnquiryForms = lazy(() =>
  import("./components/AdmissionsManagement/SendEnquiries/SendEnquiryForms")
);
const EnquiryForms = lazy(() =>
  import("./components/AdmissionsManagement/Enquiry/EnquiryForms")
);
const EnquiryFormDetails = lazy(() =>
  import("./components/AdmissionsManagement/Enquiry/EnquiryFormDetails")
);
const EnquiryEvaluation = lazy(() =>
  import("./components/AdmissionsManagement/Enquiry/EnquiryEvaluate")
);
const SaleryStucture = lazy(() =>
  import("./components/PayRoll Management/SaleryStructures/SaleryStucture")
);
const StaffAttendance = lazy(() =>
  import("./components/HumanResours/StaffAttendance/StaffAttendance")
);
const StaffRegulizations = lazy(() =>
  import("./components/HumanResours/StaffRegulizations/StaffRegulizations")
);
const Hiring = lazy(() => import("./components/HumanResours/Hiring/Hiring"));
const Analysis = lazy(() => import("./components/Transport/Analysis/Analysis"));
const Complaints = lazy(() =>
  import("./components/Transport/Complaints/Complaints")
);
const Maintenance = lazy(() =>
  import("./components/Transport/Maintenance/Maintenance")
);
const VehicleRepair = lazy(() =>
  import("./components/Transport/VehicleRepair/VehicleRepair")
);
const FuelFilling = lazy(() =>
  import("./components/Transport/FuelFilling/FuelFilling")
);
const BusRouteNew = lazy(() =>
  import("./components/Transport/BusRoute/BusRouteNew")
);
const PickUpPoints = lazy(() =>
  import("./components/Transport/PickupPoints/PickUpPoints")
);
const TransportEnrollStudents = lazy(() =>
  import("./components/Transport/Students/Students")
);
const DriversAndStaff = lazy(() =>
  import("./components/Transport/DriversAndStaff/DriversAndStaff")
);
const Buses = lazy(() => import("./components/Transport/Buses/Buses"));
const TransportHome = lazy(() =>
  import("./components/Transport/TransportHome/Dashboard/TransportHome")
);
const BranchList = lazy(() => import("./components/BranchList/BranchList"));

const StudentFeePayment = lazy(() =>
  import("./components/StudentItems/StudentFeePayment/StudentFeePayment")
);

const Reports = lazy(() =>
  import("./components/StudentAttendance/Reports/Reports")
);

const ExamGrades = lazy(() =>
  import("./components/Exam/ExamGrades/ExamGrades")
);
const viewPolicyDocument = lazy(() =>
  import("./components/Policies/ViewPolicy")
);
const Address = lazy(() =>
  import("./components/EdpediaStore/Address/UserAddress")
);
const orders = lazy(() => import("./components/EdpediaStore/Orders/Orders"));
const StudentFeeDetails = lazy(() =>
  import("./components/AdmissionsManagement/Admission/FeeDetails/FeeDetails")
);
const Departments = lazy(() => import("./components/Departments/Department"));
const Transport = lazy(() => import("./components/Transport/Transport"));
const InventoryManagementSystem = lazy(() =>
  import("./containers/InventoryManagementSystem/inventoryManagementSystem")
);
const OneOnOneMeeetings = lazy(() =>
  import("./components/Meetings/OneOnOneMeetings/OneOnOneMeeetings")
);
const Notification = lazy(() => import("./components/Topbar/Notification"));
const EdpediaStore = lazy(() =>
  import("./components/EdpediaStore/EdepediaStore/EdpediaStoreStatic")
);
const AnnounementType = lazy(() =>
  import("./components/Annoucement/AnnouncementType/AnnouncementType")
);
//

const Profile = lazy(() => import("./components/Topbar/Profile"));
const Exams = lazy(() => import("./components/Exam/Exams/Exams"));
const Syllabus = lazy(() => import("./containers/Syllabus/Syllabus"));
const SummerCamp = lazy(() => import("./components/summerCamp/SummerCamp"));
const TutorDescription = lazy(() =>
  import("./components/Tutor/TutorDescription")
);
const NotFoundPage = lazy(() =>
  import("./components/NotFoundPage/NotFoundPage")
);
const UserManagement = lazy(() =>
  import("./components/UserManagement/UserManagements/UserManagement")
);
const HumanResours = lazy(() =>
  import("./components/HumanResours/HumanResours")
);
const ViewJob = lazy(() => import("./components/HumanResours/Jobs/ViewJob"));
const StaffAttendenceDetails = lazy(() =>
  import(
    "./components/HumanResours/StaffAttendance/StaffAttendenceDetails/StaffAttendenceDetails"
  )
);
const InterView = lazy(() => import("./components/Interview/InterView"));
const Resignation = lazy(() => import("./components/Resignation/Resignation"));
const Designation = lazy(() =>
  import("./components/Designations/Designations")
);
const HostelRooms = React.lazy(() =>
  import("./components/HostelManagement/Rooms/Rooms")
);
const progressCard = React.lazy(() =>
  import("./components/ProgressCard/ProgressCard")
);
const HostelBeds = React.lazy(() =>
  import("./components/HostelManagement/Beds/Beds")
);
const HostelRent = React.lazy(() =>
  import("./components/HostelManagement/Rent/Rent")
);
const HostelStudents = React.lazy(() =>
  import("./components/HostelManagement/Tenants/Tenants")
);
const HostelExpenses = React.lazy(() =>
  import("./components/HostelManagement/Expenses/Expenses")
);
const HostelComplaints = React.lazy(() =>
  import("./components/HostelManagement/Complaints/HostelComplaints")
);
const ChatBox = lazy(() => import("./components/Help/chatBox/ChatBox"));
const HelpSupport = lazy(() =>
  import("./components/Help/Help&support/Help&Support")
);
const ClassDetails = lazy(() => import("./components/Class/ClassDetail"));
const Timetable = lazy(() =>
  import("./components/Class/Timetable/Timetable/Timetable")
);
const ChangePassword = lazy(() => import("./components/Topbar/ChangePassword"));
const Header = lazy(() => import("./components/Header"));
const Admission = lazy(() =>
  import("./components/AdmissionsManagement/Admission/Admission")
);
const AdmissionDetails = lazy(() =>
  import(
    "./components/AdmissionsManagement/Admission/AdmissionDetails/AdmissionDetails"
  )
);
const Login = lazy(() => import("./components/Login/Login/Login"));
const Teacher = lazy(() => import("./components/Teacher"));
const Expenses = lazy(() => import("./containers/AllExpenses"));
const Annoucements = lazy(() =>
  import("./components/Annoucement/Annoucement/Annoucement")
);
const FeeDetails = lazy(() => import("./containers/FeeDetails/FeeDetails"));
const Home = lazy(() => import("./containers/Home"));
const Results = lazy(() => import("./containers/Results"));
const Students = lazy(() => import("./containers/Students"));
const AdmiDashboard = lazy(() => import("./components/AdmissionsManagement/AdmissionDashboard/AdmissionDashboard"))
const AdmiClassManagement = lazy(() => import("./components/AdmissionsManagement/AdmissionClassManagement/AdmissionClassMangement"))
const AdmiStudentManagement = lazy(() => import("./components/AdmissionsManagement/AdmissionStudentMangement/AdmissionStudentManagement"))
const AdmiStudentDetailsManagement = lazy(() => import("./components/AdmissionsManagement/AdmissionStudentMangement/AdmissionStudentDetails"))

const StudentPerformance = lazy(() =>
  import("./components/StudentItems/StudentPerformance/StudentPerformance")
);
const StudentDetails = lazy(() =>
  import("./containers/Students/StudentDetails/StudentDetails")
);

const generateQuestionPaperPdf = lazy(() =>
  import("./components/Exam/AIQuestionPapers/GenerateAiQuestionPaperPdf")
);

const StudentAttendance = lazy(() =>
  import("./containers/Attendance/StudentsAttend")
);
const ViewClassDetails = lazy(() =>
  import("./components/Class/ClassDetail/ViewClassDetails/ViewClassDetails")
);
const StaffDetails = lazy(() =>
  import("./components/Teacher/TeacherDetails/StaffDetails/StaffDetails")
);
const Subject = lazy(() =>
  import("./components/Class/SubjectDetail/Subject/Subject")
);
const Topbar = lazy(() => import("./components/Topbar"));
const ViewSubject = lazy(() =>
  import("./components/Class/SubjectDetail/ViewSubject/ViewSubject")
);
const ViewExams = lazy(() => import("./components/Exam/Exams/ExamDetails"));
const EnterMarks = lazy(() =>
  import("./components/Exam/EnterMarks/EnterMarks")
);
const AnnouncementDetails = lazy(() =>
  import(
    "./components/Annoucement/AddAnnouce/AnnouncementDetails/AnnouncementDetails"
  )
);
const FeeComponent = React.lazy(() =>
  import("./components/Payment/FeeComponent/FeeComponent/FeeComponent")
);
const ViewAllStaff = React.lazy(() =>
  import("./components/Dashboard/ViewAllStaff/ViewAllStaff")
);
const LeaveDetails = React.lazy(() =>
  import("./components/Holiday/LeaveDetails/LeaveDetails")
);
// const BranchList = React.lazy(() =>
//   import("./components/BranchList/BranchList")
// );
const stocks = React.lazy(() =>
  import("./components/InventoryManagementSystem/Stocks/ViewStocksModal")
);
const StudentFee = React.lazy(() =>
  import("./components/StudentFee/StudentFee")
);
// /expense-type
const ExpenseType = React.lazy(() =>
  import("./components/Payment/ExpenseType/ExpenseType")
);
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const voiceCall = React.lazy(() =>
  import("./components/Annoucement/Voicecall/VoiceCall")
);
const nonTeaching = React.lazy(() =>
  import("./components/Teacher/Non-Teaching/NonTeaching")
);
//loader
const Loading = () => {
  return (
    <div className="loadinganimation">
      {/* <img alt="loading" src={LoadingGif} /> */}
      {/* <h3>Loading...</h3> */}
      <Spin size="large" />
    </div>
  );
};
if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
  console.debug = () => { };
  console.info = () => { };
  console.warn = () => { };
}

function App() {

  const brachCode = Cookies.get("branchCode");
  const entity = Cookies.get("entity");
  const userId = Cookies.get("userId");
  const studentId = 1;
  const driverId = 1;
  const staffId = userId;
  const [componentList, setComponentList] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [rolesData, setRolesData] = useState([]);
  const [isAuth, setIsAuth] = useState(false);
  const [updatePopupModal, setUpdatePopupModal] = useState(false);
  const [appUpdatesModal, setAppUpdatesModal] = useState(false);
  const [unpaidModal, setUnpaidModal] = useState(false);
  const [serverHealth, setServerHealth] = useState(null);
  const [settingsFields, setSettingsFields] = useState({});
  const [settingsReminderModal, setSettingsReminderModal] = useState(false);
  const [updates, setUpdates] = useState(null);
  const [academicYearsData, setAcademicYearsData] = useState();
  const [selectedAcademicYear, setSelectedAcademicYear] = useState();
  const navigate = useNavigate();
  const activeRoute = useLocation();
  useEffect(() => {
    if (nextYearSpecificRoutes?.includes(activeRoute?.pathname)) {
      navigate("/dashboard");
    }
  }, [selectedAcademicYear])

  useEffect(() => {
    if (serverHealth != null) {
      getSettingsData();
      getAcademicYearStatus();
    }
  }, [serverHealth])
  const getAcademicYearStatus = async () => {
    try {
      const response = await AcademicYearService.getAcademicYearStatus();
      const academicYearsData = response?.data;
      const presentYearData = academicYearsData?.find(academicYear => academicYear?.isPresentYear);
      const presentYearIndex = academicYearsData?.findIndex(year => year.id === presentYearData?.id);
      const nextYears = academicYearsData?.slice(presentYearIndex, presentYearIndex + 2);
      setAcademicYearsData(nextYears);
      setSelectedAcademicYear(presentYearData?.id);
    }
    catch (error) { }
  }
  const activeYear = academicYearsData?.find(yearData => yearData?.id === selectedAcademicYear);
  const filteredSidebarList = () => {
    if (activeYear?.isPresentYear) {
      return sidebarList;
    }
    else {
      return nextYearAdmissionSidebarList
    }
  }
  useEffect(() => {
    if (activeYear !== null && activeYear !== undefined) {
      if (activeYear?.isPresentYear) {
        // navigate("/dashboard")
      }
      else {
        navigate("/enquiry-forms");
      }
    }
  }, [selectedAcademicYear])

  const getSettingsData = async () => {
    try {
      const response = await InstituteService.getInstSettings()
      const settingsObject = response?.data;
      let emptyMandatoryFields = 0;
      let emptyOptionalFields = 0;
      // if (!settingsObject?.academicYearStartDate) emptyMandatoryFields++;
      // if (!settingsObject?.admissionNumberPattern) emptyMandatoryFields++;
      // if (!settingsObject?.rollNumberPattern) emptyMandatoryFields++;
      // if (!settingsObject?.weekends) emptyMandatoryFields++;
      // if (!settingsObject?.noOfTerms) emptyMandatoryFields++;

      // if (!settingsObject?.fbPageLink) emptyOptionalFields++;
      // if (!settingsObject?.instaLink) emptyOptionalFields++;
      // if (!settingsObject?.whatsAppSenderNumber) emptyOptionalFields++;

      setSettingsFields({ emptyMandatoryFields, emptyOptionalFields });
      if (emptyMandatoryFields > 0
        // || emptyOptionalFields > 0
      ) {
        setSettingsReminderModal(true);
      }
    }
    catch { }
  };

  useEffect(() => {
    if (serverHealth != null) {
      const isUpdatePopupOpen = sessionStorage.getItem("updatePopupModal");
      if (isUpdatePopupOpen) {
        return;
      }
      setUpdatePopupModal(true);
    }
  }, [serverHealth])
  useEffect(() => {
    if (serverHealth != null) {
      const appUpdatesOpen = sessionStorage.getItem("appUpdates");
      if (appUpdatesOpen) {
        return;
      }
      getUpdates();
    }
  }, [serverHealth])
  useEffect(() => {
    if (serverHealth != null) {
      const paymentReminded = sessionStorage.getItem("paymentReminder");
      if (paymentReminded) {
        return;
      }
      getPaymentData();
    }
  }, [serverHealth])
  const getUpdates = () => {
    InstituteService.getUpdates()
      .then(response => {
        sessionStorage.setItem("appUpdates", "updates displayed");
        if (response?.data?.length === 0) {
          return;
        }
        else {
          setUpdates(response?.data?.reverse());
          setAppUpdatesModal(true);
          setUpdatePopupModal(false);
        }
      })
      .catch(error => { })
  }
  const getPaymentData = () => {
    BranchService.getPaymentData({branchCode: Cookies.get("branchCode")})
      .then(response => {
        if (response?.data?.showPaymentReminder) {
          setUnpaidModal(true);
          // setAppUpdatesModal(false);
          // setUpdatePopupModal(false);
        }
        else {
          sessionStorage.setItem("paymentReminder", "reminder displayed");
        }

      })
      .catch(error => { })
  }

  const getToken = () => {
    const token = Cookies.get("accessToken");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      window.location.replace(`${config.loginUrl}`);
    }
  };

  useEffect(() => {
    const socket = new SockJS(`${process.env.REACT_APP_NOTIFICATION_BASE_URL}`);
    const stompClient = Stomp.over(socket);

    const connectCallback = (frame) => {
      stompClient.subscribe(`/topic/${brachCode}/instAdmin`, (greeting) => {
        const response = JSON.parse(greeting.body);
        notification.success({
          message: (
            <div>
              <strong>{response.message}</strong>
              <div style={{ textAlign: "end" }}>
                <p>From</p>
                <em>{response.sender}</em>
              </div>
            </div>
          ),
          duration: 10,
          placement: "topRight",
        });
      });
      stompClient.subscribe(
        `/topic/${brachCode}/exams/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/exams/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/holiday/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/holiday/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/jobOpenings/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/timeTable/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/timeTable/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/staffLeave/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/meeting/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      //transport
      stompClient.subscribe(
        `/topic/${brachCode}/driver/${driverId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      //Library
      stompClient.subscribe(
        `/topic/${brachCode}/library/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${brachCode}/library/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            duration: 10,
            placement: "topRight",
          });
        }
      );
    };

    stompClient.connect({}, connectCallback);

    return () => {
      stompClient.disconnect();
    };
  }, []);

  const notLogin = () => {
    let token = Cookies.get("accessToken");
    if (token === null || token === "" || (token === undefined && !isAuth)) {
      checkAuth();
    } else if (token) {
      checkAuth();
      setIsAuth(true);
    }
  };

  useEffect(() => {
    let token = Cookies.get("accessToken");
    if (token && isAuth) {
      getComponentList();
    }
  }, [isAuth]);

  const getInstituteFeatures = () => {
    UserManagementService.getInstituteFeatures()
      .then((response) => {
        getComponentList();
      })
      .catch((error) => { });
  };

  const getComponentList = () => {
    const params = {
      userId: userId,
      entity: entity,
    };
    UserManagementService.getLoginUserDetails(params)
      .then((res) => {
        if (res.data?.features?.length === 0) {
          getInstituteFeatures();
        }

        setComponentList(res.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    notLogin();
  }, [isAuth]);

  const routeList = [
    {
      path: "/dashboard",
      component: Home,
    },

    {
      path: "/whatsapp-message",
      component: Whatsapp,
    },
    {
      path: "/email",
      component: email,
    },
    {
      path: "/branches",
      component: BranchList,
    },
    {
      path: "/admissions",
      component: Admission,
    },
    {
      path: "/admission-details/:id",
      component: AdmissionDetails,
    },
    {
      path: "/students",
      component: Students,
    },
    {
      path: "/student-performance",
      component: StudentPerformance,
    },
    {
      path: "/students-fee-collection",
      component: StudentFee,
    },
    {
      path: "/student-promotion",
      component: studentPromotion,
    },
    {
      path: "/studentdetails/:id",
      component: StudentDetails,
    },
    {
      path: "/generate-question-paper-pdf",
      component: generateQuestionPaperPdf,
    },
    // generateQuestionPaperPdf
    {
      path: "/expense-type",
      component: ExpenseType,
    },
    {
      path: "/teaching-staff",
      component: Teacher,
    },
    {
      path: "/profile",
      component: Profile,
    },
    {
      path: "/staffviewAll",
      component: ViewAllStaff,
    },
    {
      path: "/classdetails",
      component: ClassDetails,
    },
    {
      path: "/classdetails/:id",
      component: ViewClassDetails,
    },
    {
      path: "/subject",
      component: Subject,
    },
    {
      path: "/exam-results",
      component: EnterMarks,
    },
    {
      path: "/exam-schedule",
      component: ExamSchedule,
    },
    {
      path: "/daily-update",
      component: DailyUpdate,
    },
    {
      path: "/announcement-type",
      component: AnnounementType,
    },
    {
      path: "/view-subject/:subjectId",
      component: ViewSubject,
    },
    {
      path: "/examdetails/:examId",
      component: ViewExams,
    },

    {
      path: "/view-policy",
      component: viewPolicyDocument,
    },

    {
      path: "/timetable",
      component: Timetable,
    },
    {
      path: "/feedetails",
      component: FeeDetails,
    },
    // {
    //   path: "/feedetails/:classid?/:sectionid?",
    //   component: FeeDetails,
    // },
    {
      path: "/changepassword",
      component: ChangePassword,
    },
    {
      path: "/feecomponent",
      component: FeeComponent,
    },
    {
      path: "/expenses",
      component: Expenses,
    },
    {
      path: "/exams",
      component: Exams,
    },
    {
      path: "/exam-grades",
      component: ExamGrades,
    },
    {
      path: "/results",
      component: Results,
    },
    {
      path: "/holidays",
      component: Holidays,
    },
    {
      path: "/leaves-details/:id",
      component: LeaveDetails,
    },
    {
      path: "/announcements",
      component: Annoucements,
    },
    {
      path: "/announcement-details/:id",
      component: AnnouncementDetails,
    },
    {
      path: "/staffdetails/:staffId",
      component: StaffDetails,
    },
    {
      path: "/syllabus",
      component: Syllabus,
    },
    {
      path: "/meetings",
      component: OneOnOneMeeetings,
    },
    {
      path: "/summercamp",
      component: SummerCamp,
    },
    {
      path: "/user-management",
      component: UserManagement,
    },
    {
      path: "/humanresources/:id",
      component: ViewJob,
    },
    {
      path: "/tutors",
      component: TutorDescription,
    },
    {
      path: "/resignation",
      component: Resignation,
    },
    {
      path: "/designations",
      component: Designation,
    },
    {
      path: "/staff-attendence-details/:id",
      component: StaffAttendenceDetails,
    },
    {
      path: "/inventory",
      component: InventoryManagementSystem,
    },
    {
      path: "/departments",
      component: Departments,
    },
    {
      path: "/student-attendance",
      component: StudentAttendance,
    },
    {
      path: "/transport",
      component: Transport,
    },
    {
      path: "/edpedia-store",
      component: EdpediaStore,
    },
    {
      path: "/address",
      component: Address,
    },
    {
      path: "/orders",
      component: orders,
    },
    {
      path: "/hostel-dashboard",
      component: HostelDashboard,
    },
    {
      path: "/hostel-rooms",
      component: HostelRooms,
    },
    {
      path: "/hostel-beds",
      component: HostelBeds,
    },
    {
      path: "/hostel-rent",
      component: HostelRent,
    },
    {
      path: "/hostel-students",
      component: HostelStudents,
    },
    {
      path: "/hostel-expenses",
      component: HostelExpenses,
    },
    {
      path: "/hostel-complaints",
      component: HostelComplaints,
    },

    {
      path: "/sports-management",
      component: SportsManagement,
    },
    {
      path: "/enquiry-forms",
      component: EnquiryForms,
    },
    {
      path: "/exam-results",
      component: EnterMarks,
    },
    // {
    //   path: "/hostel-management",
    //   component: HostelManagement,
    // },
    {
      path: "/designations",
      component: Designations,
    },
    {
      path: "/enquiry-forms",
      component: EnquiryForms,
    },
    {
      path: "/evaluation",
      component: EnquiryEvaluation,
    },
    {
      path: "/sports-management",
      component: SportsManagement,
    },
    {
      path: "/enquiry-form-details",
      component: EnquiryFormDetails,
    },
    {
      path: "/profile",
      component: Profile,
    },
    {
      path: "/changepassword",
      component: ChangePassword,
    },
    {
      path: "/staff-salary-structure",
      component: StaffSalaryStructure,
    },
    {
      path: "/staff-attendance",
      component: StaffAttendance,
    },
    {
      path: "/hiring",
      component: Hiring,
    },
    {
      path: "/staff-regularization",
      component: StaffRegulizations,
    },
    {
      path: "/regularization-unlocks",
      component: StaffRegUnlock,
    },
    {
      path: "/transport-dashboard",
      component: TransportMainHome,
    },
    {
      path: "/payroll",
      component: Payrols,
    },
    {
      path: "/drivers-helpers",
      component: DriversAndStaff,
    },
    {
      path: "/buses",
      component: Buses,
    },
    {
      path: "/pickup-points",
      component: PickUpPoints,
    },
    {
      path: "/routes",
      component: BusRouteNew,
    },
    {
      path: "/tranport-enrolled-students",
      component: TransportEnrollStudents,
    },
    {
      path: "/fuel-filling",
      component: FuelFilling,
    },
    {
      path: "/vehicle-repair",
      component: VehicleRepair,
    },
    {
      path: "/maintenance",
      component: Maintenance,
    },
    {
      path: "/transport-analytics",
      component: Analysis,
    },
    {
      path: "/complaints",
      component: Complaints,
    },
    {
      path: "/branchlist",
      component: BranchList,
    },
    {
      path: "/studentfee",
      component: StudentFee,
    },
    {
      path: "/reports",
      component: Reports,
    },
    {
      path: "/student-fee-details",
      component: StudentFeeDetails,
    },
    {
      path: "/daily-attendance",
      component: DailyAttendance,
    },
    {
      path: "/monthly-attendance",
      component: MonthlyAttendance,
    },
    {
      path: "/accept-attendance",
      component: AcceptAttendance,
    },
    {
      path: "/payroll-dashboard",
      component: PayrollDashboard,
    },
    {
      path: "/payslips",
      component: EmployeesPayrolls,
    },
    {
      path: "/payroll-leaves",
      component: PayrollLeaves,
    },
    {
      path: "/lop-reports",
      component: LopReports,
    },
    {
      path: "/progress-card",
      component: progressCard,
    },
    {
      path: "/employee-bank-accounts",
      component: BankAccounts,
    },
    {
      path: "/stocks",
      component: ViewStocksModal,
    },
    {
      path: "/leave-dashboard",
      component: LeavesManagementDashboard,
    },
    {
      path: "/leave-requests",
      component: Leaves,
    },
    {
      path: "/leaves-history",
      component: StaffLeavesUsage,
    },
    {
      path: "/non-teaching-staff",
      component: nonTeaching,
    },
    {
      path: "/approval-workflow",
      component: ApprovalWorkflow,
    },
    {
      path: "/leave-calendar",
      component: PayRollLeaveCalendar,
    },
    {
      path: "/policies",
      component: Policies,
    },
    {
      path: "/reimbursements",
      component: Reimbursement,
    },
    {
      path: "/leave-balances",
      component: LeaveBalances,
    },
    {
      path: "/ai-question-papers",
      component: AIQuestionPapers,
    },
    {
      path: "/ai-generated-syllabus",
      component: AiGeneratedSyllabus,
    },
    {
      path: "/send-enquiry-forms",
      component: SendEnquiryForms,
    },
    {
      path: "/social-media",
      component: SocialMedia,
    },
    {
      path: "/voice-call",
      component: voiceCall,
    },
    {
      path: "/fee-collection-reports",
      component: FeeCollectionReports,
    },
    {
      path: "/student-promotion",
      component: StudentPromotion,
    },
    {
      path: "/kitchen-management",
      component: KitchenDashboard,
    },
    {
      path: "/hostel-expense-category",
      component: ExpenseCategory,
    },
    {
      path: "/hostel-expense-category",
      component: ExpenseCategory,
    },

  ];

  const getAllowedRoutes = routeList?.map((item) => {
    /**
     * Changed this code to avoid the params check for path
      (feature) => item.path.includes(feature.routePath)
    */
    const matchingFeature = componentList?.features?.find(
      (feature) => feature.routePath === item.path
    );
    return {
      ...item,
      isAllowed: !!matchingFeature,
      grantAccess: matchingFeature?.grantAccess || false,
    };
  });

  const logOut = () => {
    setIsAuth(false);
    Cookies.remove("accessToken");
    window.location.replace(config.loginUrl);
  };
  const handleMenuCollapse = () => {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    if (isAuth) {
      getServerHealthStatus();
    }
  }, [isAuth])

  const getServerHealthStatus = async () => {
    try {
      const response = await ServerHealthService.getServerHealth();
      setServerHealth(true);
    }
    catch {
      setServerHealth(false);
    }
  };


  const commonFeatures = [
    {
      path: "/admission-dashboard",
      component: AdmiDashboard
    },
    {
      path: "/student-details",
      component: AdmiStudentManagement
    },
    {
      path: "/class-details",
      component: AdmiClassManagement
    },
    {
      path: "/student-details/:id",
      component: AdmiStudentDetailsManagement
    },
    {
      path: "/fee-components",
      component: FeeComponents
    },
    {
      path: "/class-fee-Structure",
      component: ClassFeeStructure
    },
    {
      path: "/student-fee-breakup",
      component: StudentFeeBreakup
    },
    {
      path: "/finance-reports",
      component: FinanceReports
    },
    {
      path: "/salary-structure",
      component: NewSalaryStructure
    },
    {
      path: "/salary-grade",
      component: SalaryGrade
    },
    {
      path: "/staff-salary-paid-status",
      component: StaffSalaryPaidStatus
    },
    {
      path: "/homework",
      component: Homework
    },
    {
      path: "/class-fee-summary",
      component: FeeSummary
    },
    {
      path: "/send-consent-forms",
      component: ConsentForm
    }
  ]

  if (!isAuth) {
    return (
      <Suspense fallback={<Spin indicator={<Loading />} />}>
        <Login getToken={getToken} />
      </Suspense>
    );
  } else {
    return serverHealth ? (
      <>
        <div className="app flexBox-sty">
          {isAuth && componentList?.features?.length > 0 && (
            <>
              <Suspense fallback={""}>
                <Topbar collapsed={collapsed} setCollapsed={setCollapsed} logOut={logOut} componentList={getAllowedRoutes}
                  academicYearsData={academicYearsData}
                  selectedAcademicYear={selectedAcademicYear}
                  setSelectedAcademicYear={setSelectedAcademicYear}
                />

              </Suspense>
              <Suspense fallback={""}>
                <Header
                  setRolesData={setRolesData}
                  rolesData={rolesData}
                  componentList={componentList}
                  menuDisabled={settingsFields?.emptyMandatoryFields > 0 || unpaidModal}
                  sidebarList={filteredSidebarList()}
                />
              </Suspense>
              <Suspense fallback={<Loading />}>
                <LoadData />
              </Suspense>
              {/* <Suspense fallback={<Loading />}>
                <ChatBox />
              </Suspense> */}
              <div style={{ position: "relative" }}>
                <Routes>
                  {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
                  <Route
                    path="/"
                    element={<Navigate to={getAllowedRoutes[0].path} />}
                  />
                  {getAllowedRoutes?.map((route, index) => (
                    <Route
                      key={index}
                      exact
                      path={route.path}
                      element={
                        <Suspense fallback={<Spin indicator={<Loading />} />}>
                          <BlurWrapper
                            grantAccess={route.grantAccess}
                            isAllowed={route.isAllowed}
                          >
                            <route.component activeYear={activeYear} grantAccess={route.grantAccess} />
                          </BlurWrapper>
                        </Suspense>
                      }
                    />
                  ))}

                  <Route
                    path="/settings"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <Settings getSettingsData={getSettingsData} disableCancel={settingsFields?.emptyMandatoryFields > 0} />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/lenders"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <Lenders />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/loans"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <LoansHome />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/repayments"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <Repayments />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/borrowers"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <Borrowers />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/daily-notes"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <DailyNotes />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/diary-note"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <DiaryNote />
                      </Suspense>
                    }
                  />
                  {commonFeatures?.map(feature => (
                    <Route
                      key={feature?.path}
                      path={feature?.path}
                      exact
                      element={
                        <Suspense fallback={<Spin indicator={<Loading />} />}>
                          <feature.component activeYear={activeYear} />
                        </Suspense>
                      }
                    />
                  ))}
                  <Route
                    path="/service"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <SupportService />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/user_log_details"
                    element={
                      <Suspense fallback={<Spin indicator={<Loading />} />}>
                        <UserLogDetails />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/terms&conditions"
                    element={
                      <TermsAndConditions />
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={
                      <PrivacyPolicy />
                    }
                  />
                  {

                  },
                </Routes>
                {!collapsed &&
                  <Menu
                    style={{ position: "fixed", zIndex: 999, height: "87%", overflow: "auto", width: "200px", marginTop: 65 }}
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["classdetails"]}
                    mode="inline"
                    theme="dark"
                    items={filteredSidebarList()}
                    onClick={handleMenuCollapse}
                  />}
              </div>
            </>
          )}
        </div>
        <div>
          <SupportChatIcon />
          <Footer />
        </div>
        {updatePopupModal && (
          <UpdatesPopupModal
            setUpdatePopupModal={setUpdatePopupModal}
            updatePopupModal={updatePopupModal}
          />
        )}
        {appUpdatesModal && (
          <AppUpdatesModal
            appUpdatesModal={appUpdatesModal}
            setAppUpdatesModal={setAppUpdatesModal}
            updates={updates}
          />
        )}
        {unpaidModal && (
          <UnpaidModal
            unpaidModal={unpaidModal}
            setUnpaidModal={setUnpaidModal}

          />
        )}
        {settingsReminderModal && (
          <SettingsFieldsReminderModal
            reminderModal={settingsReminderModal}
            setReminderModal={setSettingsReminderModal}
            cancelDisabled={settingsFields?.emptyMandatoryFields > 0}
          />
        )}

      </>
    ) : (
      serverHealth === null ?
        (<div className="total-page-loading">
          <Spin size="large" spinning={true} />
        </div>)
        :
        (<ServerDownPage resetErrorBoundary={() => window.location.href = "/"} />)
    );
  }
}

export default App;
