import { Button, Modal, Select, Table, TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import "./ConsentForm.scss"
import _, { after } from "lodash";
import { useSelector } from 'react-redux';
import { getClassList } from '../../store/classSlice';
import { customOrder } from '../../Constants/Utils';
import { Option } from 'antd/lib/mentions';
import { consentService } from '../../Services/ConsentService';
import { redirect, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { EyeOutlined } from '@ant-design/icons';


const ConsentForm = () => {
    const navigate = useNavigate()
    const classState = useSelector(getClassList);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState();
    const [selectedClass, setSelectedClass] = useState();
    const [selectedSection, setSelectedSection] = useState();
    const [selectedSections, setSelectedSections] = useState([]);
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [modalVisible, setmodalVisible] = useState(false);

    const [reason, setReason] = useState("");

    const handleViewReason = (reason) => {
        setReason(reason);
        setmodalVisible(true);
    };

    const handleModalClose = () => {
        setmodalVisible(false);
        setReason("");
    };

    useEffect(() => {
        if (selectedClass){
            getAllConsents()
        }
    }, [selectedClass])

    const getAllConsents = () => {
        consentService.getAllConsents()
            .then(response => {
                setData(response?.data)                
                const classData = response?.data?.filter(form => form?.presentClass === selectedClass)
                setTableData(classData)
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    useEffect(() => {
        const uniqueClasses = _.uniqBy(classState, "className").map((getClass) => {

            return {
                label: getClass.className,
                value: getClass.className,
            };
        });
        setClasses(uniqueClasses);

        if (uniqueClasses.length > 0) {
            setSelectedClass(uniqueClasses[0].value);
            getSectionsFromClass(uniqueClasses[0].value);
        }
    }, []);

    const getSectionsFromClass = (e) => {
        const filteredSection = classState?.filter((item) => item.className === e);
        const getSection = filteredSection.map((item) => {
            return {
                label: item.section,
                value: item.classId,
            };
        });
        setSections(getSection);
        setSelectedSection(filteredSection[0].classId);
        setSelectedSectionId(filteredSection[0].classId);
    };

    const onClassChange = (value) => {
        setSelectedClass(value);
    };

    const handleConsentFormToParents = () => {
        setIsModalOpen(true)
    }

    const transformData = (data) => {
        const transformedData = [];
        const classesMap = new Map();

        data.forEach((item) => {
            const { classId, className, section } = item;
            if (!classesMap.has(className)) {
                classesMap.set(className, []);
            }
            classesMap.get(className).push({ value: classId, label: section });
        });

        classesMap.forEach((sections, className) => {
            let sectionList = sections?.map((item, index) => {
                return {
                    label: `${className} - ${item.label}`,
                    value: item.value,
                };
            });
            transformedData.push({
                value: className,
                label: className,
                children: sectionList,
            });
        });
        return transformedData;
    };

    const handleChange = (value) => {
        setSelectedSections(value);
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Academic Year",
            dataIndex: "academicYear",
            key: "academicYear",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Present Class",
            dataIndex: "presentClass",
            key: "presentClass",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            render: (text) => new Date(text).toLocaleDateString(),
        },
        {
            title: "Parent Name",
            dataIndex: "parentName",
            key: "parentName",
        },
        {
            title: "Mobile Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
        },
        {
            title: "New Class",
            dataIndex: "newClass",
            key: "newClass",
        },
        {
            title: "Status",
            dataIndex: "isContinuing",
            key: "isContinuing",
            render: (text) => (
                text? "Continue" : "Discontinue"
            )
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) =>
                !record.isContinuing && record.reason ? (
                    <Button
                        type="link"
                        className='blue-button'
                        onClick={() => handleViewReason(record.reason)}
                    >
                        View Reason
                    </Button>
                ) : null,
        },
    ];

    const handleOk = () => {
        consentService.sendConsentForm(selectedSections)
            .then(response => {
                setIsModalOpen(false)
                setSelectedSections([])
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    const handleViewConsentForm = () => {
        const branchCode = Cookies.get("branchCode");

        window.open(`https://general.edpedia.co/consent-form/${branchCode}`, "_blank");
    }

    const treeData = transformData(classState);


    return (
        <main>
            <div className='consent-container'>
                <h1>Consent Form</h1>
                <div>
                    <Select
                        style={{ width: 116 }}
                        onChange={onClassChange}
                        placeholder="Class"
                        id="inputs"
                        value={selectedClass}
                    >
                        {customOrder?.map(item => (
                            <Option value={item}>{item}</Option>
                        ))}
                    </Select>
                    <Button className="blue-button" onClick={handleConsentFormToParents}>Send consent form to parents</Button>
                    {/* <Button className="blue-button" onClick={handleViewConsentForm}>View consent form</Button> */}
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                rowKey="id"
                pagination={{ pageSize: 20 }}
            />
            <Modal
                open={isModalOpen}
                title="Select classes To send"
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <TreeSelect
                    treeData={treeData}
                    placeholder="Select class and section"
                    onChange={handleChange}
                    value={selectedSections}
                    treeCheckable
                    style={{ minWidth: '180px' }}
                    multiple
                />
            </Modal>

            <Modal
                title="Reason for Discontinuation"
                visible={modalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <p>{reason}</p>
            </Modal>
        </main>
    )
}

export default ConsentForm