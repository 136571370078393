import React from "react";
import "./ErrorPage.scss";

const ErrorPage = ({ resetErrorBoundary }) => {
  return (
    <div className="error-page">
      <div className="error-content">
        <p className="error-message">Something Went Wrong</p>
        <p className="try-again">
          We Apologize for the Inconvenience Caused, Try again Later
        </p>
        <p className="error-code">
          If Problem Persist Please Contact Administrator.
        </p>
        <button className="error-button" onClick={resetErrorBoundary}>
          Refresh Page
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
