import { CREATE_BORROWERS, CREATE_LENDERS, CREATE_LOAN, CREATE_LOAN_DOCUMENT, CREATE_REPAYMENT, CREATE_TERMS, DELETE_BORROWERS, DELETE_LENDERS, DELETE_LOAN, DELETE_LOAN_DOCUMENT, DELETE_REPAYMENT, DELETE_TERMS, EDIT_BORROWERS, EDIT_LENDERS, EDIT_LOAN, EDIT_LOAN_DOCUMENT, EDIT_REPAYMENT, EDIT_TERMS, GET_BORROWERS, GET_LENDERS, GET_LOAN_DETAILS, GET_LOAN_DOCUMENTS, GET_LOANS, GET_REPAYMENTS, GET_TERMS } from "../Constants/Constants";
import { ACCOUNTS_SERVICE } from "../Constants/StringConstants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest, PutAPIRequest } from "./Api";

// Lenders
const getLenders = () => {
  return GetAPIRequest({ url: GET_LENDERS, service: ACCOUNTS_SERVICE });
};
const createLender = (data) => {
  return PostAPIRequest({url: CREATE_LENDERS, data, service: ACCOUNTS_SERVICE});
}
const editLender = (id, data) => {
  return PutAPIRequest({url: EDIT_LENDERS.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteLender = (id) => {
  return DeleteAPIRequest({url: DELETE_LENDERS.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

// Loans
const getLoanDetails = (id) => {
  return GetAPIRequest({ url: GET_LOAN_DETAILS.replace("{id}", id), service: ACCOUNTS_SERVICE });
};
const getLoans = () => {
  return GetAPIRequest({ url: GET_LOANS, service: ACCOUNTS_SERVICE });
};
const createLoan = (data) => {
  return PostAPIRequest({url: CREATE_LOAN, data, service: ACCOUNTS_SERVICE});
}
const editLoan = (id, data) => {
  return PutAPIRequest({url: EDIT_LOAN.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteLoan = (id) => {
  return DeleteAPIRequest({url: DELETE_LOAN.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

// Repayments
const getRepayments = () => {
  return GetAPIRequest({ url: GET_REPAYMENTS, service: ACCOUNTS_SERVICE });
};
const createRepayment = (data) => {
  return PostAPIRequest({url: CREATE_REPAYMENT, data, service: ACCOUNTS_SERVICE});
}
const editRepayment = (id, data) => {
  return PutAPIRequest({url: EDIT_REPAYMENT.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteRepayment = (id) => {
  return DeleteAPIRequest({url: DELETE_REPAYMENT.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

// Loan Terms
const getTerms = () => {
  return GetAPIRequest({ url: GET_TERMS, service: ACCOUNTS_SERVICE });
};
const createTerms = (data) => {
  return PostAPIRequest({url: CREATE_TERMS, data, service: ACCOUNTS_SERVICE});
}
const editTerms = (id, data) => {
  return PutAPIRequest({url: EDIT_TERMS.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteTerms = (id) => {
  return DeleteAPIRequest({url: DELETE_TERMS.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

// Loan Documents
const getDocuments = () => {
  return GetAPIRequest({ url: GET_LOAN_DOCUMENTS, service: ACCOUNTS_SERVICE });
};
const createDocuments = (data) => {
  return PostAPIRequest({url: CREATE_LOAN_DOCUMENT, data, service: ACCOUNTS_SERVICE});
}
const editDocuments = (id, data) => {
  return PutAPIRequest({url: EDIT_LOAN_DOCUMENT.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteDocuments = (id) => {
  return DeleteAPIRequest({url: DELETE_LOAN_DOCUMENT.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

// Borrowers
const getBorrowers = () => {
  return GetAPIRequest({ url: GET_BORROWERS, service: ACCOUNTS_SERVICE });
};
const createBorrowers = (data) => {
  return PostAPIRequest({url: CREATE_BORROWERS, data, service: ACCOUNTS_SERVICE});
}
const editBorrowers = (id, data) => {
  return PutAPIRequest({url: EDIT_BORROWERS.replace("{id}", id), data, service: ACCOUNTS_SERVICE});
}
const deleteBorrowers = (id) => {
  return DeleteAPIRequest({url: DELETE_BORROWERS.replace("{id}", id), service: ACCOUNTS_SERVICE});
}

export const AccountsService = {
  getLenders,
  createLender,
  editLender,
  deleteLender,

  getLoanDetails,
  getLoans,
  createLoan,
  editLoan,
  deleteLoan,

  getRepayments,
  createRepayment,
  editRepayment,
  deleteRepayment,

  getTerms,
  createTerms,
  editTerms,
  deleteTerms,

  getDocuments,
  createDocuments,
  editDocuments,
  deleteDocuments,
  
  getBorrowers,
  createBorrowers,
  editBorrowers,
  deleteBorrowers,
}