import { CREATE_TICKET , GET_ALL_TICKETS, UPDATE_TICKET, VIEW_TICKET_DETAILS, VIEW_TICKET_END_POINT, SEND_COMMNET, SEND_COMMNET_END_POINT } from "../Constants/Constants";

import { PostAPIRequest, GetAPIRequest } from "./Api";

const createTicket = (payLoad) => {
  return PostAPIRequest({ url: CREATE_TICKET, data: payLoad });
};

const getTicket =()=>{
    return GetAPIRequest({url: GET_ALL_TICKETS})
}

const updateTicket = (id, payLoad) => {
  return PostAPIRequest({url: UPDATE_TICKET+ id, data: payLoad })
}

const viewTicket = (id) => {
  return GetAPIRequest({url: VIEW_TICKET_DETAILS + id + VIEW_TICKET_END_POINT})
}

const sendComment = (id , payLoad) => {
  return PostAPIRequest({url: SEND_COMMNET + id + SEND_COMMNET_END_POINT, data: payLoad})
}

export const supportService = {
    createTicket,
    getTicket,
    updateTicket,
    viewTicket,
    sendComment
}