import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import { Modal, notification } from "antd";

export const academicYearValues = [
  "2000-01",
  "2001-02",
  "2002-03",
  "2003-04",
  "2004-05",
  "2005-06",
  "2006-07",
  "2007-08",
  "2008-09",
  "2009-10",
  "2010-11",
  "2011-12",
  "2012-13",
  "2013-14",
  "2014-15",
  "2015-16",
  "2016-17",
  "2017-18",
  "2018-19",
  "2019-20",
  "2020-21",
  "2021-22",
  "2022-23",
  "2023-24",
  "2024-25",
  "2025-26",
  "2026-27",
  // "2027-28",
];

export const fullMonthNameList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const allCustomBranchFeatures = {
  sidNo: "sidNo",
  cfdRollNumber: "cfdRollNumber",
  userAdmissionId: "userAdmissionId",
};
export const paymentModeList = [
  "Cash",
  "UPI",
  "Bank Transfer",
  "Card",
  "Cheque",
  "Demand Draft",
  "Net Banking",
];

export const tuitionFeeKeywordsList = [
  "CUSTOM",
  "DEFAULT",
  "HOSTELER",
  "DAY_BOARDING",
];

export const termsListForOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const classOrder = [
  "P.G", "Nursery", "L.K.G", "U.K.G", "1st", "2nd", "3rd", "4th", 
  "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"
];

export const handleExport = (chart, name) => {
  chart.exporting.filePrefix = name;
  chart.exporting.export("png");
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const createErrorMessage = (str) => {
  return `An error occured while creating ${str}, please try again later.`;
};
export const deleteErrorMessage = (str) => {
  return `An error occured while deleting ${str}, please try again later.`;
};
export const updateErrorMessage = (str) => {
  return `An error occured while updating ${str}, please try again later.`;
};
export const submitErrorMessage = (str) => {
  return `An error occured while submitting ${str}, please try again later.`;
};
export const saveErrorMessage = (str) => {
  return `An error occured while saving ${str}, please try again later.`;
};
export const sendErrorMessage = (str) => {
  return `An error occured while sending ${str}, please try again later.`;
};
export const uploadErrorMessage = (str) => {
  return `An error occured while uploading ${str}, please try again later.`;
};
export const addedErrorMessage = (str) => {
  return `An error occured while adding ${str}, please try again later.`;
};
export const downloadErrorMessage = (str) => {
  return `An error occured while downloading ${str}, please try again later.`;
};
export const generateErrorMessage = (str) => {
  return `An error occured while generating ${str}, please try again later.`;
};
export const assignErrorMessage = (str) => {
  return `An error occured while assigning ${str}, please try again later.`;
};
export const viewErrorMessage = (str) => {
  return `An error occured while getting ${str}, please try again later.`;
};
export const cancelErrorMessage = (str) => {
  return `An error occured while canceling ${str}, please try again later.`;
};

export const transferErrorMessage = (str) => {
  return `An error occured while ${str}, please try again later.`;
};

export const dynamicErrorMsg = (error, errorType, errorText) => {
  const getErrorMsg = (errorType, errorText) => {
    if (errorType === "Create") {
      return createErrorMessage(errorText);
    } else if (errorType === "Update") {
      return updateErrorMessage(errorText);
    } else if (errorType === "Delete") {
      return deleteErrorMessage(errorText);
    } else if (errorType === "Save") {
      return saveErrorMessage(errorText);
    } else if (errorType === "Submit") {
      return submitErrorMessage(errorText);
    } else if (errorType === "Download") {
      return downloadErrorMessage(errorText);
    } else if (errorType === "Send") {
      return sendErrorMessage(errorText);
    } else if (errorType === "Upload") {
      return sendErrorMessage(errorText);
    } else if (errorType === "Add") {
      return sendErrorMessage(errorText);
    } else if (errorType === "Generate") {
      return sendErrorMessage(errorText);
    } else if (errorType === "Assign") {
      return sendErrorMessage(errorText);
    } else if (errorType === "View") {
      return viewErrorMessage(errorText);
    } else if (errorType === "Cancel") {
      return cancelErrorMessage(errorText);
    } else if (errorType === "Transfer") {
      return transferErrorMessage(errorText);
    }
  };
  let errorMsg = getErrorMsg(errorType, errorText);

  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (error?.data?.message) {
    return error.data.message;
  } else if (error?.message) {
    return error.message;
  } else if (error?.data?.data) {
    return error.data.data;
  } else {
    return errorMsg;
  }
};
// export const dateString = (datestring, date) => {
//   return;
// };

export const validateFiles = (fileList, accept, maxSizeMB, errorMessage) => {
  const MAX_SIZE_BYTES = maxSizeMB * 1024 * 1024;
  const validFiles = [];
  const invalidFiles = [];

  fileList.forEach((file) => {
    const isAcceptedType = accept.includes(file.type);
    const isUnderMaxSize = file.size <= MAX_SIZE_BYTES;

    if (isAcceptedType && isUnderMaxSize) {
      validFiles.push(file.originFileObj);
    } else {
      invalidFiles.push(file);
    }
  });

  if (invalidFiles.length > 0) {
    notification.error({
      message: errorMessage,
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  }

  return validFiles;
};
export const validateFilesWithExtensionsAndSize = (
  fileList,
  extensions,
  maxSizeMB,
  errorMessage
) => {
  const MAX_SIZE_BYTES = maxSizeMB * 1024 * 1024;
  const validFiles = [];
  const invalidFiles = [];

  fileList.forEach((file) => {
    const isAcceptedType = extensions.some((extension) =>
      file.name.endsWith(extension)
    );
    const isUnderMaxSize = file.size <= MAX_SIZE_BYTES;

    if (isAcceptedType && isUnderMaxSize) {
      validFiles.push(file.originFileObj);
    } else {
      invalidFiles.push(file);
    }
  });

  if (invalidFiles.length > 0) {
    notification.error({
      message: errorMessage,
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  }

  return validFiles;
};

//capitalize
export const capitalizeWords = (str) => {
  return str.replace(/\b\w+/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const monthNames = [
  {
    value: "January",
    displayName: "Jan",
  },
  {
    value: "February",
    displayName: "Feb",
  },
  {
    value: "March",
    displayName: "Mar",
  },
  {
    value: "April",
    displayName: "Apr",
  },
  {
    value: "May",
    displayName: "May",
  },
  {
    value: "June",
    displayName: "Jun",
  },
  {
    value: "July",
    displayName: "Jul",
  },
  {
    value: "August",
    displayName: "Aug",
  },
  {
    value: "September",
    displayName: "Sep",
  },
  {
    value: "October",
    displayName: "Oct",
  },
  {
    value: "November",
    displayName: "Nov",
  },
  {
    value: "December",
    displayName: "Dec",
  },
];
export const examTypes = {
  internal: "internal",
  external: "external",
};

export const classSorting = (classList) => {
  const classNamesSet = new Set(classList.map((item) => item.className));
  let uniqueNamesArray = Array.from(classNamesSet);
  const customOrder = ["P.G", "Nursery", "L.K.G", "U.K.G"];
  const customOrderIndex = (className) => {
    const index = customOrder.indexOf(className);
    return index === -1 ? Infinity : index;
  };

  uniqueNamesArray = uniqueNamesArray.sort((a, b) => {
    const customIndexA = customOrderIndex(a);
    const customIndexB = customOrderIndex(b);
    if (customIndexA !== Infinity || customIndexB !== Infinity) {
      return customIndexA - customIndexB;
    }
    const numA = parseInt(a);
    const numB = parseInt(b);
    return numA - numB;
  });
  return uniqueNamesArray;
};
export const downloadFile = (data, filename) => {
  const blob = new Blob([data], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a); // Append the anchor to the body
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 1000);
};

export const classCustomOrder = ["P.G", "Nursery", "L.K.G", "U.K.G"];

export const customOrder = [
  "P.G", "Nursery", "L.K.G", "U.K.G", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"
];

export const schedulingType = {
  immediate: "immediate",
  schedule: "schedule",
};

export const inputHtmlTypeCheck = {
  EMAIL: "email",
  NUMBER: "number",
  DECIMAL: "decimal",
  DECIMAL_HOURS: "decimal_hours",
  FULL_DECIMAL: "full_decimal",
  MARKS: "marks",
};

export const successNotification = (message) => {
  notification.success({
    message,
    icon: (
      <CheckCircleFilled
        style={{
          color: "green",
        }}
      />
    ),
    duration: 3,
    placement: "bottomRight",
  });
};
export const errorNotification = (message) => {
  notification.error({
    message,
    placement: "bottomRight",
    icon: <DislikeOutlined style={{ color: "red" }} />,
    duration: 3,
  });
};
export const transferredStudentsFilter = {
  CLASS_STUDENTS: "Class Students",
  TRANSFERRED_STUDENTS: "Transferred Students",
};

export const cancelConfirmModal = ({ cancelForm }) => {
  Modal.confirm({
    title: `Are you sure you want to cancel this form`,
    onOk: cancelForm,
  });
};

export const keysToKeepInPenAdd = [
  "firstName",
  "lastName",
  "referredBy",
  "referenceReason",
  "contact",
  "email",
  "parentEmail",
  "address",
  "city",
  "state",
  "country",
  "fatherName",
  "parentAadhaarNumber",
  "admissionId",
  "userAdmissionId",
  "rationCardNo",
  "motherBankAccountNo",
  "motherName",
  "gender",
  "nationality",
  "parentContactNumber",
  "dateOfBirth",
  "classId",
  "postalCode",
  "studentAadhaarNumber",
  "parentWhatsappNumber",
  "academicYear",
  "accommodationType",
  "sidNo",
  "cfdRollNumber",
  "joiningDate",
  "ammaVadi",
];

export const indianLanguagesList = [
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "English",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];
export const homeworkTabs = ["Homework","Daily Notes", "Diary Notes"];
export const nextYearFeatures = ["Admissions"];
export const lenderTypes = ["INDIVIDUAL", "ORGANISATION"];
export const loanTypes = ["IN", "OUT"];
export const loanStatusValues = ["ONGOING", "COMPLETED"];
export const loanPaymentModes = ["ONLINE", "CHEQUE", "OFFLINE"];
export const borrowerTypes = ["EMPLOYEE", "NON_EMPLOYEE"];
export const feeDiscoutTypes = ["PERCENTAGE", "FIXED"];

export const handleInvalidInputErrorMessage = (error, type, message) => {
  if (error?.data?.message === "Invalid Input") {
    const message = error?.data?.errors?.join(", ")
    errorNotification(message);
}
else {
    errorNotification(dynamicErrorMsg(error, type, message));
}
}
export const installmentsNumberList = [1,2,3,4,5,6,7,8,9,10,11,12];
export const nextYearSpecificRoutes = ["/admission-dashboard", "/class-details", "/student-details"];