import {
  STUDENT_FEE_STRUCTURE,
  STUDENT_FEE_STRUCTURE_ENDPOINT,
  CREATE_STUDENTFEE_URL,
  EDIT_STUDENTFEE_URL,
  EDIT_STUDENTFEE_END_POINT,
  FIND_STUDENTFEE_URL,
  FIND_STUDENTFEE_END_POINT,
  STUDENT_FEEPAYMENT,
  STUDENT_SEARCH,
  GET_STUDENTS_FEE_LIST,
  REMINDER_STUDENT_FEE_ENDPOINTS,
  REMINDER_STUDENT_FEE,
  PAYMENTLINK,
  PAYMENTLINK_ENDPOINT,
  REMIND_STUDENT_FEE,
  GET_STUDENTS_FEE_COLLECTION,
  ENCRYPT_PAYMENT_DATA,
  INITIATE_PAYMENT_DATA,
  SAVE_STUDENTFEES_URL,
  CREATE_CUSTOM_STUDENT_FEE_URL,
  CUSTOM_STUDENT_FEE_PAYMENT,
  EDIT_CUSTOM_STUDENT_FEE_PAYMENT,
  REMINDER_CUSTOM_STUDENT_FEE_ENDPOINTS,
  STUDENT_TRANSPORT_ENDPOINT
} from "../../src/Constants/Constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";

const createStudentFee = (payload) => {
  return PostAPIRequest({ url: CREATE_STUDENTFEE_URL, data: payload });
};
const createCustomStudentFee = (payload) => {
  return PostAPIRequest({ url: CREATE_CUSTOM_STUDENT_FEE_URL, data: payload });
};

const saveStudentFees = (params) => {
  return PostAPIRequest({ url: SAVE_STUDENTFEES_URL, params})
}

const editStudentFee = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_STUDENTFEE_URL + id + EDIT_STUDENTFEE_END_POINT,
    data: payload,
  });
};

const studentFeeStructure = (studentId) => {
  return GetAPIRequest({
    url: STUDENT_FEE_STRUCTURE + studentId + STUDENT_FEE_STRUCTURE_ENDPOINT,
  });
};

const findStudentFee = (studentId) => {
  return GetAPIRequest({
    url: FIND_STUDENTFEE_URL + studentId + FIND_STUDENTFEE_END_POINT,
  });
};
const getStudentTransportDetails = (studentId) => {
  return GetAPIRequest({
    url: FIND_STUDENTFEE_URL + studentId + STUDENT_TRANSPORT_ENDPOINT,
  });
};
const createStudentFeePayment = (payload, params) => {
  return PostAPIRequest({ url: STUDENT_FEEPAYMENT, data: payload, params });
};
const createCustomStudentFeePayment = (payload) => {
  return PostAPIRequest({ url: CUSTOM_STUDENT_FEE_PAYMENT, data: payload });
};
const editCustomStudentFeePayment = (payload, transactionId) => {
  return PostAPIRequest({url: EDIT_CUSTOM_STUDENT_FEE_PAYMENT+transactionId, data: payload})
}

const getStudentBySearch = (value) => {
  return GetAPIRequest({ url: `${STUDENT_SEARCH}?searchKey=${value}` });
};

const getStudentFeeList = (classId, params) => {
  return GetAPIRequest({
    url: GET_STUDENTS_FEE_LIST.replace("{classId}", classId),
    params,
  });
};

const reminderStudentFee = (studentId, params) => {
  return PostAPIRequest({
    url: REMINDER_STUDENT_FEE + studentId + REMINDER_STUDENT_FEE_ENDPOINTS,
    params,
  });
};
const reminderCustomStudentFee = (studentId, params) => {
  return PostAPIRequest({
    url: REMINDER_STUDENT_FEE + studentId + REMINDER_CUSTOM_STUDENT_FEE_ENDPOINTS,
    params,
  });
};

const paymentLinkRequest = (studentId, params) => {
  return PostAPIRequest({
    url: PAYMENTLINK + studentId + PAYMENTLINK_ENDPOINT,
    params,
  });
};

const remindStudentTermFee = (classId, params) => {
  return GetAPIRequest({
    url: REMIND_STUDENT_FEE.replace("{classId}", classId),
    params,
  });
};
const studentFeeCollection = (params) => {
  return GetAPIRequest({
    url: GET_STUDENTS_FEE_COLLECTION,
    params,
  });
};

const encryptData = (payload) => {
  return PostAPIRequest({ url: ENCRYPT_PAYMENT_DATA, data: payload });
};

const initiatePayment = (params) => {
  return GetAPIRequest({ url: INITIATE_PAYMENT_DATA, params });
};
export const StudentFeeService = {
  createStudentFee,
  editStudentFee,
  studentFeeStructure,
  findStudentFee,
  createStudentFeePayment,
  getStudentBySearch,
  getStudentFeeList,
  reminderStudentFee,
  paymentLinkRequest,
  remindStudentTermFee,
  studentFeeCollection,
  encryptData,
  initiatePayment,
  saveStudentFees,
  createCustomStudentFee,
  createCustomStudentFeePayment,
  editCustomStudentFeePayment,
  reminderCustomStudentFee,
  getStudentTransportDetails,
};
