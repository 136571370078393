import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import './DailyUpdate.scss';
import ClassDropdown from '../Common/Class/ClassDropdown';
import SectionDropdown from '../Common/Section/SectionDropdown';
import { Button, Card, DatePicker, message } from 'antd';
import { dailyUpdateService } from '../../Services/DailyUpdateService';
import moment from 'moment';
import { YearMonthDate } from '../../Utils/DateConverter';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../Constants/Utils';

const DailyUpdate = () => {
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [points, setPoints] = useState(['→ ']); 
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isDisabled, setIsDisabled] = useState(false)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setPoints((prevPoints) => [...prevPoints, '→ ']);
        }
    };

    const handleChange = (event) => {
        const newPoints = event.target.value
            .split('\n')
            .map(line => line)
            .filter(line => line !== '');
        setPoints(newPoints);
    };

    useEffect(() => {
        if (selectedSection) {
            getTodayDailyUpdateList();
        }
    }, [selectedSection, selectedDate]);

    const getTodayDailyUpdateList = () => {

        const params = { date: selectedDate };

        dailyUpdateService.getTodayDailyUpdate(selectedSection?.classId, params)
            .then(response => {
                if (response?.data && response?.data.length > 0) {
                    const notes = response?.data[0]?.notes || [];
                    const updatedNotes = notes.map(note => `→ ${note.trim()}`);
                    setPoints(updatedNotes); 
                }else{
                    setPoints([])
                }
            })
            .catch(error => {
                console.error('Error fetching daily updates:', error);
            });
    };

    const handleSubmit = async () => {
        if (points.length === 0) {
            message.error('Please add at least one point.');
            return;
        }

        const cleanedPoints = points.map(point => point.replace(/^→\s*/, ''));

        const payload = {
            classId: selectedSection?.classId,
            notes: cleanedPoints, 
        };

        dailyUpdateService.saveDailyUpdate(payload)
            .then(response => {
                successNotification("Daily update saved successfully")
                setIsDisabled(false)
            })
            .catch(error => {
                const errorMessage = dynamicErrorMsg()
                errorNotification()
            });
    };

    const onClassChange = (value) => {
        setSelectedClass(value);
    };

    const onSectionChange = (value) => {
        setSelectedSection(value);
        setPoints(['→ ']); 
    };

    const handleDateChange = (date) => {
        const selectedDate = YearMonthDate(date)
        setSelectedDate(selectedDate)
    }

    console.log('points', selectedDate)

    const isButtonDisabled = points.length === 0 || points[0] === '→ ';

    return (
        <main>
            <div className="daily-update-container">
            <div className="daily-update-header">
                <h1>Daily Update</h1>
                <p>Fill in the daily updates for your class and section.</p>
            </div>

            <div className="dropdowns-container">
                <ClassDropdown
                    onClassChange={onClassChange}
                    selectedClass={selectedClass}
                />
                <SectionDropdown
                    classValue={selectedClass}
                    onSectionChange={onSectionChange}
                    selectedSection={selectedSection}
                />
                <DatePicker
                    value={
                        selectedDate
                          ? moment(selectedDate, "YYYY-MM-DD")
                          : null
                      }
                    onChange={(date) => handleDateChange(date)}
                />
            </div>

            <div className="updateViewContainer">
                <TextArea
                    className="daily-update-textarea"
                    rows={14}
                    style={{ fontSize: "16px", width:"49%", marginRight: "40px" }}
                    placeholder="Press Enter to add a point"
                    onKeyDown={handleKeyDown}
                    value={points?.join('\n')}
                    onChange={handleChange}
                />
                <Card className='preview-container'>
                    <h1>Dear Parents</h1>
                    <p>We are delighted to share the daily updates for Student Name at School Name for</p>
                    <p>Activities</p>
                    {points.map(point => (
                        <p key="point">{point}</p>
                    ))}
                    <p>Thank you for your continued support in making School Name a nurturing place for your child's growth and Learning</p>
                    <p>Regards,</p>
                    <p>School Name,</p>
                    <p>Address</p>
                </Card>
            </div>

            <div className="submit-container">
                <Button
                    className="blue-button"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                >
                    Submit
                </Button>
            </div>
        </div>
        </main>
    );
};

export default DailyUpdate;
