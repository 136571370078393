import { GetAPIRequest, PostAPIRequest, DeleteAPIRequest } from "./Api";
import {
  findInstituteInfo,
  STUDENT_MAIL,
  INSTITUTE_ADMIN_LOGO,
  INSTITUTE_ADMIN_LOGO_ENDPOINT,
  INSTITUTE_LOGO_UPLOAD,
  INSTITUTE_LOGO_UPLOAD_ENDPOINT,
  CHANGE_PASSWORD,
  EDIT_INSTITUTE_ADMIN_PROFILE,
  EDIT_INSTITUTE_ADMIN_PROFILE_ENDPOINT,
  SEND_OTP_PROFILE_EMAIL_VARIFICATION,
  VERIFY_OTP_PROFILE_EMAIL_OTP,
  NOTIFICATION,
  NOTIFICATION_ENDPOINT,
  CREATE_INST_SETTINGS,
  EDIT_INST_SETTINGS,
  GET_INST_SETTINGS,
  DELETE_INST_SETTINGS_TEMPLATE,
  NOTIFICATION_UPDATE_ENDPOINT,
  MARK_AS_READ,
  GET_UPDATES
} from "../Constants/Constants";

const getInstituteInfo = (params) => {
  return GetAPIRequest({
    url: findInstituteInfo,
    params,
  });
};
const getUpdates = () => {
  return GetAPIRequest({url: GET_UPDATES});
}
const getInstSettings = () => {
  return GetAPIRequest({url:GET_INST_SETTINGS });
}
const createInstSettings = (payload) => {
  return PostAPIRequest({url: CREATE_INST_SETTINGS, data: payload});
}
const editInstSettings = (payload) => {
  return PostAPIRequest({url: EDIT_INST_SETTINGS, data: payload});
}
const deleteTemplate = (params) => {
  return DeleteAPIRequest({url: DELETE_INST_SETTINGS_TEMPLATE, params})
}
const sendMail = (payload) => {
  return PostAPIRequest({ url: STUDENT_MAIL, data: payload });
};

const instituteLogo = (instId) => {
  return PostAPIRequest({
    url: INSTITUTE_ADMIN_LOGO + instId + INSTITUTE_ADMIN_LOGO_ENDPOINT,
  });
};
const instituteLogoUpload = (id, payload) => {
  return PostAPIRequest({
    url: INSTITUTE_LOGO_UPLOAD + id + INSTITUTE_LOGO_UPLOAD_ENDPOINT,
    data: payload,
  });
};
const changePassword = (payload) => {
  return PostAPIRequest({ url: CHANGE_PASSWORD, data: payload });
};
const editInstituteAdmin = (id, payload) => {
  return PostAPIRequest({
    url:
      EDIT_INSTITUTE_ADMIN_PROFILE + id + EDIT_INSTITUTE_ADMIN_PROFILE_ENDPOINT,
    data: payload,
  });
};
const sendOtpToEmail = (params) => {
  return PostAPIRequest({ url: SEND_OTP_PROFILE_EMAIL_VARIFICATION, params });
};
const verifyEmailOtp = (params) => {
  return PostAPIRequest({ url: VERIFY_OTP_PROFILE_EMAIL_OTP, params });
};

const getNotification = (userId, params) => {
  return GetAPIRequest({
    url: NOTIFICATION + userId + NOTIFICATION_ENDPOINT,
    params,
  });
};

const updateNotification = (notifId) => {
  return PostAPIRequest({
    url:NOTIFICATION + notifId + NOTIFICATION_UPDATE_ENDPOINT
  })
}

const markAllAsRead = () => {
  return PostAPIRequest({
    url:NOTIFICATION + MARK_AS_READ
  })
}

export const InstituteService = {
  getInstituteInfo,
  sendMail,
  instituteLogo,
  instituteLogoUpload,
  changePassword,
  editInstituteAdmin,
  sendOtpToEmail,
  verifyEmailOtp,
  getNotification,
  editInstSettings,
  createInstSettings,
  getInstSettings,
  deleteTemplate,
  updateNotification,
  markAllAsRead,
  getUpdates,
};
