import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/ErrorComponent/ErrorPage";
const rootElement = document.getElementById("root");

const appRoot = (
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => (window.location.href = "/")}
      >
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);

const root = createRoot(rootElement);
root.render(appRoot);
