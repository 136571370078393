import React, { useEffect, useState } from 'react'
import ClassDropdown from '../Common/Class/ClassDropdown'
import SectionDropdown from '../Common/Section/SectionDropdown'
import { StudyPlanService } from "../../Services/StudyPlanService";
import "./StudyPlan.scss";
import { Button, Modal, Space, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined, FilePdfFilled, PlusOutlined } from '@ant-design/icons';
import DailyNotesModal from './DailyNotesModal';
import FileViewMultiple from '../../Utils/FileViewMultiple';
import SubjectDropdown from './SubjectDropdown';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../Constants/Utils';
const DailyNotes = () => {
  const [selectedClass, setSelectedClass] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [dailyNotes, setDailyNotes] = useState(null);
  const [record, setRecord] = useState(null);
  const [view, setView] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [dailyNotesModal, setDailyNotesModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Notes",
      dataIndex: "dailyNotes",
      render: (dailyNotes) => dailyNotes?.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(dailyNotes)} />) : ("-"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditDailyNotes(record)} />
            <DeleteOutlined onClick={() => handleDeleteRecord(record)} />
        </Space>
      ),
    },
  ];

  const onClassChange = (value) => {
    setSelectedClass(value);
  }

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };

  const filteredNotes = () => {
    return dailyNotes?.filter(notes => notes?.subjectId === selectedSubject);
  }
  const handleEditDailyNotes = (record) => {
    setDailyNotesModal(true);
    setRecord(record);
  }

  const handleDeleteRecord= (record) => {
    Modal.confirm({
      title: "Are you sure to delete record?",
      onOk: () => {
        setLoading(true);
        StudyPlanService.deleteDailyNotes(record?.id)
          .then(response => {
            successNotification(response?.data);
            getDailyNotesData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "Daily Notes")))
          .finally(() => setLoading(false));
      }
    })
  }

  const handleFileView = (url) => {
    setFileUrl(url);
    setView(true);
  };

  useEffect(() => {
    getDailyNotesData();
  }, [selectedSection]);

  const getDailyNotesData = () => {
    if (selectedSection?.classId) {
      StudyPlanService.getDailyNotes(selectedSection?.classId)
        .then(response => {
          setDailyNotes(response?.data?.reverse());
        })
        .catch(error => { });
    }
  }
  const onSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  return (
    <>
    {loading && (
        <div className="total-page-loading">
          <Spin size="large" spinning={loading} />
        </div>
      )}
      <main className="daily-notes-wrapper">
        <div className="flex-between">
          <h1>Daily Notes</h1>
          <div className="flex-row">
            <ClassDropdown
              onClassChange={onClassChange}
              selectedClass={selectedClass}
            />
            <SectionDropdown
              classValue={selectedClass}
              onSectionChange={onSectionChange}
            />
            <div>
              <SubjectDropdown
                selectedSubject={selectedSubject}
                selectedSection={selectedSection}
                onSubjectChange={onSubjectChange}
                selectedClass={selectedClass}
                style={{ width: 200 }}
              />
            </div>
            <Button className="blue-button" onClick={() => setDailyNotesModal(true)}>
              <PlusOutlined className="assignment-icon" />
              Create Daily Notes
            </Button>
          </div>
        </div>
        <Table
          dataSource={filteredNotes()}
          columns={columns}
          pagination={{ pageSize: 20 }}
        />
        {dailyNotesModal && (
          <DailyNotesModal
            dailyNotesModal={dailyNotesModal}
            setDailyNotesModal={setDailyNotesModal}
            record={record}
            setRecord={setRecord}
            selectedSection={selectedSection}
            selectedSubject={selectedSubject}
            getDailyNotesData={getDailyNotesData}
            setLoading={setLoading}
          />
        )}
        {view && (
          <FileViewMultiple
            view={view}
            setView={setView}
            fileData={fileUrl}
            heading="Daily Notes"
          />
        )}
      </main>
    </>
  )
}

export default DailyNotes