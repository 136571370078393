import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FilePdfOutlined, LeftOutlined } from "@ant-design/icons";
import FileView from "../../Utils/FileView";
import "./Policies.scss";
import { Button } from "antd";
const ViewPolicy = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [heading, setHeading] = useState(null);

  const handleFileView = (url, record) => {
    setView(true);
    setFileData(url);
    setHeading(record.title);
  };

  const renderPdfIcon = () => {
    if (data?.docs) {
      return (
        <div className="pdf-div">
          <FilePdfOutlined
            style={{ fontSize: "100px", color: "red" }}
            onClick={() => handleFileView(data?.docs, data)}
          />
        </div>
      );
    }
    return null;
  };
  const handleBack = () => {
    navigate("/policies");
  };

  return (
    <main>
      {data && (
        <div className="main">
          <Button className="blue-button back-button" onClick={handleBack}>
            <LeftOutlined />
          </Button>
          <section className="view-policy">
            <header className="head">
              <h1># Title </h1>
              <h3>{data?.title}</h3>
            </header>
            <div className="text">
              <h1># Description </h1>
              <p
                dangerouslySetInnerHTML={{ __html: data?.description }}
                style={{ fontSize: 16 }}
              />
              {renderPdfIcon()}
            </div>
            <FileView
              view={view}
              setView={setView}
              fileData={fileData}
              heading={heading}
            />
          </section>
        </div>
      )}
    </main>
  );
};

export default ViewPolicy;
