import {
  EXPORT_STAFF_EXCELL,
  EXPORT_STUDENT_EXCELL,
  GENERATE_QUESTIONPAPER_EXCELL,
  GENERATE_REPORT_CARD,
  STUDENT_DOWNLOAD,
  SYLLABUS_EXPORT,
  SYLLABUS_UPLOAD_EXCELL,
  UPLOAD_STUDENT_EXCELL,
} from "../../src/Constants/Constants";
import { downloadFile, fileUpload } from "./Api";

const exportStudentExcelFile = () => {
  return downloadFile({ url: EXPORT_STUDENT_EXCELL });
};
const StudentFileUpload = (formdat) => {
  return fileUpload({ url: UPLOAD_STUDENT_EXCELL, data: formdat });
};
const exportStaffExcell = (params) => {
  return downloadFile({ url: EXPORT_STAFF_EXCELL + params });
};
const exportSyllabus = (params) => {
  return downloadFile({ url: SYLLABUS_EXPORT, params });
};
const UploadSyllabus = (formData) => {
  return fileUpload({ url: SYLLABUS_UPLOAD_EXCELL, data: formData });
};

const generateReportCard = (params) => {
  
  return downloadFile({ url: GENERATE_REPORT_CARD,params });
};
const downloadStudents = (params) => {
  return downloadFile({ url: STUDENT_DOWNLOAD, params });
};
export const UploadAndExportFileService = {
  exportStudentExcelFile,
  StudentFileUpload,
  exportStaffExcell,
  exportSyllabus,
  UploadSyllabus,
  generateReportCard,
  downloadStudents,
};
