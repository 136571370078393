import { useEffect, useState } from "react";
import { socialMediaService } from "../../Services/SocialMediaService";
import { Button, Form, Carousel } from "antd";
import "./SocialMediaPosts.scss";
import CreatePost from "./createSocialMediaPost";

const SocialMediaPosts = () => {
  const [getSocialMediaPostersList, setgetSocialMediaPostersList] = useState(
    []
  );
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [postsForm] = Form.useForm();

  const handleAddPost = () => {
    setSocialMediaModal(true);
  };

  const getSocialMediaPosters = () => {
    socialMediaService
      .getSocialMediaPosters()
      .then((response) => {
        setgetSocialMediaPostersList(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getSocialMediaPosters();
  }, []);

  const nextSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide + 1) % getSocialMediaPostersList.length
    );
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) =>
        (prevSlide - 1 + getSocialMediaPostersList.length) %
        getSocialMediaPostersList.length
    );
  };

  console.log("onePlus Nord", getSocialMediaPostersList);

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {getSocialMediaPostersList.map((post, index) => (
          <div key={post.id} className="main-container white-card ">
            <div>
              <h1>{post.title}</h1>
              <h2>{post.description}</h2>
              {post.postersURL.length === 1 ? (
                <img src={post.postersURL[0]} alt="Post" />
              ) : (
                <Carousel
                  effect="fade"
                  draggable={false}
                  beforeChange={(from, to) => setCurrentSlide(to)}
                  dotPosition="bottom"
                  dotStyle={{ background: "#fff", borderColor: "#1890ff" }}
                  dotActiveStyle={{ background: "#1890ff" }}
                  dots={false}
                  arrows={true}
                  key={index}
                  autoplay={true}
                  initialSlide={currentSlide}
                  // prevArrow={
                  //   <Button
                  //     onClick={prevSlide}
                  //     disabled={getSocialMediaPostersList.length <= 1}
                  //   >
                  //     {"<"}
                  //   </Button>
                  // }
                  // nextArrow={
                  //   <Button
                  //     onClick={nextSlide}
                  //     disabled={getSocialMediaPostersList.length <= 1}
                  //   >
                  //     {">"}
                  //   </Button>
                  // }
                >
                  {post.postersURL.map((image, idx) => (
                    <div key={idx}>
                      <img src={image} alt={`Image ${idx + 1}`} />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        ))}
      </div>
      {socialMediaModal && (
        <CreatePost
          socialMediaModal={socialMediaModal}
          setSocialMediaModal={setSocialMediaModal}
          getSocialMediaPosters={getSocialMediaPosters}
          postsForm={postsForm}
        />
      )}
    </div>
  );
};

export default SocialMediaPosts;
