import {
  CREATE_STAFF_URL,
  EDIT_STAFF_URL,
  EDIT_ENDPOINT,
  LIST_STAFF_URL,
  VIEW_STAFF_URL,
  VIEWSTAFF_ENDPOINT,
  STAFF_LEAVE_USAGE,
  STAFF_LEAVE_USAGE_ENDPOINT,
  GET_STAFF_SALARY_STRUCTURE,
  CREATE_STAFF_SALARY_STRUCTURE,
  FIND_STAFF_SALARY_STRUCTURE,
  FIND_STAFF_SALARY_STRUCTURE_ENDPOINT,
  VIEW_TECHING_CLASSES,
  VIEW_TECHING_CLASSES_ENDPOINT,
  GET_STAFF_DOCUMENTS,
  STAFF_URL,
  GET_MONTHLY_STAFF_ATTENDANCE,
  MONTHLY_ATTENDANCE_ENDPOINT,
  DELETE_STAFF,
  DELETE_STAFF_ENDPOINT
} from "../../src/Constants/Constants";
import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";

const createStaff = (payLoad) => {
  return PostAPIRequest({ url: CREATE_STAFF_URL, data: payLoad });
};

const editStaff = (payLoad, staffId) => {
  return PostAPIRequest({
    url: EDIT_STAFF_URL + staffId + EDIT_ENDPOINT,
    data: payLoad,
  });
};

const listStaff = (params) => {
  return GetAPIRequest({ url: LIST_STAFF_URL, params });
};

const staffUrl = (params) => {
  return GetAPIRequest({ url: STAFF_URL, params})
}

const getAllStaffList = (params) => {
  return GetAPIRequest({ url: LIST_STAFF_URL, params });
};

const viewStaff = (staffId) => {
  return GetAPIRequest({ url: VIEW_STAFF_URL + staffId + VIEWSTAFF_ENDPOINT });
};
const viewStaffTeachingClasses = (staffId) => {
  return GetAPIRequest({
    url: VIEW_TECHING_CLASSES + staffId + VIEW_TECHING_CLASSES_ENDPOINT,
  });
};

const staffLeaveUsage = (staffId) => {
  return GetAPIRequest({
    url: STAFF_LEAVE_USAGE + staffId + STAFF_LEAVE_USAGE_ENDPOINT,
  });
};

const getSalaryStructure = () => {
  return GetAPIRequest({ url: GET_STAFF_SALARY_STRUCTURE });
};
const getStaffDocuments = (staffId) => {
  return GetAPIRequest({
    url: GET_STAFF_DOCUMENTS.replace("{staffId}", staffId),
  });
};
const createSalaryStructure = (payload) => {
  return PostAPIRequest({ url: CREATE_STAFF_SALARY_STRUCTURE, data: payload });
};
const findSalaryStructure = (staffId) => {
  return GetAPIRequest({
    url:
      FIND_STAFF_SALARY_STRUCTURE +
      staffId +
      FIND_STAFF_SALARY_STRUCTURE_ENDPOINT,
  });
};

const getStaffMonthlyAttendance = (staffId, params) => {
  return GetAPIRequest({
    url: GET_MONTHLY_STAFF_ATTENDANCE + staffId + MONTHLY_ATTENDANCE_ENDPOINT, MONTHLY_ATTENDANCE_ENDPOINT,
    params
  })
}

const deleteStaff = (staffId) => {
  return DeleteAPIRequest({
    url: DELETE_STAFF + staffId + DELETE_STAFF_ENDPOINT
  })
}

export const StaffService = {
  createStaff,
  editStaff,
  listStaff,
  viewStaff,
  staffLeaveUsage,
  getSalaryStructure,
  createSalaryStructure,
  findSalaryStructure,
  getAllStaffList,
  viewStaffTeachingClasses,
  getStaffDocuments,
  staffUrl,
  getStaffMonthlyAttendance,
  deleteStaff
};
