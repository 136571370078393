import { configureStore } from '@reduxjs/toolkit';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import Class from './classSlice';
import Staff from './staffSlice';
import Subject from './subjectSlilce';
import CustomFeatures from './customFeaturesSlice';
import { studentsListApi } from '../RTKQuery/rtkQuery';
import { inventoryListApi } from '../RTKQuery/inventoryService';
import { stagedStudentsListApi } from '../RTKQuery/stagedStudentsQuery';

const reducer = combineReducers({
  Class,
  Staff,
  Subject,
  CustomFeatures,
  [studentsListApi.reducerPath]: studentsListApi.reducer, 
  [inventoryListApi.reducerPath]: inventoryListApi.reducer,
  [stagedStudentsListApi.reducerPath]: stagedStudentsListApi.reducer
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(studentsListApi.middleware)
    .concat(inventoryListApi.middleware)
    .concat(stagedStudentsListApi.middleware)
});


