import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import { salaryStructureService } from '../../../Services/SalaryStructureService';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';

const UpdateSalaryComponent = ({ editComponent, editModal, setEditModal, getComponentTableData }) => {
  // Initialize the form
  const [form] = Form.useForm();

  // Set the form field values when the component is opened for editing
  useEffect(() => {
    if (editComponent) {
      form.setFieldsValue({
        componentName: editComponent.componentName,
      });
    }
  }, [editComponent, form]);

  // Close the modal
  const handleCancel = () => {
    setEditModal(false);
  };

  // Handle the update action
  const handleUpdate = (values) => {
    const payload = {
      componentName: values.componentName,
    };

    // Update the component using the API
    if (editComponent?.id) {
      salaryStructureService.updateComponent(editComponent.id, payload)
        .then((response) => {
          // Refresh the component data after updating
          getComponentTableData();
          // Reset the form
          form.resetFields();
          // Close the modal
          setEditModal(false);
          successNotification("The salary component has been updated successfully.")
        })
        .catch((error) => {
          const errorMsg = dynamicErrorMsg(error, "Update", "Component");
          errorNotification(errorMsg);
        });
    }
  };

  return (
    <Modal
      title="Update Salary Component"
      open={editModal}
      onCancel={handleCancel}
      footer={null}
      width={400}
    >
      <Form
        form={form}
        layout="vertical"
        name="updateSalaryComponentForm"
        onFinish={handleUpdate}
      >
        <Form.Item
          label="Component Name"
          name="componentName"
          rules={[{ required: true, message: 'Please input the component name!' }]}
        >
          <Input />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel} style={{ width: '48%' }}>
            Cancel
          </Button>
          <Button className='blue-button' htmlType="submit" style={{ width: '48%' }}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateSalaryComponent;
