import {
  CREATE_SUBJECT_URL,
  EDIT_SUBJECT_URL,
  EDIT_SUBJECT_ENDPOINT,
  SUBJECT_LIST_URL,
  VIEW_SUBJECT_URL,
  VIEW_SUBJECT_ENDPOINT,
  DELETE_SUBJECT,
  DELETE_SUBJECT_ENDPOINT,
  SUBJECTS_FROM_S3,
  CLASS_SUBJECT_ASSOCIATE_LIST_ENDPOINT,
  CLASS_SUBJECT_ASSOCIATE_LIST,
  CLASS_SUBJECT_ASSOCIATE,
  DELETE_CLASS_SUBJECTMAP,
  CLASS_SUBJECT_STAFF,
  DELETE_CLASS_SUBJECTMAP_ENDPOINT,
  GETALLSUBJECTNAMES_BYCASSID,
  GETALLSUBJECTNAMES_BYCASSID_ENDPOINT,
  CLASS_IDS_WITH_SUBJECTS_URL,
} from "../../src/Constants/Constants";
import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";

const createSubject = (payLoad) => {
  return PostAPIRequest({ url: CREATE_SUBJECT_URL, data: payLoad });
};

const editSubject = (subjectId, payload) => {
  return PostAPIRequest({
    url: EDIT_SUBJECT_URL + subjectId + EDIT_SUBJECT_ENDPOINT,
    data: payload,
  });
};

const listSubjects = (params) => {
  return GetAPIRequest({ url: SUBJECT_LIST_URL, params });
};
const subjectClassIds = () => {
  return GetAPIRequest({ url: CLASS_IDS_WITH_SUBJECTS_URL });
};

const listSubjectsfroms3 = () => {
  return GetAPIRequest({ url: SUBJECTS_FROM_S3 });
};

const viewSubject = (subjectId) => {
  return GetAPIRequest({
    url: VIEW_SUBJECT_URL + subjectId + VIEW_SUBJECT_ENDPOINT,
  });
};

const deleteSubject = (subjectId) => {
  return DeleteAPIRequest({
    url: DELETE_SUBJECT + subjectId + DELETE_SUBJECT_ENDPOINT,
  });
};

const createClassSubjectAssociation = (payLoad) => {
  return PostAPIRequest({
    url: CLASS_SUBJECT_ASSOCIATE,
    data: payLoad,
  });
};

const getClassRelatedSubjectList = (classId) => {
  return GetAPIRequest({
    url:
      CLASS_SUBJECT_ASSOCIATE_LIST +
      classId +
      CLASS_SUBJECT_ASSOCIATE_LIST_ENDPOINT,
  });
};

const deleteClassSubjectAssociation = (classSubjectId) => {
  return DeleteAPIRequest({
    url:
      DELETE_CLASS_SUBJECTMAP +
      classSubjectId +
      DELETE_CLASS_SUBJECTMAP_ENDPOINT,
  });
};

const classSubjectStaffAssign = (payLoad) => {
  return PostAPIRequest({
    url: CLASS_SUBJECT_STAFF,
    data: payLoad,
  });
};
const getAllSubjectListByClassId = (classId) => {
  return GetAPIRequest({
    url:
      GETALLSUBJECTNAMES_BYCASSID +
      classId +
      GETALLSUBJECTNAMES_BYCASSID_ENDPOINT,
  });
};

export const SubjectService = {
  createSubject,
  editSubject,
  listSubjects,
  viewSubject,
  deleteSubject,
  listSubjectsfroms3,
  createClassSubjectAssociation,
  getClassRelatedSubjectList,
  deleteClassSubjectAssociation,
  classSubjectStaffAssign,
  getAllSubjectListByClassId,
  subjectClassIds,
};
