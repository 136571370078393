import { useState, useEffect } from "react";
import { Select } from "antd";
import { SubjectService } from "../../Services/SubjectService";
const { Option } = Select;

function SubjectDropdown({ onSubjectChange, selectedSubject, selectedClass, selectedSection = null }) {
  const [subjectData, setSubjectData] = useState(null);
  const [subject, setSubject] = useState(null);

  const getClassAssociatedSubjects = () => {
    SubjectService.getClassRelatedSubjectList(selectedSection?.classId)
      .then((response) => {
        let classSubjects = [];
        response?.data?.forEach(eachClassSubject => (
          eachClassSubject?.subjects?.forEach(eachSubject => {
            classSubjects.push({ subjectId: eachSubject?.subjectId, subject: eachSubject?.subjectName });
          })
        ))
        setSubjectData(classSubjects);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (selectedSection) {
      getClassAssociatedSubjects();
    }
  }, [selectedSection]);


  const handleIntialClass = () => {
    let initialSubject;
    if (subjectData?.some(eachSubject => eachSubject?.subjectId === subject)) {
      initialSubject = selectedSubject;
    }
    else {
      initialSubject = (subjectData?.length > 0 ? subjectData[0]?.subjectId : undefined);
    }
    setSubject(initialSubject);
    onSubjectChange(initialSubject);
  };

  useEffect(() => {
    handleIntialClass();
  }, [subjectData]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const uniqueSubjects = [
    ...new Map(subjectData?.map((item) => [item.subjectId, item])).values(),
  ];
  return (
    <Select
      value={subject}
      onChange={(value) => {
        setSubject(value);
        onSubjectChange(value);
      }}
      placeholder="Subject"
      style={{ width: 150 }}
    >
      {uniqueSubjects?.map((option) => (
        <Option key={option.subjectId} value={option.subjectId}>
          {option?.subject ? capitalizeFirstLetter(option.subject) : ""}
        </Option>
      ))}
    </Select>
  );
}

export default SubjectDropdown;
