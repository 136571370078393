import { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Button, Modal, Radio, Space, Table } from 'antd';
import LoanModal from './BorrowersModal';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import "../finance.scss";
import BorrowersModal from './BorrowersModal';
import { StaffService } from '../../../Services/StaffService';

const Borrowers = () => {
  const [loansData, setLoansData] = useState();
  const [borrowersData, setBorrowersData] = useState();
  const [record, setRecord] = useState(null);
  const [borrowersModal, setBorrowersModal] = useState(false);
  const [staffData, setStaffData] = useState();
  useEffect(() => {
    getBorrowersData();
    getLoansData();
    getStaffData();
  }, [])
  const getBorrowersData = () => {
    AccountsService.getBorrowers()
      .then(response => {
        setBorrowersData(response?.data);
      })
      .catch(error => { })
  }
  const getLoansData = () => {
    AccountsService.getLoans()
      .then(response => {
        setLoansData(response?.data);
      })
      .catch(error => { })
  }
  const getStaffData = () => {
    StaffService.getAllStaffList({pageSize: 1000})
      .then(response => {
        setStaffData(response?.data?.data);
      })
      .catch(error => { })
  }
  const columns = [
    {
      title: "S.No",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Loan No.",
      render: (_, record) => record?.loanResponseDTO?.loanNumber,
    },
    {
      title: "Amount",
      render: (_, record) => record?.loanResponseDTO?.loanAmount,
    },
    {
      title: "Name",
      dataIndex: "borrowerName",
      key: "borrowerName",
    },
    {
      title: "Type",
      dataIndex: "borrowerType",
      key: "borrowerType",
    },
    {
      title: "Mobile No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <Space>
          <EditOutlined
            onClick={() => {
              setRecord(record);
              setBorrowersModal(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDeleteBorrower(record)} />
        </Space>
      ),
    },
  ];
  const handleDeleteBorrower = record => {
    Modal.confirm({
      title: "Are you sure remove borrower ?",
      onOk: () => {
        AccountsService.deleteBorrowers(record?.id)
          .then(response => {
            successNotification(response?.data);
            getBorrowersData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "borrower")));
      }
    })
  }
  return (
    <main>
      <div className="flex-between">
        <h1>Borrowers</h1>
        <Button className="blue-button" onClick={() => setBorrowersModal(true)}><PlusOutlined /> Add</Button>
      </div>
      <Table
        pagination={false}
        dataSource={borrowersData}
        columns={columns}
      />
      {borrowersModal && (
        <BorrowersModal
          borrowersModal={borrowersModal}
          setBorrowersModal={setBorrowersModal}
          record={record}
          setRecord={setRecord}
          getBorrowersData={getBorrowersData}
          loansData={loansData}
          staffData={staffData}
        />
      )}
    </main>
  )
}

export default Borrowers