import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import config from "../Utils/Config";
export const inventoryListApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/instAdmin",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
        headers.set("Content-Type", "application/json");
      } else {
        window.location.href = `${config.loginUrl}`;
      }
      return headers;
    },
  }),
  tagTypes: ["Inventory"],
  endpoints: (builder) => ({
    getAllInventoryCategories: builder.query({
      query: () => ({
        url: "/api/assetManagement/getAllInventoryCategories",
        method: "GET",
      }),
      providesTags: ["Inventory"],
    }),
    // getAllInventorySubCategories: builder.query({
    //     query: () => ({
    //         url: "/api/assetManagement/getAllInventorySubCategories",
    //         method: "GET"
    //     }),
    // }),
    createCategory: builder.mutation({
      query: (payload) => ({
        url: "/api/assetManagement/createInventoryCategory",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inventory"],
    }),
    findStocks: builder.query({
      query: (stockId) => ({
        url: `/api/assetManagement/${stockId}/findItem`,
        method: "GET",
      }),
      providesTags: ["Inventory"],
    }),
    // https://api.edpedia.co/v1.0/instAdmin/api/assetManagement/stocks/1
    createSubCategory: builder.mutation({
      query: (payload) => ({
        url: "/api/assetManagement/createInventorySubCategory",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inventory"],
    }),
    updateCategory: builder.mutation({
      query: ({ categoryId, payload }) => ({
        url: `/api/assetManagement/${categoryId}/editInventoryCategory`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inventory"],
    }),
    updateSubCategory: builder.mutation({
      query: ({ payload, subCategoryId }) => ({
        url: `/api/assetManagement/${subCategoryId}/editInventorySubCategory`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Inventory"],
    }),

    getAllItems: builder.query({
      query: ({ categoryId, subCategoryId }) => ({
        url: `/api/assetManagement/getAllItems?categoryId=${categoryId}&subCategoryId=${subCategoryId}`,
        method: "GET",
      }),
      providesTags: ["Inventory"],
    }),

    getAllReturnItem: builder.query({
      query: () => ({
        url: `/api/assetManagement/get-return-items`,
        method: "GET",
      }),
      providesTags: ["Inventory"],
    }),

    createReturn: builder.mutation({
      query: (payload) => ({
        url: "/api/assetManagement/create-return-item",
        method: "POST",
        body: payload,
      }),
    }),
    editReturnItem: builder.mutation({
      query: ({ returnId, payload }) => ({
        url: `/api/assetManagement/${returnId}/edit-return-item`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetAllInventoryCategoriesQuery,
  useGetAllInventorySubCategoriesQuery,
  useCreateCategoryMutation,
  useCreateSubCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateSubCategoryMutation,
  useFindStocksQuery,
  useGetAllItemsQuery,
  useCreateReturnMutation,
  useGetAllReturnItemQuery,
  useEditReturnItemMutation,
} = inventoryListApi;
