import { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Button, Modal, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import "../finance.scss";
import LoanTermsModal from './LoanTermsModal';

const LoanTerms = ({
  termsModal,
  setTermsModal,
  loansData,
}) => {
  const [termsData, setTermsData] = useState();
  const [record, setRecord] = useState(null);
  useEffect(() => {
    getTermsData();
  }, [])
  const getTermsData = () => {
    AccountsService.getTerms()
      .then(response => {
        setTermsData(response?.data);
      })
      .catch(error => { })
  }
  const columns = [
    {
      title: "S.No",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Loan No.",
      render: (_, record) => record?.loanResponseDTO?.loanNumber,
      width: 80,
    },
    {
      title: "Loan Type",
      render: (_, record) => record?.loanResponseDTO?.loanType,
    },
    {
      title: "Status",
      render: (_, record) => record?.loanResponseDTO?.status,
    },
    {
      title: "Amount",
      render: (_, record) => record?.loanResponseDTO?.loanAmount,
    },
    {
      title: "Date",
      dataIndex: "takenDate",
      key: "takenDate",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space>
          <EditOutlined
            onClick={() => {
              setRecord(record);
              setTermsModal(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDeleteTerms(record)} />
        </Space>
      ),
    },
  ];
  const handleDeleteTerms = record => {
    Modal.confirm({
      title: "Are you sure remove loan terms ?",
      onOk: () => {
        AccountsService.deleteTerms(record?.id)
          .then(response => {
            successNotification(response?.data);
            getTermsData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "loan terms")));
      }
    })
  }
  return (
    <div>
      <Table
        pagination={false}
        dataSource={termsData}
        columns={columns}
      />
      {termsModal && (
        <LoanTermsModal
          termsModal={termsModal}
          setTermsModal={setTermsModal}
          record={record}
          setRecord={setRecord}
          loansData={loansData}
          getTermsData={getTermsData}
        />
      )}
    </div>
  )
}

export default LoanTerms