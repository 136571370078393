export const UPCOMING = "UPCOMING";
export const COMPLETED = "COMPLETED";
export const BANK_TRANSFER = "Bank Transfer";
export const CASH = "Cash";
export const YEARLY = "Yearly";
export const MONTHLY = "Monthly";
export const UNPAID = "UNPAID";
export const PARTIAL = "PARTIAL";
export const PAID = "PAID";
export const SUPPORT = "SUPPORT";
export const APPROVED = "approved"
export const ONGOING = "ONGOING";
export const STAFF = "STAFF";
export const OTHERS = "OTHERS";
export const PAY_NOW = "Pay Now";
export const PAY_LATER = "Pay Later";
export const NO = "No";
export const TERM_PAYMENT = "TERM PAYMENT";
export const TOTAL_PAYMENT = "TOTAL PAYMENT";

// Messages -------------------
export const BACK_TO_YOU_SOON_MESSAGE = "Thank you for your interest in our product! Our support team will get back to you soon.";
export const SEND_STUDENT_ABSENT_MESSAGE = "Are you sure to send absent message to this student";
export const SEND_CLASS_ABSENT_STUDENT_MESSAGE = "Are you sure to send absent messages to all the absent students";

// Form error messages
// Exam Error Messages ------------------
export const EXAM_SCHEDULE_DATE_ERROR = "Please select the exam date";
export const EXAM_SCHEDULE_TIME_ERROR = "Please select the time";
export const UPDATE_EXAM_TIME_TABLE_ERROR = `Exam has been updated`;

// Fee Management
export const FEE_STRUCTURE = "FEE STRUCTURE";
export const DISCOUNTS = "DISCOUNTS";
export const OTHER_PAYMENTS = "OTHER PAYMENTS";
export const LR_INSTALLMENTS = "OTHER INSTALLMENTS";
export const SPECIAL_FEE = "SPECIAL FEE";
export const ADVANCED = "ADVANCED";
export const STUDENT_FEE_STRUCTURE = "STUDENT FEE STRUCTURE";
export const STUDENT_LR_PAYMENTS = "STUDENT LR PAYMENTS";
export const STUDENT_DISCOUNTS = "STUDENT DISCOUNTS";// DocumentsText
export const DOB = "Date Of Birth";
export const TC = "Transfer Certificate";
export const CASTE = "Caste Certificate";
export const INCOME = "Income Certificate";
export const PARENT_AADHAR = "Parent Aadhar";
export const OTHER_DOCUMENTS = "CUSTOM";
export const DAY_SCHOLAR = "Day Scholar";
export const DAY_BOARDING = "Day Boarding";
export const HOSTELER = "Hosteler";

//Status
export const DOB_STATUS = "DOB";
export const TC_STATUS = "TC";
export const CASTE_STATUS = "CASTE";
export const INCOME_STATUS = "INCOME";
export const PARENT_AADHAR_STATUS = "PARENTS_AADHAR";
export const OTHER_DOCUMENTS_STATUS = "CUSTOM"

export const NOT_INITIATED = "NOT_INITIATED";
export const PARTIAL_UPLOADED = "PARTIAL_UPLOADED";
export const UPLOADED = "UPLOADED";

export const documentStatusObject = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Not Initiated",
        value: "NOT_INITIATED"
    },
    {
        label: "Partial Uploaded",
        value: "PARTIAL_UPLOADED"
    },
    {
        label: "Uploaded",
        value: "UPLOADED"
    },
]

// Loans Tabs
export const LOANS = "LOANS";
export const TERMS = "TERMS";
export const DOCUMENTS = "DOCUMENTS";
export const EMPLOYEE = "EMPLOYEE";
export const NON_EMPLOYEE = "NON_EMPLOYEE";

// SERVICE TYPE
export const FEE_SERVICE = "FEE SERVICE";
export const ACCOUNTS_SERVICE = "ACCOUNTS SERVICE";

//Salary Type
export const fixed = "FIXED";
export const PERCENTAGE = "PERCENTAGE";
export const variable = "VARIABLE";
export const deductions = "DEDUCTION";

// Reports
export const STUDENT_REPORT = "STUDENT REPORT";
export const FEE_REPORTS = "FEE REPORTS";