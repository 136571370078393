import { useState } from "react";
import HostelService from "../../../../Services/HostelService";
import { useEffect } from "react";
import { Button, DatePicker, Select, Space, Table, notification } from "antd";
import "./DailyConsmption.scss";
import DailyConsmptionModal from "./DailyConsmptionModal";
import moment from "moment";
import {
  CheckCircleFilled,
  DislikeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../../../../Constants/Constants";

const DailyConsmption = () => {
  const [consmptions, setConsmptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [selectedDate, setSelectedDate] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const { Option } = Select;

  const getConsumptionDetails = () => {
    const params = {
      month: selectedMonth,
      date: selectedDate,
    };
    HostelService.getDailyConsumption(params)
      .then((response) => {
        setConsmptions(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getConsumptionDetails();
  }, [selectedMonth, selectedDate]);

  const handleCreate = () => {
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditConsmption(record)} />
        </Space>
      ),
    },
  ];

  const handleEditConsmption = (record) => {
    seteditRow(record);
    setIsModalOpen(true);
  };
  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      const dateString = moment(date).format("YYYY-MM-DD");
      setSelectedDate(dateString);
    } else {
      setSelectedDate(date);
    }
  };

  const generateReport = () => {
    if (selectedDate) {
      return axios.get(
        `${API_URL}/dailyConsumption/report?month=${selectedMonth}&date=${selectedDate}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
    } else {
      return axios.get(
        `${API_URL}/api/dailyConsumption/report?month=${selectedMonth}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
    }
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  const handleReport = () => {
    generateReport()
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, `Consmption_report.pdf`);
          notification.success({
            message: "The File is downloading...",
            description: `Daily consmption report downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error.data);
        notification.error({
          message: "Error",
          description:
            "An error occurred while generating report, try again later.",
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <section>
      <div className="daily-consmption-container">
        <div className="title-container">
          <h1>Daily Consumption</h1>
          <div>
            <Select
              style={{ width: 150, margin: 5 }}
              value={months[selectedMonth - 1]}
              onChange={handleMonthChange}
              placeholder="Select Month"
              allowClear
            >
              {months?.map((month, index) => (
                <Option key={index + 1} value={index + 1}>
                  {month}
                </Option>
              ))}
            </Select>
            <DatePicker style={{ margin: 5 }} onChange={handleDateChange} />
            <Button className="blue-button" onClick={handleCreate}>
              Create Consumption
            </Button>
            <Button className="blue-button" onClick={handleReport}>
              Download Report
            </Button>
          </div>
        </div>
        <Table dataSource={consmptions} columns={columns} pagination={false} />
      </div>
      {isModalOpen && (
        <DailyConsmptionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editRow={editRow}
          seteditRow={seteditRow}
          getConsumptionDetails={getConsumptionDetails}
        />
      )}
    </section>
  );
};

export default DailyConsmption;
