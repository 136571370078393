import { GET_ALL_CONSENTS, SEND_CONSENT_FORM } from "../Constants/Constants"
import { GetAPIRequest, PostAPIRequest } from "./Api"

const getAllConsents = () => {
    return GetAPIRequest({
        url: GET_ALL_CONSENTS
    })
}

const sendConsentForm = (payload) => {
    return PostAPIRequest({
        url: SEND_CONSENT_FORM,
        data: payload
    })
}

export const consentService = {
    getAllConsents,
    sendConsentForm
}