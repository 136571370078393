import { Col, Form, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import { dynamicErrorMsg, errorNotification, lenderTypes, loanPaymentModes, loanStatusValues, loanTypes, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import FloatDatePicker from "../../Common/FloatLabel/Datepicker";
import moment from 'moment';

const RepaymentModal = ({
    repaymentModal,
    setRepaymentModal,
    record,
    setRecord,
    loansData,
    getRepaymentsData,
}) => {
    const [repaymentForm] = Form.useForm();
    const [remainingAmount, setRemainingAmount] = useState(null)

    useEffect(() => {
        if (record) {
            repaymentForm.setFieldsValue({
                loanId: record?.loanResponseDTO?.id,
                amount: record?.amount,
                mode: record?.mode,
                txnDate: record?.txnDate ? moment(record?.txnDate, 'DD-MM-YYYY') : null,
            })
        }
    }, [])
    const handleCancel = () => {
        setRepaymentModal(false);
        setRecord(null);
    }
    const onFinish = values => {
        const dateString = values?.txnDate && moment(values?.txnDate).isValid()
            ? values?.txnDate.format('YYYY-MM-DD')
            : null;
        const payload = {
            ...values,
            txnDate: dateString ? new Date(dateString) : null
        }
        if (record) {
            AccountsService.editRepayment(record?.id, payload)
                .then(response => {
                    successNotification("Payment Updated Successfully.");
                    handleCancel();
                    getRepaymentsData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Payment"));
                })
        }
        else {
            AccountsService.createRepayment(payload)
                .then(response => {
                    successNotification("Payment Created Successfully.");
                    handleCancel();
                    getRepaymentsData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Payment"));
                })
        }
    }

    const handleLoanChange = (value) => {
        const loan = loansData?.find(loan => loan?.id === value)
        setRemainingAmount(loan?.reamingAmount)
    }
    return (
        <Modal
            title={record ? "Edit Payment" : "Add Payment"}
            open={repaymentModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={repaymentForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="loanId"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                onChange={handleLoanChange}
                                options={loansData?.map(loan => ({ label: loan?.loanNumber, value: loan?.id }))}
                                label="Select Loan"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="mode"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={loanPaymentModes?.map(mode => ({ label: mode, value: mode }))}
                                label="Payment Mode"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                                // {
                                //     validator: (_, value) => {
                                //       if (value > remainingAmount) {
                                //         return Promise.reject(new Error(`Amount cannot exceed the remaining amount of ${remainingAmount}`));
                                //       }
                                //       return Promise.resolve();
                                //     },
                                //   },
                            ]}
                        >
                            <FloatInput
                                type="number"
                                label="Amount"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="txnDate"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatDatePicker
                                label="Date"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default RepaymentModal