import {
  GET_HOSTEL_COMPLAINT_LIST,
  GET_HOSTEL_ROOM_LIST,
  CREATE_HOSTEL_ROOM,
  EDIT_HOSTEL_ROOM,
  DELETE_HOSTEL_ROOM,
  GET_ALL_BEDS,
  CREATE_BED,
  UPDATE_BED,
  GET_ALL_COMPLAINTS,
  UPDATE_COMPLAINT_STATUS,
  GET_HOSTEL_EXPENSE_CATEGORY,
  CREATE_HOSTEL_EXPENSE_CATEGORY,
  EDIT_HOSTEL_EXPENSE_CATEGORY,
  GET_HOSTEL_EXPENSE_LIST,
  CREATE_HOSTEL_EXPENSE,
  EDIT_HOSTEL_EXPENSE,
  GET_ALL_HOSTEL_STUDENTS,
  CREATE_COMPLAINT,
  ASSIGN_ROOM,
  GET_ALL_KITCHEN_EXPENSES,
  GET_KITCHEN_ITEMS,
  CREATE_KITCHEN_ITEM,
  EDIT_KITCHEN_ITEM,
  CREATE_KITCHEN_EXPENSES,
  EDIT_KITCHEN_EXPENSES,
  UPDATE_DAILY_CONSUMPTION,
  CREATE_DAILY_CONSUMPTION,
  GET_REMAINING_ITEMS,
  GET_DAILY_CONSUMPTION,
  GET_DAILY_CONSUMPTION_REPORT,
  DELETE_KITCHEN_ITEM,
  HOSTEL_DASHBOARD_COUNT
} from "../Constants/Constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

// Rooms
const roomsList = () => {
  return GetAPIRequest({ url: GET_HOSTEL_ROOM_LIST });
};

const complaintsList = () => {
  return GetAPIRequest({ url: GET_HOSTEL_COMPLAINT_LIST });
};

const hostel_dashboard_count=()=>{
  return GetAPIRequest({url:HOSTEL_DASHBOARD_COUNT});
}

const createHostelRoom = (payload) => {
  return PostAPIRequest({ url: CREATE_HOSTEL_ROOM, data: payload });
};

const editHostelRoom = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_HOSTEL_ROOM.replace("{roomId}", id),
    data: payload,
  });
};

const assignRooms = (payload, id) => {
  return PostAPIRequest({
    url: ASSIGN_ROOM.replace("{studentId ", id),
    data: payload,
  });
};
const deleteHostelRoom = (id) => {
  return DeleteAPIRequest({ url: DELETE_HOSTEL_ROOM.replace("{roomId}", id) });
};

// Expense Category and expenses

const expenseCategoryList = () => {
  return GetAPIRequest({ url: GET_HOSTEL_EXPENSE_CATEGORY });
};

const createExpenseCategory = (payload) => {
  return PostAPIRequest({ url: CREATE_HOSTEL_EXPENSE_CATEGORY, data: payload });
};

const editExpenseCategory = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_HOSTEL_EXPENSE_CATEGORY.replace("{categoryId}", id),
    data: payload,
  });
};

const getHostelExpenseList = () => {
  return GetAPIRequest({ url: GET_HOSTEL_EXPENSE_LIST });
};

const createHostelExpense = (payload) => {
  return PostAPIRequest({ url: CREATE_HOSTEL_EXPENSE, data: payload });
};

const editHostelExpense = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_HOSTEL_EXPENSE.replace("{expensesId}", id),
    data: payload,
  });
};

// Beds

const getBedsList = () => {
  return GetAPIRequest({
    url: GET_ALL_BEDS,
  });
};

const createBed = (payload) => {
  return PostAPIRequest({
    url: CREATE_BED,
    data: payload,
  });
};

const updateBed = (bedId, payload) => {
  return PostAPIRequest({
    url: UPDATE_BED.replace("{bedId}", bedId),
    data: payload,
  });
};

// Complaints
const getAllComplaints = () => {
  return GetAPIRequest({
    url: GET_ALL_COMPLAINTS,
  });
};

const updateComplaintStatus = () => {
  return PostAPIRequest({
    url: UPDATE_COMPLAINT_STATUS,
  });
};

const createComplaint = (payload) => {
  return PostAPIRequest({
    url: CREATE_COMPLAINT,
    data: payload,
  });
};

// Students
const getAllHostelStudents = () => {
  return GetAPIRequest({
    url: GET_ALL_HOSTEL_STUDENTS,
  });
};

// Kitchen Managment

// Expenses
const getAllKitchenExpenses = (params) => {
  return GetAPIRequest({
    url: GET_ALL_KITCHEN_EXPENSES,params
  });
};
const createKitchenExpenses = (payload) => {
  return PostAPIRequest({ url: CREATE_KITCHEN_EXPENSES, data: payload });
};
const editKitchenExpenses = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_KITCHEN_EXPENSES.replace("{expensesId}", id),
    data: payload,
  });
};

// Items
const getKitchenItems = () => {
  return GetAPIRequest({
    url: GET_KITCHEN_ITEMS,
  });
};

const createKitchenItem = (payload) => {
  return PostAPIRequest({
    url: CREATE_KITCHEN_ITEM,
    data: payload,
  });
};

const editKitchenItem = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_KITCHEN_ITEM.replace("{id}", id),
    data: payload,
  });
};

const deleteKitchenItem = (Id) => {
  return DeleteAPIRequest({
    url:DELETE_KITCHEN_ITEM.replace("{Id}", Id)
  })
}

// Daily Consmption
export const getDailyConsumption = (params) => {
  return GetAPIRequest({
    url: GET_DAILY_CONSUMPTION,params
  });
};

export const updateDailyConsumption = (expensesId, payload) => {
  return PostAPIRequest({
    url: UPDATE_DAILY_CONSUMPTION.replace("{expensesId}", expensesId),
    data: payload,
  });
};

export const createDailyConsumption = (payload) => {
  return PostAPIRequest({
    url: CREATE_DAILY_CONSUMPTION,
    data: payload,
  });
};

export const getRemainingItems = () => {
  return GetAPIRequest({
    url: GET_REMAINING_ITEMS,
  });
};

export const getDailyConsumptionReport = (params) => {
  return GetAPIRequest({
    url: GET_DAILY_CONSUMPTION_REPORT, params,
  });
};

const HostelService = {

  // count
  hostel_dashboard_count,
  
  // Rooms
  roomsList,
  complaintsList,
  createHostelRoom,
  editHostelRoom,
  deleteHostelRoom,

  // Beds
  getBedsList,
  createBed,
  updateBed,
  assignRooms,

  // Complaints
  createComplaint,
  getAllComplaints,
  updateComplaintStatus,

  // Expense Category
  expenseCategoryList,
  createExpenseCategory,
  editExpenseCategory,
  getHostelExpenseList,
  createHostelExpense,
  editHostelExpense,

  // Students
  getAllHostelStudents,

  // Kitchen Mangement Expenses
  getAllKitchenExpenses,
  createKitchenExpenses,
  editKitchenExpenses,

  // Kitchen Items
  getKitchenItems,
  createKitchenItem,
  editKitchenItem,
  deleteKitchenItem,

  // Daily Consmption
  getDailyConsumption,
  updateDailyConsumption,
  createDailyConsumption,
  getRemainingItems,
  getDailyConsumptionReport
};

export default HostelService;
