import { Table } from 'antd'
import React, { useEffect, useState } from 'react'

import { UserManagementService } from '../../Services/UserManagementService'

const UserLogDetails = () => {
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getUserLogDetails()
    }, [])

    const getUserLogDetails = () => {
        UserManagementService.getUserLogDetails()
            .then(response => {
                setDataSource(response?.data?.reverse())
                console.log('response', response)
            }).catch(error => {
                console.log('error', error)
            })
    }

    const columns = [
        {
          title: 'User Name',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: 'Login Time',
          dataIndex: 'loginTime',
          key: 'loginTime',
        },
        {
          title: 'Role',
          dataIndex: 'userRole',
          key: 'userRole',
        },
      ];
  return (
    <main>
        <Table dataSource={dataSource} columns={columns} pagination={{pageSize: 15}} />
    </main>
  )
}

export default UserLogDetails