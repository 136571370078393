  import { GET_FINANCIAL_REPORTS, EXPORT_EXCEL, GET_FEE_INFORMATION, FINANCE_REPORTS } from "../Constants/Constants";
import { ACCOUNTS_SERVICE } from "../Constants/StringConstants";
import {GetAPIRequest } from "./Api";
  
  const getAllReports = (params) => {
    return GetAPIRequest({ url: GET_FINANCIAL_REPORTS, params });
  };

  const getFeeInfo = (id) => {
    return GetAPIRequest({url: GET_FEE_INFORMATION.replace("{classId}", id)})
  }

  const exportExcel = (params) => {
    return GetAPIRequest({ url: EXPORT_EXCEL, params})
  }

  const getFinanceReports = (params) => {
    return GetAPIRequest({
      url: FINANCE_REPORTS, service: ACCOUNTS_SERVICE, params
    })
  }
 
  
  export const instituteReportsService = {
    getAllReports,
    getFeeInfo,
    exportExcel,
    getFinanceReports
  };
  