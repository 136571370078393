import React, { useState } from "react";
import { Input } from "antd";
import "./index.css";
import { inputHtmlTypeCheck } from "../../../Constants/Utils";

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, onBlur, onPressEnter } =
    props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0) || value === 0;

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const rules = required
    ? [{ required: true, message: `${label} is required` }]
    : [];

  const handleKeyPress = (e) => {
    const isNumberOrSymbolOrSpace =
      /[0-9~`!@#$%^&*()_+\-=\[\]{}|;':"\\,<>/?\s]/.test(e.key);
    if (
      type !== inputHtmlTypeCheck.EMAIL &&
      type !== inputHtmlTypeCheck.NUMBER &&
      type !== inputHtmlTypeCheck.DECIMAL &&
      type !== inputHtmlTypeCheck.DECIMAL_HOURS &&
      type !== inputHtmlTypeCheck.FULL_DECIMAL &&
      type !== inputHtmlTypeCheck.MARKS &&
      isNumberOrSymbolOrSpace &&
      e.key !== " "
    ) {
      e.preventDefault();
    }
    if (type === inputHtmlTypeCheck.EMAIL) {
        const newValue = parseInt(e.target.value + e.key);
        if ((!/^[0-9.]$/.test(e.key)) || (newValue < 0 || newValue > 10)) {
            e.preventDefault();
        }
    }
    if (type === inputHtmlTypeCheck.DECIMAL){
      const newValue = parseInt(e.target.value + e.key);
        if ((!/^[0-9.]$/.test(e.key) || (e.target.value.includes('.') && e.key === '.')) || (newValue < 0)) {
            e.preventDefault();
        }
    }
    if (type === inputHtmlTypeCheck.FULL_DECIMAL){
      const newValue = parseInt(e.target.value + e.key);
      console.log('newValue', newValue)
        if ((!/^[0-9.]$/.test(e.key))) {
            e.preventDefault();
        }
    }
    if (type === inputHtmlTypeCheck.MARKS){
      const newValue = parseInt(e.target.value + e.key);
      console.log('newValue', newValue)
        if ((!/^[0-9]$/.test(e.key))) {
            e.preventDefault();
        }
    }
    // if (type === "decimal" && !/^[0-9.]$/.test(e.key)) {
    //   e.preventDefault();
    // }
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props}
        defaultValue={value}
        type={type}
        min={0}
        max={10}
        onKeyPress={handleKeyPress}
      />
      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

export default FloatInput;
