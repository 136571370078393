import { Button, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { COMPLETED, DOCUMENTS, LOANS, ONGOING, TERMS } from '../../../Constants/StringConstants';
import Loans from '../Loans/Loans';
import LoanTerms from '../LoanTerms/LoanTerms';
import LoanDocuments from '../LoanDocuments/LoanDocuments';
import { PlusOutlined } from '@ant-design/icons';
import { AccountsService } from '../../../Services/AccountsService';

const LoansHome = () => {
    const [activeTab, setActiveTab] = useState(LOANS);
    const [loanTab, setLoanTab] = useState(ONGOING)
    const RadioGroup = Radio.Group;
    const [loanModal, setLoanModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);
    const [loansData, setLoansData] = useState();
    useEffect(() => {
        getLoansData();
    }, [])
    const getLoansData = () => {
        AccountsService.getLoans()
          .then(response => {
            setLoansData(response?.data);
          })
          .catch(error => { })
      }
    return (
        <main className="loans-wrapper">
            <div className="flex-between">
                <RadioGroup
                    onChange={(event) => setActiveTab(event?.target?.value)}
                    value={activeTab}
                    optionType="button"
                    buttonStyle="solid"
                >
                    <Radio.Button className="RadioButtons" value={LOANS}>
                        {LOANS}
                    </Radio.Button>
                    <Radio.Button className="RadioButtons" value={TERMS}>
                        {TERMS}
                    </Radio.Button>
                    <Radio.Button className="RadioButtons" value={DOCUMENTS}>
                        {DOCUMENTS}
                    </Radio.Button>
                </RadioGroup>
                {activeTab === LOANS && (<div className="flex-between loans-wrapper">
                    <RadioGroup
                        onChange={(event) => setLoanTab(event?.target?.value)}
                        value={loanTab}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        <Radio.Button className="RadioButtons" value={ONGOING}>
                            {ONGOING}
                        </Radio.Button>
                        <Radio.Button className="RadioButtons" value={COMPLETED}>
                            {COMPLETED}
                        </Radio.Button>
                    </RadioGroup>
                    <Button className="blue-button margin-left" onClick={() => setLoanModal(true)}><PlusOutlined /> Add</Button>
                </div>)}
                {activeTab === TERMS && <Button className="blue-button" onClick={() => setTermsModal(true)}><PlusOutlined /> Add</Button>}
                {activeTab === DOCUMENTS && <Button className="blue-button" onClick={() => setDocumentsModal(true)}><PlusOutlined /> Add</Button>}
            </div>
            {activeTab === LOANS && (
                <Loans
                    loanModal={loanModal}
                    setLoanModal={setLoanModal}
                    activeTab={loanTab}
                    loansData={loansData}
                    getLoansData={getLoansData}
                />
            )}
            {activeTab === TERMS && (
                <LoanTerms
                    termsModal={termsModal}
                    setTermsModal={setTermsModal}
                    loansData={loansData}
                />
            )}
            {activeTab === DOCUMENTS && (
                <LoanDocuments
                    documentsModal={documentsModal}
                    setDocumentsModal={setDocumentsModal}
                    loansData={loansData}
                />
            )}

        </main>
    )
}

export default LoansHome