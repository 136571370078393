import { DESIGNATIONS_CREATE, DESIGNATIONS_LIST_FROM_S3, DESIGNATION_DELETE, EDIT_DESIGNATION, GET_ALL_DESIGNATIONS } from "../Constants/Constants"
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api"


const createDesignation = (payload) => {
    return PostAPIRequest({
        url:DESIGNATIONS_CREATE,
        data:payload
    })
}

const getAllDesignations = () => {
    return GetAPIRequest({
        url:GET_ALL_DESIGNATIONS
    })
}

const getAllDesignationsFromS3 = () => {
    return GetAPIRequest({
        url:DESIGNATIONS_LIST_FROM_S3
    })
}

const editDesignations = (designationId,payload) => {
    return PostAPIRequest({
        url:EDIT_DESIGNATION.replace("{designationId}", designationId),
        data:payload
    })
}

const deleteDesignation = (designationId) => {
return DeleteAPIRequest({
    url:DESIGNATION_DELETE.replace("{designationId}", designationId)
})
}

export const designationService = {
    createDesignation,
    getAllDesignations,
    getAllDesignationsFromS3,
    editDesignations,
    deleteDesignation
}