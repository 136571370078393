import {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import {Form, Row, Col, Select, Button, notification, Upload} from 'antd';
import moment from "moment";

import { InstituteService } from "../../Services/InstituteService";
import InputAlphaNumUnderscore from "../Common/FloatLabel/InputAlphaNumUnderscore";
import InputAllCharacters from "../Common/FloatLabel/InputAllCharacters";
import InputFloatDecimal from "../Common/FloatLabel/InputDecimal";
import SelectFloatLabel from "../Common/FloatLabel/Select";
import InputFloatLabel from "../Common/FloatLabel/Input";
import DatePicker from "../Common/FloatLabel/Datepicker";
import './settings.css';
import FormButton from '../Common/formButtons/FormButton';
import { dynamicErrorMsg, validateFiles, validateFilesWithExtensionsAndSize } from '../../Constants/Utils';
import { DislikeOutlined, UploadOutlined, FilePdfOutlined, CloseSquareOutlined } from '@ant-design/icons';
import FileView from '../../Utils/FileView';

function Settings({disableCancel, getSettingsData}) {
  const [instituteSettingsForm] = Form.useForm();
  const [instituteSettingsData, setInstitutesSettingsData] = useState({});
  const [hallTicketTemplate, setHallTicketTemplate] = useState(null);
  const [feeReceiptTemplate, setFeeReceiptTemplate] = useState(null);
  const [paySlipTemplate, setPaySlipTemplate] = useState(null);
  const [view, setView] = useState(false);
  const [fileViewUrl, setFileViewUrl] = useState("");
  const [fileViewTitle, setFileViewTitle] = useState("");
  const [principleSignFile, setPrincipleSignFile] = useState(null)
  const [officeSealFile, setOfficeSealFile] = useState(null)
  const { Option } = Select;
  const navigate = useNavigate();

  useEffect(() => {
    getInstituteSettings();
  }, []);
  useEffect(() => {
    setInputFields();
  }, [instituteSettingsData]);

  const setInputFields = () => {
    const updatedDate =
        instituteSettingsData?.data?.academicYearStartDate === undefined
          ? null
          : moment(instituteSettingsData?.data?.academicYearStartDate, "YYYY-MM-DD");
    instituteSettingsForm.setFieldsValue({
      noOfTerms: instituteSettingsData?.data?.noOfTerms,
      admissionNumberPattern: instituteSettingsData?.data?.admissionNumberPattern,
      rollNumberPattern: instituteSettingsData?.data?.rollNumberPattern,
      weekends: instituteSettingsData?.data?.weekends,
      fbPageLink: instituteSettingsData?.data?.fbPageLink,
      instaLink: instituteSettingsData?.data?.instaLink,
      whatsAppSenderNumber: instituteSettingsData?.data?.whatsAppSenderNumber,
      academicYearStartDate: updatedDate,
    })
  }

  const getInstituteSettings = () => {
    InstituteService.getInstSettings()
      .then(res => setInstitutesSettingsData(res))
      .catch(err => console.log('getInstituteSettings', err))
  };

  const submitInstituteSettings = (values) => {
    // const updatedDate = moment(values.academicYearStartDate).format("YYYY-MM-DD");
    const formData = new FormData();
    formData.append("academicYearStartDate", values.academicYearStartDate);
    if (values?.noOfTerms) {
      formData.append("noOfTerms", values?.noOfTerms);
    }
    formData.append("admissionNumberPattern", values?.admissionNumberPattern);
    if (values?.fbPageLink) {
      formData.append("fbPageLink", values?.fbPageLink);
    }
    if (values?.instaLink) {
      formData.append("instaLink", values?.instaLink);
    }
    formData.append("rollNumberPattern", values?.rollNumberPattern);
    formData.append("weekends", values?.weekends);
    if (values?.whatsAppSenderNumber) {
      formData.append("whatsAppSenderNumber", values?.whatsAppSenderNumber);
    }
    if (hallTicketTemplate != null) {
      formData.append("hallTicketTemplateFile", hallTicketTemplate);
    }
    if (paySlipTemplate != null) {
      formData.append("paySlipTemplateFile", paySlipTemplate);
    }
    if (feeReceiptTemplate != null) {
      formData.append("feeReceiptTemplateFile", feeReceiptTemplate);
    }
    if (principleSignFile !== null) {
      formData.append("principleSign", principleSignFile)
    }
    if (officeSealFile !== null){
      formData.append("officeSeal", officeSealFile)
    }
    // const payload = {
    //   ...values,
    //   academicYearStartDate: updatedDate,
    //   // hallTicketTemplateFile: hallTicketTemplate
    // }
    if (instituteSettingsData?.data) {
      InstituteService.editInstSettings(formData)
      .then(response => {
        getInstituteSettings();
        getSettingsData?.();
        notification.success({
            message: response?.data,
            duration: 3,
            placement: "bottomRight",
          });
    })
    .catch(error => {
      const errorMessage = dynamicErrorMsg(error, "Update", "Institute Settings");
          
      notification.error({
        message: errorMessage,
        placement: "bottomRight",
        icon: <DislikeOutlined style={{ color: "red" }} />,
      });
    })
    }
    else {
      InstituteService.createInstSettings(formData)
        .then(response => {
            getInstituteSettings();
            getSettingsData?.();
            notification.success({
                message: response?.data,
                duration: 3,
                placement: "bottomRight",
              });
        })
        .catch(error => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Institute Settings");
          
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        })
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  }

  const accept = ".png, .jpg, .jpeg, .doc, .docx, .html"
  const extensions = [".doc", ".docx", ".html", "png", "jpeg", "jpg"];
  const maxSizeMB = 10;
  const errorMessage = "File should be png/jpeg/jpg/doc/docx/html format and below 10 MB only.";
  const handleHallTicketTemplate = ({fileList}) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    setHallTicketTemplate(validFiles[0]);
  }
  const handleFeeReceiptTemplate = ({fileList}) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    setFeeReceiptTemplate(validFiles[0]);
  }
  const handlePaySlipTemplate = ({fileList}) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    setPaySlipTemplate(validFiles[0]);
  }

  const handlePrincipleSign = ({fileList}) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    setPrincipleSignFile(validFiles[0])
  }

  const handleOfficeSeal = ({fileList}) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    setOfficeSealFile(validFiles[0])
  }

  const handleFileView = (url, fileTitle) => {
    setView(true);
    setFileViewUrl(url);
    setFileViewTitle(fileTitle);
  }

  const handleDeleteFile = (shortUrl) => {
    const params = {shortUrl};
    InstituteService.deleteTemplate(params)
    .then(response => {
      getInstituteSettings();
      notification.success({
          message: response?.data,
          duration: 3,
          placement: "bottomRight",
        });
  })
    .catch(error => {
    const errorMessage = dynamicErrorMsg(error, "Delete", "Template");
        
    notification.error({
      message: errorMessage,
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  });
  }


  return (
    <main className='settings-main-container'>
        <h1>Institute Settings</h1>
        <div className='settings-container'>
            <Form
            name="instituteSettings"
            form={instituteSettingsForm}
            onFinish={submitInstituteSettings}
            autoComplete="off"
            >
            <h1>Basic Configuration</h1>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="admissionNumberPattern"
                    rules={[
                    {
                        required: true,
                        message: "",
                    },
                    ]}
                >
                    <InputAlphaNumUnderscore
                    type="text"
                    label="Admission Number Pattern"
                    name=""
                    />
                </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="rollNumberPattern"
                    rules={[
                    {
                        required: true,
                        message: "",
                    },
                    ]}
                >
                    <InputAlphaNumUnderscore
                    type="text"
                    label="Roll Number Pattern"
                    name="rollNumberPattern"
                    />
                </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="weekends"
                    rules={[
                    {
                        required: true,
                        message: "",
                    },
                    ]}
                >
                    <SelectFloatLabel mode="multiple" label="Weekends" name="weekends">
                    <Option value="FRIDAY">Friday</Option>
                    <Option value="SATURDAY">Saturday</Option>
                    <Option value="SUNDAY">Sunday</Option>
                    </SelectFloatLabel>
                </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="fbPageLink"
                    rules={[
                    {
                        required: false,
                        message: "",
                    },
                    ]}
                >
                    <InputAllCharacters
                    type="text"
                    label="FaceBook Page Link"
                    name="fbPageLink"
                    />
                </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="instaLink"
                    rules={[
                    {
                        required: false,
                        message: "",
                    },
                    ]}
                >
                    <InputAllCharacters
                    type="text"
                    label="Instagram Link"
                    name="instaLink"
                    />
                </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="whatsAppSenderNumber"
                    rules={[
                    {
                        min: 10,
                        message: "Please enter valid phone number",
                    },
                    {
                        max: 10,
                        message: "Cannot exceed more than 10",
                    },
                    ]}
                >
                    <InputFloatLabel
                    label="WhatsApp Number"
                    name="whatsAppSenderNumber"
                    type="number"
                    />
                </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="academicYearStartDate"
                    rules={[{ required: true, message: "" }]}
                    >
                    <DatePicker
                        label="Academic Year Start Date"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        allowClear={false}
                    />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                <Form.Item
                    name="noOfTerms"
                    rules={[
                    {
                        required: false,
                        message: "",
                    },
                    ]}
                >
                    <SelectFloatLabel label="No. of Terms" name="noOfTerms">
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    </SelectFloatLabel>
                </Form.Item>
                </Col>
                {/* <Col className="gutter-row" span={12}>
                <Form.Item
                    name="generalWorkingHours"
                    rules={[
                    {
                        required: true,
                        message: "",
                    },
                    ]}
                >
                    <InputFloatDecimal type="decimalHours" label="General Working Hours Per Day" name="generalWorkingHours"/>
                </Form.Item>
                </Col> */}
            </Row>
            
            <h1>Template Configuration</h1>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Upload 
                        beforeUpload={() => false}
                        accept={accept}
                        onChange={handleHallTicketTemplate}
                        maxCount={1}
                        maxFileSize={10240}
                      >
                        <Button icon={<UploadOutlined />}>Upload Hall Ticket Template</Button>
                      </Upload>
                    </Form.Item>
                    {instituteSettingsData?.data?.hallTicketTemplate !== undefined && instituteSettingsData?.data?.hallTicketTemplate !== null ?  (
                <div className="file_uploading-icon">
                      <div className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(instituteSettingsData?.data?.hallTicketTemplate, "Hall Ticket Template")}
                        />
                        <CloseSquareOutlined className="delete-file-icon" style={{color: "red"}}
                          onClick={() => handleDeleteFile(instituteSettingsData?.data?.hallTicketTemplate)}
                        />
                      </div>
                </div>
              ) : (
                ""
              )}
              </Col>
              <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Upload 
                        beforeUpload={() => false}
                        accept={accept}
                        onChange={handleFeeReceiptTemplate}
                        maxCount={1}
                        maxFileSize={10240}
                      >
                        <Button icon={<UploadOutlined />}>Upload Fee Receipt Template</Button>
                      </Upload>
                    </Form.Item>
                    {instituteSettingsData?.data?.feeReceiptTemplate !== undefined && instituteSettingsData?.data?.feeReceiptTemplate !== null ?  (
                <div className="file_uploading-icon">
                      <div className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(instituteSettingsData?.data?.feeReceiptTemplate, "Fee Receipt Template")}
                        />
                        <CloseSquareOutlined className="delete-file-icon" style={{color: "red"}}
                          onClick={() => handleDeleteFile(instituteSettingsData?.data?.feeReceiptTemplate)}
                        />
                      </div>
                </div>
              ) : (
                ""
              )}
              </Col>
              <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Upload 
                        beforeUpload={() => false}
                        accept={accept}
                        onChange={handlePrincipleSign}
                        maxCount={1}
                        maxFileSize={10240}
                      >
                        <Button icon={<UploadOutlined />}>Upload Principle Sign File</Button>
                      </Upload>
                    </Form.Item>
                    {instituteSettingsData?.data?.principleSign !== undefined && instituteSettingsData?.data?.principleSign !== null ?  (
                <div className="file_uploading-icon">
                      <div className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(instituteSettingsData?.data?.principleSign, "Fee Receipt Template")}
                        />
                        <CloseSquareOutlined className="delete-file-icon" style={{color: "red"}}
                          onClick={() => handleDeleteFile(instituteSettingsData?.data?.principleSign)}
                        />
                      </div>
                </div>
              ) : (
                ""
              )}
              </Col>
              <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Upload 
                        beforeUpload={() => false}
                        accept={accept}
                        onChange={handleOfficeSeal}
                        maxCount={1}
                        maxFileSize={10240}
                      >
                        <Button icon={<UploadOutlined />}>Upload Office Seal File</Button>
                      </Upload>
                    </Form.Item>
                    {instituteSettingsData?.data?.officeSeal !== undefined && instituteSettingsData?.data?.officeSeal !== null ?  (
                <div className="file_uploading-icon">
                      <div className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(instituteSettingsData?.data?.officeSeal, "Fee Receipt Template")}
                        />
                        <CloseSquareOutlined className="delete-file-icon" style={{color: "red"}}
                          onClick={() => handleDeleteFile(instituteSettingsData?.data?.officeSeal)}
                        />
                      </div>
                </div>
              ) : (
                ""
              )}
              </Col>
              <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Upload 
                        beforeUpload={() => false}
                        accept={accept}
                        onChange={handlePaySlipTemplate}
                        maxCount={1}
                        maxFileSize={10240}
                      >
                        <Button icon={<UploadOutlined />}>Upload Pay Slip Template</Button>
                      </Upload>
                    </Form.Item>
                    {instituteSettingsData?.data?.paySlipTemplate !== undefined && instituteSettingsData?.data?.paySlipTemplate !== null ?  (
                <div className="file_uploading-icon">
                      <div className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(instituteSettingsData?.data?.paySlipTemplate, "Pay Slip Template")}
                        />
                        <CloseSquareOutlined className="delete-file-icon" style={{color: "red"}}
                          onClick={() => handleDeleteFile(instituteSettingsData?.data?.paySlipTemplate)}
                        />
                      </div>
                </div>
              ) : (
                ""
              )}
              </Col>
            </Row>

            <FormButton
            cancelText={"Cancel"}
            saveText={"Save"}
            onCancel={handleCancel}
            disabledCancel={disableCancel}
          />
            </Form>
        </div>
        <FileView
        view={view}
        setView={setView}
        fileData={fileViewUrl}
        heading={fileViewTitle}
      />
    </main>
  )
}

export default Settings