import { createSlice } from "@reduxjs/toolkit";
import { SubjectService } from "../Services/SubjectService";

const subjectInitialState = {
  subjectList: JSON.parse(sessionStorage.getItem("subjectList")) || [],
};

export const fetchSubjectList = () => async (dispatch) => {
  try {
    const response = await SubjectService.listSubjects();
    const data = response.data.data;
    if (data.length > 0) {
      dispatch(setSubjectList(data));
    }
  } catch (error) {
    console.log("error", error);
  }
};

const Subject = createSlice({
  name: "Subject",
  initialState: subjectInitialState,
  reducers: {
    setSubjectList: (state, action) => {
      state.subjectList = action.payload;
      sessionStorage.setItem("subjectList", JSON.stringify(action.payload));
    },
    addSubject: (state, action) => {
      state.subjectList.push(action.payload);
      sessionStorage.setItem("subjectList", JSON.stringify(state.subjectList));
    },
    editSubject: (state, action) => {
      const { subjectId, data } = action.payload;
      console.log('state', state)
      const index = state?.findIndex((s) => s.subjectId === subjectId);
      if (index !== -1) {
        state.subjectList[index] = {
          ...state.subjectList[index],
          ...data,
        };
        sessionStorage.setItem(
          "subjectList",
          JSON.stringify(state.subjectList)
        );
      }
    },
    deleteSubject: (state, action) => {
      const id = action.payload;
      state.subjectList = state.subjectList.filter((c) => c.classId !== id);
      sessionStorage.setItem("subjectList", JSON.stringify(state.subjectList));
    },
  },
});

export const getSubjectList = (state) => state?.Subject?.subjectList || [];

export const { setSubjectList, addSubject, editSubject, deleteSubject } =
  Subject.actions;

export default Subject.reducer;
