import { Col, Form, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import FloatInput from '../../Common/FloatLabel/Input';
import FormButton from '../../Common/formButtons/FormButton';
import FloatSelect from "../../Common/FloatLabel/Select";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import { dynamicErrorMsg, errorNotification, lenderTypes, successNotification } from '../../../Constants/Utils';
import { AccountsService } from '../../../Services/AccountsService';
import TextArea from 'antd/lib/input/TextArea';

const LendersModal = ({
    lendersModal,
    setLendersModal,
    record,
    setRecord,
    getLendersData,
}) => {
    const [lendersForm] = Form.useForm();

    useEffect(() => {
        if (record) {
            lendersForm.setFieldsValue({
                lenderName: record?.lenderName,
                lenderType: record?.lenderType,
                contactNumber: record?.contactNumber,
                email: record?.email,
                address: record?.address
            })
        }
    }, [])
    const handleCancel = () => {
        setLendersModal(false);
        setRecord(null);
    }
    const onFinish = values => {
        if (record) {
            AccountsService.editLender(record?.id, values)
                .then(response => {
                    successNotification(response?.message);
                    handleCancel();
                    getLendersData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Lender details"));
                })
        }
        else {
            AccountsService.createLender(values)
                .then(response => {
                    successNotification(response?.message);
                    handleCancel();
                    getLendersData?.();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Lender details"));
                })
        }
    }
    return (
        <Modal
            title={record ? "Edit Lender" : "Add Lender"}
            open={lendersModal}
            width={modalMediumWidth}
            footer={null}
            onCancel={handleCancel}
            bodyStyle={modalSize}
            maskClosable={false}
        >
            <Form
                form={lendersForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="lenderName"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatInput
                                type="text"
                                label="Lender Name"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="lenderType"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <FloatSelect
                                options={lenderTypes?.map(type => ({label: type, value: type}))}
                                label="Lender Type"
                            >

                            </FloatSelect>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="contactNumber"
                            rules={[
                                {
                                  required: true,
                                  message: "Mobile number should only contain 10 digits",
                                },
                                { max: 10 },
                                { min: 10 },
                              ]}
                        >
                            <FloatInput
                                type="number"
                                label="Contact Number"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: "" }]}
                        >
                            <InputFloatLabel label="Email" type="email" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                placeholder="Address"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormButton 
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default LendersModal