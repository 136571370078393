import {SAVE_STORE_DETAILS} from "../Constants/Constants"
import { PostAPIRequest, GetAPIRequest } from "./Api";


const saveStoreDetails = (payload) => {
    return PostAPIRequest({ url: SAVE_STORE_DETAILS, data: payload });
};

export const storeDetailsApi = {
    saveStoreDetails
}