import {
  CREATE_AI_IMAGE,
  CREATE_POSTER,
  GET_POSTERS_LIST,
  POST_ON_FACEBOOK,
  POST_ON_INSTAGRAM,
  SOCIAL_MEDIA_SETTINGS,
  SEND_EMAIL,
  WHATSAPP_MESSAGE
} from "../Constants/Constants";
import { GetAPIRequest, PostAPIRequest } from "./Api";

const getSocialMediaPosts = () => {
  return GetAPIRequest({ url: SOCIAL_MEDIA_SETTINGS });
};
const getSocialMediaPosters = () => {
  return GetAPIRequest({ url: GET_POSTERS_LIST });
};
const createSocialMediaPoster = (payload) => {
  return PostAPIRequest({ url: CREATE_POSTER, data: payload });
};
const GenerateAiImage = (payload) => {
  return PostAPIRequest({ url: CREATE_AI_IMAGE, data: payload });
};
const facebookpost = (params) => {
  return PostAPIRequest({ url: POST_ON_FACEBOOK, params });
};
const InstagramPost = (params) => {
  return PostAPIRequest({ url: POST_ON_INSTAGRAM, params });
};
const SendEmail = (payload) => {
  return PostAPIRequest({url: SEND_EMAIL, data: payload})
}
const SendWhatsAppMessage = (payload) => {
  return PostAPIRequest({url: WHATSAPP_MESSAGE, data: payload})
}

export const socialMediaService = {
  getSocialMediaPosts,
  getSocialMediaPosters,
  createSocialMediaPoster,
  GenerateAiImage,
  facebookpost,
  InstagramPost,
  SendEmail,
  SendWhatsAppMessage
};
