import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  notification,
  message,
} from "antd";
import React, { useState } from "react";
import {
  CheckCircleFilled,
  DislikeOutlined,
  LikeOutlined,
  LikeTwoTone,
  SettingFilled,
} from "@ant-design/icons";
import "./AIQuestionPaper.scss";

import { useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom/dist";
import { API_URL } from "../../../Constants/Constants";
const GenerateAiQuestionPaperPdf = () => {
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [totalMarks, setTotalMarks] = useState(0);
  const [form] = Form.useForm();
  const [generatingPdfListQuestions, setGeneratingPdfListQuestions] = useState(
    location?.state.selectedQuestions
  );
  const { selectedQuestions, selectedSubject, selectedSection } =
    location.state || {};

  const navigate = useNavigate();
  const handleMarkChange = (index, value) => {
    const marks = parseFloat(value) || 0;
    const newMarksArray = [...generatingPdfListQuestions];
    newMarksArray[index].marks = marks;
    setTotalMarks(newMarksArray.reduce((sum, q) => sum + q.marks, 0));

    setIsButtonDisabled(
      newMarksArray.reduce((sum, q) => sum + q.marks, 0) > 100
    );

    if (newMarksArray.reduce((sum, q) => sum + q.marks, 0) > 100) {
      message.warning("Total marks should be less than 100!");
    }
  };

  const onFinish = async (values) => {
    const payload = {
      classId: selectedSection?.classId,
      subjectId: selectedSubject,
      time: "3 hours",
      totalMarks: totalMarks,
      questionReqList: values?.questions?.map((question, index) => ({
        question: generatingPdfListQuestions[index].question,
        marks: question.marks,
      })),
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/questionPaper/generate-question-paper`,
        payload,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );

      if (response && response.data) {
        downloadFile(response.data, "question_paper.pdf");
        notification.success({
          message: `Question Paper PDF downloaded successfully.`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 5,
          placement: "bottomRight",
        });

        navigate("/ai-question-papers");

        form.resetFields();
      } else {
        console.error("No response data available from the API.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      notification.error({
        message: "Error",
        description:
          "An error occurred while generating the PDF. Please try again later.",
        icon: <DislikeOutlined style={{ color: "red" }} />,
        duration: 3,
      });
    }
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    // Download after 1 second to ensure file has been fully downloaded
    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  return (
    <main>
      <Form onFinish={onFinish} form={form}>
        <div className="GenerateAiQuestionPaperPdf-container">
          <header className="head">
            <h1>Generate Question paper Pdf</h1>
            <Button
              className="blue-button"
              htmlType="submit"
              disabled={isButtonDisabled}
              // onclick={downloadStaffDetails}
            >
              Generate Pdf <SettingFilled />
            </Button>
          </header>
          <section className="questions-section">
            <div className="card-items">
              {generatingPdfListQuestions?.map((question, index) => (
                <Card className="question-card" key={index}>
                  <div className="left-content">
                    <span className="text-dec-1">{index + 1} . </span>{" "}
                    <span className="text-dec">{question.question}</span>
                  </div>
                  <div className="right-content">
                    <Form.Item
                      name={["questions", index, "marks"]}
                      rules={[
                        { required: true, message: "Please enter marks!" },
                        {
                          validator: (_, value) => {
                            if (value && parseFloat(value) > 100) {
                              return Promise.reject("Marks cannot exceed 100!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter marks"
                        className="inputs"
                        max={100}
                        onChange={(e) =>
                          handleMarkChange(index, e.target.value)
                        }
                      />
                    </Form.Item>
                  </div>
                </Card>
              ))}
            </div>
            <p> {totalMarks > 0 && <p>Total Marks: {totalMarks}</p>}</p>
          </section>
        </div>
      </Form>
    </main>
  );
};

export default GenerateAiQuestionPaperPdf;
