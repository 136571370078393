import { Select } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getClassList } from "../../../store/classSlice";
import { stagedService } from "../../../Services/StagedService";
const { Option } = Select;

function SectionDropdown({
  classValue,
  onSectionChange,
  selectedSection,
  fromAdmissions = false
}) {
  const [classAndSections, setClassAndSections] = useState([])

  const [Section, setSection] = useState(null);
  const ClassAndSections = useSelector(getClassList);

  useEffect(() => {
    if (fromAdmissions) {
      // Fetch data from stagedService when fromAdmissions is true
      stagedService.getStagedClasses()
        .then(response => {
          setClassAndSections(response?.data);
        })
        .catch(error => {
          console.error('Error fetching staged classes:', error);
        });
    } else {
      // Use Redux data when fromAdmissions is false
      setClassAndSections(ClassAndSections);
    }
  }, [fromAdmissions, ClassAndSections]);

  const getUniqueSections = () => {
    let sectionsSet = new Set();
    classAndSections?.forEach((item) => {
      if (item.className === classValue) {
        sectionsSet.add(item.section);
      }
    });
    return Array.from(sectionsSet).sort();
  };

  const uniqueSections = getUniqueSections().sort();

  useEffect(() => {
    let requiredSection;
    if (uniqueSections.includes(selectedSection)) {
      requiredSection = selectedSection
    }
    else {
      requiredSection = uniqueSections.length > 0 ? uniqueSections[0] : undefined
    }
    setSection(requiredSection);
    getSectionDetails(requiredSection);
  }, [classValue, classAndSections]);

  const getSectionDetails = (value) => {
    let sections = classAndSections?.filter(
      (x) => x.className === classValue && x.section === value
    );
    if (sections.length > 0) {
      onSectionChange({
        classId: sections[0].classId,
        section: sections[0].section,
      });
    }
  };

  return (
    <Select
      value={Section}
      onChange={(value) => {
        setSection(value);
        getSectionDetails(value);
      }}
      placeholder="Section"
      // allowClear
    >
      {classValue !== null &&
        uniqueSections.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
    </Select>
  );
}

export default SectionDropdown;
