import { Col, Form, Modal, Row, Select } from 'antd';
import React, { useEffect } from 'react'
import FormButton from '../../Common/formButtons/FormButton';
import SelectFloatLabel from '../../Common/FloatLabel/Select';
import InputFloatLabel from '../../Common/FloatLabel/Input';
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, feeDiscoutTypes, successNotification } from '../../../Constants/Utils';
import { DAY_BOARDING, fixed, HOSTELER, PERCENTAGE } from '../../../Constants/StringConstants';

const AdvancedFeeConfigurationModal = ({
    advancedModal,
    setAdvancedModal,
    record,
    setRecord,
    classId,
    getAdvanceFeeConfiguration,
    activeYear,
    getInstallmentPlans,
    getInstallmentsMasterData,
    setLoading,
}) => {
    const [advancedConfigurationForm] = Form.useForm();

    useEffect(() => {
        if (record !== null) {
            advancedConfigurationForm?.setFieldsValue({
                amount: record?.amount,
                accommodationType: record?.accommodationType,
            })
        }
    }, [])

    const handleCancel = () => {
        setAdvancedModal(false);
        setRecord(null);
        advancedConfigurationForm.resetFields();
    }
    const onFinish = values => {
        const payload = {
            ...values,
            classId,
        }
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        setLoading(true);
        if (record) {
            FeeManagementService.editAdvancedFeeConfiguration(record?.id, payload, params)
                .then(response => {
                    handleCancel();
                    getAdvanceFeeConfiguration();
                    getInstallmentPlans();
                    getInstallmentsMasterData();
                    successNotification("Configuration updated successfully.");
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Configuration"))
                })
                .finally(() => setLoading(false));
        }
        else {
            FeeManagementService.createAdvancedFeeConfiguration(payload, params)
                .then(response => {
                    handleCancel();
                    getInstallmentPlans();
                    getAdvanceFeeConfiguration();
                    getInstallmentsMasterData();
                    successNotification("Configuration created successfully.");
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Configuration"))
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <Modal
            title={record ? "Edit Configuration" : "Create Configuration"}
            open={advancedModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
        >
            <Form
                form={advancedConfigurationForm}
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                        <Form.Item
                            name="accommodationType"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                disabled={!!record}
                                label="Select Type"
                            >
                                <Select.Option value={DAY_BOARDING}>{DAY_BOARDING}</Select.Option>
                                <Select.Option value={HOSTELER}>{HOSTELER}</Select.Option>
                            </SelectFloatLabel>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Amount"
                                type="number"
                            />
                        </Form.Item>
                    </Col>


                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>

        </Modal>
    )
}

export default AdvancedFeeConfigurationModal