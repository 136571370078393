import React from "react";
import "./ErrorPage.scss";

const ServerDownPage = ({ resetErrorBoundary }) => {
  return (
    <div className="error-page">
      <div className="error-content error-page-width">
        <p className="try-again">
        Our website is undergoing scheduled maintenance. During this period, you may experience temporary disruptions. We apologize for any inconvenience and appreciate your understanding.
        </p>
        <p>Please visit again in 30 minutes.</p>
        <p className="error-code">
          If Problem Persist Please Contact Administrator.
        </p>
        <button className="error-button" onClick={resetErrorBoundary}>
          Refresh Page
        </button>
      </div>
    </div>
  );
};

export default ServerDownPage;
