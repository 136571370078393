import React from "react";
import { Empty } from "antd";
const StudentPromotion = () => {
  return (
    <main
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <h1 style={{ fontSize: 25 }}>
          <Empty />
        </h1>
      </div>
    </main>
  );
};

export default StudentPromotion;
