import { Button, Col, Form, Modal, Row, Upload } from "antd"
import { CloseSquareOutlined, FilePdfOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { StudyPlanService } from "../../Services/StudyPlanService";
import { dynamicErrorMsg, errorNotification, homeworkTabs, successNotification } from "../../Constants/Utils";
import FormButton from "../Common/formButtons/FormButton";
import TextArea from "antd/lib/input/TextArea";
import "./StudyPlan.scss";
import FileView from "../../Utils/FileViewMultiple";

const DailyNotesModal = ({
    dailyNotesModal,
    setDailyNotesModal,
    record,
    setRecord,
    selectedSection,
    selectedSubject,
    getDailyNotesData,
    setLoading,
}) => {
    const [dailyNotesForm] = Form.useForm();
    const [file, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState();
    const [view, setView] = useState(false);
    const [removedFiles, setRemovedFiles] = useState([]);

    useEffect(() => {
        if (record) {
            dailyNotesForm.setFieldValue("description", record?.description)
        }
    }, [])
    const handleCancel = () => {
        setDailyNotesModal(false);
        dailyNotesForm.resetFields();
        setRecord(null);
        setFileUrl(null);
        setRemovedFiles([]);
    }
    const onFinish = (values) => {
        const formData = new FormData();
        formData.append("classId", selectedSection?.classId);
        formData.append("description", values?.description);
        file.map((file, i) => {
            formData.append(`notesFile`, file);
        });
        formData.append("subjectId", selectedSubject);
        if (record) {
            formData.append("deletedUrls", removedFiles);
        }
        setLoading(true);

        if (record?.id) {
            StudyPlanService.updateDailyNotes(record?.id, formData)
                .then(response => {
                    successNotification(response?.data);
                    getDailyNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Update", "Daily Notes")))
                .finally(() => setLoading(false));
        }
        else {
            StudyPlanService.createDailyNotes(formData)
                .then(response => {
                    successNotification(response?.data);
                    getDailyNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Create", "Daily Notes")))
                .finally(() => setLoading(false));
        }
    }

    const handleUpload = ({ fileList }) => {
        const modifiedFiles = [];
        fileList?.forEach(file => modifiedFiles.push(file.originFileObj));
        setFile(modifiedFiles);
    };

    return (
        <Modal
            title={`${record ? "Edit" : "Create"} ${homeworkTabs[1]}`}
            open={dailyNotesModal}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={dailyNotesForm} onFinish={onFinish} onFinishFailed={() => errorNotification("Please, Upload Notes")}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                        <Form.Item name="dailyNoteFile"
                            rules={[
                                {
                                    required: record ? false : true,
                                    message: "",
                                },
                            ]}>
                            <Upload
                                onChange={handleUpload}
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png"
                                // maxCount={1}
                                showUploadList={true}
                                beforeUpload={() => false}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    className="blue-button"
                                >
                                    Upload Daily Notes (pdf/image format)
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="flex-row">
                    {record?.dailyNotes?.map(url => (
                        !removedFiles?.includes(url) && <div key={url} className="file-icon-container">
                            <div className="file-icon">
                                <FilePdfOutlined
                                    style={{ fontSize: "30px", color: "black" }}
                                    onClick={() => {
                                        setFileUrl([url]);
                                        setView(true);
                                    }}
                                />
                                <CloseSquareOutlined className="delete-file-icon" style={{ color: "red" }}
                                    onClick={() => setRemovedFiles([...removedFiles, url])}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <Row>
                    <Col span={20}>
                        <Form.Item name="description" >
                            <TextArea placeholder="Description" rows={5} cols={40} />
                        </Form.Item>
                    </Col>
                </Row>

                <FormButton
                    saveText={"Save"}
                    cancelText={"Cancel"}
                    onCancel={handleCancel}
                />
            </Form>
            {view && (
                <FileView
                    view={view}
                    setView={setView}
                    fileData={fileUrl}
                    heading="Daily Notes"
                />
            )}
        </Modal>
    )
}

export default DailyNotesModal