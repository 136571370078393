export const BASE_URL_TRANSPORT = `https://api.edpedia.co/v1.0/transport`;
// export const BASE_URL_TRANSPORT = `http://localhost:8088/v1.0/transport`;
export const INSTITUTE_BASE_URL = `https://api.edpedia.co/v1.0/superAdmin`;
// export const INSTITUTE_BASE_URL = `http://localhost:8082/v1.0/superAdmin`;
export const AI_SERVICE_BASE_URL = `https://api.edpedia.co/v1.0/aiService`;
export const BASE_LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL;
// export const STFF_BASE_URL = `http://localhost:8084/v1.0/staff`;
export const STFF_BASE_URL = `https://api.edpedia.co/v1.0/staff`;
export const STUDENT_BASE_URL = `https://api.edpedia.co/v1.0/student`;
export const ADMISSION_BASE_URL = `https://api.edpedia.co/v1.0/admissions`;
// export const ADMISSION_BASE_URL = `http://localhost:8091/v1.0/admissions`;
// export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL = "https://api.edpedia.co/v1.0/instAdmin";
// export const BASE_URL = "http://localhost:8081/v1.0/instAdmin";
export const FEE_MANAGEMENT_BASE_URL = "https://api.edpedia.co/v1.0/fee_service";
// export const FEE_MANAGEMENT_BASE_URL = "http://localhost:8096/v1.0/fee_service";
export const ACCOUNTS_BASE_URL = "https://api.edpedia.co/v1.0/accounts";
// export const ACCOUNTS_BASE_URL = "http://localhost:8097/v1.0/accounts";
export const STAFF_BASE_URL = "https://api.edpedia.co/v1.0/staff";
// export const STAFF_BASE_URL = "http://localhost:8084/v1.0/staff";
export const SPORTS_BASE_URL = "https://api.edpedia.co/v1.0/sports";
export const ATTENDANCE_BASE_URL = "https://api.edpedia.co/v1.0/attendance";
// export const ATTENDANCE_BASE_URL = "http://localhost:8090/v1.0/attendance";
export const AI_BASE_URL = "https://api.edpedia.co/v1.0/aiService";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_URL = API_BASE_URL;
export const EDPEDIA_STORE_BASE_URL = `https://api.edpedia.co/v1.0/store`;
// export const EDPEDIA_STORE_BASE_URL = `http://localhost:8087/v1.0/store`;
//login url
export const LOGIN_URL = BASE_LOGIN_URL + "/ulp/api/auth/login";
// Server Health
export const GET_SERVER_HEALTH_STATUS = BASE_URL + "/api/server-health/check";

// Academic Year Related API
export const GET_ACADEMIC_YEAR_DETAILS = BASE_URL + "/api/academicYear";
// Branch list

export const BRANCH_LIST = BASE_URL + `/api/institute-branches`;
export const SAVE_BRANCH = BASE_URL + `/api/institute-branches/createBranch`;
export const EDIT_BRANCH = BASE_URL + `/api/institute-branches`;
export const CREATE_BRANCHADMIN = BASE_URL + `/api/branch-admin/save`;
export const EDIT_BRANCHADMIN = BASE_URL + `/api/branch-admin`;

// Custom Branch Features
export const GET_CUSTOM_BRANCH_FEATURES = BASE_URL + "/api/features/get-custom"

// student api urls
export const FIND_STUDENT_DOCUMENTS = `${BASE_URL}/api/student/{studentId}/findDocuments
`;
export const FIND_STUDENT_ATTENDANCE = `${ATTENDANCE_BASE_URL}/api/{studentId}/getAttendanceByMonthAndYear`;
export const STUDENT_LIST_URL = BASE_URL + `/api/student/getAllStudents`;
export const DELETED_STUDENT_LIST = BASE_URL + `/api/student/`;
export const DELETE_STUDENT_LIST_ENDPOINT = `/get_deleted_students_list`;
export const RESTORE_STUDENT = BASE_URL + `/api/student/`;
export const RESTORE_STUDENT_ENDPOINT = `/restore_student`;
export const CREATE_STUDENT_URL = BASE_URL + `/api/student/create`;
export const EDIT_STUDENT_URL = BASE_URL + `/api/student/`;
export const EDIT_END_POINT = `/edit`;
export const UPDATE_PROFILE_PIC = BASE_URL + `/api/student/upload-profile/`
export const VIEW_STUDENT_URL = BASE_URL + `/api/student/`;
export const VIEW_END_POINT = `/find`;
export const SEARCH_STUDENT_URL =
  BASE_URL + `/api/student/search?searchKeyword=`;
export const GET_STUDENTS_FEE_LIST = `${BASE_URL}/api/studentFee/{classId}/student-fee-list`;
export const GET_STUDENTS_FEE_COLLECTION = `${BASE_URL}/api/payments/fees/feeCollectionMonthly`;
export const REMIND_STUDENT_FEE = `${BASE_URL}/api/studentFee/{classId}/remainderForAll`;
export const DOWNLOAD_STUDENT_DETAILS = BASE_URL + `/api/student/export-excel`;
export const GET_STUDENT_ATTENDANCE = ATTENDANCE_BASE_URL + `/api/`;
export const GET_STDENT_ATTENDANCE_ENDPOINT = `/getAttendanceBetweenDates`;

export const STUDENT_MONTHWISE_ATTENANCE =
  ATTENDANCE_BASE_URL + `/api/MonthWisePercentage`;
export const STUDENT_SUBJECTWISE_ATTENDANCE =
  ATTENDANCE_BASE_URL + `/api/SubjectWiseMonthAttendance`;
export const GET_STUDENT_INFO = BASE_URL + `/api/student/`;
export const GET_STUDENT_INFO_ENDPOINT = `/getStudentInfo`;
export const DELETE_STUDENT = BASE_URL + `/api/student/`;
export const DELETE_STUDENT_END_POINT = `/deleteStudent`;
export const STUDENT_FEE_VIEW = BASE_URL + `/api/studentFee/`;
export const STUDENT_FEE_VIEW_ENDPOINT = `/find`;
export const CUSTOM_STUDENT_FEE_VIEW_ENDPOINT = "/find-custom"
export const SAVE_STUDENT_SUBJECT_WISE_ATTENDACE =
  STFF_BASE_URL + `/api/result/save`;
export const STUDENT_ATTENDANCE_PUT_REQUEST =
  ATTENDANCE_BASE_URL + `/api/class/`;
export const STUDENT_ATTENDANCE_PUT_REQUEST_MIDDLE_CONTENT = `/student/`;
export const STUDENT_ATTENDANCE_PUT_REQUEST_END_POINT = `/present/`;
export const YEARWISE_FEE_COMPONENT =
  BASE_URL + `/api/tuitionFee/getAllClassWiseTuitionFee`;
export const FIND_ACADEMIC_YEAR_CLASS_FEE = `${BASE_URL}/api/tuitionFee/{classId}/find`;

export const TRANSFER_STUDENT = BASE_URL + "/api/student/transfer-student"

export const UPLOAD_STUDENT_DOCUMENTS = BASE_URL + "/api/student/";
export const UPLOAD_STUDENT_DOCUMNETS_ENDPOINT = "/upload-student-document";

export const MARK_ALL_DOCUMNETS_UPLOADED_END_POINT = "/mark-all-documents-uploaded"

// preferences
export const PREFERENCE_YEAR = BASE_URL + `/api/fee_structure_category/list`;
export const CREATE_PREFERENCE = BASE_URL + `/api/fee_structure_category/save`;
export const DELETE_PREFERENCE = BASE_URL + `/api/fee_structure_category/`;
export const DELETE_END_POINT = "/delete";

export const PREFERENCE_EDIT =
  BASE_URL + `/api/fee_structure_category/{feeStructureCategoryId}/edit`;

//UserLogDetails
export const USER_LOG_DETAILS = BASE_URL + `/api/userLogDetails/list`

// class details api urls
export const GET_ALL_CLASSES_URL = BASE_URL + `/api/class/getAll`;
export const GET_CLASS_SUBJECTS = BASE_URL + `/api/class/`;
export const GET_CLASS_SUBJECTS_ENDPOINT = `/getSubjects`;
export const CREATE_CLASS_URL = BASE_URL + `/api/class/create`;
export const EDIT_CLASS_URL = BASE_URL + `/api/class/`;
export const EDIT_CLASS_END_POINT = `/edit`;
export const VIEW_CLASS_URL = BASE_URL + `/api/class/`;
export const VIEW_CLASS_END_POINT = `/find`;
export const GET_CLASSES_AND_SECTIONS_FROM_S3_URL =
  BASE_URL + `/api/class/getClassesFromS3`;
export const TOP_STUDENTS_IN_CLASS =
  BASE_URL + `/api/result/topStudentsInClass`;
export const DELETE_CLASS = BASE_URL + `/api/class/`;
export const DELETE_CLASS_ENDPOINT = `/deleteClass`;

//daily update api urls
export const TODAY_DAILY_UPDATE = BASE_URL + `/api/dailyUpdates/`;
export const GET_DAILY_UPDATE = "/get";
export const SAVE_DAILY_UPDATE = BASE_URL + `/api/dailyUpdates/save`;

// time table api urls
export const TIME_TABLE_LIST_URL = BASE_URL + `/api/timeTable/list?class=`;
export const TIME_TABLE_LIST_URL_ENDPOINT = `&offSet=0&pageSize=10`;
export const CREATE_FIXED_TIME_TABLE =
  BASE_URL + `/api/timeTable/createFixedTT`;
export const CREATE_FIXED_TIME_TABLE_CONFIG =
  BASE_URL + `/api/timeTable/saveConfig`;
export const CREATE_VARYING_TIME_TABLE =
  BASE_URL + `/api/timeTable/createVaryingTT`;
export const EDIT_FIXED_TIME_TABLE = BASE_URL + `/api/timeTable/updateFixedTT`;
export const EDIT_VARYING_TIME_TABLE =
  BASE_URL + `/api/timeTable/updateVaryingTT`;
export const FIND_TIME_TABLE = BASE_URL + `/api/timeTable/`;
export const FIND_TIME_TABLE_ENDD_POINT = `/find`;
export const TIME_TABLE_CONFIG = BASE_URL + `/api/timeTable/`;
export const SAVE_FIXED_TIME_TABLE = BASE_URL + `/api/timeTable/createFixedTT`;
export const UPDATE_FIXED_TIME_TABLE =
  BASE_URL + `/api/timeTable/updateFixedTT`;
export const SAVE_VARYING_TIME_TABLE =
  BASE_URL + `/api/timeTable/createVaryingTT`;
export const GET_TIME_TABLE = BASE_URL + `/api/timeTable/list`;
export const UPDATE_VARYING_TIME_TABLE =
  BASE_URL + `/api/timeTable/updateVaryingTT`;
//teacher and staff urls
export const VIEW_STAFF_URL = BASE_URL + `/api/staff/`;
export const VIEWSTAFF_ENDPOINT = `/find`;
export const VIEW_TECHING_CLASSES = BASE_URL + `/api/staff/`;
export const VIEW_TECHING_CLASSES_ENDPOINT = `/getTeachingSubjects`;
export const EDIT_STAFF_URL = BASE_URL + `/api/staff/`;
export const EDIT_ENDPOINT = `/edit`;
export const CREATE_STAFF_URL = BASE_URL + `/api/staff/create`;
export const LIST_STAFF_URL = BASE_URL + `/api/staff/get-all`;
export const STAFF_URL = BASE_URL + `/api/staff/get`;
export const staffListEndpoint = `/api/staff/getAllStaff`;

export const STAFF_LEAVE_USAGE = BASE_URL + `/api/staff/`;
export const STAFF_LEAVE_USAGE_ENDPOINT = `/getLeaveUsage`;

export const STAFF_LEAVE_USAGE_NAMES_LIST = BASE_URL + `/api/staff/`;
export const STAFF_LEAVE_USAGE_NAMES_LIST_ENDPOINT = `/`;

//staff documents
export const GET_STAFF_DOCUMENTS = `${STAFF_BASE_URL}/api/staffIdentity/{staffId}/findStaffDocs`;

//staff payment structure

export const GET_STAFF_SALARY_STRUCTURE =
  BASE_URL + `/api/salaryStructure/getSalaryStructure`;

// class and subject mapping urls
export const CLASS_AND_SUBJECTS_LIST_URL =
  BASE_URL + `/api/class-subject-mapping/getAllClassSubjects`;
export const CLASS_AND_SUBJECT_CREATE_URL =
  BASE_URL + `/api/class-subject-mapping/create`;
export const EDIT_CLASS_AND_SUBJECT = BASE_URL + `/api/class-subject-mapping/`;
export const EDIT_END_POINT_CLASS_AND_SUBJECT = `/edit`;

//Admissions url
export const CREATE_ADMISSION_URL = ADMISSION_BASE_URL + `/api/create`;
export const ADMISSION_LIST_URL = ADMISSION_BASE_URL + `/api/list`;
export const VIEW_ADMISSION_URL = ADMISSION_BASE_URL + `/api/`;
export const VIEW_ADMISSION_ENDPOINT = `/find`;
export const EDIT_ADMISSION_URL = ADMISSION_BASE_URL + `/api/`;
export const EDIT_ADMISSION_ENDPOINT = `/edit`;
export const ADMISSION_INFO_URL = ADMISSION_BASE_URL + `/api/info`;
export const ADMISSION_CREATE =
  ADMISSION_BASE_URL + `/api/admissionData/createAdmissionData`;
export const GET_ADMISSION_NUMBER = BASE_URL + `/api/student/admission-number`



//WhatsApp Urls
export const CREATE_SEND_TEMPLATE = BASE_URL + `/api/whatsapp/send-custom-whatsapp-message`;
export const UPDATE_SEND_TEMPLATE = BASE_URL + `/api/whatsapp/update-template/`;
export const GET_TEMPLATES = BASE_URL + `/api/whatsapp/get-templates`;
export const FIND_TEMPLATE = BASE_URL + `/api/whatsapp/find-template/`;
export const SEND_MESSAGE = BASE_URL + `/api/whatsapp/send`;
export const GET_MESSAGES = BASE_URL + `/api/whatsapp/get-messages`

//subject
export const CREATE_SUBJECT_URL = BASE_URL + `/api/subject/create`;
export const SUBJECT_LIST_URL = BASE_URL + `/api/subject/getAllSubjects`;
export const VIEW_SUBJECT_URL = BASE_URL + `/api/subject/`;
export const EDIT_SUBJECT_URL = BASE_URL + `/api/subject/`;
export const CLASS_IDS_WITH_SUBJECTS_URL = BASE_URL + "/api/subject/get-classes-with-subjects"
export const EDIT_SUBJECT_ENDPOINT = `/edit`;
export const VIEW_SUBJECT_ENDPOINT = `/find`;
export const DELETE_SUBJECT = BASE_URL + `/api/subject/`;
export const DELETE_SUBJECT_ENDPOINT = `/deleteSubject`;
export const SUBJECTS_FROM_S3 = BASE_URL + `/api/subject/createSubjectsFromS3`;
export const CLASS_SUBJECT_ASSOCIATE = BASE_URL + `/api/class/associateSubject`;
export const CLASS_SUBJECT_ASSOCIATE_LIST = BASE_URL + `/api/class/`;
export const CLASS_SUBJECT_ASSOCIATE_LIST_ENDPOINT = `/getClassSubjects`;
export const DELETE_CLASS_SUBJECTMAP = BASE_URL + `/api/class-subject-mapping/`;
export const DELETE_CLASS_SUBJECTMAP_ENDPOINT = `/deleteClassSubject`;
export const CLASS_SUBJECT_STAFF = BASE_URL + `/api/class/associateStaff`;

/*ANNOUNCEMENT API*/
export const CREATE_ANNOUNCEMENT_URL = BASE_URL + `/api/announcement/create`;
export const EDIT_ANNONCE_URL = BASE_URL + `/api/announcement/`;
export const EDIT_ANNOUNCE_ENDPOINT = `/editAnnouncement`;
export const ANNOUNCE_LIST =
  BASE_URL + `/api/announcement/list?offSet=0&pageSize=30`;
export const VIEW_ANNOUNCE_URL = BASE_URL + `/api/announcement/`;
export const VIEW_ANNOUNCE_URL_ENDPOINT = `/find`;
export const DELETE_ANNOUNCE = BASE_URL + `/api/announcement/`;
export const DELETE_ANNOUNCE_ENDPOINT = `/deleteAnnouncement`;
export const CREATE_ANNOUNCEMENT =
  BASE_URL + `/api/announcement/createAnnouncementType`;
export const ANNOUNCEMENT_LIST =
  BASE_URL + `/api/announcement/announcementTypeList`;

//email
export const SEND_EMAIL = BASE_URL + "/api/email/send";

//whatsAppMessage
export const WHATSAPP_MESSAGE = BASE_URL + "/api/whatsapp/send";

//exam apis
export const CREATE_INITIAL_EXAM_GRADES = BASE_URL + "/api/examGrades/createExamGradeFromS3";
export const CREATE_EXAM_URL = BASE_URL + `/api/exam/create`;
export const EXAM_LIST_URL = BASE_URL + `/api/exam/getAll`;
export const FIND_EXAM_LIST_URL = BASE_URL + `/api/exam/`;
export const FIND_EXAM_ENDPOINT = `/getExamTableWithClass`;
export const EDIT_EXAM = BASE_URL + `/api/exam/`;
export const EDIT_EXAM_ENDPOINY = `/edit`;
export const DELETE_EXAM = BASE_URL + `/api/exam/`;
export const DELETE_EXAM_ENDPOINT = `/deleteExam`;
export const GETALL_EXAM_TIMETABLE = BASE_URL + `/api/exam-timetable/list`;
export const TO_SHOW_UN_SCHEDULE_EXAMS = BASE_URL + `/api/exam/show_un_schedule_exam_modal`;
export const UPDATE_TO_SHOW_UN_SCHEDULE = BASE_URL + `/api/exam/update_show_un_schedule_exam_modal`;
export const GET_EXAM_DETAILS = BASE_URL + `/api/exam/get-exam-details`;
export const MULTIPLE_CLASS_EXAM_SCHEDULE = BASE_URL + `/api/exam/update-multiple-schedule`;


// Halltickets
export const GET_CLASS_HALLTICKETS = `${BASE_URL}/api/hall-ticket/exam-hall-ticket`;
export const GENERATE_HALLTICKETS = `${BASE_URL}/api/hall-ticket/generate`;

//exam Grade api
export const CREATE_EXAMGRADES = BASE_URL + `/api/examGrades/create`;
export const LIST_EXAMGRADES = BASE_URL + `/api/examGrades/getAll`;
export const EDIT_EXAMGRADES = BASE_URL + `/api/examGrades/`;
export const EDIT_EXAMGRADES_ENDPOINT = `/edit`;
export const FIND_EXAMGRADES = BASE_URL + `/api/examGrades/`;
export const FIND_EXAMGRADES_ENDPOINT = `/find`;
export const DELETE_EXAMGRADES = BASE_URL + `/api/examGrades/`;
export const DELETE_EXAMGRADES_ENDPOINT = `/delete`;
//exam timetable controller
export const EXAM_TIMETABLE_URL =
  BASE_URL + `/api/exam-timetable/list?offSet=0&pageSize=10`;
export const UPDATE_EXAM_TIMETABLE = BASE_URL + `/api/exam-timetable/update`;

/* payment apis */
export const FEE_COMPONENT_LIST_URL =
  BASE_URL + `/api/tuitionFee/getAllComponents`;
export const CREATE_FEE_COMPONENT =
  BASE_URL + `/api/tuitionFee/createComponent`;
export const CEATE_DEFAULT_FEE_COMPONENTS = BASE_URL + "/api/tuitionFee/createFeeComponentsFromS3";
export const EDIT_FEE_COMPONENT = BASE_URL + `/api/tuitionFee/`;
export const EDIT_FEE_COMPONENT_ENDPOINT = `/editComponent`;
export const FIND_FEE_COMPONENT = BASE_URL + `/api/tuitionFee/`;
export const FIND_FEE_COMPONENT_ENDPOINT = `/findComponent`;
export const DELETE_FEE_COMPONENT = BASE_URL + `/api/tuitionFee/`;
export const DELETE_FEE_COMPONENT_ENDPOINT = `/deleteFeeComponent`;
export const GETALL_TUITION_FEE_LIST =
  BASE_URL + `/api/tuitionFee/getAllClassWiseTuitionFee`;
export const CREATE_TUITION_FEE = BASE_URL + `/api/tuitionFee/tuitionFeeCreate`;
export const EDIT_TUITION_FEE = BASE_URL + `/api/tuitionFee/`;
export const EDIT_TUITION_FEE_ENDPOINT = `/tuitionEdit`;
export const FIND_TUITION_FEE = BASE_URL + `/api/tuitionFee/`;
export const FIND_TUITION_FEE_ENDPOINT = `/find`;
export const CLASS_FEE_STRUCTURE = BASE_URL + `/api/tuitionFee/`;
export const CLASS_FEE_STRUCTURE_ENDPOINT = `/classFeeStructure`;
export const DELETE_TUTION_FEE = BASE_URL + `/api/tuitionFee/`;
export const DELETE_TUTION_FEE_ENDPOINT = `/deleteTuitionFee`;
export const DELETE_FEECOMPONENT = BASE_URL + `/tuitionFee/`;
export const DELETE_FEECOMPONENT_ENDPOINT = `/deleteFeeComponent`;
/* result api */
export const RESULT_LIST_URL =
  BASE_URL +
  `/api/result/list?sortField=resultId&sortOrder=ASC&offSet=0&pageSize=50`;
export const CREATE_RESULT_URL = BASE_URL + `/api/result/create`;
// export const SEND_SCORE_CARD = BASE_URL + `/api/result/send-score-card/`
export const SEND_SCORE_CARD = BASE_URL + `/api/result/send-score-card-by-each-exam/`
export const EDIT_RESULT_URL = BASE_URL + `/api/result/`;
export const EDIT_RESULT_ENDPOINT = `/edit`;
export const FIND_RESULT_URL = BASE_URL + `/api/result/`;
export const FIND_RESULT_ENDPOINT = `/find`;
export const GET_RESULT_CLASSWISE_URL = BASE_URL + `/api/result/`;
export const GET_RESULT_CLASSWISE_ENDPOINT = `/getResultByClass`;
export const FIND_STUDENT_RESULT_URL = BASE_URL + `/api/result/`;
export const FIND_STUDENT_RESULT_URL_ENDPOINT = `/studentResults`;
export const GENERATE_RESULTS = BASE_URL + `/api/result/generateScoreCards`;
export const DOWNLOAD_ALL_SCORE_CARDS = BASE_URL + `/print-pdfs`;
export const DOWNLOAD_ALL_HALL_TICKETS = BASE_URL + "/print-hallTicket-pdfs";
export const GENERATE_SCORE_CARDS_FOR_CLASS = BASE_URL + "/api/result/generateScoreCardsByClass"
export const SEND_STUDENT_SCORE_CARD = BASE_URL + `/api/result/send-student-score-card/`

/*location Api*/
export const COUNTRIES_LIST_URL = BASE_URL + `/api/geoLoc/countries`;
export const STATES_LIST_URL = BASE_URL + `/api/geoLoc/states`;
export const CITIES_LIST_URL = BASE_URL + `/api/geoLoc/cities`;

//leaves controller
export const LEAVES_LIST = BASE_URL + `/api/leave/list?offSet=0&pageSize=1000`;
export const FIND_LEAVE = BASE_URL + `/api/leave/`;
export const FIND_LEAVE_ENDPOINT = `staffLeaveFind`;
export const STAFF_LEAVE_BALANCES = `${BASE_URL}/api/leave/staff-leave-balance`;

//leave dashboard
export const STAFF_ATTENDANCE_COUNT = `${BASE_URL}/api/leave/today-attendance-count`;
export const TODAY_STAFF_ABSENT_LIST = `${BASE_URL}/api/leave/absent-employees-today`;
export const MONTHLY_LEAVES_SUMMARY = `${BASE_URL}/api/leave/monthly-summary`;
export const STAFF_MONTHLY_SUMMARY = `${BASE_URL}/api/leave/staff-monthly-summary`;

//leave calendar controller
export const LEAVE_CALENDAR_LIST = BASE_URL + `/api/leave/leaveCalenderList`;
export const CREATE_LEAVE_CALENDAR =
  BASE_URL + `/api/leave/leaveCalenderCreate`;
export const EDIT_LEAVE_CALENDAR = BASE_URL + `/api/leave/`;
export const EDIT_LEAVE_CALENDAR_ENDPOINT = `/leaveCalenderEdit`;

//leave approval controller
export const LEAVE_APPROVAL_LIST =
  BASE_URL + `/api/leave/leaveApprovalList?offSet=0&pageSize=10`;
export const EDIT_LEAVE_APPROVAL_LIST = BASE_URL + `/api/leave/`;
export const EDIT_LEAVE_APPROVAL_ENDPOINT = `/editLeaveApproval`;

//Department Apis
export const CREATE_DEPARTMENT_URL = BASE_URL + `/api/department/create`;
export const DEPARTMENT_LIST_URL = BASE_URL + `/api/department/getAllDepts`;
export const EDIT_DEPARTMENT_URL = BASE_URL + `/api/department/`;
export const EDIT_DEPARTMENT_ENDPOINT = `/edit`;
export const DEPARTMENTS_LIST_FROM_S3 =
  BASE_URL + `/api/department/createDepartmentsFromS3`;
export const DELETE_DEPARTMENT = BASE_URL + `/api/department/`;
export const DELETE_DEPARTMENT_ENDPOINT = `/deleteDepartment`;

// Staff bank account apis
export const STAFF_BANK_ACCOUNTS_LIST_URL =
  STAFF_BASE_URL + `/api/staff/staffBankAccount/list`;
export const CREATE_STAFF_BANK_ACCOUNT_URL =
  STAFF_BASE_URL + `/api/staffBankAccount/create`;
export const EDIT_STAFF_BANK_ACCOUNT_URL =
  STAFF_BASE_URL + `/api/staffBankAccount/`;
export const EDIT_STAFF_BANK_ACCOUNT_ENDPOINT = `/edit`;
export const FIND_STAFF_BANK_ACCOUNT_URL =
  BASE_URL + `/api/staffBankAccount/`;
export const FIND_STAFF_BANK_ACCOUNT_ENDPOINT = `/find`;

// Staff Previous Employment apis
export const FIND_STAFF_PREVIOUS_EMPLOYMENT_URL =
  BASE_URL + `/api/previous-employment/`;
export const FIND_STAFF_PREVIOUS_EMPLOYMENT_ENDPOINT = `/find`;
export const GET_STAFF_EMPLOYMENT = BASE_URL + `/api/previous-employment/list`;

// Syllabus
export const SAVE_CHAPTERS = BASE_URL + `/api/syllabus/saveChapter`;
export const SAVE_SUBCHAPTERS = BASE_URL + `/api/syllabus/saveSubChapter`;
export const UPDATE_SUBCHAPTER = BASE_URL + `/api/syllabus/`;
export const UPDATE_CHAPTER = BASE_URL + `/api/syllabus/`;
export const SAVE_TOPIC = BASE_URL + `/api/syllabus/saveTopic`;
export const UPDATE_TOPIC = BASE_URL + `/api/syllabus/`;
export const UPDATE_TOPIC_ENDPOINT = `/updateTopic`;
export const EXPORT_SYLLABUS = `${BASE_URL}api/syllabus/exportSyllabus`;

// delete Chapter
export const DELETE_SYLLABUS_CHAPTER = BASE_URL + `/api/syllabus/`;
export const DELETE_SYLLABUS_CHAPTER_END_POINT = `/deleteChapter`;
// subChpater
export const DELETE_SYLLABUS_SUBCHAPTER = BASE_URL + `/api/syllabus/`;
export const DELETE_SYLLABUS_SUBCHAPTER_END_POINT = `/deleteSubChapter`;
// delete topic
export const DELETE_SYLLABUS_TOPIC = BASE_URL + `/api/syllabus/`;
export const DELETE_SYLLABUS_TOPIC_END_POINT = `/deleteTopic`;

export const GET_SYLLABUS = BASE_URL + `/api/syllabus/getSyllabus`;
// export const SAVE_SUBCHAPTERS = BASE_URL + `/api/syllabus/savSubChapter`;
// export const GET_SYLLABUS =
//   BASE_URL + `/api/syllabus/getSyllabus?classId=3&subjectId=1`;
//meetings
export const CREATE_MEETINGS = BASE_URL + `/api/meeting/createMeeting`;
export const GET_MEETINGS = BASE_URL + `/api/meeting/getAllMeetingsList`;
export const EDIT_MEETING = BASE_URL + `/api/meeting/`;
export const EDIT_MEETING_ENDPOINT = `/editMeetingSchedule`;
export const DELETE_MEETING = BASE_URL + `/api/meeting/`;
export const DELETE_MEETING_ENDPOINT = `/deleteMeeting`;

// /api/meeting/{meetingId}/editMeetingSchedule

// Student Fee Urls
export const STUDENT_FEE_STRUCTURE = BASE_URL + `/api/payments/fees/`;
export const STUDENT_FEE_STRUCTURE_ENDPOINT = `/getStudentFeePayments`;
export const CREATE_STUDENTFEE_URL = BASE_URL + `/api/studentFee/create`;
export const CREATE_CUSTOM_STUDENT_FEE_URL = BASE_URL + "/api/studentFee/create-custom";
export const SAVE_STUDENTFEES_URL =
  BASE_URL + `/api/studentFee/saveFeeConfigStudents`;
export const EDIT_STUDENTFEE_URL = BASE_URL + `/api/studentFee/`;
export const EDIT_STUDENTFEE_END_POINT = `/edit`;
export const FIND_STUDENTFEE_URL = BASE_URL + `/api/studentFee/`;
export const FIND_STUDENTFEE_END_POINT = `/studentFee`;
export const STUDENT_TRANSPORT_ENDPOINT = `/transport-details`;
export const STUDENT_FEEPAYMENT = BASE_URL + `/api/payments/fees/pay`;
export const CUSTOM_STUDENT_FEE_PAYMENT = BASE_URL + `/api/payments/fees/pay-custom`;
export const EDIT_CUSTOM_STUDENT_FEE_PAYMENT = BASE_URL + `/api/payments/fees/edit-pay-custom/`;
export const STUDENT_SEARCH = BASE_URL + `/api/student/getAllStudents`;
export const CLEAR_TOTAL_FEE = STUDENT_FEE_STRUCTURE + `{studentId}/clear-remaining-funds`


//Holidays
export const GET_HOLIDAYS =
  BASE_URL + `/api/holiday/list?&sortField=holidayId&sortOrder=ASC`;
export const CREATE_HOLIDAYS = BASE_URL + `/api/holiday/create`;
export const EDIT_HOLIDAY = BASE_URL + `/api/holiday/`;
export const EDIT_HOLIDAY_ENDPOINT = `/edit`;
export const DELETE_HOLIDAY_ENDPOINT = `/delete`;

/*interview*/
export const CREATE_INTERVIEW_SCHEDULE =
  BASE_URL + `/api/hrOperations/scheduleInterview`;
export const GETALL_INTERVIEWSCHEDULW =
  BASE_URL + `/api/hrOperations/getAllInterviewsData`;
export const EDIT_INTERVIEW_SCHEDULE = BASE_URL + `/api/hrOperations/`;
export const EDIT_INETRVIEW_SCHEDULE_ENDPOINT = `/updateInterviewDetails`;
export const VIEW_INTERVIEW_SCHEDULE = BASE_URL + `/api/hrOperations/`;
export const VIEW_INTERVIEW_SCHEDULE_ENDPOINT = `/getInterviewDetails`;


//School Financial Reports
export const GET_FINANCIAL_REPORTS = BASE_URL + `/api/payments/fees/feeCollection`
export const FINANCE_REPORTS = ACCOUNTS_BASE_URL + "/api/payment-details";

// Dashboard
export const GET_TEACHERS_DATA = BASE_URL + `/api/dashBoard/getAllStaffData`;
export const GET_STUDENTS_DATA = BASE_URL + `/api/dashBoard/getAllStudentData`;
export const GET_ALL_PAYMENT_DATA =
  BASE_URL + `/api/dashBoard/getTodayCollectedFee`;
// export const CLASSWISE_FEE_DETAILS = BASE_URL + `/api/dashBoard/`;
// export const CLASSWISE_FEE_DETAILS_ENDPOINT = `/getAllClassWisePaymentData`;
export const GET_GENDER_WISE_DATA =
  BASE_URL + `/api/dashBoard/getGenderWiseCount`;
export const TOTAL_COLLECTED_FEE =
  BASE_URL + `/api/dashBoard/totalWisePaymentData`;
export const YEARWISE_FEE_DETAILS = BASE_URL + `/api/expenses/monthly`;
export const CLASS_WISE_PAYMENT =
  BASE_URL + `/api/dashBoard/getAllClassWisePaymentData`;
export const CLASS_ATTENDANCE =
  ATTENDANCE_BASE_URL + `/api/attendance-v2/overall`;

export const ATTENDANCE_PERCENTAGE = ATTENDANCE_BASE_URL + `/api/attendance-v2/get_attendance_percentage`

export const CATEGORY_WISE_FEE_COLLECTED =
  BASE_URL + "/api/dashBoard/categoryWiseCollectesFee";
export const OVERALL_COLLECTED_FEE = BASE_URL + `/api/dashBoard/class-fees`;
export const TOP_PERFORMERS = BASE_URL + `/api/result/top-students`;
export const PAYMENT_SUMMARY =
  BASE_URL + `/api/transactionTable/payment-summary`;
//HR
export const CREATE_HR_JOB = BASE_URL + `/api/hrOperations/createCurrOpenings`;
export const GET_ALL_JOBS = BASE_URL + `/api/hrOperations/findAllJobs`;
export const EDIT_JOB_DETAILS = BASE_URL + `/api/hrOperations/`;
export const EDIT_JOB_DETAILS_END_POINT = `/updateJobOpening`;
export const FIND_JOB_URL = BASE_URL + `/api/hrOperations/`;
export const FIND_JOB_URL_END_POINT = `/findJobDetails`;
export const GETALL_STAFF_UNLOCK_REQUEST =
  BASE_URL + `/api/hrOperations/getAllUnlockRequest`;
export const GET_ALL_REGULARIZATION =
  BASE_URL + `/api/hrOperations/getAllRegularization`;
export const UPDATE_STAFF_UNLOCK_REQUEST = BASE_URL + `/api/hrOperations/`;
export const UPDATE_STAFF_UNLOCK_REQUEST_ENDPOINT = `/updateUnlockReqStatus`;

export const GETALL_RESIGNATION =
  BASE_URL + `/api/hrOperations/getAllResignation`;
export const STATUS_UPDATE_RESIGNATION =
  BASE_URL + `/api/hrOperations/updateResStatus`;
export const STATUS_DECLINE_RESIGNATION = BASE_URL + `/api/hrOperations/`;
export const STATUS_DECLINE_RESIGNATION_ENDPOINT = `/rejectResStatus`;
export const GET_ALL_ATTENDENCE_UNLOCK_REQUEST =
  BASE_URL + `/api/hrOperations/getAllUnlockRequest`;
export const GET_ALL_REGULARAIZATIONS_REQUEST =
  BASE_URL + `/api/hrOperations/getAllRegularization`;
export const EDIT_REGULARAIZATIONS_STATUS = BASE_URL + `/api/hrOperations/`;
export const EDIT_REGULARAIZATIONS_STATUS_END_POINT = `/updateRegStatus`;
export const GET_STAFF_ALL_ATTENDENCE_CURRENT_DAY =
  BASE_URL + `/api/hrOperations/todayStaffAttend`;
export const FIND_STAFF_DETAILS = BASE_URL + `/api/hrOperations/`;
export const FIND_STAFF_DETAILS_END_POINT = `/StaffAttendInMonth`;
export const GET_MONTHLY_STAFF_ATTENDANCE = STAFF_BASE_URL + `/api/staffAttendance/`
export const MONTHLY_ATTENDANCE_ENDPOINT = "/getMonthAttendance"
export const DELETE_STAFF = BASE_URL + `/api/staff/`;
export const DELETE_STAFF_ENDPOINT = "/delete-staff"

// staff regularization lock and unlock
export const ALL_STAFF_UNLOCK =
  BASE_URL + `/api/hrOperations/updateLockStatusOfAllStaff`;
export const LOCK_STATUS =
  BASE_URL + `/api/hrOperations/getLockStatusOfAllStaff`;

//Transactions
export const FIND_STUDENT_FEE_TRANSACTION = BASE_URL + `/api/transactionTable/`;
export const FIND_STUDENT_FEE_TRANSACTION_ENDPOINT = `/getAllTransactions`;
export const SEND_TRANSACTION_INVOICE = `${BASE_URL}/api/student/sendReceipt`;
export const EDIT_STUDENT_FEE_TRANSACTION = BASE_URL + `/api/transactionTable/update-fee`


//summerCamp
export const CREATE_SUMMERCAMP = BASE_URL + `/api/summerCamp/summerCampCreate`;
export const LIST_SUMMERCAMP = BASE_URL + `/api/summerCamp/summerCampList`;
export const FIND_SUMMERCAMP = BASE_URL + `/api/summerCamp/`;
export const ENDPOINT_FINDSUMMERCAMP = `/findSummerCamp`;
export const EDIT_SUMMERCAMP = BASE_URL + `/api/summerCamp/`;
export const EDIT_SUMMERCAMP_ENDPOINT = `/summerCampEdit`;

//salary Structure

export const CREATE_SALARY_STRUCTURE = BASE_URL + `/api/salaryStructure/save`;
export const EDIT_SALARY_STRUCTURE = BASE_URL + `/api/salaryStructure/`;
export const EDIT_SALARY_STRUCTURE_ENDPOINT = `/editCategory`;
export const SALARY_STRUCTURE_CONFIG =
  BASE_URL + `/api/salaryStructure/getSalaryStructure`;
export const CREATE_STAFF_SALARY_STRUCTURE =
  BASE_URL + `/api/hrOperations/saveSalaryStructureForStaff`;
export const UPDATE_STAFF_SALARY_STRUCTURE = BASE_URL + `/api/hrOperations/`;
export const UPDATE_STAFF_SALARY_STRUCTURE_ENDPOINT = `/updateSalaryStructureOfStaff`;
export const FIND_STAFF_SALARY_STRUCTURE = BASE_URL + `/api/hrOperations/`;
export const FIND_STAFF_SALARY_STRUCTURE_ENDPOINT = `/getSalaryStructureOfStaff`;

//tutor
export const TUTOR_LIST = BASE_URL + `/api/tutor/tutorList`;
export const VIEW_TUTOR = BASE_URL + `/api/tutor/`;
export const VIEW_TUTOR_ENDPOIN = `/findTutorById`;
export const EDIT_TUTOR = BASE_URL + `/api/tutor/`;
export const EDIT_TUTOR_ENDPOINT = `/tutorEdit`;
export const DELETE_TUTOR = BASE_URL + `/api/tutor/`;
export const DELETE_TUTOR_ENDPOINT = `/deleteTutor`;

// Invoice
export const GENERATE_PAYSLIP = BASE_URL + `/api/invoice/generatePaySlips`;
export const GET_STAFF_PAYSLIPS = BASE_URL + `/api/hrOperations/getAllPaySlips`;
export const GET_STAFF_PAYSLIP_DETAILS = BASE_URL + `/api/staff/`
export const END_POINT_PAYSLIP_DETAILS = "/get-staff-payslip-details"
export const GENERATE_PAYSLIP_STAFF = BASE_URL + `/api/invoice/generatePayslip`

//payroll dashboard
export const MONTHWISE_STAFF_ATTENDANCE =
  BASE_URL + `/api/staff/findAllEmployeeSalaryByMonth`;
export const STAFF_SALARY_DETAILS = BASE_URL + `/api/staff/staffSalaryDetails`;
// Attendance Service
export const STUDENT_CLASS_WISE_ATTENDANCE =
  ATTENDANCE_BASE_URL + `/api/class/`;
  export const CLASS_WISE_STUDENT_ATTENDANCE_REPORT = ATTENDANCE_BASE_URL + "/api/class/{classId}/report";

export const STUDENT_ATTENDANCE_LIST =
  ATTENDANCE_BASE_URL + `/api/class-attendance/students/{classId}`;
export const SEND_ABSENT_REPORT = ATTENDANCE_BASE_URL + "/api/sendAbsentReport"
export const STUDNT_VIEW_ATTENDACE = STAFF_BASE_URL + `/api/result/`;
export const STUDENT_VIEW_ATTENDACE_ENDPOINT = `/getAllStudentResultByClass`;

//studentAttendace requestUpdate
export const STUDENT_ATTENDACE_REQUEST_UPDATE_LIST =
  ATTENDANCE_BASE_URL + `/api/listUpdateAttend`;
export const STUDENT_ATTENDACE_REQUEST_UPDATE_STATUS_UPDATE =
  ATTENDANCE_BASE_URL + `/api/`;
export const STUDENT_ATTENDACE_REQUEST_UPDATE_STATUS_UPDATE_ENDPOINT = `/acceptanceByInstAdmin`;

//STAFF ATTENDANCE URL's
export const STUDENT_SAVE_ATTENDANCE =
  STAFF_BASE_URL + `/api/attendance-v2/staff/`;
export const GET_STUDENT_ATTENDANCE_DETAILS =
  STAFF_BASE_URL + `/api/attendance-v2/class/`;
export const GET_ALL_STUDENTS = BASE_URL + `/api/student/getAllStudents`;
export const ATTENDANCE_TAKEN =
  ATTENDANCE_BASE_URL + `/api/class-attendance/attendanceAvailability`;
export const GET_TODAYTIMETABLE_ATTENDANCE = STAFF_BASE_URL + `/api/staff/`;
export const SAVE_ATTENDANCE_ENDPOINT = `${ATTENDANCE_BASE_URL}/api/class-attendance/save`;
export const GET_TODAYTIMETABL_ATTENDANCE_ENDPOINT = `/getTodayTimetable`;
export const STAFF_ATTENDANCE_API = ATTENDANCE_BASE_URL + "/api/staffAttendance/";
export const GET_STAFF_ATTENDANCE_DATA = "/getAttendance"
export const STAFF_CHECK_IN = "/selfCheckIn";
export const STAFF_CHECK_OUT = "/selfCheckOut";

// Inventory Management
export const EDIT_ASSET_ITEM = BASE_URL + `/api/assetManagement/`;
export const EDIT_ASSET_ITEM_ENDPOINT = `/editItem`;
export const GENERATE_REPORT = `${BASE_URL}/api/assetManagement/generateReport`;
export const DELETE_INVENTORY_CATEGORY = `${BASE_URL}/api/assetManagement/{categoryId}/deleteInventoryCategory`;
export const DELETE_INVENTORY_SUBCATEGORY = `${BASE_URL}/api/assetManagement/{subCategoryId}/deleteInventorySubCategory`;
export const INVENTORY_REPORT = `${BASE_URL}/api/assetManagement/getInventoryStockMonthly`;

// User Mangement

// features api
export const FEATURES_LIST = BASE_URL + `/api/uiComponent/save`;

export const USER_DETAILS = BASE_URL + `/api/userManagement/loginUserDetails`;
// Ui Components
export const GET_COMPONENT_LIST =
  BASE_URL + `/api/uiComponent/list?offSet=0&pageSize=200`;

export const DELETE_COMPONENT = BASE_URL + `/api/uiComponent/`;
export const DELETE_COMPONENT_ENDPOINT = `/deleteUIComponent`;

export const EDIT_UI_COMPONENT = BASE_URL + `/api/uiComponent/`;
export const EDIT_UI_COMPONENT_ENDPOINT = `/componentEdit`;

export const CREATE_UI_COMPONENT = BASE_URL + `/api/uiComponent/save`;
export const FIND_UI_COMPONENT = BASE_URL + `/api/uiComponent/`;
export const FIND_UI_COMPONENT_ENDPOINT = `/findComponentById`;

// ui-role controller
export const EDIT_USER_ROLE = BASE_URL + `/api/uiRole/`;
export const EDIT_USER_ROLE_ENDPOINT = `/update`;
export const CREATE_USER_ROLE = BASE_URL + `/api/uiRole/save`;
export const CREATE_USER = BASE_URL + `/api/userManagement/save`;
export const USERS_LIST = BASE_URL + `/api/uiRole/list?offSet=0&pageSize=200`;
export const USERS_LIST_USERS = BASE_URL + `/api/userManagement/list`;

export const DELETE_UI_ROLE = BASE_URL + `/api/uiRole/`;
export const DELETE_USER = BASE_URL + `/api/userManagement/`;
// export const DELETE_UI_ROLE_ENDPOINT = `/deleteUIRole`;

// user management controller
export const USER_MANAGEMENT_EDIT = BASE_URL + `/api/userManagement/`;
export const USER_MANAGEMENT_EDIT_ENDPOINT = `/userManagementEdit`;

export const CREATE_USER_MANAGEMENT = BASE_URL + `/api/uiRole/`;
export const CREATE_USER_MANAGEMENT_UPDATE = `/update`;
export const USER_MANAGEMENT_LIST =
  BASE_URL + `/api/userManagement/list?offSet=0&pageSize=200`;

export const DELETE_USER_MANAGEMENT = BASE_URL + `/api/userManagement`;
export const DELETE_USER_MANAGEMENT_ENDPOINT = `/deleteUserManagement`;

export const FIND_USER_COMPONENTS = BASE_URL + `/api/uiRole/`;

// Transport controller

export const TRANSPORT_DRIVERS_LIST =
  BASE_URL_TRANSPORT + `/api/driver/getAllDriver`;
export const CREATE_DRIVER = BASE_URL_TRANSPORT + `/api/driver/create`;
export const VIEW_DRIVER_URL = BASE_URL_TRANSPORT + `/api/driver/`;
export const VIEW_DRIVER_POINT = `/find`;
export const VIEW_BUS_URL = BASE_URL_TRANSPORT + `/api/vehicle/`;
export const VIEW_BUS_POINT = `/find`;
export const EDIT_DRIVER = BASE_URL_TRANSPORT + `/api/driver/`;
export const EDIT_DRIVER_ENDPOINT = `/edit`;
export const DELETE_DRIVER = BASE_URL_TRANSPORT + `/api/driver/`;
export const DELETE_DRIVER_ENDPOINT = `/deleteDriver`;
// https://api.edpedia.co/v1.0/transport/api/vehicle-income/2/weekly-income?month=1&year=2024
export const MONTHWISE_VEHICLE_INCOME =
  BASE_URL_TRANSPORT + `/api/vehicle-income/`;
export const MONTHWISE_VEHICLE_INCOME_ENDPOINT = `/weekly-income`;
export const TRANSPORT_HELPERS_LIST =
  BASE_URL_TRANSPORT + `/api/driverHelper/getAllDriverHelper`;
export const CREATE_HELPER = BASE_URL_TRANSPORT + `/api/driverHelper/create`;
export const VIEW_HELPER_URL = BASE_URL_TRANSPORT + `/api/driverHelper/`;
export const VIEW_HELPER_POINT = `/find`;
export const EDIT_HELPER = BASE_URL_TRANSPORT + `/api/driverHelper/`;
export const EDIT_HELPER_ENDPOINT = `/edit`;
export const DELETE_HELPER = BASE_URL_TRANSPORT + `/api/driverHelper/`;
export const DELETE_HELPER_ENDPONT = `/deleteDriverHelper`;

export const TRANSPORT_BUSES_LIST =
  BASE_URL_TRANSPORT + `/api/vehicle/getAllVehicle`;
export const CREATE_BUS = BASE_URL_TRANSPORT + `/api/vehicle/create`;
export const EDIT_VEHICLE = BASE_URL_TRANSPORT + `/api/vehicle/`;
export const EDIT_VEHICLE_ENDPOINT = `/edit`;
export const DELETE_VEHICLE = BASE_URL_TRANSPORT + `/api/vehicle/`;
export const DELETE_VEHICLE_ENDPOINT = `/deleteVehicle`;
export const DELETE_VEHICLE_ROUTE = BASE_URL_TRANSPORT + `/api/route/`;
export const DELETE_VEHICLE_ROUTE_ENDPOINT = `/removeVehicle`;
export const VEHICLE_PICKUP_POINT_LIST = `${BASE_URL_TRANSPORT}/api/pickupPoint/vehicles/{vehicleId}/pickup-points`;
export const VEHICLE_WISE_PICKUP_POINT_LIST = `${BASE_URL_TRANSPORT}/api/pickupPoint/all-bus-pickup-points`;
export const UPDATE_VEHICLE_DOCUMENT = `${BASE_URL_TRANSPORT}/api/vehicle/{vehicleId}/updateVehicleDoc`;

export const VIEW_ROUTE_URL = BASE_URL_TRANSPORT + `/api/route/`;
export const VIEW_ROUTE_POINT = `/find`;
export const TRANSPORT_ROTES_LIST =
  BASE_URL_TRANSPORT + `/api/route/getAllRoutes`;
export const CREATE_ROUTE = BASE_URL_TRANSPORT + `/api/route/create`;
export const EDIT_ROUTE = BASE_URL_TRANSPORT + `/api/route/`;
export const EDIT_ROUTE_ENDPOINT = `/edit`;
export const DELETE_ROUTE = BASE_URL_TRANSPORT + `/api/route/`;
export const DELETE_ROUTE_ENDPOINT = `/deleteRoute`;
export const DELETE_VEHICLE_FROM_ROUTE = `${BASE_URL_TRANSPORT}/api/route/{routeId}/removeVehicle`;
export const DELETE_PICKUP_POINT_FROM_ROUTE = `${BASE_URL_TRANSPORT}/api/route/{routeId}/removePickupPoint`;

export const CREATE_PICKUP_POINT =
  BASE_URL_TRANSPORT + `/api/pickupPoint/create`;
export const ADD_STUDNET_AT_PICKUP_POINT_URL =
  BASE_URL_TRANSPORT + `/api/pickupPoint/addStudent`;
export const DELETE_PICKUP_POINT = BASE_URL_TRANSPORT + `/api/pickupPoint/`;
export const DELETE_PICKUP_POINT_ENDPOINT = `/deletePickupPoint`;
export const PICKUP_POINT_LIST =
  BASE_URL_TRANSPORT + `/api/pickupPoint/getAllPickupPoint`;
export const EDIT_PICKUPPOINT = BASE_URL_TRANSPORT + `/api/pickupPoint/`;
export const EDIT_PICKUPPOINT_ENDPOINT = `/edit`;

export const TRANSPORT_STUDENTS_LIST =
  BASE_URL_TRANSPORT + `/api/getAllBusOptedStudents`;
export const TRANSPORT_STUDENTS_LIST_INFO =
  BASE_URL_TRANSPORT + `/api/getBusStudentsInfo`;

export const UPDATE_FILES_URL = BASE_URL_TRANSPORT + `/api/driver/`;
export const UPDATE_FILES_ENDPOINT = `/updateDriverFile`;

//repair vehicle
export const VEHICLE_REPAIRE_LIST =
  BASE_URL_TRANSPORT + `/api/vehicle-repair/list`;
export const VEHICLE_FUEL_FILLING_LIST =
  BASE_URL_TRANSPORT + `/api/vehicle-fuel/list`;
export const VEHICLE_REPAIRE_EDIT = BASE_URL_TRANSPORT + `/api/vehicle-repair/`;

export const VEHICLE_REPAIRE_EDIT_ENDPOINT = `/edit`;
// Transport Complaints
export const GET_ALL_COMPLAINTS_LIST =
  BASE_URL_TRANSPORT + `/api/complaint/showAllComplaint`;
export const EDIT_COMPLAINT = BASE_URL_TRANSPORT + `/api/complaint/`;
export const EDIT_COMPLAINT_ENDPOINT = `/updateStatus`;

//Transport Analysis

export const GET_BUS_WISE_ANALYSIS = `${BASE_URL_TRANSPORT}/api/vehicle-income/bus-wise`;

// Vehicle Maintenance
export const VEHICLE_MAINTENANCE_LIST = `${BASE_URL_TRANSPORT}/api/vehicle-maintenance/list`;
export const VEHICLE_MAINTENANCE_CREATE = `${BASE_URL_TRANSPORT}/api/vehicle-maintenance/save`;
export const VEHICLE_MAINTENANCE_EDIT = `${BASE_URL_TRANSPORT}/api/vehicle-maintenance/{maintenanceId}/edit`;

// Institute Info
export const findInstituteInfo =
  INSTITUTE_BASE_URL + `/api/institute/getInstituteDetails`;
export const INSTITUTE_LOGO_UPLOAD = `https://api.edpedia.co/v1.0/admissions/api/`;
export const INSTITUTE_LOGO_UPLOAD_ENDPOINT = `/instLogo`;

// instituteSettings
export const CREATE_INST_SETTINGS =
  BASE_URL + "/api/settings/add-configuration";
export const EDIT_INST_SETTINGS = BASE_URL + "/api/settings/edit-configuration";
export const GET_INST_SETTINGS = BASE_URL + "/api/settings/get-configuration";
export const DELETE_INST_SETTINGS_TEMPLATE = BASE_URL + "/api/settings/delete-template";

// Updates
export const GET_UPDATES = INSTITUTE_BASE_URL + "/api/updates/get-recent-updates";
export const GET_BRANCH_PAYMENT_DATA = INSTITUTE_BASE_URL + "/api/institute/getBranch";
export const SEND_PAYMENT_RESPONSE = BASE_URL + "/api/branch-payment-reminder";

//studentmail
export const STUDENT_MAIL = BASE_URL + `/api/student/sendMail`;
// Expenses
export const EXPENSE_LIST = BASE_URL + `/api/expenses/list`;
export const CREATE_EXPENSE = BASE_URL + `/api/expenses/create`;
export const EDIT_EXPENSE = BASE_URL + `/api/expenses/`;
export const EDIT_EXPENSE_ENDPOINT = `/edit`;
export const DELETE_EXPENSE = BASE_URL + `/api/expenses/`;
export const DELETE_EXPENSE_ENDPOINT = `/deleteExpenses`;
export const GET_EXPENSE_CATEGORY_LIST =
  BASE_URL + `/api/expenses-category/list`;
export const CREATE_EXPENSE_CATEGORY = BASE_URL + `/api/expenses-category/save`;

export const EDIT_EXPENSE_LIST = BASE_URL + `/api/expenses-category/`;
export const EDIT_EXPENSE_LIST_ENDPOINT = `/edit`;

export const DELETE_EXPENSE_LIST = BASE_URL + `/api/expenses-category/`;
export const DELETE_EXPENSE_LIST_ENDPOINT = `/delete`;

export const DOWNLOAD_FILE = `/api/storage/download`;

// Edpedia store

// category
export const GET_ALL_CATEGORIES_PRODUCTS_LIST =
  EDPEDIA_STORE_BASE_URL + `/api/storeCategory/categoryList`;
export const FIND_CATEGORY_PRODUCTS_URL =
  EDPEDIA_STORE_BASE_URL + `/api/storeCategory/`;
export const FIND_CATEGORY_PRODUCTS_URL_ENDPOINT =
  EDPEDIA_STORE_BASE_URL + `/findCategoryById`;

// sub category
export const FIND_SUBCATEGORY =
  EDPEDIA_STORE_BASE_URL + `/api/storeSubCategory/`;
export const FIND_SUBCATEGORY_ENDPOINT = `/findListOfSubCategory`;

//products
export const GET_PRODUCTS_USING_SUB_CATEGORYOID =
  EDPEDIA_STORE_BASE_URL + `/api/product/`;
export const GET_PRODUCTS_USING_SUB_CATEGORYOID_ENDPOINT = `/findProductBySubCategoryId`;
//http://localhost:8088/v1.0/store/api/product/6c69e6da-ae4d-4925-af72-accc5aeda614/findProductBySubCategoryId

// address
export const CREATE_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const CREATE_ADDRESS_ENDPOINT = `/createAddress`;
export const FIND_ADDRESS_BY_USER = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const FIND_ADDRESS_BY_USER_ENDPOINT = `/findByUser`;
export const EDIT_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const EDIT_ADDRESS_ENDPOINT = `/edit`;
export const DELETE_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const DELETE_ADDRESS_ENDPOINT = `/delete`;

// cart
export const ADD_PRODUCT_TO_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const ADD_PRODUCT_TO_CART_ENDPOINT = `/add`;
export const DELETE_PRODUCT_FROM_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const DELETE_PRODUCT_FROM_CART_ENDPOINT = `/delete/`;
export const CART_LIST = EDPEDIA_STORE_BASE_URL + `/cart/`;

// order
export const USER_ORDER_LIST = EDPEDIA_STORE_BASE_URL + `/api/order/`;
export const USER_ORDER_LIST_ENDPOINT = `/findByUser`;
export const ORDER_FROM_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const ORDER_FROM_CART_ENDPOINT = `/createOrder`;

//wishlist
export const WISHLIST_ADD = EDPEDIA_STORE_BASE_URL + `/api/wishlist/add`;
export const GET_WISHLIST_USING_BRANCHCODE =
  EDPEDIA_STORE_BASE_URL + `/api/wishlist/`;
export const GET_WISHLIST_USING_BRANCHCODE_ENDPOINT = `/findByUser`;
export const REMOVE_WISHLIST = EDPEDIA_STORE_BASE_URL + `/api/wishlist/`;
export const REMOVE_WISHLIST_ENDPOINT = `/remove/`;
//offer products
export const OFFER_PRODUCT_LIST =
  EDPEDIA_STORE_BASE_URL + `/api/product/offerProducts`;
//size list
export const GET_ALL_SIZES = EDPEDIA_STORE_BASE_URL + `/api/sizes/getAllSizes`;
//brand list
export const GET_ALL_BRAND = EDPEDIA_STORE_BASE_URL + `/api/brand/getAllBrands`;
export const INSTITUTE_ADMIN_LOGO = BASE_URL + `/api/admission/`;
export const INSTITUTE_ADMIN_LOGO_ENDPOINT = `/instLogo`;
//questionpaper
export const UPLOAD_QUESTIONPAPERS = STAFF_BASE_URL + `/api/questionPaper/upload`;
export const QUESTION_PAPER_LIST = STAFF_BASE_URL + `/api/questionPaper/exam/`;
export const QUESTIONPAPER_LIST_ENDPOINT = `/all`;
export const EDIT_QUESTION_PAPER = STAFF_BASE_URL + `/api/questionPaper/`;
export const EDIT_QUESTION_PAPER_ENDPOINT = `/edit`;
export const DELETE_QUESTION_PAPER = STAFF_BASE_URL + `/api/questionPaper/`;
export const DELETE_QUESTION_PAPER_ENDPOINT = `/delete`;
// http://localhost:8084/v1.0/staff/api/questionPaper/1/remove?url=www.edpedia.com/XHcSk49
export const REMOVE_QUESTIONPAPER = STAFF_BASE_URL + `/api/questionPaper/`;
export const REMOVE_QUESTIONPAPER_ENDPOINT = `/delete`;

// sports_urls

// Game APIs
export const CREATE_GAME = SPORTS_BASE_URL + "/api/game/save";
export const SPORTS_GAME_LIST = SPORTS_BASE_URL + "/api/game/list";
export const GAME_FIND = SPORTS_BASE_URL + "/api/game/save/";
export const GAME_FIND_ENDPOINT = "/find-game";
export const SPORTS_GAME_EDIT = SPORTS_BASE_URL + "/api/game/";
export const SPORTS_GAME_EDIT_ENDPOINT = "/edit-game";

// Matches APIs
export const MATCHES_SCHEDULE = SPORTS_BASE_URL + "/api/matches/schedule";
export const MATCHES_RECORD_RESULT =
  SPORTS_BASE_URL + "/api/matches/{matchId}/record-result";
export const MATCHES_LIST = SPORTS_BASE_URL + "/api/matches/list";

// Support APIs
export const CREATE_TICKET = BASE_URL + "/api/support/create-ticket"
export const GET_ALL_TICKETS = BASE_URL + "/api/support/ticket/get-all"
export const UPDATE_TICKET = BASE_URL + "/api/support/update-ticket/"
export const VIEW_TICKET_DETAILS = BASE_URL + "/api/support/ticket/"
export const VIEW_TICKET_END_POINT = "/all-details"
export const SEND_COMMNET = BASE_URL + "/api/support/ticket/"
export const SEND_COMMNET_END_POINT = "/add-comment"

// Sports Events APIs
export const SPORTS_EVENT_LIST = SPORTS_BASE_URL + "/api/sports-event/list";
export const SPORTS_EVENT_SAVE = SPORTS_BASE_URL + "/api/sports-event/save";
export const SPORTS_EVENT_FIND =
  SPORTS_BASE_URL + "/api/sports-event/{eventId}/find";
export const SPORTS_EVENT_EDIT =
  SPORTS_BASE_URL + "/api/sports-event/{sportEventId}/edit";
export const SPORTS_EVENT_CLASS =
  SPORTS_BASE_URL + "/api/sports-event/{sportEventId}/class/{classId}";

// Sports Type APIs
export const SPORTS_TYPE_SAVE = SPORTS_BASE_URL + "/api/sports-type/save";
export const SPORTS_TYPE_FIND =
  SPORTS_BASE_URL + "/api/sports-type/{typeId}/find";
export const SPORTS_TYPE_LIST = SPORTS_BASE_URL + "/api/sports-type/list";
export const SPORTS_TYPE_EDIT =
  SPORTS_BASE_URL + "/api/sports-type/{typeId}/edit";

// Sports Registration APIs
export const SPORTS_REGISTRATION_SAVE = `${SPORTS_BASE_URL}/api/sports-registration/save`;
export const SPORTS_REGISTRATION_LIST = `${SPORTS_BASE_URL}/api/sports-registration/list`;
export const SPORTS_REGISTRATION_FIND = `${SPORTS_BASE_URL}/api/sports-registration/{registrationId}/find`;
export const SPORTS_REGISTRATION_EDIT = `${SPORTS_BASE_URL}/api/sports-registration/{registrationId}/edit`;
export const SPORTS_REGISTRATION_DELETE = `${SPORTS_BASE_URL}/api/sports-registration/{registrationId}/delete`;

// Teams APIs
export const TEAMS_SAVE = `${SPORTS_BASE_URL}/api/teams/save`;
export const TEAMS_LIST = `${SPORTS_BASE_URL}/api/teams/list`;
export const TEAMS_FIND = `${SPORTS_BASE_URL}/api/teams/{teamId}/find`;
export const TEAMS_EDIT = `${SPORTS_BASE_URL}/api/teams/{teamId}/edit`;

// Team Participations APIs
export const TEAM_PARTICIPATIONS_SAVE = `${SPORTS_BASE_URL}/api/team-participations/save`;
export const TEAM_PARTICIPATIONS_EDIT = `${SPORTS_BASE_URL}/api/team-participations/{participationId}/edit`;
export const TEAM_PARTICIPATIONS_DELETE = `${SPORTS_BASE_URL}/api/team-participations/{participationId}/delete`;
export const TEAM_PARTICIPATIONS_FIND_BY_ID = `${SPORTS_BASE_URL}/api/team-participations/{participationId}/findById`;
export const TEAM_PARTICIPATIONS_LIST = `${SPORTS_BASE_URL}/api/team-participations/list`;

// Enquiry
export const ENQUIRY_LIST = `${ADMISSION_BASE_URL}/api/enquiry/list?pageNo=0&pageSize=1000`;
export const ENQUIRY_EDIT = `${ADMISSION_BASE_URL}/api/enquiry/{enquiryId}/edit`;

// Enquiry Evaluate
export const ENQUIRY_EVALUATE_POST = `${ADMISSION_BASE_URL}/api/enquiry_evaluations/create`;

// Enquiry Skip Evaluate
export const SKIP_EVALUATE = `${ADMISSION_BASE_URL}/api/enquiry_evaluations/skip-evaluation`;

// Enquiry Create
export const ENQUIRY_FORM_CREATE = `${ADMISSION_BASE_URL}/api/enquiry-data/create`;
export const ENQUIRY_FORM_FIND = `${ADMISSION_BASE_URL}/api/enquiry-data/find_enquiry_data`;

// Send Enquiries
export const UPLOAD_FILE = `${ADMISSION_BASE_URL}/api/enquiry_status/upload-file`;
export const SEND_ENQURIES = `${ADMISSION_BASE_URL}/api/enquiry-status/send-enquiry-links`;

//change institute admin password
export const CHANGE_PASSWORD = BASE_LOGIN_URL + `/api/auth/change-password`;
export const EDIT_INSTITUTE_ADMIN_PROFILE = BASE_URL + `/api/branch-admin/`;
export const EDIT_INSTITUTE_ADMIN_PROFILE_ENDPOINT = `/editBranchAdmin`;
export const SEND_OTP_PROFILE_EMAIL_VARIFICATION =
  INSTITUTE_BASE_URL + `/api/institute/send-otp-to-email`;
export const VERIFY_OTP_PROFILE_EMAIL_OTP =
  INSTITUTE_BASE_URL + `/api/institute/validate-email-with-otp`;

// Designations
export const DESIGNATIONS_LIST_FROM_S3 = `${BASE_URL}/api/designation/createDesignationFromS3`;
export const GET_ALL_DESIGNATIONS = `${BASE_URL}/api/designation/getAllDesignation`;
export const DESIGNATIONS_CREATE = `${BASE_URL}/api/designation/create`;
export const EDIT_DESIGNATION = `${BASE_URL}/api/designation/{designationId}/edit`;
export const DESIGNATION_DELETE = `${BASE_URL}/api/designation/{designationId}/delete_designation`;

//classWiseAllSubject
export const GETALLSUBJECTNAMES_BYCASSID =
  BASE_URL + `/api/class-subject-mapping/`;
export const GETALLSUBJECTNAMES_BYCASSID_ENDPOINT = `/getClassSubjects`;

// export const EDIT_SUBJECT = BASE_URL + `/api/subject/`;
// export const EDIT_SUBJECT_ENDPOINTS = `/edit`;
// export const DELETE_SUBJECTS = BASE_URL + `/api/subject/`;
// export const DELETE_SUBJECT_ENDPOINTS = `/deleteSubject`;

// Transport income and expenses
export const BUS_WISE_INCOME = `${BASE_URL}/api/vehicle-income/bus-wise`;

//hostel management
export const GET_HOSTEL_ROOM_LIST = `${BASE_URL}/api/hostel-room/list`;
export const GET_HOSTEL_COMPLAINT_LIST = `${BASE_URL}/api/complaints/list`;
export const CREATE_HOSTEL_ROOM = `${BASE_URL}/api/hostel-room/create`;
export const EDIT_HOSTEL_ROOM = `${BASE_URL}/api/hostel-room/{roomId}/edit`;
export const DELETE_HOSTEL_ROOM = `${BASE_URL}/api/hostel-room/{roomId}/delete`;
export const HOSTEL_DASHBOARD_COUNT = `${BASE_URL}/api/hostel-room/count`;

// Hostel management - expenses
export const GET_HOSTEL_EXPENSE_CATEGORY = `${BASE_URL}/api/hostel_expenses_category/list`;
export const CREATE_HOSTEL_EXPENSE_CATEGORY = `${BASE_URL}/api/hostel_expenses_category/save`;
export const EDIT_HOSTEL_EXPENSE_CATEGORY = `${BASE_URL}/api/hostel_expenses_category/{categoryId}/edit`;
export const GET_HOSTEL_EXPENSE_LIST = `${BASE_URL}/api/hostel-expenses/get`;
export const CREATE_HOSTEL_EXPENSE = `${BASE_URL}/api/hostel-expenses/save`;
export const EDIT_HOSTEL_EXPENSE = `${BASE_URL}/api/hostel-expenses/{expensesId}/edit`;
// Hostel management - Beds
export const GET_ALL_BEDS = `${BASE_URL}/api/hostel-beds/list`;
export const CREATE_BED = `${BASE_URL}/api/hostel-beds/save`;
export const UPDATE_BED = `${BASE_URL}/api/hostel-beds/{bedId}/update`;
export const ASSIGN_ROOM = `${BASE_URL}/api/hostel-room/{studentId}/assign-room`;

// Hostel Management - Complaints
export const GET_ALL_COMPLAINTS = `${BASE_URL}/api/complaints/list`;
export const UPDATE_COMPLAINT_STATUS = `${BASE_URL}/api/complaints/update-status`;
export const CREATE_COMPLAINT = `${BASE_URL}/api/complaints/raise`;

// Hostel Management - Students
export const GET_ALL_HOSTEL_STUDENTS = `${BASE_URL}/api/hostel-room/student/hostel-details`;

// Hostel Kitchen Management

// Kitchen Expenses
export const GET_ALL_KITCHEN_EXPENSES = `${BASE_URL}/api/kitchen/get-kitchen-expenses`;
export const CREATE_KITCHEN_EXPENSES = `${BASE_URL}/api/kitchen/save-expenses`;
export const EDIT_KITCHEN_EXPENSES = `${BASE_URL}/api/kitchen/{expensesId}/edit-expenses`;

// Kitchen Items
export const GET_KITCHEN_ITEMS = `${BASE_URL}/api/kitchen/getAllKitchenItems`;
export const CREATE_KITCHEN_ITEM = `${BASE_URL}/api/kitchen/create`;
export const EDIT_KITCHEN_ITEM = `${BASE_URL}/api/kitchen/{id}/edit`;
export const DELETE_KITCHEN_ITEM = `${BASE_URL}/api/kitchen/{Id}/delete_kitchenItems`;

// Daily Consmption
export const GET_DAILY_CONSUMPTION = `${BASE_URL}/api/dailyConsumption/getAll`;
export const UPDATE_DAILY_CONSUMPTION = `${BASE_URL}/api/dailyConsumption/{expensesId}/update`;
export const CREATE_DAILY_CONSUMPTION = `${BASE_URL}/api/dailyConsumption/create`;
export const GET_REMAINING_ITEMS = `${BASE_URL}/api/dailyConsumption/remainingItems`;
export const GET_DAILY_CONSUMPTION_REPORT = `${BASE_URL}/api/dailyConsumption/report`;

//emergency Announcement
export const CREATE_EMERGENCY_ANNOUNCEMENT = BASE_URL + `/api/emergency/create`;
export const EMERGENCY_ANNOUNCMENT_LIST = BASE_URL + `/api/emergency/list`;

export const EDIT_EMERGENCY_ANNOUNCEMENT = BASE_URL + `/api/emergency/`;
export const EDIT_EMERGENCY_ANNOUNCEMENT_ENDPOINT = `/edit`;

export const EDIT_ANNOUNCEMENT_TYPE = BASE_URL + `/api/announcement/`;
export const EDIT_ANNOUNCEMENT_TYPE_ENDPOINT = `/edit-announcement-type`;

export const DELETE_ANNOUNCEMENT = BASE_URL + `/api/announcement/`;
export const DELETE_ANNOUNCEMENT_ENDPOINT = `/delete-announcement-type`;

// /api/announcement/1/delete-announcement-type
//inventry stocks
export const STOCKS_LIST = BASE_URL + `/api/assetManagement/get-all-stock`;

//policy
export const POLICY_LIST = BASE_URL + `/api/policies/list`;
export const CREATE_POLICY = BASE_URL + `/api/policies/create`;
export const DELETE_POLICIES = BASE_URL + `/api/policies/`;
export const DELETE_POLICIES_ENDPOINT = `/delete`;
export const EDIT_POLICIES = BASE_URL + `/api/policies/`;
export const EDIT_POLICIES_ENDPOINT = `/edit`;
// /api/policies/{id}/edit

//notification

export const NOTIFICATION = BASE_URL + `/api/notification/`;
export const NOTIFICATION_ENDPOINT = `/getAllNotifications`;
export const NOTIFICATION_UPDATE_ENDPOINT = `/updateStatus`;
export const MARK_AS_READ = `mark_all_as_read`;
//reimburesement

export const REIMBURESEMENT_lIST = BASE_URL + `/api/reimbursement/list`;
export const REIMBURESEMENT_STATUS_UPDATE = BASE_URL + `/api/reimbursement/`;
export const REIMBURESEMENT_STATUS_UPDATE_ENDPOINT = `/update-status`;

//LOP

export const GET_LOP_LIST = BASE_URL + `/api/staff/findAllStaffLops`;

//student fee

export const TERMS_BY_CLASSID = BASE_URL + `/api/tuitionFee/`;
export const TERMS_BY_CLASSID_ENDPOINT = `/getTermsByClassId`;

export const REMINDER_STUDENT_FEE = BASE_URL + `/api/studentFee/`;
export const REMINDER_STUDENT_FEE_ENDPOINTS = `/remainder-date`;
export const REMINDER_CUSTOM_STUDENT_FEE_ENDPOINTS = "/custom-reminder-date";

// /api/student/uploadDocumentRequest
export const REQUEST_UPLOAD_STUDENT_DOCS =
  BASE_URL + `/api/student/uploadDocumentRequest`;

// http://localhost:8081/v1.0/instAdmin/api/questionPaper/1/getQuestions?subjectId=1
export const GET_QUESTIONPAPER = BASE_URL + `/api/questionPaper/`;
export const GET_QUESTIONPAPER_ENDPOINT = `/getQuestions`;

export const DOWNLOAD_QUESTIONPAPER =
  BASE_URL + `/api/questionPaper/generate-question-paper`;

export const PAYMENTLINK = BASE_URL + `/api/payments/fees/students/`;
export const PAYMENTLINK_ENDPOINT = `/payment-links`;

//Social Media

export const SOCIAL_MEDIA_SETTINGS =
  AI_SERVICE_BASE_URL + `/api/custom-bot/uploaded-posters-count`;
export const GET_POSTERS_LIST = AI_SERVICE_BASE_URL + `/api/posters/list`;
export const CREATE_POSTER = AI_SERVICE_BASE_URL + `/api/posters/save`;
export const CREATE_AI_IMAGE =
  AI_SERVICE_BASE_URL + `/api/custom-bot/generate-image`;
export const POST_ON_FACEBOOK =
  AI_SERVICE_BASE_URL + `/api/custom-bot/post-to-facebook`;
export const POST_ON_INSTAGRAM =
  AI_SERVICE_BASE_URL + `/api/custom-bot/post-to-instagram`;

// Storage urls

export const downloadFile = `${BASE_URL}/api/storage/download`;

// Ai urls
export const AI_TEXT_URL = `${AI_BASE_URL}/api/custom-bot/chat`;

//online payment
export const ENCRYPT_PAYMENT_DATA = `https://pay.eaglemountsoft.com/payment/encrypt`;
export const INITIATE_PAYMENT_DATA = `https://pay.eaglemountsoft.com/payment/initiate`;

//admission
export const ENQUIRY_LISTS =
  ADMISSION_BASE_URL + `/api/enquiry-status/enquiry-links-list`;

//excel urls
export const EXPORT_STUDENT_EXCELL = BASE_URL + `/api/student/export-excel`;
export const UPLOAD_STUDENT_EXCELL = BASE_URL + `/api/student/uploadExcelFile`;
export const EXPORT_STAFF_EXCELL = `/api/staff/export-excel`;
export const SYLLABUS_EXPORT = `/api/syllabus/exportSyllabus`;
export const SYLLABUS_UPLOAD_EXCELL = BASE_URL + `/api/syllabus/upload-file`;
export const GENERATE_REPORT_CARD =
  BASE_URL + `/api/payments/fees/generateReport`;
export const STUDENT_DOWNLOAD = BASE_URL + `/api/storage/download`;
export const GETGENDERBYCLASSID =
  BASE_URL + `/api/dashBoard/getGenderWiseCount`;
export const UPLOAD_STUDENT_EXCELL_ENDPOINT = `/api/student/uploadExcelFile`;
export const STUDENT_EXCELL_DOWNLOAD = `/api/student/export-excel`;


//report Apis
export const GET_FEE_INFORMATION = BASE_URL + `/api/reports/{classId}/getFeeInfo`;
export const EXPORT_EXCEL = `/api/reports/export-excel`
export const GENERATE_STUDENT_ATTENDANCE_REPORT = BASE_URL + "/api/reports/{classId}/export-student-attendance/{rollNo}";
export const GENERATE_STUDENT_ACADEMIC_REPORT = BASE_URL + "/api/reports/{classId}/export-student-academic-report/{rollNo}";
export const GENERATE_CLASS_ACADEMIC_REPORT = BASE_URL + "/api/reports/{classId}/export-academic-reports";
export const GENERATE_CLASS_ATTENDANCE_REPORTS = BASE_URL + "/api/reports/{classId}/export-attendance-reports";


// store Apis

export const SAVE_STORE_DETAILS = EDPEDIA_STORE_BASE_URL + `/api/store/save`;


// study plan apis

//Daily Notes
export const CREATE_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/send-daily-notes";
export const UPDATE_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{dailyNotesId}/update-send-daily-notes";
export const GET_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{classId}/get-daily-notes";
export const DELETE_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{dailyNotesId}/delete-daily-notes"

// Diary Note
export const CREATE_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/send-diary-note";
export const UPDATE_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{diaryNoteId}/update-send-diary-note";
export const GET_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{classId}/get-diary-note";
export const DELETE_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{diaryNoteId}/delete-diary-note";

// NEW Fee Management 

// Fee Components
export const GET_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-components/get";
export const CREATE_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-components/create";
export const CREATE_DEFAULT_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-components/create-defaults";
export const EDIT_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-components/{feeComponentId}/edit";
export const DELETE_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-components/{feeComponentId}/delete";

// Class Fee Components
export const GET_CLASS_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee/by-class/{classId}";
export const ADD_CLASS_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee/add";
export const EDIT_CLASS_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee/{classFeeComponentId}";
export const DELETE_CLASS_FEE_COMPONENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee/{classFeeComponentId}";

// InstallmentNumber
export const GET_ALL_CLASS_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee-instalments";
export const CREATE_CLASS_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee-instalments";
export const EDIT_CLASS_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee-instalments/{id}";
export const DELETE_CLASS_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/class-fee-instalments/{id}";

// Installment Plans
export const GENERATE_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans/generate";
export const GET_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans/class/{classId}";
export const GET_SPECIFIC_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans/{id}";
export const CREATE_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans";
export const EDIT_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans/{id}";
export const DELETE_INSTALLMENT_PLANS = FEE_MANAGEMENT_BASE_URL + "/api/installment-plans/{id}";

// Fee Installment Master
export const GET_INSTALLMENTS_MASTER = FEE_MANAGEMENT_BASE_URL + "/api/fee-installments-master/class/{classId}";
export const CREATE_INSTALLMENTS_MASTER = FEE_MANAGEMENT_BASE_URL + "/api/fee-installments-master";
export const EDIT_INSTALLMENTS_MASTER = FEE_MANAGEMENT_BASE_URL + "/api/fee-installments-master/{id}";
export const DELETE_INSTALLMENTS_MASTER = FEE_MANAGEMENT_BASE_URL + "/api/fee-installments-master/{id}";

// Fee Discounts
export const GET_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-discounts/class/{classId}";
export const CREATE_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-discounts";
export const EDIT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-discounts/{id}";
export const DELETE_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/fee-discounts/{id}";

// LR Payments
export const GET_LR_PAYMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payments/class/{classId}";
export const CREATE_LR_PAYMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payments";
export const EDIT_LR_PAYMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payments/{id}";
export const DELETE_LR_PAYMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payments/{id}";

// LR Installments
export const GET_LR_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payment-installments/class/{classId}";
export const CREATE_LR_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payment-installments";
export const EDIT_LR_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payment-installments/{id}";
export const DELETE_LR_INSTALLMENTS = FEE_MANAGEMENT_BASE_URL + "/api/lr-payment-installments/{id}";

// Student Fee BreakUp
export const GENERATE_STUDENT_FEE_BREAKUP_FOR_CLASS = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/{classId}";
export const GET_STUDENT_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/class/{classId}";
export const CREATE_STUDENT_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup";
export const EDIT_STUDENT_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/{id}";
export const UPDATE_STUDENT_FEE_BREAKUP_AMOUNT = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/{id}/update-amount";
export const PAY_STUDNET_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/{id}/update-paid";
export const DELETE_STUDENT_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/{id}";

// Student Installment Breakup
export const GET_STUDENT_INSTALLMENT_FEE_BREAKUP = FEE_MANAGEMENT_BASE_URL + "/api/student-fee-breakup/installment-breakup/class/{classId}";

// Student Fee Discounts
export const ADD_STUDENT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/students-fee-discounts/student-discount";
export const GET_STUDENT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/students-fee-discounts/class/{classId}";
export const CREATE_STUDENT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/students-fee-discounts";
export const EDIT_STUDENT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/students-fee-discounts/{id}";
export const DELELTE_STUDENT_FEE_DISCOUNTS = FEE_MANAGEMENT_BASE_URL + "/api/students-fee-discounts/{id}";

// Advanced Fee Configuration
export const GET_ADVANCED_FEE_CONFIGURATION = FEE_MANAGEMENT_BASE_URL + "/api/advanced-fee/class/{classId}";
export const CREATE_ADVANCED_FEE_CONFIGURATION = FEE_MANAGEMENT_BASE_URL + "/api/advanced-fee";
export const EDIT_ADVANCED_FEE_CONFIGURATION = FEE_MANAGEMENT_BASE_URL + "/api/advanced-fee/{id}";
export const DELELTE_ADVANCED_FEE_CONFIGURATION = FEE_MANAGEMENT_BASE_URL + "/api/advanced-fee/{id}";

// Student Transactions
export const CREATE_STUDENT_FEE_TRANSACTION = FEE_MANAGEMENT_BASE_URL + "/api/transactions";
export const UPDATE_STUDENT_FEE_TRANSACTION = FEE_MANAGEMENT_BASE_URL + "/api/transactions/{transactionId}/correct";
export const GET_STUDENT_FEE_TRANSACTION = FEE_MANAGEMENT_BASE_URL + "/api/transactions/students/{studentId}/transactions";
export const CLEAR_STUDENT_FEE_FUNDS = FEE_MANAGEMENT_BASE_URL + "/api/transactions/{studentId}/clear-remaining-funds";

// Special Fee
export const GET_CLASS_SPECIAL_FEES = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/class/{classId}/students";
export const GET_SPECIAL_FEES = FEE_MANAGEMENT_BASE_URL + "/api/special-fee";
export const EDIT_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/{id}";
export const CREATE_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee";
export const DELETE_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/{id}";

// Student Special Fee
export const GENERATE_CLASS_STUDENT_SPECIAL_FEES = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/generate/{id}";
export const CREATE_STUDENT_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/student";
export const GET_CLASS_STUDENT_SPECIAL_FEES = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/class/{id}/students";
export const EDIT_STUDENT_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/student/{id}";
export const DELETE_STUDENT_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/student/{id}";
export const PAY_STUDENT_SPECIAL_FEE = FEE_MANAGEMENT_BASE_URL + "/api/special-fee/student/{id}/pay";


// Finance
// Lenders
export const GET_LENDERS = ACCOUNTS_BASE_URL + "/api/lenders";
export const CREATE_LENDERS = ACCOUNTS_BASE_URL + "/api/lenders";
export const EDIT_LENDERS = ACCOUNTS_BASE_URL + "/api/lenders/{id}";
export const DELETE_LENDERS = ACCOUNTS_BASE_URL + "/api/lenders/{id}";

// Loans
export const GET_LOANS = ACCOUNTS_BASE_URL + "/api/loans";
export const CREATE_LOAN = ACCOUNTS_BASE_URL + "/api/loans";
export const EDIT_LOAN = ACCOUNTS_BASE_URL + "/api/loans/{id}";
export const DELETE_LOAN = ACCOUNTS_BASE_URL + "/api/loans/{id}";
export const GET_LOAN_DETAILS = ACCOUNTS_BASE_URL + "/api/loans/{id}/getLoanOverAllDetails"

// Repayments
export const GET_REPAYMENTS = ACCOUNTS_BASE_URL + "/api/repayment-txn";
export const CREATE_REPAYMENT = ACCOUNTS_BASE_URL + "/api/repayment-txn";
export const EDIT_REPAYMENT = ACCOUNTS_BASE_URL + "/api/repayment-txn/{id}";
export const DELETE_REPAYMENT = ACCOUNTS_BASE_URL + "/api/repayment-txn/{id}";


// Terms
export const GET_TERMS = ACCOUNTS_BASE_URL + "/api/loan-terms";
export const CREATE_TERMS = ACCOUNTS_BASE_URL + "/api/loan-terms";
export const EDIT_TERMS = ACCOUNTS_BASE_URL + "/api/loan-terms/{id}";
export const DELETE_TERMS = ACCOUNTS_BASE_URL + "/api/loan-terms/{id}";

// Loan Documents
export const GET_LOAN_DOCUMENTS = ACCOUNTS_BASE_URL + "/api/loan_docs";
export const CREATE_LOAN_DOCUMENT = ACCOUNTS_BASE_URL + "/api/loan_docs";
export const EDIT_LOAN_DOCUMENT = ACCOUNTS_BASE_URL + "/api/loan_docs/{id}";
export const DELETE_LOAN_DOCUMENT = ACCOUNTS_BASE_URL + "/api/loan_docs/{id}";

// Borrowers
export const GET_BORROWERS = ACCOUNTS_BASE_URL + "/api/borrowers";
export const CREATE_BORROWERS = ACCOUNTS_BASE_URL + "/api/borrowers";
export const EDIT_BORROWERS = ACCOUNTS_BASE_URL + "/api/borrowers/{id}";
export const DELETE_BORROWERS = ACCOUNTS_BASE_URL + "/api/borrowers/{id}";


// AsmissionsAcademicUrls

export const CREATE_CLASSES_FROM_PREVIOUS_YEAR = ADMISSION_BASE_URL + "/api/classes/create-from-previous-year";
export const STAGED_CLASSES = ADMISSION_BASE_URL + "/api/classes";

export const GET_STAGED_STUDENTS = ADMISSION_BASE_URL + "/api/stg_student/getAllStudents";


export const ADMISSION_DASHBOARD_DATA = ADMISSION_BASE_URL + "/api/dashboard";


//Staff Salary Structure

export const CATEGORY_URL = ACCOUNTS_BASE_URL + "/api/salary-categories";

export const COMPONENT_URL = ACCOUNTS_BASE_URL + "/api/salary_component";

export const SALARY_CONFIG_URL = ACCOUNTS_BASE_URL + "/api/staff-salary-configs"

export const SALARY_GRADE = ACCOUNTS_BASE_URL + "/api/salary-grade";

export const SALARY_STRUCTURE = ACCOUNTS_BASE_URL + "/api/staff-salary-structures";

export const SALARY_PAID_STATUS = ACCOUNTS_BASE_URL + "/api/staff-salary-paid-status";


//homework
export const CREATE_HOMEWORK = `${STAFF_BASE_URL}/api/homeWork/create`;
export const GET_CLASS_HOMEWORK = STAFF_BASE_URL + "/api/homeWork/";
export const GET_CLASS_HOMEWORK_ENDPOINT = "/getHomeWork";
export const DELETE_TASK_ENDPOINT = "/removeTask";

//Fee Summary

export const GET_FEE_SUMMARY_DETAILS = FEE_MANAGEMENT_BASE_URL + "/api/fee-summary/";

//Lead Management

export const GET_ALL_INSTITUTE_LEADS = BASE_URL + "/api/leads";
export const UPLOAD_LEAD_EXCEL = "/api/leads/uploadExcelFile";

//consent details

export const GET_ALL_CONSENTS = BASE_URL + "/api/consent-details/get";
export const SEND_CONSENT_FORM = BASE_URL + "/api/consent-details/send";