import { useDispatch, useSelector } from "react-redux";
import { fetchClasses, getClassList } from "../../../store/classSlice";
import { fetchSubjectList, getSubjectList } from "../../../store/subjectSlilce";
import { useEffect } from "react";
import { fetchStaffList, getStaffList } from "../../../store/staffSlice";
import { DepartmentService } from "../../../Services/DepartmentService";
import { designationService } from "../../../Services/DesignationService";
import { fetchCustomFeaturesList, getCustomFeaturesList } from "../../../store/customFeaturesSlice";
import { examService } from "../../../Services/ExamService";
import { tuitionFeeService } from "../../../Services/PaymentService";
import { FeeManagementService } from "../../../Services/FeeManagementService";

const LoadData = () => {
  const dispatch = useDispatch();
  const classList = useSelector(getClassList);
  const subjectList = useSelector(getSubjectList);
  const staffList = useSelector(getStaffList);
  const customFeaturesList = useSelector(getCustomFeaturesList);
  const classData = JSON.parse(sessionStorage.getItem("classlist"));
  const staffData = JSON.parse(sessionStorage.getItem("stafflist"));
  const subjectData = JSON.parse(sessionStorage.getItem("subjectlist"));

  useEffect(() => {
    if (staffList.length <= 0) {
      dispatch(fetchStaffList());
    }
    if (classList.length <= 0) {
      dispatch(fetchClasses());
    }
    if (subjectList.length <= 0) {
      dispatch(fetchSubjectList());
    }
    if (customFeaturesList.length <= 0) {
      dispatch(fetchCustomFeaturesList());
    }

    const storedDepartments = JSON.parse(sessionStorage.getItem("departments"));
    const storedDesignations = JSON.parse(
      sessionStorage.getItem("designations")
    );
    const storedExamGrades = sessionStorage.getItem("examGrades");
    const storedFeeComponents = sessionStorage.getItem("feeComponents")

    if (!storedDepartments) {
      fetchAndStoreDepartments();
    }

    if (!storedDesignations) {
      getDesignationsList();
    }
    if (!storedExamGrades) {
      createExamGradesList();
    }
    if (!storedFeeComponents) {
      createDefaultFeeComponents();
    }
  }, [classList, staffList, subjectList]);

  const createDefaultFeeComponents = () => {
    tuitionFeeService.createDefaultFeeComponents().then(response => {}).catch(error => {});
      FeeManagementService.createDefaultFeeComponent().then(response => {}).catch(error => {});
      sessionStorage.setItem("feeComponents", "Initial feeComponents are created.");
  }

  const getDesignationsList = () => {
    designationService
      .getAllDesignations()
      .then((response) => {
        if (response.data.length > 0) {
          sessionStorage.setItem("designations", JSON.stringify(response.data));
        } else {
          getDesignationsListFromS3();
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };
  const createExamGradesList = () => {
    examService.createInitialExamGrades()
      .then((response) => {
        sessionStorage.setItem("examGrades", "Initial examGrades are created.");
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const getDesignationsListFromS3 = () => {
    designationService
      .getAllDesignationsFromS3()
      .then((response) => {
        getDesignationsList();
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const fetchAndStoreDepartments = () => {
    DepartmentService.getDepartments()
      .then((res) => {
        if (res.data.length > 0) {
          sessionStorage.setItem("departments", JSON.stringify(res.data));
        } else {
          departmentListFromS3();
        }
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  };

  const departmentListFromS3 = () => {
    DepartmentService.departmentListFromS3().then((res) => {
      fetchAndStoreDepartments();
    });
  };
};

export default LoadData;
