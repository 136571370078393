import './Footer.scss'
const PrivacyPolicy=()=>{
    return (
        <main>
             <section className="privacy-policy-area pt-120 pb-120">
        <div className="container">
          <div className="inner-privacy-policy-wrap blog-widget">
            <div className="row justify-content-center">
              <div className="col-12">
              <div className="terms-conditions-content">
  <h1>Privacy Policy</h1>
  <p>
    Welcome to EdPedia! This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
  </p>
  
  <dl>
    <dt>1. Information We Collect</dt>
    <dd>
      <h5>Personal Data</h5>
      <p>
        When you register on our site, subscribe to our newsletter, fill out a form, or enter information on our site, we may collect personal data such as your name, email address, phone number, and other details necessary for providing our services.
      </p>
      
      <h5>Non-Personal Data</h5>
      <p>
        We may also collect non-personal information such as browser type, operating system, and IP address to help us understand how our visitors use our site.
      </p>
    </dd>

    <dt>2. How We Use Your Information</dt>
    <dd>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li>To personalize your experience and respond better to your individual needs.</li>
        <li>To improve our website and service offerings based on the information and feedback we receive from you.</li>
        <li>To administer a contest, promotion, survey, or other site feature.</li>
        <li>To send periodic emails regarding your order or other products and services.</li>
      </ul>
    </dd>

    <dt>3. How We Protect Your Information</dt>
    <dd>
      <p>
        We implement various security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no data transmission over the Internet or wireless network can be guaranteed to be 100% secure.
      </p>
    </dd>

    <dt>4. Sharing Your Information</dt>
    <dd>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
      </p>
    </dd>

    <dt>5. Third-Party Links</dt>
    <dd>
      <p>
        Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
      </p>
    </dd>

    <dt>6. Cookies</dt>
    <dd>
      <p>
        We use cookies to enhance your experience, gather general visitor information, and track visits to our website. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings.
      </p>
    </dd>

    <dt>7. Changes to This Privacy Policy</dt>
    <dd>
      <p>
        EdPedia reserves the right to update this privacy policy at any time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.
      </p>
    </dd>

    <dt>8. Contact Us</dt>
    <dd>
      <p>If you have any questions about this privacy policy, please contact us at:</p>
      <p>Ph: +91-78010 64510</p>
      <p>Email: contact@eaglemountsoft.com</p>
      <p>Address: Plot No: 8-1-284/OU/623, OU Colony, Dream Valley Road, Manikonda, Shaikpet, Hyderabad Telangana - 500008.</p>
    </dd>
  </dl>
</div>

              </div>
            </div>
          </div>
        </div>
      </section>
        </main>
    )
}

export default PrivacyPolicy