import { Col, Form, Modal, Row } from 'antd';
import React, { useEffect } from 'react'
import FormButton from '../../Common/formButtons/FormButton';
import SelectFloatLabel from '../../Common/FloatLabel/Select';
import InputFloatLabel from '../../Common/FloatLabel/Input';
import { modalMediumWidth, modalSize } from '../../../Constants/StyleConstants';
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { dynamicErrorMsg, errorNotification, feeDiscoutTypes, successNotification } from '../../../Constants/Utils';
import { fixed, PERCENTAGE } from '../../../Constants/StringConstants';

const FeeDiscountsModal = ({
    discountModal,
    setDiscountModal,
    record,
    setRecord,
    classId,
    getFeeDiscounts,
    activeYear
}) => {
    const [feeDiscountForm] = Form.useForm();

    useEffect(() => {
        if (record !== null) {
            feeDiscountForm?.setFieldsValue({
                discountValue: record?.discountValue,
                discountType: record?.discountType,
                eligibilityCriteria: record?.eligibilityCriteria,
            })
        }
    }, [])

    const handleCancel = () => {
        setDiscountModal(false);
        setRecord(null);
        feeDiscountForm.resetFields();
    }
    const onFinish = values => {
        const payload = {
            ...values,
            classId,
        }
        if (record) {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.editFeeDiscounts(record?.id, payload, params)
                .then(response => {
                    handleCancel();
                    successNotification("Fee discount updated successfully.");
                    getFeeDiscounts();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Update", "Fee Discount"))
                })
        }
        else {
            const params = {
                isStaging: !activeYear?.isPresentYear
            }
            FeeManagementService.createFeeDiscounts(payload, params)
                .then(response => {
                    handleCancel();
                    successNotification("Fee discount created successfully.");
                    getFeeDiscounts();
                })
                .catch(error => {
                    errorNotification(dynamicErrorMsg(error, "Create", "Fee Discount"))
                })

        }
    }

    return (
        <Modal
            title={record ? "Edit Fee Discount" : "Create Fee Discount"}
            open={discountModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
        >
            <Form
                form={feeDiscountForm}
                onFinish={onFinish}
                onValuesChange={(changedValues) => {
                    if (changedValues?.discountType) {
                        feeDiscountForm.validateFields(["discountValue"]);
                    }
                }}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                        <Form.Item
                            name="discountType"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                options={feeDiscoutTypes?.map(type => ({ label: type, value: type }))}
                                label="Select Type"
                            ></SelectFloatLabel>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="eligibilityCriteria"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Eligibility Criteria"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="discountValue"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                                {
                                    validator(_, value) {
                                        const type = feeDiscountForm.getFieldValue("discountType");
                                        if (type === fixed || !type || !value) return Promise.resolve();
                                        if (
                                            !isNaN(value) &&
                                            value >= 0 &&
                                            value <= 100 &&
                                            type === PERCENTAGE
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "invalid Percentage(%)"
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <InputFloatLabel
                                label="Discount"
                                type="number"
                            />
                        </Form.Item>
                    </Col>


                </Row>
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>

        </Modal>
    )
}

export default FeeDiscountsModal