import { useEffect, useState } from 'react'
import { AccountsService } from '../../../Services/AccountsService'
import { Button, Modal, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { dynamicErrorMsg, errorNotification, successNotification } from '../../../Constants/Utils';
import "../finance.scss";
import RepaymentModal from './RepaymentModal';
import { ONGOING } from '../../../Constants/StringConstants';

const Repayments = () => {
  const [loansData, setLoansData] = useState();
  const [repaymentsData, setRepaymentsData] = useState();
  const [repaymentModal, setRepaymentModal] = useState(false);
  const [record, setRecord] = useState(null);
  useEffect(() => {
    getLoansData();
    getRepaymentsData();
  }, [])
  const getRepaymentsData = () => {
    AccountsService.getRepayments()
      .then(response => {
        setRepaymentsData(response?.data);
      })
      .catch(error => { })
  }
  const getLoansData = () => {
    AccountsService.getLoans()
      .then(response => {
        setLoansData(response?.data);
      })
      .catch(error => { })
  }
  const columns = [
    {
      title: "S.No",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Lenders",
      render: (_, record) => record?.loanResponseDTO?.loanNumber,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Mode",
      dataIndex: "mode",
      key: "mode",
    },
    {
      title: "Date",
      dataIndex: "txnDate",
      key: "txnDate",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space>
          <EditOutlined
            onClick={() => {
              setRecord(record);
              setRepaymentModal(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDeleteRepayment(record)} />
        </Space>
      ),
    },
  ];
  const handleDeleteRepayment = record => {
    Modal.confirm({
      title: "Are you sure remove this payment ?",
      onOk: () => {
        AccountsService.deleteRepayment(record?.id)
          .then(response => {
            successNotification(response?.message);
            getRepaymentsData();
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "payment")));
      }
    })
  }
  // const filteredLoansData = () => {
  //   return loansData?.filter(loan => loan?.status === ONGOING);
  // }
  return (
    <main>
      <div className="flex-between">
        <h1>Loan Repayments Details</h1>
        <Button className="blue-button" onClick={() => setRepaymentModal(true)}><PlusOutlined /> Add</Button>
      </div>
      <Table
        pagination={{pageSize: 30}}
        dataSource={repaymentsData}
        columns={columns}
      />
      {repaymentModal && (
        <RepaymentModal
          repaymentModal={repaymentModal}
          setRepaymentModal={setRepaymentModal}
          record={record}
          setRecord={setRecord}
          loansData={loansData}
          getRepaymentsData={getRepaymentsData}
        />
      )}
    </main>
  )
}

export default Repayments