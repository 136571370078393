import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Table, notification } from "antd";
import axios from "axios";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import "./FeeCollectionReports.scss";
import { StudentFeeService } from "../../Services/StudentFeeService";
import { Option } from "antd/lib/mentions";
import { UploadAndExportFileService } from "../../Services/UploadFile";
import { dynamicErrorMsg, fullMonthNameList } from "../../Constants/Utils";
import { DateConverter } from "../../Utils/DateConverter";
import { GENERATE_REPORT_CARD } from "../../Constants/Constants";

const FeeCollectionReports = () => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [selectedDate, setSelectedDate] = useState(null);
  const accessToken = Cookies.get("accessToken");

  // const generateReport = () => {
  //   return axios.get(
  //     `${API_URL}/api/payments/fees/generateReport?classId=${selectedSection?.classId}&date=${selectedDate}`,
  //     {
  //       responseType: "arraybuffer",
  //       headers: {
  //         Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
  //       },
  //     }
  //   );
  // };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  const handleFeeCollectionReport = () => {
    const params = {};

    // Only add properties if they are defined
    if (selectedSection && selectedSection.classId) {
      params.classId = selectedSection.classId;
    }

    if (selectedDate) {
      params.date = selectedDate;
    }
    else {
      if (selectedMonth) {
        params.month = selectedMonth;
      }

      if (selectedYear) {
        params.year = selectedYear;
      }
    }

    UploadAndExportFileService.generateReportCard(params)
      .then((response) => {
        console.log(response)
        if (response) {
          downloadFile(response, `Fee_collection_report.pdf`);
          notification.success({
            message: "The File is downloading...",
            description: `Fee collection report downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Generate", "Report Card");

        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const getStudentsList = () => { };

  const handleDateChange = (date) => {
    if (date) {
      const dateString = moment(date).format("YYYY-MM-DD");
      setSelectedDate(dateString);
    }
    else {
      setSelectedDate(null);
    }
  };
  const onClassChange = (value) => {
    setSelectedClass(value);
  };
  const onSectionChange = (value) => {
    setSelectedSection(value);
  };
  const handleMonthChange = (value) => {
    if (!value) {
      setSelectedDate(null);
    }
    setSelectedMonth(value);
  };
  const getLastThreeYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 3 }, (_, index) => currentYear - index).map(
      (year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      )
    );
  };
  const getMonthOptions = () => {
    return fullMonthNameList.map((month, index) => (
      <Option key={index} value={month}>
        {month}
      </Option>
    ));
  };
  const handleYearChange = (value) => {
    setSelectedYear(value);
  };
  const columns = [
    {
      title: "Roll No.",
      dataIndex: "rollNo",
      key: "rollNo",
      sorter: (a, b) => a.rollNo - b.rollNo,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
      sorter: (a, b) => a.studentName.localeCompare(b.studentName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Paid Amount (INR)",
      dataIndex: "paidAmount",
      key: "paidAmount",
      sorter: (a, b) => a.paidAmount - b.paidAmount,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      sorter: (a, b) => a.paymentMode.localeCompare(b.paymentMode),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      sorter: (a, b) =>
        new Date(a.transactionDate) - new Date(b.transactionDate),
      sortDirections: ["ascend", "descend"],
      render: (enquiryDate) => DateConverter(enquiryDate) || "-",
    },
  ];
  const getFeeCollectionReports = () => {
    let params;
    if (selectedDate) {
      params = {
        classId: selectedSection?.classId,
        date: selectedDate,
      }
    }
    else {
      params = {
        classId: selectedSection?.classId,
        month: selectedMonth,
        year: selectedYear,
      };
    }
    StudentFeeService?.studentFeeCollection(params)
      .then((response) => {
        setStudentsList(response?.data);
      })
      .catch((error) => {
        console.log("unable to fetch info", error);
      });
  };
  useEffect(() => {
    getFeeCollectionReports();
  }, [selectedMonth, selectedYear, selectedSection, selectedDate]);

  const disabledDate = (current) => {
    if (!current) return false;
    let monthIndex;
    fullMonthNameList.forEach((month, index) => {
      if (month === selectedMonth) monthIndex = index;
    })
    return !(current.year() === selectedYear && current.month() === monthIndex);
  };

  return (
    <main>
      <div className="main">
        <h1>Fee Collection Reports</h1>
        <div className="inner">
          <ClassDropdown
            onClassChange={onClassChange}
            selectedClass={selectedClass}
          />
          <SectionDropdown
            classValue={selectedClass}
            onSectionChange={onSectionChange}
          // selectedSection={selectedSection}
          />
          <Select
            style={{ width: 120, margin: 5 }}
            value={selectedYear}
            onChange={handleYearChange}
            placeholder="Select Year"
          >
            {getLastThreeYears()}
          </Select>
          <Select
            style={{ width: 150, margin: 5 }}
            value={selectedMonth}
            onChange={handleMonthChange}
            placeholder="Select Month"
            allowClear
          >
            {getMonthOptions()}
          </Select>

          {selectedMonth && <DatePicker
            style={{ margin: 5 }}
            onChange={handleDateChange}
            disabledDate={disabledDate}
          // value={selectedDate}
          // format="YYYY-MM-DD"
          // defaultValue={moment()}
          />}
          <Button
            className="blue-button"
            onClick={handleFeeCollectionReport}
            style={{
              backgroundColor: studentsList.length === 0 ? "grey" : "",
              color: "#fff",
              margin: 10,
            }}
            disabled={studentsList.length === 0}
          >
            Download Report
          </Button>
        </div>
      </div>
      <Table
        dataSource={studentsList}
        columns={columns}
        pagination={false}
        showSorterTooltip={false}
      />
    </main>
  );
};
export default FeeCollectionReports;
