import { Col, Form, Modal, Row } from "antd";
import InputFloatLabel from "../../Common/FloatLabel/InputAll";
import InputFloatLabelAll from "../../Common/FloatLabel/InputAllCharacters";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import FormButton from "../../Common/formButtons/FormButton";
import DatePickerFloatLabel from "../../Common/FloatLabel/Datepicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { modalMediumWidth, modalSize } from "../../../Constants/StyleConstants";
import { FeeManagementService } from "../../../Services/FeeManagementService";
import { handleInvalidInputErrorMessage, successNotification } from "../../../Constants/Utils";

const FeeInstallmentsModal = ({
    installmentModal,
    setInstallmentModal,
    record,
    setRecord,
    classId,
    getInstallmentPlans,
    classInstallments,
    activeYear,
    componentsData,
    installmentsMasterData,
    getInstallmentsMasterData,
}) => {
    const [installmentForm] = Form.useForm();
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        if (record) {
            handleTotalAmount();
        }
    }, [selectedComponents]);

    useEffect(() => {
        if (record) {
            const existingComponents = installmentsMasterData?.map(master => `${master?.feeComponentsResponse?.id}_${master?.feeComponentsResponse?.componentName}`);
            setSelectedComponents(existingComponents);
            installmentForm.setFieldValue("components", existingComponents);
        }
    }, [])

    const handleTotalAmount = () => {
        const values = installmentForm.getFieldsValue();
        let total = 0;
        Object.entries(values).forEach(([key, value]) => {
            if (key?.includes("amount_")) {
                if (parseInt(value)) {
                    total += parseInt(value);
                }
            }
        })
        setTotalAmount(total);
    }

    useEffect(() => {
        if (record) {
            installmentForm.setFieldsValue({
                installmentNo: record?.installmentNo,
                planName: record?.planName,
                installmentTotalAmount: record?.installmentTotalAmount,
                dueDate: record?.dueDate ? moment(record?.dueDate, "DD-MM-YYYY") : null,
            })
        }
    }, [])
    let installmentNumberOptions = [];
    for (let i = 1; i <= classInstallments; i++) {
        installmentNumberOptions?.push({ value: i, label: i })
    }
    const handleCancel = () => {
        setInstallmentModal(false);
        setRecord(null);
        installmentForm.resetFields();
        setSelectedComponents([]);
    }
    const onFinish = values => {
        const dateString = values?.dueDate && moment(values?.dueDate).isValid()
            ? values?.dueDate.format('YYYY-MM-DD')
            : null;
        let masterInstallmentsData = [];
        Object.entries(values)?.forEach(([key, value]) => {
            if (key?.includes("amount_")) {
                masterInstallmentsData.push({
                    componentId: key?.split("_")[1],
                    amount: value,
                })
            }
        })
        const payload = {
            classId,
            installmentTotalAmount: values?.installmentTotalAmount,
            installmentNo: values?.installmentNo,
            planName: values?.planName,
            dueDate: dateString ? new Date(dateString) : null,
            masterInstallmentsData,
        };
        const params = {
            isStaging: !activeYear?.isPresentYear
        }
        if (record) {
            FeeManagementService.editInstallmentPlans(record?.id, payload, params)
                .then(response => {
                    getInstallmentPlans();
                    getInstallmentsMasterData?.();
                    handleCancel();
                    successNotification("Installment updated successfully");
                })
                .catch(error => {
                    handleInvalidInputErrorMessage(error, "Update", "Installment Plan");
                })
        }
        else {
            FeeManagementService.createInstallmentPlans(payload, params)
                .then(response => {
                    getInstallmentPlans();
                    getInstallmentsMasterData?.();
                    handleCancel();
                    successNotification("Installment Created Successfully");
                })
                .catch(error => {
                    handleInvalidInputErrorMessage(error, "Create", "Installment Plan");
                });
        }
    }
    const getInitialComponentAmount = componentId => {
        if (record) {
            return installmentsMasterData?.find(master => master?.feeComponentsResponse?.id === componentId)?.amount;
        }
    }
    return (
        <Modal
            title={record ? `Update Installment - ${record?.installmentNo}` : "Create Installment"}
            open={installmentModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={false}
            width={modalMediumWidth}
            bodyStyle={modalSize}
            centered
        >
            <Form
                form={installmentForm}
                onFinish={onFinish}
                onValuesChange={(changedValues) => {
                    if (Object.keys(changedValues).some(key => key.startsWith("amount_"))) {
                        installmentForm.validateFields(["installmentTotalAmount"]);
                    }
                }}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="installmentNo"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <SelectFloatLabel
                                options={installmentNumberOptions}
                                allowClear={false}
                                label="Installment No."
                                disabled={record != null}
                            ></SelectFloatLabel>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="planName"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                            ]}
                        >
                            <InputFloatLabelAll
                                label="Installment Name"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="installmentTotalAmount"
                            rules={[
                                {
                                    required: true,
                                    message: ""
                                },
                                {
                                    validator(_, value) {
                                        if (
                                            ((!isNaN(value) &&
                                                // value >= 0 &&
                                                value == totalAmount) || totalAmount === undefined || totalAmount === null || isNaN(totalAmount))
                                        ) {
                                            setShowErrorMessage(false);
                                            return Promise.resolve();
                                        }
                                        setShowErrorMessage(true);
                                        return Promise.reject(
                                            new Error(
                                                ""
                                                // "The total fee installment amount does not match the sum of the individual fee components. Please ensure the amounts are correctly allocated."
                                            )
                                        );
                                    },
                                }
                            ]}
                        >
                            <InputFloatLabel
                                label="Total Amount"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="dueDate"
                            rules={[{ required: true, message: "" }]}
                        >
                            <DatePickerFloatLabel
                                allowClear={false}
                                style={{ width: "100%" }}
                                label="Due Date"
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            name="components"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        // initialValue={selectedComponents}
                        >
                            <SelectFloatLabel
                                options={componentsData?.map(component => ({ label: component?.componentName, value: `${component?.id}_${component?.componentName}` }))}
                                onChange={(value) => setSelectedComponents(value)}
                                value={selectedComponents}
                                mode="multiple"
                                label="Select Fee Components"
                            />
                        </Form.Item>
                    </Col>
                    {selectedComponents?.map(componentValue => (
                        <Col span={12}
                            key={componentValue?.split("_")[0]}
                        >
                            <Form.Item
                                name={`amount_${componentValue?.split("_")[0]}`}
                                initialValue={getInitialComponentAmount(parseInt(componentValue?.split("_")[0]))}
                                onChange={handleTotalAmount}
                                rules={[
                                    {
                                        required: true,
                                        message: ""
                                    },
                                ]}
                            >
                                <InputFloatLabel
                                    label={componentValue?.split("_")[1]}
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                    ))}

                </Row>
                {showErrorMessage &&
                    <p className="total-amount-error-message">
                       * The total fee installment amount does not match the sum of the individual fee components. Please ensure the amounts are correctly allocated.
                    </p>
                }
                <FormButton
                    saveText="Save"
                    cancelText="Cancel"
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default FeeInstallmentsModal